import { useProjectContext } from 'shared/_pages/project/provider';
import { Fragment, useMemo } from 'react';
import { DOCUMENT_TYPES_FINISH_FILES, DOCUMENT_TYPES_MEDIA_FILES } from 'shared/constants/pouchDB';
import ProjectFilesGroup, { TITLE_MAP } from 'shared/_model/project-files/project-files-group';
import { Box, Typography } from '@mui/material';
import {
	ProjectFileGroupActionsContainer,
	ProjectFileGroupContainer,
} from 'shared/_model/project-details/styles';
import AddFileButton from 'app/_model/project/components/AddFileButton';
import ActionFileGroupMenu from 'shared/_model/project-files/ActionFileGroupMenu';
import { DocTypeFiles, TDocFile } from 'shared/constants/docs';

export default function ProjectFiles() {
	const { files, addFiles } = useProjectContext();

	const { mediaFiles, finishFiles } = useMemo(() => {
		return {
			mediaFiles: DOCUMENT_TYPES_MEDIA_FILES.map((type) => ({
				type,
				files: files.filter((f) => f.type === type),
			})) as { type: DocTypeFiles; files: TDocFile[] }[], // TODO: Remove `as ...`
			finishFiles: DOCUMENT_TYPES_FINISH_FILES.map((type) => ({
				type,
				files: files.filter((f) => f.type === type),
			})) as { type: DocTypeFiles; files: TDocFile[] }[], // TODO: Remove `as ...`
		};
	}, [files]);

	return (
		<>
			<ProjectFileGroupContainer>
				<Typography noWrap variant="h1">
					Media
				</Typography>
			</ProjectFileGroupContainer>
			<Box sx={(theme) => ({ overflowY: 'auto', paddingBottom: theme.spacing(4 * (2 + 2.5)) })}>
				{mediaFiles.map(({ files: filesInGroup, type }) => (
					<ProjectFilesGroup key={type} files={filesInGroup} type={type} divider />
				))}
				<Box>
					{finishFiles.map(({ files: filesInGroup, type }, index) => (
						<Fragment key={type}>
							<ProjectFileGroupContainer>
								<Typography noWrap variant="h1">
									{TITLE_MAP[type]}
								</Typography>
								<ProjectFileGroupActionsContainer data-box-id="file_type_actions">
									{!!addFiles && <AddFileButton type={type} variation="group" />}
									<ActionFileGroupMenu type={type} />
								</ProjectFileGroupActionsContainer>
							</ProjectFileGroupContainer>
							<ProjectFilesGroup
								key={type}
								files={filesInGroup}
								type={type}
								divider={index !== finishFiles.length - 1}
							/>
						</Fragment>
					))}
				</Box>
			</Box>
		</>
	);
}
