import CloudProvider from 'shared/_model/cloud/provider/CloudProvider'
import { CloudValueS3 } from 'shared/_model/cloud/types'
import { apiS3GetDownloadFileUrl } from 'shared/utils/cloud/cloudS3'

export default class CloudProviderS3 extends CloudProvider<CloudValueS3> {
  public async getUrl(force?: boolean) {
    if (!force && this._url) {
      return this._url
    }

    if (!this._value?.key) {
      return this._url
    }

    const response = await apiS3GetDownloadFileUrl(this._value?.key)
    return response.url || undefined
  }
}
