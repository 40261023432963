import PouchDB from 'app/pouch-db';
import { TDocument } from 'shared/model/doc-base'


async function printDbInfo(db: PouchDB.Database, showError: (e: unknown) => void): Promise<boolean> {
  try {
    const info = await db.info();
    console.debug('Connected to DB', info);
    return true;
  } catch (e) {
    showError(`Fetching DB info, ${e}`);
  }
  return false
}

export async function connectToDB(
  name: string,
  showError: (e: unknown) => void,
  opts?: PouchDB.Configuration.DatabaseConfiguration,
): Promise<PouchDB.Database<TDocument>|undefined> {
  try {
    const db = new PouchDB<TDocument>(name, opts);
    console.log('tst: connectToDB', db);
    if (await printDbInfo(db, showError)) {
      return db;
    }

    try {
      await db.close();
    } catch (e) {
      // nothing
    }
  } catch (e) {
    showError(`Connecting to DB, ${e}`);
  }

  return undefined;
}
