// Let's refresh token before it expires, in sec
const BUFFER = 2 * 60;
// Minimum refresh interval, in sec
const DEFAULT = 10 * 60;

function parseJwt<T>(token: string): T|undefined {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16))
      .slice(-2))
      .join('')
    );

    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error('Couldn\'t parse token', { token }, e);
    return undefined;
  }
}

/** Returns token expire interval in ms */
export function getExpireIn(token: string): number {
  const date = Date.now() / 1000;
  const exp = parseJwt<{ exp: number}>(token)?.exp || date + DEFAULT;

  const expiresIn = Math.trunc(exp - date); // in sec

  return (expiresIn - BUFFER) * 1000;
}
