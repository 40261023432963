import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CONTENT_TYPE } from '../../constants/general';
import { ROUTE } from '../../constants/routes_old';
import { SHARE_TYPES } from '../../constants/share';
import { ShareSettingsButton } from '../ShareSettingsButton';
import S3img from '../S3img/S3img';
import IconFileUploadToYoutube from '../StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';
import styles from './ListContainer.module.css';
import IconUploadedToCloud from '../IconUploadedToCloud/IconUploadedToCloud';
import IconVideoInPlaylist from '../IconVideoInPlaylist/IconVideoInPlaylist';

const getItemLink = (type, item, rest) => {
    switch (type) {
    case CONTENT_TYPE.PROJECT:
        return ROUTE.projects.id(item._id);
    case CONTENT_TYPE.SERIES:
        return ROUTE.series.id(item._id);
    case CONTENT_TYPE.SEASON:
        return ROUTE.series.seasons.episodes.root(rest.seriesId, item._id);
    case CONTENT_TYPE.EPISODE:
        return item.videoId
            ? ROUTE.videos.id(item.videoId)
            : '#';
    case CONTENT_TYPE.AUDIO:
        return ROUTE.audios.id(item._id);
    case CONTENT_TYPE.VIDEO:
        return ROUTE.videos.id(item._id);
    default:
        return '';
    }
};

const getItemTitle = (type, item) => item.title || '(empty)';

const getItemPreview = (type, item) => {
    switch (type) {
    case CONTENT_TYPE.SERIES:
        return (
            <>
                <S3img src={item.thumbnail?.cloud?.s3 || item.image} className={styles.previewImage} />
            </>
        );
    case CONTENT_TYPE.EPISODE:
        return (
            <>
                <S3img
                    src={item.thumbnail?.cloud?.s3 || item.fileDoc?.image}
                    className={styles.previewImage}
                />
            </>
        );
    case CONTENT_TYPE.VIDEO:
        return (
            <>
                <S3img src={item.thumbnail?.cloud?.s3 || item.image} className={styles.previewImage} />
            </>
        );
    case CONTENT_TYPE.AUDIO:
        return (
            <>
                <S3img src={item.thumbnail?.cloud?.s3 || item.image} className={styles.previewImage} />
            </>
        );
    default:
        return '';
    }
};
const getHasPreview = (type) => {
    switch (type) {
    case CONTENT_TYPE.SERIES:
        return true;
    case CONTENT_TYPE.EPISODE:
        return true;
    case CONTENT_TYPE.AUDIO:
        return true;
    case CONTENT_TYPE.VIDEO:
        return true;
    default:
        return false;
    }
};

const getItemIcons = (type, item, rest) => {
    switch (type) {
    case CONTENT_TYPE.PROJECT:
        return <rest.icons item={item} />;
    case CONTENT_TYPE.AUDIO:
        return (
            <>
                <IconUploadedToCloud cloud={item.cloud} />
                <IconVideoInPlaylist videoId={item._id} />
                <ShareSettingsButton onlyIcon sharing={item.sharing} type={SHARE_TYPES.file} />
            </>
        );
    case CONTENT_TYPE.VIDEO:
        return (
            <>
                <IconUploadedToCloud cloud={item.cloud} />
                <IconVideoInPlaylist videoId={item._id} />
                <IconFileUploadToYoutube youtubeId={item.youtube && item.youtube.youtubeId} />
                <ShareSettingsButton onlyIcon sharing={item.sharing} type={SHARE_TYPES.file} />
            </>
        );
    case CONTENT_TYPE.SEASON:
        return <rest.icons item={item} />;
    case CONTENT_TYPE.EPISODE:
        return <rest.icons item={item} />;
    default:
        return '';
    }
};
const getHasIcons = type => [
    CONTENT_TYPE.PROJECT,
    CONTENT_TYPE.AUDIO,
    CONTENT_TYPE.VIDEO,
    CONTENT_TYPE.SEASON,
    CONTENT_TYPE.EPISODE,
].includes(type);

const ListItem = ({ type, item, ...rest }) => (
    <>
        <div className={styles.item}>
            {getHasPreview(type) && (
                <figure className={styles.preview}>
                    <Link to={getItemLink(type, item, rest)}>
                        {getItemPreview(type, item)}
                    </Link>
                </figure>
            )}
            <div className={styles.itemInfo}>
                <div className={styles.itemData}>
                    <div className={styles.baseInfo}>
                        <Link key="itemlink" to={getItemLink(type, item, rest)}>
                            <div className={styles.name}>
                                {getItemTitle(type, item)}
                            </div>
                        </Link>
                        {rest.details && <rest.details item={item} />}
                    </div>
                    {rest.middle && (<div className={styles.middle}>
                      <rest.middle item={item} />
                    </div>)}
                    <div className={styles.id}>
                        {item._id}
                    </div>
                    {getHasIcons(type) && (
                        <div className={styles.icons}>
                            {getItemIcons(type, item, rest)}
                        </div>
                    )}
                </div>
            </div>
        </div>
        {!!rest.footer && rest.footer(item)}
    </>
);

ListItem.propTypes = {
    type: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
};

export default ListItem;
