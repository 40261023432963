// @ts-nocheck
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { ButtonNew } from 'shared/components/ButtonNew';
import Input from 'shared/components/Input/Input';
import { DOCUMENT_DESCRIPTIONS, TDocumentTypeFile } from 'shared/constants/docs';
import { DOC_TYPES_FILES } from 'shared/constants/pouchDB';
import { IconDelete } from 'shared/resources/svg/IconDelete';
import { TSelectedFile } from 'shared/utils/files/files';
import styles from 'shared/Pages/common/ProjectCreate/ProjectCreateFiles/project-create-files.module.scss';
import FileTypeSelect from './FileTypeSelect'


type TProjectCreateFilesProps = {
  files: TSelectedFile[]
  handleSelectFile: (file: TSelectedFile) => void
  handleAddFiles: (newFiles: TSelectedFile[]) => void
  handleDeleteFiles: (files: TSelectedFile[]) => void
  showFiles: boolean
  disabled?: boolean
  defaultType: TDocumentTypeFile
  setDefaultType: (type: TDocumentTypeFile) => void
};


export function ProjectCreateFiles(props: TProjectCreateFilesProps): JSX.Element {
  const { files, defaultType, setDefaultType, showFiles, handleSelectFile, handleAddFiles, handleDeleteFiles, disabled = false } = props

  const handleConfirm = useCallback(() => {
    const curFiles = files.filter(fl => fl.selected)
    handleDeleteFiles(curFiles)
    handleAddFiles(curFiles.map(f => ({ ...f, type: defaultType, typeConfirmed: true, selected: false })))
  }, [files, defaultType, handleDeleteFiles, handleAddFiles])

  const unconfirmedFiles = useMemo(() => files.filter(fl => fl.typeConfirmed !== true), [files])

  return (
    <>
      {!!unconfirmedFiles.length && (
        <>
          <div className={styles.fileType}>
            <div />
            <div className={cn(styles.fileItem, styles.flex)}>
              <span className={styles.lightText}>
                The following files will be added to the
              </span>
              <FileTypeSelect
                value={defaultType}
                setValue={setDefaultType}
                sx={{ fontWeight: 'bold', mr: 1, ml: 1 }}
              />
              <span className={styles.lightText}>
                folder
              </span>
              <ButtonNew
                disabled={files.filter(f => f.selected).length === 0 || disabled}
                onClick={handleConfirm}
                className={styles.confirmButton}
              >
                Add to Folder
              </ButtonNew>
            </div>
          </div>
          <div className={styles.fileType}>
            <div />
            <div className={styles.typeContent}>
              {unconfirmedFiles.map((fl, ind) => (
                <div className={styles.fileItem} key={`${fl.file.name}-${ind}`}>
                  <span>{fl.file.name}</span>
                  <Input
                    id={`${fl.file.name}_${fl.type}`}
                    type={Input.TYPE.CHECKBOX}
                    field={{
                      value: fl.selected,
                      onChange: () => handleSelectFile(fl),
                    }}
                    noValidateIcon
                    spaceBottom={Input.SPACE_BOTTOM.NONE} />
                  <ButtonNew
                    size="tiny"
                    color="transparent"
                    onClick={() => handleDeleteFiles([fl])}
                    disabled={disabled}
                  >
                    <IconDelete />
                  </ButtonNew>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {showFiles && (DOC_TYPES_FILES.map((fileType: TDocumentTypeFile) => (
        <div className={styles.fileType} key={fileType}>
          <div className={styles.typeTitle}>
            {`${DOCUMENT_DESCRIPTIONS[fileType].title}:`}
          </div>
          <div className={styles.typeContent}>
            {!files.filter(fl => fl.type === fileType).length && (
              <div className={styles.typeContentEmpty}>
                {/*{DOC_TYPES_PLACEHOLDERS[fileType]}*/}
              </div>
            )}
            {files.filter(fl => fl.type === fileType && fl.typeConfirmed === true).map((fl, ind) => (
              <div className={styles.fileItem} key={`${fl.file.name}-${ind}`}>
                <span>{fl.file.name}</span>
                <ButtonNew
                  size="tiny"
                  color="transparent"
                  onClick={() => handleDeleteFiles([fl])}
                  disabled={disabled}
                >
                  <IconDelete />
                </ButtonNew>
              </div>
            ))}
          </div>
        </div>
      )))}
    </>
  )
}
