import { FILE_TYPE_ICON_MAP } from 'shared/_model/project/const';
import IconWithBadge from 'shared/_ui/icon/IconWithBadge';
import { DocType } from 'shared/constants/docs';

type Props = {
	type: DocType.FILE_FINISH_AUDIO | DocType.FILE_FINISH_VIDEO;
	count: number | null;
};

export default function ProjectFileCountIcon({ type, count }: Props) {
	const Icon = FILE_TYPE_ICON_MAP[type];

	return (
		<IconWithBadge
			icon={
				<Icon
					sx={(theme) => ({
						// [theme.breakpoints.up('lg')]: {
						// 	height: '40px',
						// 	width: '40px',
						// },
					})}
				/>
			}
			badgeContent={count}
			title={count ? `has ${count} files` : 'has no files'}
			sx={{
				'.MuiBadge-badge': (theme) => ({
					// [theme.breakpoints.up('lg')]: {
					// 	fontSize: '0.75rem',
					// 	padding: `${theme.spacing(4 * 0.1)} ${theme.spacing(4 * 0.35)} 0`, // '0.1rem 0.35rem 0',
					// },
				}),
			}}
		/>
	);
}
