import { ENDPOINTS } from '../constants/endpoints';
import { USER_SCHEMA } from '../constants/general';
import { api } from '../constants/globals';
import { readUserDoc, writeUserDoc } from '../handlers/userDB';
import { actionAuthError } from '../modules/general';
import {
    actionSetDbUser,
    actionSetLocalSettings,
    actionSetUserId,
    actionSetUserProperties
} from '../modules/user/userActions';
import { apiUserGetUserProperties } from '../utils/user';
import { getDatabaseId, getUserId, getWeb } from '../utils/webSettings';
import { readLocalSettings } from 'app/utils/local-settings-utils';

export const getDbUser = () => (_, getState) => {
    const { user: { dbUser } } = getState();
    return dbUser;
};

export const doFetchUserId = () => async (dispatch) => {
    try {
        const response = await api.apiGet(ENDPOINTS.user.getUserId);
        if (response && response.id) {
            dispatch(actionSetUserId(response.id));
            // return true;
        } else {
            dispatch(actionAuthError(response));
        }
    } catch (e) {
        dispatch(actionAuthError(e));
    }

    return !!getUserId();
};

export const doFetchUserProperties = () => async (dispatch) => {
    // console.log('properties', getWeb() && getWeb().properties);
    const userId = getUserId();
    try {
        const response = await apiUserGetUserProperties(userId);
        if (response && response.roles) {
            dispatch(actionSetUserProperties(response));
        } else {
            dispatch(actionAuthError(response));
        }
    } catch (e) {
        dispatch(actionAuthError(e));
    }

    const web = getWeb();
    // console.log('properties', web && web.properties);
    return !!(web && web.properties && web.properties.roles);
};

export const doReadLocalSettings = userId => async (dispatch, getState) => {
    dispatch(actionSetLocalSettings(await readLocalSettings(userId)));

    const { user: { isLocalConfigured } } = getState();
    return isLocalConfigured;
};
export const doReadDbUser = () => async (dispatch) => {
  const userId = getUserId();
  const databaseId = getDatabaseId();
    let dbUser = await dispatch(readUserDoc(userId));

    if (!dbUser._id) {
        dbUser = {
            ...USER_SCHEMA.default(),
          _id: userId,
          databaseId,
        };
        await dispatch(writeUserDoc(dbUser));
        dbUser = await dispatch(readUserDoc(userId));
    }

    dispatch(actionSetDbUser(dbUser));
    return dbUser;
};
