import React from 'react';

const k = 3.1753 / 5.2917;

export const IconAudioNav: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 17, fill = 'white', ...rest } = props;

  const aspectWidth = (height as number) * k;
  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 3.1753 5.2917" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="a">
          <path d="m0 792h612v-792h-612z" />
        </clipPath>
      </defs>
      <g transform="matrix(.57754 0 0 -.57754 -6.2451 375.06)" clipPath="url(#a)" fill={fill}>
        <g transform="translate(13.562 643.91)">
          <path d="m0 0c-0.708 0-1.283 0.574-1.283 1.283v2.932c0 0.709 0.575 1.283 1.283 1.283s1.283-0.574 1.283-1.283v-2.932c0-0.709-0.575-1.283-1.283-1.283"/>
        </g>
        <g transform="translate(15.578 646.48)">
          <path d="m0 0v-1.282c0-1.112-0.905-2.016-2.016-2.016s-2.016 0.904-2.016 2.016v1.282h-0.733v-1.282c0-1.392 1.04-2.542 2.382-2.722v-1.493h-1.283v-0.733h3.299v0.733h-1.282v1.493c1.342 0.18 2.382 1.33 2.382 2.722v1.282z"/>
        </g>
      </g>
    </svg>
  );
};
