import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TDocFile, TDocProject } from 'shared/constants/docs';
import { updateUserDoc } from 'shared/handlers/userDB';
import * as projectActions from 'shared/modules/projects/actions';
import { readUserProject } from 'app/_temp/new_file_search';
import { useProjectFiles } from 'shared/helpers/use-project-files';
import { UserContext } from 'shared/Providers/User';
import ProjectDetails from 'shared/_model/project-details';
import ProjectProvider from 'shared/_pages/project/provider';

export default function Project(): JSX.Element {
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();

	const {
		permissions: { canEditProject },
	} = useContext(UserContext);
	const { addFiles, removeFile, findFiles } = useProjectFiles({ projectId });

	const readProject = useCallback(async () => dispatch(readUserProject(projectId, true)) as Promise<TDocProject>, [dispatch, projectId]);

	const saveProject = useCallback(
		async (values: TDocProject) => {
			dispatch(projectActions.saveProject(values));
		},
		[dispatch],
	);

	const deleteProject = useCallback(async () =>
	// @ts-ignore
     dispatch(projectActions.deleteProject(projectId)) as Promise<
      string[]
    >,
	[dispatch, projectId]);

	const saveFile = useCallback(
		async (values: TDocFile) => {
			dispatch(updateUserDoc(values));
		},
		[dispatch],
	);

	return (
  <ProjectProvider projectId={projectId}>
  <ProjectDetails
				// TODO: Remove unused props
				// projectId={projectId}
  readProject={readProject}
  saveProject={canEditProject ? saveProject : undefined}
  deleteProject={canEditProject ? deleteProject : undefined}
  findFiles={findFiles}
  addFiles={addFiles}
  removeFile={removeFile}
  saveFile={canEditProject ? saveFile : undefined}
			/>
		</ProjectProvider>
	);
}
