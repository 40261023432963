import React from 'react';

const k = 3.81 / 7.9375;

export const IconFileTypeAudio: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 30, fill = 'black', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} fill={fill} viewBox="0 0 3.81 7.9375" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.15623 0 0 -.15623 .16125 3.1084)">
        <path
          d="m0 0c-0.543 0.012-1.041-0.661-1.032-1.395v-4.132c0-8.646 4.912-15.72 11.177-16.413v-6.223h-4.064c-0.562 0-1.017-0.614-1.017-1.373 0-0.758 0.455-1.373 1.017-1.373h10.16c0.561 0 1.016 0.615 1.016 1.373 0 0.759-0.455 1.373-1.016 1.373h-4.064v6.223c6.264 0.693 11.177 7.767 11.177 16.413v4.132c7e-3 0.726-0.479 1.393-1.016 1.393s-1.024-0.667-1.016-1.393v-4.132c0-7.631-4.514-13.732-10.161-13.732s-10.161 6.101-10.161 13.732v4.132c8e-3 0.719-0.468 1.383-1 1.395m11.161 19.896c-4.204 0-7.621-4.616-7.621-10.297v-15.105c0-5.682 3.417-10.299 7.621-10.299s7.62 4.617 7.62 10.299v15.105c0 5.681-3.416 10.297-7.62 10.297"
          />
          {/*style="fill:#231f20" />*/}
      </g>
    </svg>
  );
};
