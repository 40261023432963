import { SyntheticEvent, useState } from 'react'

type UseTabsType = [
  number,
  (event: SyntheticEvent, newValue: number) => void
]

export default function useTabs(): UseTabsType {
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return [value, handleChangeTab];
}
