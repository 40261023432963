// @flow
import { removeFileFromFolders } from 'app/utils/files';
import { fsEnsureDirSync } from 'app/_temp/fse';
import { fetchProjectFiles } from 'app/_temp/new_file_search';
import { deleteProjectFolder, getProjectFolder } from 'app/_temp/projects';
import { findProjectFile } from 'app/_temp/search';
import { apiS3Remove, getS3ProjectKey } from '../../utils/cloud/cloudS3';
import {
	findUserDocs,
	readUserDoc,
	readUserDocs,
	removeUserDoc,
	updateUserDoc,
	writeUserDoc,
	writeUserDocs,
} from '../../handlers/userDB';
import { DOC_TYPES_FILES } from '../../constants/pouchDB';
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../constants/docs';
import { CLOUD_TYPES } from '../../utils/uppyUploader/uppyHandler';
import { fetchEpisodesByVideoId } from '../episodes/actions';
import { ACTION_TYPES } from './reducer';
import { isWeb } from '../../constants/modules';

// TODO: Need to delete related files & links if we are deleting project

export const ACTIONS = {
	projects: {
		success: (list) => ({ type: ACTION_TYPES.projects.success, list }),
	},
};

/**
 * Returned map with total values for each project id
 * @param {string[]} projectIds
 * @return {{ [string]: { fileCount: {} }}} Complex object with File and new DB Doc
 */
export const getProjectTotals = (projectIds) => async (dispatch) => {
	const emptyTotals = {
		fileCount: {
			[DOC_TYPE_FILE_FINISH_AUDIO]: 0,
			[DOC_TYPE_FILE_FINISH_VIDEO]: 0,
		},
	};

	const files = await dispatch(fetchProjectFiles(
		projectIds,
		[{ key: 'type', value: [DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO] }],
		['_id', 'type', 'projectId'],
	));

	return {
		...projectIds.reduce((acc, id) => ({
			...acc,
			[id]: { _totals: { ...emptyTotals } },
		}), {}),
		...files.reduce((acc, doc) => {
			const curCounts = (acc[doc.projectId] && acc[doc.projectId]._totals && acc[doc.projectId]._totals.fileCount)
                || emptyTotals.fileCount;
			return {
				...acc,
				[doc.projectId]: {
					_totals: {
						fileCount: {
							...curCounts,
							[doc.type]: curCounts[doc.type] + 1,
						},
					},
				},
			};
		}, {}),
	};
};

export const insertProject = (valuesWithId) => async (dispatch) => {
	const project = await dispatch(writeUserDoc(valuesWithId));

	if (!isWeb) {
		fsEnsureDirSync(await getProjectFolder(project));
	}

	return project;
};

export const saveProject = (values) => async (dispatch) => dispatch(updateUserDoc(values));

export const deleteProject = (projectId) => async (dispatch) => {
	const errors = [];

	const files = await dispatch(fetchProjectFiles(projectId));
	if (files.length) {
		errors.push(`Unable to delete a project that has files (${files.length})`);
	}

	const episodes = await dispatch(fetchEpisodesByVideoId(files.map((file) => file._id)));
	if (episodes.length) {
		errors.push(`Unable to delete project whose files are included in episodes (${episodes.length})`);
	}

	if (errors.length === 0) {
		await dispatch(removeUserDoc(projectId));
		await deleteProjectFolder(projectId);
	}

	return errors;
};

export const fetchProjects = () => (dispatch, getState) => {
	const { projects } = getState();
	return dispatch(findUserDocs(projects));
};

export const readProjectsList = () => async (dispatch) => {
	const list = await dispatch(fetchProjects());
	dispatch(ACTIONS.projects.success(list));
};

export const removeProjectFromCloud = (projectId) => async (dispatch) => {
	const errors = [];

	const files = await dispatch(fetchProjectFiles(projectId));
	if (files.length > 0) {
		if (isWeb) {
			errors.push('You can not remove files from cloud in web application. Use desktop app please.');
			return errors;
		}

		const lostNames = files.reduce((acc, file) => {
			const path = findProjectFile(projectId, file._id);
			if (!path && file.cloud) {
				return [
					...acc,
					file.title,
				];
			}
			return acc;
		}, []);

		if (lostNames.length > 0) {
			errors.push(`Files:\n\n${
				lostNames.join('\n')
			}\n\nnot found on disk. Removing from the cloud is not possible to prevent the loss of files.`);
		}

		if (errors.length === 0) {
			const key = getS3ProjectKey(projectId);
			const response = await apiS3Remove(key);

			if (response && response.Deleted) {
				const keys = response.Deleted.map((resp) => resp.Key);
				if (keys.length) {
					const changedFiles = files
						.filter((file) => file.cloud && file.cloud.s3 && keys.includes(file.cloud.s3.key))
						.map((file) => {
							const newFile = { ...file };
							delete newFile.cloud.s3;
							if (Object.keys(newFile.cloud).length === 0) {
								delete newFile.cloud;
							}
							return newFile;
						});

					if (changedFiles.length) {
						await dispatch(writeUserDocs(changedFiles));
					}
				}
			}
		}
	}

	return errors;
};

const removeFileFromCloud = async (cloud) => {
	let newCloud;
	let cloudChanged = false;

	if (cloud) {
		newCloud = { ...cloud };
		const clouds = Object.keys(cloud);

		for (const ind in clouds) {
			const cloudType = clouds[ind];
			const cloudData = cloud[cloudType];
			let apiResult;

			switch (cloudType) {
			case CLOUD_TYPES.s3:
				// eslint-disable-next-line no-await-in-loop
				apiResult = await apiS3Remove(cloudData.key);

				if (apiResult) {
					if (apiResult.Deleted) {
						let fileRemoved = false;

						apiResult.Deleted.some((del) => {
							if (del.Key === cloudData.key) {
								fileRemoved = true;
							}
							return fileRemoved;
						});

						if (fileRemoved) {
							delete newCloud[cloudType];
							cloudChanged = true;
						} else {
							console.error(`${cloudType}: could not delete file ${cloudData.key}`);
						}
					} else {
						const err = apiResult.error ? `Reason:\n${apiResult.error}` : '';
						console.error(`${cloudType}: could not delete file ${cloudData.key}${err}`);
					}
				} else {
					console.log(`${cloudType}: file ${cloudData.key} does not exists`);
					delete newCloud[cloudType];
					cloudChanged = true;
				}
				break;

			default:
				break;
			}
		}
	}

	return cloudChanged ? newCloud : null;
};

/**
 * @param {string[]} fileIds
 * @param {string} projectId
 * @return {Promise<string[]>}
 */
export const removeFile = (fileIds, projectId) => async (dispatch) => {
	const errors = [];

	const episodes = await dispatch(fetchEpisodesByVideoId(fileIds));
	if (episodes && episodes.length) {
		errors.push(`Some files are used in ${episodes.length} episode(s)`);
		return errors;
	}

	const files = (await dispatch(readUserDocs(fileIds)))
		.map((f) => ({ ...f, _deleted: true }));
	if (fileIds.length !== files.length) {
		fileIds
			.filter((id) => !files.map((f) => f._id).includes(id))
			.forEach((id) => errors.push(`File with id "${id}" not found`));
		if (errors.length) {
			return errors;
		}
	}

	const project = await dispatch(readUserDoc(projectId));
	if (!project._id) {
		errors.push(`Project with id "${project._id}" not found`);
		return errors;
	}

	const isCloudPresent = (f) => f.cloud && !!Object.keys(f.cloud).length;
	const promises = files.filter((f) => isCloudPresent(f))
		.map((f) => (async () => {
			const cloudResult = await removeFileFromCloud(f.cloud);
			if (cloudResult) {
				return { ...f, cloud: { ...cloudResult } };
			}
			return null;
		})());
	const results = await Promise.all(promises);

	const removedFiles = [
		...results.filter((f) => !!f),
		...files.filter((f) => !isCloudPresent(f)),
	];

	if (files.length !== removedFiles.length) {
		files
			.filter((doc) => !removedFiles.map((f) => f._id).includes(doc._id))
			.forEach((doc) => errors.push(`Unable to remove file with id ${doc._id}`));
	}

	const fileSystemResult = removeFileFromFolders(removedFiles.map((f) => f._id), project);
	if (fileSystemResult === true) {
		DOC_TYPES_FILES.forEach((fileType) => {
			if (project[fileType]) {
				project[fileType] = project[fileType].filter((id) => !removedFiles.map((f) => f._id).includes(id));
			}
		});
	} else {
		console.error(`Unable to delete file with ids ${removedFiles.map((f) => f._id).join(', ')} from file system`);
	}

	await dispatch(writeUserDocs([
		project,
		...removedFiles,
	]));

	return errors;
};
