import { createTheme, LinkProps, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PropsWithChildren } from 'react';
import HenrietteBlackWoff2 from 'shared/fonts/Henriette-Black.woff2';
import RouterLinkComponent from 'shared/Providers/MuiTheme/components/RouterLinkComponent';

const borderRadius = 10;

export const FONT_FAMILY_HEADER = ['"Henriette Black"', 'Georgia', 'serif'].join(',');

const FONT_FAMILY_BODY = [
	'"Lato"',
	'-apple-system',
	'BlinkMacSystemFont',
	'"Segoe UI"',
	'Helvetica',
	'Arial',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"',
].join(',');

// --bgColor: white;
// --btnPrimaryBg: black;
// --btnPrimaryText: white;
// --btnSecondaryBg: #d1d3d4;
// --btnSecondaryText: white;
// --btnWarningBg: #bf1e2d;
// --btnWarningText: white;
// --btnTransparentBg: #00000000;
// --btnTransparentText: black;
// --btnNoneText: black;
// --btnNoneHover: #eee;
// --textColor: #000;
// --lightText: #9e9e9e;
// --bgHover: #00000019;
// --bg2ndRow: #00000008;
// --disabledOpacity: 0.5;
// --inputValidateFlagWidth: 20px
// d8d8d8

const lineHeight = {
	// xs: 1.1,
	// sm: 1.2,
	md: '1.3',
	// lg: 1.4,
	// xlg: 1.5,
} as const;

// TODO: Why do I need to add fontSize everywhere?
let theme = createTheme({
	spacing: (factor: number) => `${0.25 * factor}rem`,
	palette: {
		mode: 'light',
		primary: {
			main: '#000000',
			dark: grey[600],
		},
		secondary: {
			main: grey[400],
			dark: grey[200],
		},
		background: {
			default: '#ffffff',
		},
		// contrastThreshold: 4.5,
		// tonalOffset: 0.2,
	},
});

type PartialDeep<T> = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	[K in keyof T]?: T[K] extends object ? PartialDeep<T[K]> : T[K];
};

theme = createTheme(theme, {
	palette: {
		...theme.palette,
		success: theme.palette.augmentColor({
			color: {
				main: '#00a14b',
			},
			name: 'success',
		}),
	},
	typography: {
		allVariants: {
			fontFamily: FONT_FAMILY_BODY,
		},
		fontFamily: FONT_FAMILY_BODY,
		fontSize: 14,
		lineHeight: lineHeight.md,
		body1: {
			fontSize: '0.875rem', // 14px
			// [theme.breakpoints.up('md')]: {
			// 	fontSize: '1rem', // 16px
			// },
		},
		body2: {
			fontSize: '0.875rem', // 14px
			// [theme.breakpoints.up('md')]: {
			// 	fontSize: '1rem', // 16px
			// },
		},
		h1: {
			textAlign: 'left',
			fontFamily: FONT_FAMILY_HEADER,
			fontWeight: 900,
			lineHeight: 1.4,
			textTransform: 'capitalize',
			fontSize: '1.25rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '1.75rem',
			},
		},
		h2: {
			fontFamily: FONT_FAMILY_HEADER,
			fontSize: 31,
		},
		h3: {
			fontFamily: FONT_FAMILY_HEADER,
			fontSize: 22,
		},
		h4: {
			fontFamily: FONT_FAMILY_HEADER,
			fontSize: 18,
		},
		h5: {
			fontFamily: FONT_FAMILY_HEADER,
			fontSize: 16,
		},
		h6: {
			fontFamily: FONT_FAMILY_HEADER,
			fontSize: 14,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
        @font-face {
          font-family: 'Henriette Black';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('Henriette'), local('Henriette Black'), url(${HenrietteBlackWoff2}) format('woff2');
          unicode-range: U+000D-FB04;
        }
      `,
		},
		MuiCheckbox: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiChip: {
			defaultProps: {
				size: 'small',
			},
			variants: [
				{
					props: { color: 'default' },
					style: {
						'.MuiChip-label': {
							color: theme.palette.primary.main,
						},
					},
				},
			],
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius,
				},
			},
		},
		MuiList: {
			defaultProps: {
				color: 'primary',
			},
			// styleOverrides
		},
		MuiPaper: {
			// defaultProps: {
			// 	color: 'primary',
			// },
			styleOverrides: {
				root: {
					color: theme.palette.primary.main, // 'black', // TODO: Use theme constants
					borderRadius,
				},
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				variant: 'contained',
			},
			styleOverrides: {
				root: {
					borderRadius,
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				color: 'primary',
			},
			styleOverrides: {
				root: {
					height: 'fit-content',
				},
			},
			variants: [
				{
					props: { size: 'inherit' },
					style: {
						fontSize: 'inherit',
						'.MuiSvgIcon-root': {
							fontSize: 'inherit',
						},
					},
				},
			],
		},
		MuiLink: {
			defaultProps: {
				component: RouterLinkComponent,
				underline: 'hover',
			} as LinkProps,
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: RouterLinkComponent,
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				size: 'medium',
			},
			styleOverrides: {
				root: {
					fontSize: '0.75rem',
					borderRadius,
					minWidth: 'unset',
					width: 'max-content',
					height: 'max-content',
				},
				endIcon: {
					marginRight: 0,
					marginLeft: theme.spacing(1),
				},
			},
			variants: [
				{
					props: { size: 'medium' },
					style: {
						fontSize: '0.75rem',
						padding: theme.spacing(2),
						minHeight: 'calc(1.4em)',
						'.MuiButton-endIcon': {
							'&>*:nth-of-type(1)': {
								fontSize: '0.75rem',
							},
						},
					},
				},
			],
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: 'currentColor',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',
				},
			},
		},
		MuiBadge: {
			defaultProps: {
				color: 'primary',
			},
			variants: [
				{
					props: { variant: 'outlined' },
					style: {
						height: 'fit-content',
						'.MuiBadge-badge': {
							border: '0.1rem solid black',
							color: theme.palette.primary.main, // 'black', // TODO: Use theme constants
							backgroundColor: theme.palette.background.default, // 'white', // TODO: Use theme constants
							padding: `${theme.spacing(4 * 0.05)} ${theme.spacing(4 * 0.22)} 0`, // '0.05rem 0.22rem 0',
							fontSize: '0.5rem', // 6pt // TODO: Use theme constants
							height: 'min-content',
							lineHeight: '1.34',
							textTransform: 'none',
							transform: 'none',
							minWidth: 'unset',
							fontWeight: '900',
						},
						'.MuiBadge-badge.MuiBadge-invisible': {
							transform: 'scale(0) translate(50%, 50%)',
						},
					},
				},
				{
					props: { variant: 'component' },
					style: {
						'.MuiBadge-badge': {
							backgroundColor: 'transparent',
							padding: 0,
						},
					},
				},
			],
			styleOverrides: {
				root: {
					whiteSpace: 'nowrap',
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					'&.field': {
						flexDirection: 'row',
						gap: theme.spacing(1),
						'&.editable': {},
						'.MuiFormLabel-root': {
							position: 'unset',
							transform: 'none',
							alignItems: 'center',
							display: 'inline-flex',
							flex: 'none',
							alignSelf: 'start',
							[theme.breakpoints.up('md')]: {
								paddingBlock: theme.spacing(2),
							},
						},
						'.MuiTypography-root': {
							padding: 0,
							[theme.breakpoints.up('md')]: {
								padding: theme.spacing(2),
							},
						},
						'.MuiInputBase-input': {
							padding: 0,
						},
						'.MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
							'&.MuiInputBase-readOnly': {
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								padding: 0,
							},
						},
						'.MuiInputBase-root.MuiInputBase-readOnly': {
							padding: 0,
							[theme.breakpoints.up('md')]: {
								padding: theme.spacing(2),
							},
							'> fieldset': {
								border: 'none',
							},
						},
						'.MuiInputBase-root': {
							padding: theme.spacing(2),
						},
						'.MuiInputBase-root > fieldset': {
							top: 0,
							legend: {
								display: 'none',
							},
						},
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: ({ ownerState }) => {
					const readOnly = !!ownerState.InputProps?.readOnly;
					const hasOnClick = !!ownerState.onClick;

					return {
						'.MuiInputBase-root': hasOnClick
							? {
								transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
								cursor: 'pointer',
								'& input, & textarea': {
									cursor: 'pointer',
								},
								'&:hover': {
									backgroundColor: 'rgba(0,0,0,0.04)',
								},
							}
							: {},
						'.MuiFormLabel-root': {
							'--lineHeight': lineHeight.md,
							lineHeight: 'var(--lineHeight)',
							paddingTop: readOnly ? undefined : theme.spacing(2),
						},
						'.MuiInputBase-input': {
							lineHeight: 'var(--lineHeight)',
						},
						'input.MuiInputBase-input': {
							height: 'calc(var(--lineHeight)em)',
						},
					};
				},
			},
		},
	},
} as PartialDeep<typeof theme>);

declare module '@mui/material/Badge' {
	interface BadgePropsVariantOverrides {
		outlined: true;
		component: true;
	}
}
declare module '@mui/material/IconButton' {
	interface IconButtonPropsSizeOverrides {
		inherit: true;
	}
}

export const MuiThemeProvider = ({ children }: PropsWithChildren): JSX.Element => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
