import React from 'react';
import Button from '../../../components/Button/Button';
import Logo from '../../../components/Logo/Logo';
import { ROUTE } from '../../../constants/routes_old';
import Hr from '../Blocks/Hr/Hr';
import styles from './Header.module.css';

const Header = () => (
    <div className={styles.wrapper}>
        <div className={styles.container}>
            <div className={styles.logoWrapper}>
                <Logo />
            </div>
            <div className={styles.menu}>
                <Button
                    color={Button.COLOR.NONE}
                    href="#HowItWorks"
                    className={styles.menuItem}
                    blank={false}
                >
                        How it Works
                </Button>
                <Button
                    color={Button.COLOR.NONE}
                    href="#About"
                    className={styles.menuItem}
                    blank={false}
                >
                        About
                </Button>
                <Button
                    color={Button.COLOR.NONE}
                    href="#Download"
                    className={styles.menuItem}
                    blank={false}
                >
                        Get Started
                </Button>
            </div>
            <Button
                link={ROUTE.login}
                color={Button.COLOR.PRIMARY}
                className={styles.right}
            >
                    Log in
            </Button>
        </div>
        <Hr className={styles.hr}></Hr>
    </div>
);

export default Header;
