import React, { PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';
import Button from 'shared/components/Button/Button';
import { Icon } from 'shared/components/Icon';
import styles from 'shared/components/ModalWindow/modal-window.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


type TModalWindowProps = PropsWithChildren<{
  backgroundClassName?: string
  noBackground?: boolean
  onClose?: () => void | Promise<void>
  caption?: string
  captionClassName?: string
  containerClassName?: string
  childrenClassName?: string
}>


export const ModalWindow = (props: TModalWindowProps): JSX.Element => {
  const {
    onClose,
    backgroundClassName,
    noBackground = false,
    children,
    caption,
    captionClassName,
    containerClassName,
    childrenClassName,
  } = props;

  const handleClose = useMemo(() => {
    if (onClose) {
      return async () => {
        await onClose();
      }
    }
    return undefined
  }, [onClose])

  return (
    <>
      <div
        className={cn(styles.background,
          { [styles.noBackground]: noBackground },
          backgroundClassName
        )}
        onClick={handleClose}
      />
      <div className={styles.windowContainer}>
        <div className={cn(styles.container, containerClassName)}>
          {handleClose && (
            <Button
              tiny
              color={Button.COLOR.NONE}
              onClick={handleClose}
              className={styles.closeButton}
            >
              <Icon icon={faTimes} className={styles.closeIcon} />
            </Button>
          )}
          {!!caption && <h1 className={cn(styles.caption, captionClassName)}>{caption}</h1>}
          <div className={cn(styles.children, childrenClassName)}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
