import { TUseWrongMediaDurationResult, useWrongMediaDuration } from 'shared/Providers/UserData/use-wrong-media-duration.hook';


export type TUseUserInformationResult = {
  wrongDuration: TUseWrongMediaDurationResult
}

// TODO: Remove this temporary stuff
export const useUserInformation = (): TUseUserInformationResult => {
  const wrongDuration = useWrongMediaDuration();


  return {
    wrongDuration
  };
}
