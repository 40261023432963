import { TDocFile } from 'shared/constants/docs';

type FileDriveIconProps = {
	file: TDocFile;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function FileDriveIcon({ file }: FileDriveIconProps) {
	return null;
}
