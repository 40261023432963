import { TApiPermissionName, TApiUser, TRole } from 'shared/helpers/types-api'
import { TUser, TUserPermission } from './user-types'

const PERMISSION_MAP: Record<TUserPermission, TApiPermissionName> = {
  canEditProject: 'CanEditProject',
  canAddFiles: 'CanAddFiles',
  canRemoveFiles: 'CanRemoveFiles',
  canUseApp: 'DesktopAndWebApp',
  canManageAccount: 'ManagementAccount',
  canManageUsers: 'UserManagement',
  canReadUsers: 'UserManagementRead',
  canManageMembers: 'MemberManagement',
  canReadMembers: 'MemberManagementRead',
  canUseCloud: 'S3UploadAudioAndVideo',
  canPublishMedia: 'AbilityPublicMedia',
};

function convertPermissions(roles: TRole[], permissions?: TApiPermissionName[]): Record<TUserPermission, boolean> {
  const apiPermissions = new Set([
    ...roles.map(r => r.permissions.map(p => p.key)),
    ...(permissions || [])
  ].flat());
  return (Object.keys(PERMISSION_MAP) as TUserPermission[])
    .reduce(
      (acc, key) => ({ ...acc, [key]: apiPermissions.has(PERMISSION_MAP[key]) }),
      {} as Record<TUserPermission, boolean>
    );
}

export function convertApiUser(apiUser?: TApiUser): TUser|undefined {
  if (!apiUser) {
    return undefined;
  }
  const { email, is_active, name, permissions, roles, user_id, database_id } = apiUser;

  return {
    databaseId: database_id,
    email,
    id: user_id,
    isActive: is_active,
    name,
    permissions: convertPermissions(roles, permissions),
    roles: roles.map(({ key, name }) => ({ key, name })),
  };
}


export const emptyUser: TUser = {
  databaseId: '',
  email: '',
  id: '',
  isActive: false,
  name: '',
  roles: [],
  permissions: convertPermissions([]),
}
