import { getFrameCanvas } from './canvas'
import { getDefaultHeight, getDefaultPosition, getDefaultQuality, getDefaultTimeout, getDefaultType, getDefaultWidth, validateErrors, validateHeight, validatePosition, validateQuality, validateSource, validateTimeout, validateWidth } from './options'
import { getTime } from './utils'

type TGetThumbnailOptions = {
  height: number;
  position: number;
  quality?: number;
  timeout: number;
  type: string;
  width: number;
}

type TGetThumbnailResult = {
  height: number;
  position: number;
  type: string;
  url: string;
  blob: Blob | null;
  width: number;
  time: number;
}

function getOptions(options?: Partial<TGetThumbnailOptions>): TGetThumbnailOptions {
  const { width, height, type, quality, position, timeout } = options ?? {};
  return {
    height: getDefaultHeight(height),
    width: getDefaultWidth(width),
    type: getDefaultType(type),
    quality: getDefaultQuality(quality),
    position: getDefaultPosition(position),
    timeout: getDefaultTimeout(timeout),
  };
}

function validate(videoSrc: string, options?: Partial<TGetThumbnailOptions>): TGetThumbnailOptions {
  const opts = getOptions(options);

  validateErrors([
    validateSource(videoSrc),
    validatePosition(opts.position),
    validateTimeout(opts.timeout),
    validateQuality(opts.quality),
    validateHeight(opts.height),
    validateWidth(opts.width),
  ]);

  return opts;
}

export async function getThumbnail(videoSrc: string, options?: Partial<TGetThumbnailOptions>): Promise<TGetThumbnailResult|undefined> {
  const { width, height, type, quality, position, timeout } = validate(videoSrc, options);
  const targetRatio = width / height;

  try {
    const canvas = await getFrameCanvas(videoSrc, { position, timeout, ratio: targetRatio });
    if (canvas) {
      try {
        const start = window.performance.now();
        const cv = document.createElement('canvas');
        cv.width = width;
        cv.height = height;

        const ctx = cv.getContext('2d');
        if (!ctx) {
          throw new Error('unable to create context("2d")');
        }
        ctx.drawImage(canvas, 0, 0, width, height);

        try {
          const url = cv.toDataURL(type, quality);

          const blob = await new Promise<Blob | null>((resolve) => {
            cv.toBlob(resolve, 'image/jpeg', 0.9)
          });

          return {
            height,
            position,
            type,
            url,
            blob,
            width,
            time: getTime(start),
          };
        } catch (e) {
          throw new Error(`Can't get dataUrl: ${e}`);
        }
      } catch (e) {
        throw new Error(`Can't downscale image: ${e}`);
      }
    }
  } catch (e) {
    console.error('Error getting video frame', e);
  }

  return undefined;
}
