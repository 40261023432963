import {
	setLocalSettings,
	getUserWeb,
	setUserWeb,
	removeUserWeb,
} from '../../utils/localStorageUtils';
import { GENERAL_ACTION_TYPES } from '../general';
import { validateLocalSettings, validateWebSettings } from './utils';

export const USER_ACTION_TYPES = {
	setLoginProcess: 'USERS_SET_LOGIN_PROCESS',
	setToken: 'USERS_SET_TOKEN',
	setTokenRefreshing: 'USERS_SET_TOKEN_REFRESHING',
	setDbUser: 'USERS_SET_DB_USER',
	setUserId: 'USERS_SET_USER_ID',
	setProperties: 'USERS_SET_PROPERTIES',
	setLocalSettings: 'USERS_SET_LOCAL_SETTINGS',

	changeProperty: 'USERS_CHANGE_PROPERTY', // TODO: remove after implementing user management for admins
};

const initialStateWeb = {
	id: '',
	token: '',
	refreshToken: '',
	refreshing: false,
	properties: {
		permissions: [],
		roles: {},
		received: false,
	},
};

const getWeb = () => {
	const web = getUserWeb();
	if (
		web
		&& web.properties // TODO: remove after implementing user management for admins
	) {
		return {
			...initialStateWeb,
			...web,
			refreshing: false,
		};
	}
	return { ...initialStateWeb };
};

const initialState = {
	web: getWeb(),
	local: {
		projectPath: '',
	},
	dbUser: {
		_id: '',
		helpFlags: {
			openFirstProject: false,
			createFirstProject: false,
		},
		created: '',
	},
	isLoggedIn: false,
	isLocalConfigured: false,
	isLoginProcess: true,
};

const processLocal = (state) => ({
	...state,
	isLocalConfigured: validateLocalSettings(state.local),
});
const processWeb = (state) => ({
	...state,
	isLoggedIn: validateWebSettings(state.web),
});
const processState = (state) => processLocal(processWeb(state));

const reducer = (state = initialState, action) => {
	let newState = state;

	switch (action.type) {
		case USER_ACTION_TYPES.setLoginProcess: {
			newState = {
				...state,
				isLoginProcess: action.isLoginProcess,
			};
			break;
		}

		case USER_ACTION_TYPES.setToken: {
			newState = {
				...state,
				web: {
					...state.web,
					token: action.token,
					refreshToken: action.refreshToken,
					refreshing: false,
				},
			};
			break;
		}
		case USER_ACTION_TYPES.setTokenRefreshing: {
			newState = {
				...state,
				web: {
					...state.web,
					refreshing: action.refreshing,
				},
			};
			break;
		}

		case USER_ACTION_TYPES.changeProperty: {
			// TODO: remove after implementing user management for admins
			const {
				web: {
					properties: { permissions },
				},
			} = state;
			newState = {
				...state,
				web: {
					...state.web,
					properties: {
						...state.web.properties,
						permissions: permissions.includes(action.key)
							? permissions.filter((key) => action.key !== key)
							: [...permissions, action.key].sort(),
					},
				},
			};
			break;
		}
		case USER_ACTION_TYPES.setProperties: {
			newState = {
				...state,
				web: {
					...state.web,
					properties: {
						...action.properties,
						roles: action.properties.roles.reduce(
							(accumulator, role) => ({
								...accumulator,
								[role.key]: role.name,
							}),
							{}
						),
						permissions: [
							...action.properties.roles.map(({ permissions }) =>
								permissions.map(({ key }) => key)
							),
							...(action.properties.permissions ?? []),
						]
							.flat()
							.sort(),
						received: true,
					},
				},
			};
			break;
		}

		case USER_ACTION_TYPES.setUserId: {
			newState = {
				...state,
				web: {
					...state.web,
					id: action.id,
				},
			};
			break;
		}

		case USER_ACTION_TYPES.setLocalSettings: {
			const local = {
				...state.local,
				...action.local,
			};

			setLocalSettings(state.web.id, local); // TODO: Use electron storage for local settings

			return processLocal({
				...state,
				local: {
					...state.local,
					...action.local,
				},
			});
		}

		case USER_ACTION_TYPES.setDbUser: {
			return {
				...state,
				dbUser: action.dbUser,
			};
		}

		case GENERAL_ACTION_TYPES.authClear: {
			removeUserWeb();
			return processState({ ...initialState, web: { ...initialStateWeb } });
		}

		default: {
			return state;
		}
	}

	setUserWeb(newState.web);

	return processState(newState);
};

export default reducer;
