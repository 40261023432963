import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Redirect } from 'react-router-dom';
// import { emitUserError } from '../../../constants/globals';
import { UserContext } from 'shared/Providers/User';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';
import { ROUTE } from '../../../constants/routes_old';
// import { doWebLogin } from '../../../new/loginUtils';
import { getLocalHelpScreens, KEY_FIRST_REGISTER } from '../../../utils/localStorageUtils';
import styles from './Login.module.css';
import configs from 'shared/constants/configs';

export default function Login({ online, location }) {
	const dispatch = useDispatch();
	const { login: handleLogin } = useContext(UserContext);

	const handleSubmit = useCallback(
		async (values, { setSubmitting }) => {
			setSubmitting(true);
			await handleLogin(values.email, values.password);
			setSubmitting(false);
		},
		[dispatch, handleLogin]
	);

	if (online === true) {
		// TODO: ??
		const { from } = location?.state || { from: { pathname: ROUTE.home } };
		return <Redirect to={from} />;
	}

	if (!getLocalHelpScreens()[KEY_FIRST_REGISTER]) {
		return <Redirect to={ROUTE.register} />;
	}

	return (
		<Formik
			initialValues={{ email: '', password: '' }}
			validationSchema={object().shape({
				email: string().required().email(),
				password: string().required().min(1),
			})}
			enableReinitialize
			onSubmit={handleSubmit}
			render={({ isSubmitting }) => (
				<Form className={styles.formContainer}>
					<Field
						name="email"
						component={Input}
						labelWidth={Input.LABEL_WIDTH.FULL}
						label="Email"
						required
					/>
					<Field
						name="password"
						type="password"
						component={Input}
						label="Password"
						required
						labelWidth={Input.LABEL_WIDTH.FULL}
						rootClassName={styles.password}
					/>
					<Button
						link={ROUTE.resetPassword}
						tiny
						color="none"
						noDecorate
						className={styles.resetPasswordButton}
					>
						forgot password?
					</Button>
					<FormButtons>
						<Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY}>
							Sign in
						</Button>
						<span>or</span>
						<Button link={ROUTE.register} color={Button.COLOR.PRIMARY}>
							Register
						</Button>
						{/* {configs.isDev && <a href="/share/6295dcdec8628a9d8971fc10">share test</a>} */}
					</FormButtons>
				</Form>
			)}
		/>
	);
}
