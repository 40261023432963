import React, { useCallback, useEffect, useState } from 'react';
import { PageContainer } from 'shared/Pages/common/PageContainer';
import { NewMember } from 'shared/Pages/members/new-member';
import useApiGetUserMembers from 'shared/utils/api/use-api-get-user-members'
import { Box, Button, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import useApiGetUserProperties from 'shared/utils/api/use-api-get-user-properties'

type TListItem = {
  id: string;
  name?: string;
  email?: string;
  isActive?: boolean;
};

export function Members(): JSX.Element {
  const { members, loading, doGetUserMembers } = useApiGetUserMembers({ lazy: false });
  const { doGetUserProperties } = useApiGetUserProperties();
  const [data, setData] = useState<Record<string, TListItem>>({});

  const [showNewDialog, setShowNewDialog] = useState(false)
  const [updating, setUpdating] = useState(false)

  const updateData = useCallback(async (ids?: string[]) => {
    if (ids) {
      const results = await Promise.allSettled(ids.map((id) => doGetUserProperties(id)));

      setData(s => {
        return ({
          ...s,
          ...results.reduce<Record<string, TListItem>>((acc, res) => {
            if (res.status === "fulfilled" && res.value) {
              return {
                ...acc,
                [res.value.user_id]: {
                  id: res.value.user_id,
                  name: res.value.name,
                  email: res.value.email,
                  isActive: res.value.is_active,
                }
              }
            }
            return acc
          }, {}),
        })
      });
    }
    setUpdating(false);
  }, [doGetUserProperties]);

  useEffect(() => {
    if (members) {
      setData(s => members.reduce<Record<string, TListItem>>((acc, id) => ({
        ...acc,
        [id]: s[id],
      }), {}));
      updateData(members);
    }
  }, [members, updateData]);

  const handleUpdate = useCallback(async () => {
    setUpdating(true)
    await doGetUserMembers();
  }, [])

  const handleCloseNewDialog = useCallback(async () => {
    setShowNewDialog(false);
    await handleUpdate();
  }, [handleUpdate])

  return (
    <PageContainer title={`Members${members?.length ? ' (' + members?.length + ')' : ''}`}>
      {showNewDialog && <NewMember handleClose={handleCloseNewDialog} />}
      <Box sx={{ textAlign: 'right' }} mb={2}>
        <Box sx={{ '& > button': { ml: 1 }}}>
          <Button disabled={updating || loading} onClick={handleUpdate} color="secondary">Update</Button>
          <Button onClick={() => setShowNewDialog(true)}>New member</Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="Members">
          <TableHead>
            <TableRow>
              <TableCell width={100}>id</TableCell>
              <TableCell>name</TableCell>
              <TableCell>email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!members && (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            )}
            {members?.map((memberId) => {
              const memberData = data[memberId];
              return (
                <TableRow
                  hover
                  key={memberId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {memberId}
                  </TableCell>
                  {memberData
                    ? (
                      <>
                        <TableCell>{memberData.name}</TableCell>
                        <TableCell>{memberData.email}</TableCell>
                      </>
                    )
                    : (
                      <>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                      </>
                    )
                  }
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  )
}
