export const ROUTE = {
	home: '/',
	login: '/login',
	register: '/register',
	registerConfirmed: '/register/confirmed',
	registerSuccess: '/register/success',
	logout: '/logout',
	resetPassword: '/resetPassword',
	applyResetPassword: '/applyResetPassword',
	members: '/members',
	users: '/users',
	roles: '/roles',
	communications: '/communications',
	stats: '/stats',
	videos: {
		root: '/videos',
		id: (fileId = ':fileId') => `${ROUTE.videos.root}/${fileId}`,
	},
	files: {
		root: '/files',
		id: (fileId = ':fileId') => `${ROUTE.files.root}/${fileId}`,
	},
	audios: {
		root: '/audio',
		id: (fileId = ':fileId') => `${ROUTE.audios.root}/${fileId}`,
	},
	share: {
		root: '/share',
		id: (sharingId = ':sharingId') => `${ROUTE.share.root}/${sharingId}`,
		project: (sharingId = ':sharingId') => `${ROUTE.share.id(sharingId)}/project`,
		file: {
			id: (sharingId = ':sharingId', fileId = ':fileId') =>
				`${ROUTE.share.id(sharingId)}/file/${fileId}`,
		},
	},
	projects: {
		root: '/projects',
		id: (projectId = ':projectId') => `${ROUTE.projects.root}/v/${projectId}`,
		project: {
			share: (projectId = ':projectId') => `${ROUTE.projects.id(projectId)}/share`,
		},
	},
	series: {
		root: '/series',
		create: () => `${ROUTE.series.root}/create`,
		id: (seriesId = ':seriesId') => `${ROUTE.series.root}/v/${seriesId}/seasons`,
		seasons: {
			root: (seriesId = ':seriesId') => `${ROUTE.series.id(seriesId)}`,
			create: (seriesId = ':seriesId') => `${ROUTE.series.seasons.root(seriesId)}/create`,
			id: (seriesId = ':seriesId', seasonId = ':seasonId') =>
				`${ROUTE.series.seasons.root(seriesId)}/v/${seasonId}`,
			episodes: {
				root: (seriesId = ':seriesId', seasonId = ':seasonId') =>
					`${ROUTE.series.seasons.id(seriesId, seasonId)}/episodes`,
				create: (seriesId = ':seriesId', seasonId = ':seasonId') =>
					`${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/create`,
				id: (seriesId = ':seriesId', seasonId = ':seasonId', episodeId = ':episodeId') =>
					`${ROUTE.series.seasons.episodes.root(seriesId, seasonId)}/v/${episodeId}`,
				links: {
					root: (
						seriesId = ':seriesId',
						seasonId = ':seasonId',
						episodeId = ':episodeId',
						linkType = ':linkType'
					) => `${ROUTE.series.seasons.episodes.id(seriesId, seasonId, episodeId)}/${linkType}`,
					create: (
						seriesId = ':seriesId',
						seasonId = ':seasonId',
						episodeId = ':episodeId',
						linkType = ':linkType'
					) =>
						`${ROUTE.series.seasons.episodes.links.root(
							seriesId,
							seasonId,
							episodeId,
							linkType
						)}/create`,
					id: (
						seriesId = ':seriesId',
						seasonId = ':seasonId',
						episodeId = ':episodeId',
						linkType = ':linkType',
						linkId = ':linkId'
					) =>
						`${ROUTE.series.seasons.episodes.links.root(
							seriesId,
							seasonId,
							episodeId,
							linkType
						)}/v/${linkId}`,
				},
			},
		},
		episodes: {
			root: (seriesId = ':seriesId') => `${ROUTE.series.id(seriesId)}/episodes`,
			create: (seriesId = ':seriesId') => `${ROUTE.series.episodes.root(seriesId)}/create`,
			id: (seriesId = ':seriesId', episodeId = ':episodeId') =>
				`${ROUTE.series.episodes.root(seriesId)}/v/${episodeId}`,
			seasons: {
				create: (seriesId = ':seriesId') => `${ROUTE.series.episodes.root(seriesId)}/seasoncreate`, // TODO:
			},
		},
	},
	account: {
		root: '/account',
		pages: {},
	},
};
