export const wait = (ms: number): Promise<NodeJS.Timeout> =>
	new Promise((resolve) => setTimeout(resolve, ms, true));

type TCallbackAsync<T, K> = (value: T, index: number, array: T[]) => Promise<K>;

export const asyncForEach = async <T, K>(
	array: Array<T>,
	callback: TCallbackAsync<T, K>
): Promise<K[]> => {
	const results = [];
	for (let index = 0; index < array.length; index += 1) {
		results.push(await callback(array[index], index, array));
	}
	return results;
};

export const asyncForEachParallel = async <T, K>(
	array: Array<T>,
	callback: TCallbackAsync<T, K>
): Promise<K[]> => {
	return Promise.all(array.map((item, index) => callback(item, index, array)));
};

/** Make form [[x,x,x,...],[y,y,...],[z,z,...],...] one array [x,x,...,y,y,...,z,z,...] */
export const combineArraysOfArrays = <T>(arrayOfArrays: Array<Array<T>>): Array<T> =>
	arrayOfArrays.reduce((acc, array) => [...acc, ...array], [] as Array<T>);

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isEmpty = (val: any): boolean => {
	if (Array.isArray(val)) {
		return !val.length;
	}
	return !val;
};
