import { Divider, IconButton, Menu } from '@mui/material';
import { IconMoreVert } from 'shared/_ui/icon';
import { useMemo } from 'react';
import useMenu from 'shared/hooks/use-menu';
import { DocTypeFiles } from 'shared/constants/docs';
import { useProjectContext } from 'shared/_pages/project/provider';
import FileSyncMenuItem from 'app/_model/sync/components/FileSyncMenuItem';
import RemoveDocumentsMenuItem from 'shared/_model/docs/components/RemoveDocumentsMenuItem';
import SelectFilesMenuItem from 'shared/_model/project-files/SelectFilesMenuItem';
import CreateFolderMenuItem from 'app/_model/project/components/CreateFolderMenuItem';

type Props = {
	type?: DocTypeFiles;
};

export default function ActionFileGroupMenu({ type }: Props) {
	const { anchorEl, open, handleOpen, handleClose } = useMenu();

	const { files, project, removeFile, selectedFileIds } = useProjectContext();

	const selectedFiles = useMemo(() => {
		return files.filter((f) => selectedFileIds.includes(f._id));
	}, [files, selectedFileIds]);

	return (
		<>
			<IconButton onClick={handleOpen} size="small">
				<IconMoreVert />
			</IconButton>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<CreateFolderMenuItem type={type} handleClose={handleClose} />
				<Divider />
				<SelectFilesMenuItem type={type} handleClose={handleClose} />
				<FileSyncMenuItem
					files={selectedFiles}
					disabled={!project.storeInCloud}
					handleClose={handleClose}
				/>
				{/* TODO: implement multiple download */}
				{/* <DownloadFilesMenuItem files={checkedFiles} disabled={!project.storeInCloud} callback={handleClose} /> */}
				<RemoveDocumentsMenuItem docs={selectedFiles} action={removeFile} callback={handleClose} />
			</Menu>
		</>
	);
}
