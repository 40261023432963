import { Field } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IconUploadedToCloud from '../../../../components/IconUploadedToCloud/IconUploadedToCloud';
import IconVideoInPlaylist from '../../../../components/IconVideoInPlaylist/IconVideoInPlaylist';
import Input from '../../../../components/Input/Input';
import S3img from '../../../../components/S3img/S3img';
import IconFileUploadToYoutube from '../../../../components/StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';
import VideoPlayer from '../../../../components/VideoPlayer/VideoPlayer';
import { DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../constants/docs';
import { ROUTE } from '../../../../constants/routes_old';
import { getDownloadUrl } from '../../../../utils/cloud/cloud';
import { prettyDate } from '../../../../utils/format';
import styles from './FinishVideo.module.css';
import { UserContext } from 'shared/Providers/User';
import { useCurrentFile } from 'shared/Providers/CurrentFile';
import { useSharingContext } from 'shared/Providers/Sharing';
import { useEditDocumentContext } from 'shared/hooks/use-edit-document';

export default function FinishVideo() {
  const { sharingId } = useSharingContext();
  const { file, saveFile, readFile } = useCurrentFile();
  const { permissions: { canUseCloud } } = useContext(UserContext);
  const [playURL, setPlayURL] = useState('');

  const { isEdit } = useEditDocumentContext();

  const preparePlayURL = useCallback(async (cloud) => {
    const urlResponse = await getDownloadUrl(cloud);
    if (urlResponse && urlResponse.url) {
      setPlayURL(urlResponse.url);
    }
  }, []);

  const init = useCallback(async () => {
    preparePlayURL(file.cloud);
  }, [preparePlayURL, file.cloud]);

  useEffect(() => {
    init();
  }, [init]);

  const handleSubmit = useCallback(async (values) => {
    try {
      await saveFile({ ...values, _id: file._id });
    } catch (err) {
      alert(err.message);
      console.error(err.message);
    }
  }, [file._id, readFile, saveFile]);

  return (
    <div className={styles.videoDetails}>
      <div className={styles.mediaContainer}>
        <div className={styles.videoPlayer}>
          <VideoPlayer id={file._id} src={playURL} />
          <S3img
            src={file.thumbnail?.cloud?.s3 || file.image}
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.editContainer}>
        <div>
          <Field
            component={Input}
            label="Status:"
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            type={Input.TYPE.LABEL}
            field={{
              value: (
                <>
                  <IconUploadedToCloud cloud={file.cloud} />
                  {!sharingId && <IconVideoInPlaylist videoId={file._id} />}
                  {file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
                    <IconFileUploadToYoutube
                      youtubeId={file.youtube && file.youtube.youtubeId}
                    />
                  )}
                </>
              ),
            }}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
          />
          <Field
            name="_id"
            component={Input}
            label="ID:"
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
          />
          <Field
            component={Input}
            label="Created:"
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            field={{ value: prettyDate(file.created) }}
            type={Input.TYPE.LABEL}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
          />
          <Field
            component={Input}
            label="Project ID:"
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            type={Input.TYPE.LABEL}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
            field={{
              value: (
                <Link
                  to={!sharingId
                    ? ROUTE.projects.id(file.projectId)
                    : ROUTE.share.project(sharingId)
                  }
                >
                  {file.projectId}
                </Link>
              ),
            }}
          />
          <Field
            name="filename"
            component={Input}
            label="File name"
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
          />
          {
            isEdit && canUseCloud && (
              <Field
                name="image"
                type={Input.TYPE.FILE}
                component={Input}
                label="Image:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                readOnly={!isEdit}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                uploadSuccess={img => handleSubmit({
                  image: undefined,
                  thumbnail: {
                    ...file.thumbnail,
                    cloud: {
                      ...file.thumbnail?.cloud,
                      s3: img,
                    }
                  }
                })}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}
