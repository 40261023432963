import {
	useCallback, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { initGlobals } from 'shared/constants/handlers';
import { checkLoginLocalSettings } from 'shared/new/loginUtils';
import { UserContext } from 'shared/Providers/User';
import { isSharing } from 'shared/utils/webSettings';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useInitApp() {
	const { user } = useContext(UserContext);
	const [isDone, setIsDone] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const dispatch = useDispatch();

	const init = useCallback(async () => {
		console.log('tst: init');
		await dispatch(initGlobals());
		if (isSharing()) {
			// await dispatch(shareCheckLogin());
		} else {
			// await dispatch(checkLogin());
			await checkLoginLocalSettings();
		}
	}, [dispatch]);

	useEffect(() => {
		// console.log('tst: init effect', inProgress, isDone, user?.databaseId);
		if (user?.databaseId) {
			// if (inProgress || isDone) {
			//   return;
			// }
			setIsDone(false);
			setInProgress(true);

			init().finally(() => {
				setIsDone(true);
				setInProgress(false);
			});
		}
	}, [
		// dispatch,
		init,
		// inProgress,
		// isDone,
		user?.databaseId,
	]);

	return { isDone, inProgress };
}
