import React, { useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { ROUTE } from '../../../constants/routes_old';
import { webRegister } from '../../../modules/user/actions';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';
import { getLocalHelpScreens, setLocalHelpScreens, KEY_FIRST_REGISTER } from '../../../utils/localStorageUtils';
import { useHistory } from 'react-router';

const SCHEMA = yup.object().shape({
    name: yup.string()
        .trim()
        .required()
        .min(1)
        .max(100),
    email: yup.string()
        .required()
        .email(),
    password: yup.string()
        .required()
        .min(1),
    passwordConfirm: yup.string()
        .required()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function Register() {
  const helpFlag = getLocalHelpScreens()[KEY_FIRST_REGISTER] || false
  const history = useHistory();

  const goToLoginPage = useCallback(() => {
    setLocalHelpScreens(KEY_FIRST_REGISTER, true);
    history.push(ROUTE.login);
  }, [history]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await webRegister(values);
      setLocalHelpScreens(KEY_FIRST_REGISTER, true);
      history.push(ROUTE.registerSuccess);
    } finally {
      setSubmitting(false);
    }
  }, [history]);

  return (
    <Formik
      initialValues={{
        name: '',
        password: '',
        passwordConfirm: '',
        email: ''
      }}
      validationSchema={SCHEMA}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => (
        <Form>
          {!helpFlag && (
            <h1>
              Okay, create an account
              <br />
              and let’s get you started!
            </h1>
          )}
          <Field name="name" component={Input} label="Name" required />
          <Field name="email" type="email" component={Input} label="Email" required />
          <Field name="password" type="password" component={Input} label="Password" required />
          <Field
            name="passwordConfirm"
            type="password"
            component={Input}
            label="Confirm password"
            required
          />
          <FormButtons>
            <Button
              type="submit"
              disabled={isSubmitting}
              color={Button.COLOR.PRIMARY}
            >
              Register
            </Button>
            <Button
              disabled={isSubmitting}
              color={Button.COLOR.PRIMARY}
              onClick={goToLoginPage}
            >
              Go to Login page
            </Button>
          </FormButtons>
        </Form>
      )}
    />
  );
}
