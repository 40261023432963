import { IconButton, Menu } from '@mui/material';
import { IconMoreVert } from 'shared/_ui/icon';
import useMenu from 'shared/hooks/use-menu';
import { TDocFile } from 'shared/constants/docs';
import { useProjectContext } from 'shared/_pages/project/provider';
import FileSyncMenuItem from 'app/_model/sync/components/FileSyncMenuItem';
import DownloadFilesMenuItem from 'shared/_model/cloud/components/DownloadFilesMenuItem';
import RemoveDocumentsMenuItem from 'shared/_model/docs/components/RemoveDocumentsMenuItem';
import EditFileDescriptionMenuItem from 'shared/_model/project-files/EditFileDescriptionMenuItem';

type Props = {
	file: TDocFile;
};

export default function ActionFileMenu({ file }: Props) {
	const { anchorEl, open, handleOpen, handleClose } = useMenu();
	const { project, readFiles, removeFile, saveFile } = useProjectContext();

	return (
		<>
			<IconButton onClick={handleOpen} size="small">
				<IconMoreVert />
			</IconButton>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<FileSyncMenuItem
					files={[file]}
					disabled={!project.storeInCloud}
					callback={readFiles}
					handleClose={handleClose}
				/>
				<DownloadFilesMenuItem
					files={[file]}
					disabled={!project.storeInCloud}
					callback={handleClose}
				/>
				<RemoveDocumentsMenuItem docs={file} action={removeFile} callback={handleClose} />
				<EditFileDescriptionMenuItem doc={file} action={saveFile} callback={handleClose} />
			</Menu>
		</>
	);
}
