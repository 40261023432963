import { MenuItem } from '@mui/material'
import React, { useCallback } from 'react'

type ToggleActiveMenuItemProps = {
  isActive: boolean;
  disabled?: boolean;
  toggleActive: () => void;
  callback: () => void;
}

export default function ToggleActiveMenuItem({ isActive, disabled = false, toggleActive, callback }: ToggleActiveMenuItemProps): JSX.Element {
  const onClick = useCallback(() => {
    toggleActive();
    if (callback) {
      callback();
    }
  }, [toggleActive, callback]);

  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      {isActive ? 'Deactivate' : 'Activate'}
    </MenuItem>
  );
}
