import { FormControl, InputLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import { useFormik } from 'formik';
import cn from 'classnames';
import { ReactNode } from 'react';
import { deepmerge } from '@mui/utils';

type StyledTextFieldProps = {
	labelProps?: {
		width: string | number;
	};
	readOnly?: boolean;
	onReadOnlyClick?: () => void;
};

type CommonTextFieldProps = Omit<
	TextFieldProps,
	'value' | 'onChange' | 'onBlur' | 'error' | 'helperText'
> &
	StyledTextFieldProps;

type FormTextFieldProps = CommonTextFieldProps & {
	formik: ReturnType<typeof useFormik>;
	readOnly?: boolean;
	onReadOnlyClick?: () => void;
	name: string;
};

function TextFieldStyled(props: TextFieldProps & StyledTextFieldProps) {
	const {
		className,
		labelProps,
		readOnly = false,
		onReadOnlyClick,
		onClick,
		...fieldProps
	} = props;
	const { InputProps, name, sx = {} } = fieldProps;

	return (
		<TextField
			id={name}
			{...fieldProps}
			onClick={readOnly ? onReadOnlyClick ?? onClick : onClick}
			className={cn('field', 'editable', className)}
			InputProps={{ readOnly, ...InputProps }}
			sx={deepmerge(sx, {
				'.MuiFormLabel-root': {
					...(labelProps?.width ? { width: labelProps.width } : {}),
				},
			})}
		/>
	);
}

type FieldTextFieldProps = Pick<
	TextFieldProps,
	'className' | 'name' | 'label' | 'sx' | 'fullWidth'
> &
	StyledTextFieldProps & {
		value: ReactNode;
	};

export function FieldTextField(props: FieldTextFieldProps) {
	const { className, labelProps, fullWidth, ...fieldProps } = props;
	const { label, name, sx, value } = fieldProps;

	const sxLabel = {
		...(sx?.['.MuiFormLabel-root' as keyof typeof sx] ?? {}),
		...(labelProps?.width ? { width: labelProps.width } : {}),
	};

	return (
		<FormControl className={cn('field', className)} sx={{ flexDirection: 'row' }} {...fieldProps}>
			<InputLabel htmlFor={name} sx={sxLabel}>
				{label}
			</InputLabel>
			<Typography id={name} display="inline-block" width={fullWidth ? '100%' : undefined}>
				{value}
			</Typography>
			{/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
		</FormControl>
	);
}

export function FormTextField({ formik, ...props }: FormTextFieldProps) {
	const { name } = props;

	const helperText = formik.touched[name] && (formik.errors[name] as TextFieldProps['helperText']);

	return (
		<TextFieldStyled
			id={name}
			{...props}
			value={formik.values[name]}
			onChange={formik.handleChange}
			onBlur={formik.handleBlur}
			error={formik.touched[name] && Boolean(formik.errors[name])}
			helperText={helperText}
		/>
	);
}
