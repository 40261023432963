import PropTypes from 'prop-types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CreateFolderMenuItem() {
  return null;
}

CreateFolderMenuItem.propTypes = {
  disabled: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  callback: PropTypes.func,
};
