import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import { SERIES_TYPES } from '../../../../constants/pouchDB';
import { ROUTE } from '../../../../constants/routes_old';
import * as seriesActions from '../../../../modules/series/actions';
import { SERIES_SCHEMA } from '../../../../constants/general';
import styles from './SeriesCreate.module.css';
import { useHistory } from 'react-router';

export default function SeriesCreate() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const newSeries = await dispatch(seriesActions.insert(values));
      dispatch(seriesActions.readSeriesList());
      history.push(ROUTE.series.id(newSeries._id));
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, history]);

  return (
    <div className={styles.container}>
      <h1 className="margin-top-none">New Series</h1>
      <Formik
        initialValues={SERIES_SCHEMA.default()}
        validationSchema={SERIES_SCHEMA}
        onSubmit={handleSubmit}
        render={({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <Field
              name="title"
              component={Input}
              label="Name:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
              spellCheck
            />
            <Field
              name="description"
              type="textarea"
              component={Input}
              label="Description:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
              spellCheck
            />
            <Field
              name="keywords"
              component={Input}
              type={Input.TYPE.MULTI_SELECT}
              maxLength={500}
              label="Keywords:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              showCopyButton
              inputProps={{ spellCheck: true }}
            />
            <Field
              name="seriesType"
              component={Input}
              type={Input.TYPE.DROPDOWN}
              label="Type:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              data={Object.keys(SERIES_TYPES)}
              defaultValue={values?.seriesType}
              required
              onChange={value => setFieldValue('seriesType', value)}
            />
            <Field
              name="sponsored"
              label="Sponsored:"
              component={Input}
              type={Input.TYPE.SWITCH}
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              onChange={e => setFieldValue('sponsored', e.target.value)}
            />
            <Field
              name="seasons"
              label="Seasons:"
              component={Input}
              type={Input.TYPE.SWITCH}
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              onChange={e => setFieldValue('seasons', e.target.value)}
            />
            <FormButtons>
              <Button
                  type="submit"
                  disabled={isSubmitting}
                  color={Button.COLOR.PRIMARY}
              >
                Create
              </Button>
            </FormButtons>
          </Form>
        )}
      />
    </div>
  );
}
