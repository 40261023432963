import { useCallback } from 'react';
import ApiError from './api-error';
import useApiSimple, { TApiError } from './useApiSimple';

type TRGetUserPropertiesSuccess = { total_size: number };

type TRGetUserProperties = TRGetUserPropertiesSuccess | TApiError

const validate = (response?: TRGetUserProperties): response is TRGetUserPropertiesSuccess => !!(response && ('total_size' in response));

const convert = (response?: TRGetUserProperties) => (validate(response) ? response : undefined);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiTrackingGetProjectAllFilesSize(onError?: (error: ApiError) => void) {
	const {
		response, error, loading, doFetch,
	} = useApiSimple<TRGetUserProperties, TRGetUserPropertiesSuccess>(
		{ slug: '/api/tracking/get-project-all-files-size', lazy: true },
		{ validate, convert, onError },
	);

	const doGetProjectSize = useCallback(
		(projectId: string) => doFetch(undefined, { project_id: projectId }),
		[doFetch],
	);

	return {
		projectSize: response?.total_size || 0,
		error,
		loading,
		doGetProjectSize,
	};
}
