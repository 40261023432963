import { LIST_OPTIONS } from 'shared/constants/pouchDB';
import { findUserDocs, readUserDoc } from 'shared/handlers/userDB';
import { getProjectTotals } from 'shared/modules/projects/actions';
import { addFilters } from 'shared/utils/pouchDB/documents';

/**
 * Return files by Project Id(s)
 * @param {string|string[]} projectIds Project id(s)
 * @param {{}[]} filters additional filters
 * @param {string[]} fields fields in result
 * @return {function(*): *}
 */
export const fetchProjectFiles = (projectIds, filters = [], fields = undefined) => async (dispatch) => {
	const opt = {
		...addFilters(LIST_OPTIONS.files, [
			{ key: 'projectId', value: projectIds },
			...filters,
		]),
		sort: [{ title: 'asc' }],
		fields: LIST_OPTIONS.files.fields || fields,
	};
	return dispatch(findUserDocs(opt));
};

/**
 *  Insert file documents into the user DB
 * @param {string} id Document Id
 * @param {boolean} withTotals
 * @return {Promise<{ _id: string, type: string, ...}>} PouchDB Document
 * */
export const readUserProject = (id, withTotals = false) => async (dispatch) => {
	const project = {
		...(await dispatch(readUserDoc(id))),
		...(withTotals ? (await dispatch(getProjectTotals([id])))[id] : {}),
	};

	return project;
};

/**
 * Fetch files in DB, search for them on a drive in the project folder
 * @return {Promise<TDocFile[]>}
 * */
export const scanProjectFiles = (projectParam, newFiles = true, existsFiles = true) => async (dispatch) => {
	const project = (typeof projectParam === 'string')
		? await dispatch(readUserProject(projectParam))
		: projectParam;

	const docs = await dispatch(fetchProjectFiles(project._id));

	return docs;
};
