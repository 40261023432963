import React, { PropsWithChildren } from 'react';
import Snackbar from 'shared/components/Snackbar';
import { DialogProvider } from 'shared/Providers/Dialog';
import { RecentProvider } from 'shared/Providers/Recent';
import { UserProvider } from 'shared/Providers/User';
import { UserDataProvider } from 'shared/Providers/UserData';
import { UserDbProvider } from 'shared/Providers/UserDB';
import SessionProviderNew from 'shared/Providers/SessionNew';
import LocalSettingsProvider from 'shared/Providers/LocalSettings';
import SnackStackProvider from 'shared/Providers/SnackStack';
import SharingProvider from 'shared/Providers/Sharing';
import SessionProvider from 'shared/Providers/Session';
import RemoteDbProvider from 'shared/Providers/RemoteDB';

export function AllProviders({ children }: PropsWithChildren): JSX.Element {
	return (
		<RecentProvider>
			<DialogProvider>{children}</DialogProvider>
		</RecentProvider>
	);
}

export function CommonProviders({ children }: PropsWithChildren): JSX.Element {
	return (
		<SnackStackProvider>
			<Snackbar />
			<SessionProviderNew>
				<SharingProvider>
					<UserProvider>
						<SessionProvider>
							<LocalSettingsProvider>
								<UserDbProvider>
									<RemoteDbProvider>
										<UserDataProvider>{children}</UserDataProvider>
									</RemoteDbProvider>
								</UserDbProvider>
							</LocalSettingsProvider>
						</SessionProvider>
					</UserProvider>
				</SharingProvider>
			</SessionProviderNew>
		</SnackStackProvider>
	);
}
