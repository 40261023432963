import React from 'react';

type TProps = {
  projectId: string
  disabled: boolean
  onChange: () => void
}

export default function ActionProjectReSync(_: TProps): JSX.Element|null {
  return null;
}
