import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useContext, useMemo } from 'react';
import { TApiUser } from 'shared/helpers/types-api';
import { useRoles } from 'shared/helpers/use-roles-hook';
import { useUser } from 'shared/helpers/use-user-hook';
import { UserContext } from 'shared/Providers/User';
import { getUserId } from 'shared/utils/webSettings';
import { ButtonNew } from 'shared/components/ButtonNew';
import Input from 'shared/components/Input/Input';
import styles from 'shared/components/User/user.module.scss';
import ManageUserMenu from './ManageUserMenu'


type TUserProps = {
  id: string
  callback?: () => void
}

type TUserValues = Omit<TApiUser, 'roles'> & { roles: Record<string, boolean> }

export const User: React.FC<TUserProps> = (props) => {
  const { permissions: { canManageUsers } } = useContext(UserContext);

  const { id, callback } = props;

  // TODO: We have separate page to edit current user: Account
  //  Also it will be not good if the latest admin will be able to remove his own roles :-)
  const isMe = useMemo(() => id === getUserId(), [id]);

  const { roles } = useRoles();
  const { user, save, activate, deactivate } = useUser({ id });

  const userValues = useMemo<TUserValues | undefined>(() => {
    if (user) {
      return {
        ...user,
        roles: user.roles.reduce((acc, { key }) => ({ ...acc, [key]: true }), {})
      };
    }
    return undefined;
  }, [user]);


  const handleSubmit = useCallback(async (values: TUserValues, actions: FormikHelpers<TUserValues>) => {
    await save({
      // ...values, // TODO: For now we will edit roles only
      user_id: values.user_id,
      roles: Object.entries(values.roles).filter(([, value]) => value).map(([key]) => key)
    });

    actions.setSubmitting(false);

    if (callback) {
      await callback();
    }
  }, [save, callback]);

  const toggleActive = useCallback(async (setSubmitting: (s: boolean) => void) => {
    if (user) {
      setSubmitting(true);
      if (user.is_active) {
        await deactivate()
      } else {
        await activate()
      }
      setSubmitting(false);
    }
  }, [user, deactivate, activate]);


  return (
    <>
      {userValues && (<Formik
        initialValues={userValues}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({ dirty, isSubmitting, setSubmitting }) => {
          const isReadOnly = !canManageUsers || isSubmitting || isMe;
          return (
            <Form>
              <div className={styles.flexHeader}>
                <h3 className={styles.subheader}>Details{!userValues.is_active ? ' (deactivated)' : ''}</h3>
                {canManageUsers && (
                  <div>
                    <ButtonNew
                      type="submit"
                      disabled={isReadOnly || !dirty}
                    >
                      Save
                    </ButtonNew>
                    <ManageUserMenu
                      isActive={userValues.is_active}
                      disabledToggleActive={isReadOnly || dirty}
                      toggleActive={() => toggleActive(setSubmitting)}
                    />
                  </div>
                )}
              </div>
              <div className={styles.fields}>
                <Field
                  name="name"
                  label="Full Name"
                  component={Input}
                  readOnly//={!editMode}
                  disabled={isReadOnly}
                  // editClick={this.toggleEdit}
                  labelWidth={Input.LABEL_WIDTH.FULL}
                  spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                />
                <Field
                  name="email"
                  label="Email"
                  placeholder="example@domain.com"
                  component={Input}
                  readOnly//={!editMode}
                  disabled={isReadOnly}
                  // editClick={this.toggleEdit}
                  labelWidth={Input.LABEL_WIDTH.FULL}
                  spaceBottom={Input.SPACE_BOTTOM.MEDIUM}
                />
              </div>
              <h3 className={styles.subheader}>Roles</h3>
              <div className={styles.fields}>
                {roles.map(({ key, name }) => (
                  <Field
                    key={key}
                    labelOnTheRight
                    name={`roles.${key}`}
                    label={name}
                    component={Input}
                    type={Input.TYPE.SWITCH}
                    spaceBottom={Input.SPACE_BOTTOM.SMALL}
                    readOnly={isReadOnly}
                  />
                ))}
              </div>
            </Form>
          );
        }}
      />)}
    </>
  );
}
