import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../StatusIcon/StatusIcon';
import icon from './icon_youtube.svg';
import { UserContext } from 'shared/Providers/User';

export default function IconFileUploadToYoutube({ youtubeId, className }) {
  const { permissions: { canPublishMedia } } = useContext(UserContext);

  const statusIcon = useMemo(() => (
    <StatusIcon
      enabled={!!youtubeId}
      icon={icon}
      altOn="not uploaded"
      altOff="uploaded"
      title={youtubeId}
      className={className}
    />
  ), [youtubeId, className]);

  if (!canPublishMedia) {
    return null;
  }

  if (!youtubeId) {
    return statusIcon;
  }

  return (
    <a href={`https://www.youtube.com/watch?v=${youtubeId}`} target="_blank" rel="noopener noreferrer">
      {statusIcon}
    </a>
  );
}

IconFileUploadToYoutube.propTypes = {
  youtubeId: PropTypes.string,
  className: PropTypes.string,
};
