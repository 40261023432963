import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/Button/Button';
import ListContainer from 'shared/components/ListContainer/ListContainer';
import { useListFilter } from 'shared/components/ListFilter';
import { WindowContainer } from 'shared/components/WindowContainer';
import { TDocProject } from 'shared/constants/docs';
import { CONTENT_TYPE } from 'shared/constants/general';
import { TStore } from 'shared/helpers/types-store';
import { usePrevious } from 'shared/hooks/common';
import { getProjectTotals, readProjectsList } from 'shared/modules/projects/actions';
import ProjectCreate from 'shared/Pages/common/ProjectCreate';
import ProjectListItemDetails from 'shared/Pages/common/ProjectsList/ProjectListItemDetails';
import ProjectListItemIcons from 'shared/Pages/common/ProjectsList/ProjectListItemIcons';
import { ACTION_TYPES } from 'shared/modules/projects/reducer';
import useResyncProjects from 'app/hooks/use-resync-projects';

type TProjectsListProps = {
	projects: TDocProject[];
	isReady: boolean;
	readList: () => void;
	getTotals: () => void;
	reSyncAll: () => void;
};

export const ProjectsList: FC<TProjectsListProps> = () => {
	const dispatch = useDispatch();
	const { projects, isReady, selector } = useSelector<
		TStore,
		{ projects: TDocProject[]; isReady: boolean; selector: string[] }
	>(
		({
			projects: {
				list,
				readDate,
				options: {
					selector: { $and: selector },
				},
			},
		}) => ({
			projects: list,
			isReady: !!readDate,
			selector: selector.find((s) => !!s.tags)?.tags?.$in ?? [],
		})
	);

	const prevProjects = usePrevious<TDocProject[]>(projects);
	const [totals, setTotals] = useState<Record<string, any>>({});
	const [showCreate, setShowCreate] = useState(false);
	const toggleCreateNewProject = useCallback(() => {
		setShowCreate((s) => !s);
	}, []);

	const readList = useCallback(async () => {
		await dispatch(readProjectsList());
	}, [dispatch]);

	const reSyncAll = useResyncProjects();

	const readTotals = useCallback(async () => {
		const totalsIds = Object.keys(totals);
		const projectIds = projects?.map((pr) => pr._id) ?? [];

		const newIds = projectIds.filter((id) => !totalsIds.includes(id));
		if (newIds) {
			const newTotals = await dispatch(getProjectTotals(newIds));

			if (true) {
				// (this._mounted) { // TODO: How to control this in React.FC?
				setTotals({
					...projectIds.reduce(
						(acc, id) => ({
							...acc,
							[id]: totals[id],
						}),
						{}
					),
					...newTotals,
				});
			}
		}
	}, [dispatch, projects, totals]);

	const setTagFilter = useCallback(
		(tags: string[]) => {
			dispatch({
				type: ACTION_TYPES.projects.filter,
				selector: [...(tags.length ? [{ tags: { $in: tags } }] : [])],
			});
			void readList();
		},
		[dispatch, readList]
	);

	const filterProps = useListFilter({
		tagSearch: {
			enabled: true,
			onChange: setTagFilter,
			values: selector,
		},
	});

	useEffect(() => {
		void readList();
	}, [readList]);

	const shouldUpdateTotals = useMemo<boolean>(() => {
		return projects !== prevProjects;
	}, [projects, prevProjects]);

	useEffect(() => {
		if (shouldUpdateTotals) {
			void readTotals();
		}
	}, [readTotals, shouldUpdateTotals]);

	return (
		<>
			<ListContainer
				ready={isReady}
				title="Projects"
				items={projects?.map((pr) => ({ ...pr, ...totals[pr._id] })) ?? []}
				actions={[
					{
						label: 'Sync projects',
						onClick: reSyncAll,
						color: Button.COLOR.GRAY,
						visible: !!reSyncAll,
					},
					{
						label: 'Add Project',
						onClick: toggleCreateNewProject,
					},
				]}
				details={(item: Record<string, any>) => (
					<ProjectListItemDetails {...item} onTagClick={filterProps.tagSearch.add} />
				)}
				icons={ProjectListItemIcons}
				type={CONTENT_TYPE.PROJECT}
				filterProps={filterProps}
			/>
			{showCreate && (
				<WindowContainer closeAction={toggleCreateNewProject}>
					<ProjectCreate
					// onChange={toggleCreateNewProject}
					/>
				</WindowContainer>
			)}
		</>
	);
};
