import React, { useContext, useMemo } from 'react';
import { getShareProperty } from 'shared/_model/sharing/utils'
import ShareIcon from 'shared/icons/share-icon'
import { UserContext } from 'shared/Providers/User'
import { isSharing } from 'shared/utils/webSettings'

const COLOR_GREEN = '#00a14b';

type DriveStatusIconProps = Parameters<typeof ShareIcon>[0] & {
  disabled?: boolean;
  type: 'project' | 'file';
  sharing: any;
};

const isShareSet = (sharing: any, type: 'project' | 'file') => !!getShareProperty(sharing)
  .filter((sh: unknown) => (sh as { type: string }).type === type)
  .length;

export default function ShareStatusIcon({ disabled = false, type, sharing, fontSize = 'large' }: DriveStatusIconProps): JSX.Element | null {
  const { permissions: { canUseCloud } } = useContext(UserContext);

  const isSet = isShareSet(sharing, type);

  const color = useMemo(() => {
    return isSet && !disabled ? undefined : 'disabled';
  }, [isSet, disabled]);
  const sxProps = useMemo(() => {
    if (disabled) {
      return undefined;
    }
    return isSet ? { color: COLOR_GREEN } : undefined;
  }, [isSet, type, disabled]);


  if (!canUseCloud) {
    return null;
  }
  if (isSharing()) {
    return null;
  }

  return (
    <ShareIcon
      fontSize={fontSize}
      color={color}
      sx={sxProps}
    />
  );
}
