import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { TToken } from 'shared/model/token-types';
import { TUser } from 'shared/model/user-types';
import { convertApiUser } from 'shared/model/user-utils';
import useApiGetUserId from 'shared/utils/api/use-api-get-user-id';
import useApiGetUserProperties from 'shared/utils/api/use-api-get-user-properties';
import useApiTokenAuth from 'shared/utils/api/use-api-token-auth';
import {
	getLocalStorageItem as getLocalStorageItemOld,
	getLocalWebKey,
} from 'shared/utils/localStorageUtils';
import {
	getLocalStorageItem,
	LOCAL_STORAGE_KEY_TOKEN,
	LOCAL_STORAGE_KEY_USER,
	setLocalStorageItem,
} from 'shared/utils/local-storage';
import { actionSetUserId, actionSetUserProperties } from 'shared/modules/user/userActions';
import { actionAuthError } from 'shared/modules/general';
import { ACTIONS } from 'shared/modules/user/actions';

function readCurrentToken() {
	let currentToken = getLocalStorageItem(LOCAL_STORAGE_KEY_TOKEN);
	if (!currentToken) {
		// TODO: old logic. remove.
		const oldVal = getLocalStorageItemOld(getLocalWebKey());
		if (oldVal?.token && oldVal?.refreshToken) {
			currentToken = { token: oldVal?.token, refreshToken: oldVal?.refreshToken };
		}
	}
	return currentToken;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useLogin() {
	const dispatch = useDispatch(); // TODO: old logic. remove.
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [isRestoring, setIsRestoring] = useState(true);

	const { doTokenAuth } = useApiTokenAuth();
	const { doGetUserId } = useApiGetUserId();
	const { doGetUserProperties } = useApiGetUserProperties();

	// Get current user/share
	// const [user, setUserState] = useState<TUser>(() => getLocalStorageItem(LOCAL_STORAGE_KEY_USER) ?? {});
	const [user, setUserState] = useState<TUser | undefined>();
	const [token, setTokenState] = useState<TToken | undefined>();
	// const [isValidating, setIsValidating] = useState(false);

	const setToken = useCallback((token?: TToken) => {
		console.log('tst: set token', token?.token?.slice(0, 10));
		setTokenState(token);
		setLocalStorageItem(LOCAL_STORAGE_KEY_TOKEN, token);
	}, []);

	const setUser = useCallback((user?: TUser) => {
		setUserState(user);
		setLocalStorageItem(LOCAL_STORAGE_KEY_USER, user);
	}, []);

	const handleLogout = useCallback(async () => {
		console.trace('tst: handleLogout');
		setUser();
		setToken();
		dispatch(ACTIONS.clear());
	}, [setUser, dispatch]);

	const getUser = useCallback(async () => {
		const id = await doGetUserId();
		console.log('tst: user_id', id);
		if (id) {
			dispatch(actionSetUserId(id));
			const userProps = await doGetUserProperties(id);
			dispatch(actionSetUserProperties(userProps));
			console.log('tst: userProps', JSON.stringify(userProps)?.slice(0, 10));
			return userProps;
		}
		console.log('tst: getUser', undefined);
		dispatch(actionAuthError('Unable to login'));
		return undefined;
	}, [doGetUserId, doGetUserProperties, dispatch]);

	const handleLogin = useCallback(
		async (email: string, password: string) => {
			setIsLoggingIn(true);
			const token = await doTokenAuth(email, password);
			console.log('tst: token', token?.token?.slice(0, 10));
			if (token) {
				setToken(token);

				const usr = convertApiUser(await getUser());
				console.log('tst: usr', JSON.stringify(usr)?.slice(0, 10));
				if (usr) {
					setUser(usr);
					return;
				}
			}
			console.log('tst: handleLogin', 'logout');
			handleLogout();
			setIsLoggingIn(false);
		},
		[doTokenAuth, getUser, handleLogout]
	);

	const restoreSession = useCallback(async () => {
		console.log('tst: restoreSession start');
		const currentToken = readCurrentToken();
		// TODO: decode token and if it expired - decode refreshToken and if it is not expired - do refresh token
		if (currentToken) {
			setToken(currentToken); // TODO: old logic. replace to setTokenState()

			const usr = convertApiUser(await getUser());
			if (usr) {
				setUser(usr);
				setIsRestoring(false);
				return;
			}

			dispatch(actionAuthError('Unable to restore session'));
			handleLogout();
		}
		console.log('tst: restoreSession off');
		setIsRestoring(false);
	}, [dispatch, getUser, handleLogout]);

	useEffect(() => {
		void restoreSession();
	}, [restoreSession]);

	return {
		user,
		token,
		setToken,
		isLoggingIn,
		isRestoring,
		login: handleLogin,
		logout: handleLogout,
	};
}
