import { getFrameCanvas } from './canvas';
import { getDefaultHeight, getDefaultPosition, getDefaultQuality, getDefaultTimeout, getDefaultType, getDefaultWidth, validateErrors, validateHeight, validatePosition, validateQuality, validateSource, validateTimeout, validateWidth } from './options'
import { getTime } from './utils';

type TGetThumbnailLanczosOptions = {
  height: number;
  position: number;
  quality?: number;
  timeout: number;
  type: string;
  width: number;
}

type TGetThumbnailLanczosResult = {
  height: number;
  position: number;
  type: string;
  url: string;
  width: number;
  time: number;
}

function getOptions(options?: Partial<TGetThumbnailLanczosOptions>): TGetThumbnailLanczosOptions {
  const { width, height, type, quality, position, timeout } = options ?? {};
  return {
    height: getDefaultHeight(height),
    width: getDefaultWidth(width),
    type: getDefaultType(type),
    quality: getDefaultQuality(quality),
    position: getDefaultPosition(position),
    timeout: getDefaultTimeout(timeout),
  };
}

function validate(videoSrc: string, options?: Partial<TGetThumbnailLanczosOptions>): TGetThumbnailLanczosOptions {
  const opts = getOptions(options);

  validateErrors([
    validateSource(videoSrc),
    validatePosition(opts.position),
    validateTimeout(opts.timeout),
    validateQuality(opts.quality),
    validateHeight(opts.height),
    validateWidth(opts.width),
  ]);

  return opts;
}

// Returns a function that calculates lanczos weight
function lanczosCreate(lobes: number): (x: number) => number {
  return (x: number): number => {
    if (x > lobes) {
      return 0;
    }
    x *= Math.PI;
    if (Math.abs(x) < 1e-16) {
      return 1;
    }
    const xx = x / lobes;
    return Math.sin(x) * Math.sin(xx) / x / xx;
  };
}

type InputMediaElement = HTMLCanvasElement | HTMLImageElement;

// elem: canvas element, img: image element, sx: scaled width, lobes: kernel radius
async function doLanczosResize(canvas: HTMLCanvasElement, img: InputMediaElement, sx: number, lobes: number) {
  canvas.width = img.width;
  canvas.height = img.height;
  canvas.style.display = "none";
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error('unable to create context("2d")');
  }
  ctx.drawImage(img, 0, 0);
  let src: ImageData = ctx.getImageData(0, 0, img.width, img.height);

  const destWidth = sx;
  const destHeight = Math.round(img.height * sx / img.width);
  const dest = {
    width: destWidth,
    height: destHeight,
    data: new Array(destWidth * destHeight * 3),
  };
  const lanczos = lanczosCreate(lobes);
  const ratio = img.width / sx;
  const rcp_ratio = 2 / ratio;
  const range2 = Math.ceil(ratio * lobes / 2);
  const cache = <Record<number,Record<number, number>>>{};
  const center = { x: 0, y: 0 };
  const iCenter = { x: 0, y: 0 };

  const process2 = async () => {
    canvas.width = dest.width;
    canvas.height = dest.height;
    ctx.drawImage(img, 0, 0, dest.width, dest.height);
    src = ctx.getImageData(0, 0, dest.width, dest.height);
    let idx, idx2;
    for (let i = 0; i < dest.width; i++) {
      for (let j = 0; j < dest.height; j++) {
        idx = (j * dest.width + i) * 3;
        idx2 = (j * dest.width + i) * 4;
        src.data[idx2] = dest.data[idx];
        src.data[idx2 + 1] = dest.data[idx + 1];
        src.data[idx2 + 2] = dest.data[idx + 2];
      }
    }
    ctx.putImageData(src, 0, 0);
    canvas.style.display = "block";
  };

  const process1 = async (u: number) => {
    center.x = (u + 0.5) * ratio;
    iCenter.x = Math.floor(center.x);
    for (let v = 0; v < dest.height; v++) {
      center.y = (v + 0.5) * ratio;
      iCenter.y = Math.floor(center.y);
      let a, r, g, b;
      a = r = g = b = 0;
      for (let i = iCenter.x - range2; i <= iCenter.x + range2; i++) {
        if (i < 0 || i >= src.width)
          continue;
        const f_x = Math.floor(1000 * Math.abs(i - center.x));
        if (!cache[f_x])
          cache[f_x] = {};
        for (let j = iCenter.y - range2; j <= iCenter.y + range2; j++) {
          if (j < 0 || j >= src.height)
            continue;
          const f_y = Math.floor(1000 * Math.abs(j - center.y));
          if (cache[f_x][f_y] == undefined)
            cache[f_x][f_y] = lanczos(Math.sqrt(Math.pow(f_x * rcp_ratio, 2) + Math.pow(f_y * rcp_ratio, 2)) / 1000);
          const weight = cache[f_x][f_y];
          if (weight > 0) {
            const idx = (j * src.width + i) * 4;
            a += weight;
            r += weight * src.data[idx];
            g += weight * src.data[idx + 1];
            b += weight * src.data[idx + 2];
          }
        }
      }
      const idx = (v * dest.width + u) * 3;
      dest.data[idx] = r / a;
      dest.data[idx + 1] = g / a;
      dest.data[idx + 2] = b / a;
    }

    await new Promise((resolve) => {
      setTimeout(async () => {
        if (++u < dest.width)
          await process1(u);
        else
          await process2();
        resolve(undefined);
      }, 0);
    });
  };

  await new Promise((resolve) => {
    setTimeout(async () => {
      await process1(0);
      resolve(undefined);
    }, 0);
  });
}

export async function getThumbnailLanczos(videoSrc: string, options: Partial<TGetThumbnailLanczosOptions>): Promise<TGetThumbnailLanczosResult|undefined> {
  // const { width, height, type, quality, position, timeout } = validate(videoSrc, options);
  const { width, height, type, quality, position, timeout } = validate(videoSrc, options);

  const targetRatio = width / height;

  try {
    const canvas = await getFrameCanvas(videoSrc, { position, timeout, ratio: targetRatio });
    if (canvas) {
      try {
        const start = window.performance.now();
        const cv = document.createElement('canvas');
        await doLanczosResize(cv, canvas, width, 3);

        try {
          const url = cv.toDataURL(type, quality);
          return {
            height,
            position,
            type,
            url,
            width,
            time: getTime(start),
          };
        } catch (e) {
          throw new Error(`Can't get dataUrl: ${e}`);
        }
      } catch (e) {
        throw new Error(`Can't downscale image: ${e}`);
      }
    }
  } catch (e) {
    console.error('Error getting video frame', e);
  }

  return undefined;
}
