import { DOC_TYPE_EPISODE, DOC_TYPE_FILE_AUDIO, DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO, DOC_TYPE_FILE_MEDIA, DOC_TYPE_FILE_MISC, DOC_TYPE_FILE_PROJECT, DOC_TYPE_PROJECT, DOC_TYPE_SEASON, DOC_TYPE_SERIES, DOC_TYPE_USER } from 'shared/constants/docs'
import { object, string } from 'yup'

const DOC_TYPES_FILES = [
  DOC_TYPE_FILE_AUDIO,
  DOC_TYPE_FILE_MEDIA,
  DOC_TYPE_FILE_MISC,
  DOC_TYPE_FILE_PROJECT,
  DOC_TYPE_FILE_FINISH_VIDEO,
  DOC_TYPE_FILE_FINISH_AUDIO,
] as const

const DOC_TYPES = [
  ...DOC_TYPES_FILES,
  DOC_TYPE_USER,
  DOC_TYPE_PROJECT,
  DOC_TYPE_SERIES,
  DOC_TYPE_SEASON,
  DOC_TYPE_EPISODE,
] as const;

// type TDocumentTypeFile = typeof DOC_TYPES_FILES[number];
type TDocumentType = typeof DOC_TYPES[number];

export type TDocument = {
  _id: string;
  _rev?: string;
  created?: string;
  updated?: string;
  createdBy?: string;
  title: string;
  type: TDocumentType; // TODO: string???
};

export const DOC_BASE_SCHEMA = object({
  _id: string().required().min(5).default(''),
  _rev: string().optional(),
  created: string().optional().default(() => new Date().toISOString()),
  updated: string().optional().default(() => new Date().toISOString()),
  createdBy: string().optional().default(undefined),
  title: string().required().default(''),
  type: string().required().default(''),
}).required();

console.log('tst: yup: DOC_BASE_SCHEMA', DOC_BASE_SCHEMA.default());
