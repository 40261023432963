// Based on https://github.com/bghveding/use-fetch/blob/6a4d6e495c7106c860e789b1b5bf0bedf973e3a4/src/useFetch.js
import { useRef, useCallback, useEffect, useReducer, Reducer } from 'react';
import md5 from 'blueimp-md5';
import { useAuthRequest } from './http-requests'
import { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import ApiError from './api-error'


type TMethodLower = 'get'|'post'|'put'|'patch'|'delete'
type TMethod = Extract<Method, TMethodLower|Uppercase<TMethodLower>>


const defaultOnError = (error: unknown) => console.error(error);

type TState<R> = {
  response?: R,
  fetching: boolean,
  requestKey?: string,
  error?: ApiError,
};
type TAction<R> = {
  type: 'in-flight',
}|{
  type: 'response',
  payload: {
    fetching: boolean,
    response: R,
    requestKey: string,
  },
}|{
  type: 'error',
  payload: {
    error?: ApiError,
  },
};

function reducer<R>(state: TState<R>, action: TAction<R>): TState<R> {
	switch (action.type) {
		case 'in-flight': {
			// Avoid updating state unnecessarily
			// By returning unchanged state React won't re-render
			if (state.fetching === true) {
				return state;
			}

			return {
				fetching: true,
				response: undefined,
        requestKey: undefined,
				error: undefined,
			};
		}

		case 'response': {
			return {
				error: undefined,
				fetching: action.payload.fetching,
				response: action.payload.response,
				requestKey: action.payload.requestKey,
			};
		}

		case 'error':
			return {
				...state,
				fetching: false,
				error: action.payload.error,
			};

		default:
			return state;
	}
}

const getRequestKey = (data?: Record<string, unknown>) => JSON.stringify(data);

const isReadRequest = (method: TMethod) => method.toUpperCase() === 'GET';

const requestsCache: Record<string, unknown> = {};

type TUseApiParams<R, D extends Record<string, unknown>, P extends Record<string, string> = Record<string, string>> = {
	slug: string,
	slugParams?: P,
	method?: TMethod,
	lazy?: boolean,
	data?: D,
  config?: AxiosRequestConfig,
	requestKey?: string,
	onError?: (error: ApiError) => void,
	onSuccess?: (response?: R) => void,
	cache?: boolean,
};

type TUseApiResponse<R, D extends Record<string, unknown>, P extends Record<string, string> = Record<string, string>> = {
  response?: R,
  loading: boolean,
  error?: ApiError,
  requestKey?: string,
  doFetch: (extraData?: Partial<D>, extraSlugParams?: P) => Promise<R|ApiError>
};

export type TUseApi<TResponse, TData extends Record<string, unknown> = Record<string, unknown>, TParams extends Record<string, string> = Record<string, string>> = (params: TUseApiParams<TResponse, TData, TParams>) => TUseApiResponse<TResponse, TData, TParams>

const useApi = <
R,
D extends Record<string, unknown> = Record<string, unknown>,
P extends Record<string, string> = Record<string, string>
>({
	slug,
	slugParams,
	method = 'GET',
	lazy,
	data,
  config,
	requestKey: userRequestKey,
	onError = defaultOnError,
	onSuccess,
	cache = false,
}: Parameters<TUseApi<R, D, P>>[0]): ReturnType<TUseApi<R, D, P>> => {
	const abortControllerRef = useRef<Record<string, AbortController>>({});
	const authRequest = useAuthRequest();

	const isLazy = lazy === undefined ? !isReadRequest(method) : lazy;

	const [state, dispatch] = useReducer<Reducer<TState<R>, TAction<R>>>(reducer, {
		response: undefined,
		fetching: !isLazy,
		error: undefined,
	});

	function setFetching() {
		dispatch({ type: 'in-flight' });
	}

	function setResponse(response: R, requestKey: string, fetching = false) {
		dispatch({ type: 'response', payload: { response, requestKey, fetching } });
	}

	function setError(error: ApiError) {
		dispatch({ type: 'error', payload: { error } });
	}

	const cancelRunningRequest = (key?: string) => {
    if (key) {
      abortControllerRef.current[key]?.abort();
    } else {
      Object.values(abortControllerRef.current).forEach((ac) => {
        ac.abort();
      });
    }
	};

	const doFetch = useCallback<TUseApiResponse<R, D, P>['doFetch']>(async (extraData, extraSlugParams) => {
    const _slugParams = { ...slugParams, ...extraSlugParams };
    const _data = { ...data, ...extraData };

    const searchParams = new URLSearchParams(_slugParams).toString();
    const route = `${slug}${searchParams ? `?${searchParams}` : ''}`;
    const m = method.toLowerCase() as Lowercase<TMethod>;

    const requestKey = userRequestKey || getRequestKey({
      data: _data,
      method: method.toLowerCase(),
      slug,
      slugParams: _slugParams,
    });
    const cacheKey = md5(requestKey);

    cancelRunningRequest(cacheKey);

    abortControllerRef.current[cacheKey] = new AbortController();

    let promise: Promise<AxiosResponse<R>>;
    if (cache && requestsCache[cacheKey]) {
      promise = Promise.resolve<AxiosResponse<R>>(requestsCache[cacheKey] as AxiosResponse<R>);
    } else {
      promise = authRequest.request<R>({
        ...config,
        method,
        url: route,
        [m === 'get' ? 'params' : 'data']: _data,
        signal: abortControllerRef.current[cacheKey].signal,
      });
    }

    setFetching();

    return promise
      .then(response => {
        if (cache) {
          requestsCache[cacheKey] = response;
        }
        setResponse(response.data, requestKey);
        if (onSuccess) {
          onSuccess(response.data);
        }

        return response.data;
      })
      .catch(error => {
        if (!abortControllerRef.current[cacheKey].signal.aborted && !(error instanceof ApiError && error.aborted)) {
          setError(error);
          onError(error);
        }

        return error;
      })
      .finally(() => {
        // Remove the abort controller now that the request is done
        abortControllerRef.current = Object.keys(abortControllerRef.current)
          .filter((key) => key !== cacheKey)
          .reduce((acc, key) => ({
            ...acc,
            [key]: abortControllerRef.current[key],
          }), {});
      });
  }, [authRequest, cache, data, method, onError, onSuccess, slug, slugParams, userRequestKey]);

	// Start requesting onMount if not lazy
	// Start requesting if isLazy goes from true to false
	// Start requesting every time the request key changes if not lazy
	useEffect(() => {
		// Do not start request automatically when in lazy mode
		if (isLazy === true) {
			return;
		}

		doFetch();
	}, [doFetch, isLazy]);

	// Cancel any running request when unmounting to avoid updating state after component has unmounted
	// This can happen if a request's promise resolves after component unmounts
	useEffect(() => cancelRunningRequest, []);

  return {
		response: state.response,
		loading: state.fetching,
		error: state.error,
		requestKey: state.requestKey,
		doFetch,
	};
};

export default useApi;
