import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconStoreOnLocalDrive } from 'shared/resources/svg/IconStoreOnLocalDrive';
import Input from '../../Input/Input';
import * as projectActions from '../../../modules/projects/actions';
import styles from './ButtonStoreOnLocalDrive.module.css';
import { UserContext } from 'shared/Providers/User';


export default function ButtonStoreOnLocalDrive({ disabled = false, storeOnLocalDrive, storeInCloud, onChange, projectId }) {
  const dispatch = useDispatch();
  const { permissions: { canUseCloud } } = useContext(UserContext);

  const onClick = useCallback(async () => {
      await dispatch(projectActions.saveProject({
          storeOnLocalDrive: !storeOnLocalDrive,
          _id: projectId,
      }));

      if (onChange) {
          await onChange();
      }
  }, [dispatch, storeOnLocalDrive, projectId]);

  return (
    <>
      <div className={styles.icon}>
        <IconStoreOnLocalDrive {...{
          ...(storeOnLocalDrive ? {} : {fill: "#9e9e9e"})
        }}
        />
      </div>
        {/* <Input
          type={Input.TYPE.SWITCH}
          readOnly={disabled || (storeOnLocalDrive && (!storeInCloud || !canUseCloud))}
          form={{ touched: null, errors: null }}
          field={{ name: 'storeOnLocalDrive', value: storeOnLocalDrive }}
          rootClassName={styles.inputRoot}
          wrapperClassName={styles.inputWrapper}
          onChange={onClick}
        /> */}
    </>
  );
}

ButtonStoreOnLocalDrive.propTypes = {
  projectId: PropTypes.string.isRequired,
  storeOnLocalDrive: PropTypes.bool.isRequired,
  storeInCloud: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
