import React, { useContext, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { RecentContext } from 'shared/Providers/Recent';
import { SeriesContext, SeriesProvider } from 'shared/Providers/Series';
import EpisodeList from 'shared/Pages/common/Series/EpisodeList/EpisodeList';
import SeasonList from 'shared/Pages/common/Series/SeasonList/SeasonList';
import { SeriesDetails } from 'shared/Pages/common/Series/SeriesDetails/SeriesDetails';


const SeriesPageContainer: React.FC = () => {
  const { series } = useContext(SeriesContext);

  const { addRecent } = useContext(RecentContext);
  const rm = useRouteMatch();
  useEffect(() => {
    if (series?._id && series.type) {
      addRecent({
        type: series.type,
        title: series.title,
        to: rm.url
      })
    }
  }, [series, addRecent]);


  if (!series?._id) {
    return null;
  }

  return (
    <>
      <SeriesDetails />
      {series.seasons
        ? (
          <SeasonList
            // showSeasons={series.seasons}
            seriesId={series._id}
          />
        )
        : (
          <EpisodeList
            seriesId={series._id}
            noPadding
          />
        )
      }
    </>
  );
}


export const SeriesPage: React.FC = () => {
  const { seriesId } = useParams<{ seriesId: string }>();

  return (
    <SeriesProvider seriesId={seriesId}>
      <SeriesPageContainer />
    </SeriesProvider>
  );
}
