import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import Button from '../../../components/Button/Button';
import { Icon } from '../../../components/Icon';
import { synchronizer } from '../../../constants/globals';
import { EVENTS_NAMES } from '../../../utils/synchronizer/constans';
import styles from './SyncLayout.module.css';
import SyncLayoutItem from './SyncLayoutItem/SyncLayoutItem';

const MAX_ITEM_COUNT = 10;
const INITIAL_COUNTS = {
  running: 0,
  error: 0,
  success: 0,
};
const ITEM_STATE_ORDER = ['running', 'error', 'success'];

class SyncLayout extends Component {
  state = {
    hide: false,
    counts: { ...INITIAL_COUNTS },
    sync: {
      items: {},
    },
  };

  eventFunc = async ([state, prevState, patch]) => {
    if (this._mounted) {
      if (state.total === state.done) {
        this.debouncedHide(true);
      } else {
        this.debouncedHide.cancel();
      }
      this.setState({ sync: state, counts: this.calculateCounts(state) });
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.debouncedHide = debounce(this.handleShowHide, 5000);
    // eslint-disable-next-line no-unused-vars
    synchronizer.on(EVENTS_NAMES.stateUpdate, this.eventFunc);
  }

  componentWillUnmount() {
    this._mounted = false;
    synchronizer.off(EVENTS_NAMES.stateUpdate, this.eventFunc);
  }

  handleShowHide = (hide = undefined) => {
    if (this._mounted) {
      this.setState(state => ({ hide: hide || !state.hide }));
    }
  };

  handleClear = () => {
    synchronizer.clearDoneItems();
  };

  calculateCounts = (state) => {
    const fn = (acc, fieldName) => (!acc.other ? acc
      : {
        [fieldName]: Math.min(state[fieldName], acc.other),
        other: acc.other - Math.min(state[fieldName], acc.other),
      });

    return {
      ...INITIAL_COUNTS,
      ...ITEM_STATE_ORDER.reduce((acc, fieldName) => ({
        ...acc,
        ...fn(acc, fieldName),
      }), { other: MAX_ITEM_COUNT })
    };
  };

  render() {
    const { sync, counts, hide } = this.state;
    const { items } = sync;

    if (!Object.keys(items).length) {
      return '';
    }

    const sortedItems = Object.values(items)
      .sort((a, b) => a.dateCreate - b.dateCreate);
    const shownItems = [
      ...sortedItems
        .filter(item => item.progress.ended && !item.progress.errorMessage)
        .filter((item, index, array) => (index >= array.length - counts.success)),
      ...sortedItems
        .filter(item => item.progress.ended && !!item.progress.errorMessage)
        .filter((item, index, array) => (index >= array.length - counts.error)),
      ...sortedItems
        .filter(item => !item.progress.ended && item.progress.started)
        .filter((item, index, array) => (index >= array.length - counts.running)),
      ...sortedItems
        .filter(item => !item.progress.started)
        .filter((item, index, array) => (index >= array.length - counts.other)),
    ];

    return (
      <div className={styles.container}>
        {!hide
        && shownItems
          .map(item => <SyncLayoutItem item={item} key={item.id} />)
        }
        <div className={styles.bottomBar}>
          <div className={styles.totals}>
            <div className={styles.progressContainer}>
              <div
                className={styles.progressSuccess}
                style={{
                  width: `${sync.success ? sync.success / sync.total * 100 : 0}%`
                }}
              >
                {' '}
              </div>
              <div
                className={styles.progressError}
                style={{
                  width: `${sync.error ? sync.error / sync.total * 100 : 0}%`
                }}
              >
                {' '}
              </div>
            </div>
            <div className={styles.totalsHeader}>
              {sync.done || 0}
              {' of '}
              {sync.total || 0}
            </div>
          </div>
          <Button
            tiny
            color={Button.COLOR.NONE}
            className={styles.bottomButton}
            onClick={() => this.handleClear()}
          >
            <Icon icon={faTimes} />
          </Button>
          <Button
            tiny
            color={Button.COLOR.NONE}
            className={styles.bottomButton}
            onClick={() => this.handleShowHide()}
          >
            {hide
              ? <Icon icon={faArrowUp} />
              : <Icon icon={faArrowDown} />
            }
          </Button>
        </div>
      </div>
    );
  }
}

export default SyncLayout;
