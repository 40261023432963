import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO, TDocumentTypeFile } from 'shared/constants/docs'

export function getTitleCategoryName(fileType: TDocumentTypeFile): string {
  switch (fileType) {
    case DOC_TYPE_FILE_FINISH_VIDEO:
      return 'Videos';
    case DOC_TYPE_FILE_FINISH_AUDIO:
      return 'Audio';
    default:
      return '';
  }
}
