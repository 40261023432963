import { Button, styled } from '@mui/material';
import { IconPlus } from 'shared/_ui/icon';
import FileTypesSelectDialog from 'shared/_model/project-files/FileTypesSelectDialog';
import { useCallback, useRef, useState } from 'react';
import { DocType, DocTypeFiles } from 'shared/constants/docs';
import { asyncForEach } from 'shared/utils/common';
import { getSelectedFile, TSelectedFile } from 'shared/utils/files/files';
import { useProjectContext } from 'shared/_pages/project/provider';

const AddFileButtonStyled = styled(Button, {
	name: 'AddFileButtonStyled',
	shouldForwardProp: (propName: string) => !['variation'].includes(propName),
})<{ variation?: 'top' | 'group' }>(({ theme, variation }) => {
	const commonSx = {
		minWidth: 'max-content',
	};
	let breakpointsSx = {};
	if (variation !== 'group') {
		breakpointsSx = {
			[theme.breakpoints.down('md')]: {
				position: 'fixed',
				bottom: theme.spacing(8),
				right: theme.spacing(8),
				left: `calc(158px + ${theme.spacing(8)})`, // 158px - nav width
				width: `calc(100% - ${theme.spacing(16)} - 158px)`,
				minWidth: 'max-content',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '1.25rem',
				lineHeight: 1.4,
				fontWeight: 700,
				width: '7.5rem',
				height: '4rem',
			},
		};
	}

	return {
		...commonSx,
		...breakpointsSx,
	};
});

type Props = {
	type?: DocTypeFiles;
	disabled?: boolean;
	variation?: 'top' | 'group';
};

export default function AddFileButton({ type, variation, disabled }: Props) {
	const [fileType, setFileType] = useState(type ?? DocType.FILE_MEDIA);
	const { addFilesNew } = useProjectContext();
	const [showAddFileDialog, setShowAddFileDialog] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const validate = useCallback(
		(files: TSelectedFile[]) => {
			addFilesNew!(files, 'copy');
		},
		[addFilesNew],
	);

	const handleInputChange = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const files = await asyncForEach([...(e.target.files ?? [])], async (file) => getSelectedFile(file, fileType));
			validate(files);
		},
		[fileType, validate],
	);

	const handleAddFile = useCallback((selectedType: DocTypeFiles) => {
		setFileType(selectedType);
		if (inputRef.current) {
			inputRef.current.click();
		}
	}, []);

	const handleAddFileClick = useCallback(() => {
		if (type) {
			handleAddFile(type);
		} else {
			setShowAddFileDialog(true);
		}
	}, [handleAddFile, type]);

	const handleFileTypeClick = useCallback(
		(selectedType?: DocTypeFiles) => {
			setShowAddFileDialog(false);
			if (selectedType) {
				handleAddFile(selectedType);
			}
		},
		[handleAddFile],
	);

	return (
		<>
			<AddFileButtonStyled variation={variation} startIcon={<IconPlus />} onClick={handleAddFileClick} disabled={disabled}>
				Add
			</AddFileButtonStyled>
			{showAddFileDialog && <FileTypesSelectDialog onClose={handleFileTypeClick} />}
			<input ref={inputRef} onChange={handleInputChange} type="file" multiple style={{ display: 'none' }} />
		</>
	);
}
