import React from 'react';
import imageArrow from './image_arrow.svg';
import styles from './ListPlaceholderProject.module.css';
import configs from '../../../../constants/configs';

export default function ListPlaceholderProject() {
  // const { user: { helpFlags: { createFirstProject } } } = useUserData();
  const flag = false; //createFirstProject;

  if (flag) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.textLine}>
          To get started click Add Project. It will appear
          here. There’s also create a project folder in your
          {` ${configs.appName} `}
          folder
        </h2>
        <h2 className={styles.textLine}>
          We recommend creating a new project per episode
        </h2>
      </div>
      <img className={styles.imgArrow} src={imageArrow} alt="arrow" />
    </div>
  );
}
