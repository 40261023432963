import { PropsWithChildren, ReactNode } from 'react'
import Spinner from 'shared/components/Spinner/Spinner'

type LoadingProps = PropsWithChildren & {
  component?: ReactNode
  isLoading: boolean
}

export default function Loading({
  children,
  component,
  isLoading,
}: LoadingProps): JSX.Element {
  if (isLoading) {
    const loadingComponent = component ?? <Spinner big full />
    return <>{loadingComponent}</> // TODO: Define loading state
  }

  return <>{children}</>
}
