import { ComponentProps } from 'react'
import { red } from '@mui/material/colors'
import { ColoredIcon, IconYoutube } from 'shared/_ui/icon'
import { Youtube } from 'shared/constants/docs'
import { useUserContext } from 'shared/Providers/User'
import { deepmerge } from '@mui/utils'

type Props = Omit<ComponentProps<typeof ColoredIcon>, 'color' | 'Component'> & {
  youtube: Youtube | undefined
}

export default function YoutubeFileIcon({ youtube, ...rest }: Props) {
  const {
    permissions: { canPublishMedia },
  } = useUserContext()

  if (!canPublishMedia) {
    return null
  }

  if (youtube?.youtubeId) {
    return (
      <IconYoutube
        {...rest}
        sx={deepmerge(rest.sx ?? {}, { color: red[500] })}
      />
    )
  }

  return <ColoredIcon {...rest} Component={IconYoutube} color="disabled" />
}
