import { useCallback } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { TDoc } from 'shared/model';
import { IconDelete } from 'shared/_ui/icon';

type TUseRemoveDocumentsResponse = {
	removeDocumentAllowed: boolean;
	runRemoveDocument: () => Promise<void>;
};

type Props = {
	docs: Pick<TDoc, '_id'> | Pick<TDoc, '_id'>[];
	action: ((ids: string[]) => Promise<void | string[]> | void | string[]) | undefined;
	actionCallback?: () => unknown;
	callback?: () => unknown;
};

function useRemoveDocuments(
	docs: Pick<TDoc, '_id'>[],
	action: Props['action'],
	callback?: Props['callback']
): TUseRemoveDocumentsResponse {
	const runRemoveDocument = useCallback(async () => {
		if (!action) {
			return;
		}

		const answer = window.confirm(`Items (${docs.length}) will be removed.\nContinue?`);

		if (answer) {
			const errors = await action(docs.map((f) => f._id));
			if (errors?.length) {
				alert(`Can not delete items:\n${errors.reduce((acc, msg) => `${acc}\n${msg}`)}`);
			}
			if (callback && (errors?.length ?? 0) < docs.length) {
				callback();
			}
		}
	}, [docs, action, callback]);

	return {
		removeDocumentAllowed: !!action,
		runRemoveDocument,
	};
}

export default function RemoveDocumentsMenuItem({
	docs: docsProp,
	action,
	actionCallback,
	callback,
}: Props) {
	const docs = Array.isArray(docsProp) ? docsProp : [docsProp];
	const showCount = Array.isArray(docsProp);

	const { removeDocumentAllowed, runRemoveDocument } = useRemoveDocuments(
		docs,
		action,
		actionCallback
	);

	const onClick = useCallback(() => {
		runRemoveDocument();
		if (callback) {
			callback();
		}
	}, [runRemoveDocument, callback]);

	if (!removeDocumentAllowed) {
		return null;
	}

	return (
		<MenuItem onClick={onClick} disabled={!docs.length}>
			<ListItemIcon>
				<IconDelete />
			</ListItemIcon>
			<ListItemText>Delete{showCount ? ` (${docs.length})` : ''}</ListItemText>
		</MenuItem>
	);
}
