import { Skeleton, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
	ComponentProps, useCallback, useEffect, useState,
} from 'react';
import CloudReader from 'shared/_model/cloud/CloudReader';
import { Cloud } from 'shared/_model/cloud/types';

const NoImage = styled('div')(() => ({
	backgroundColor: grey[800],
	height: '100%',
	width: '100%',
}));

const Image = styled('img')(() => ({
	height: '100%',
	objectFit: 'cover',
	width: '100%',
}));

type CloudImageProps = Omit<ComponentProps<typeof Image>, 'src'> & {
  image: Cloud | undefined
}

export default function CloudImage({ image, ...props }: CloudImageProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [src, setSrc] = useState<string>('');

	const handleGetUrl = useCallback(async () => {
		const url = await new CloudReader(image).getUrl();
		setSrc(url);
		setIsLoading(false);
	}, [image]);

	useEffect(() => {
		handleGetUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleGetUrl]);

	if (isLoading) {
		return <Skeleton />;
	}

	if (!src) {
		return <NoImage sx={props.sx} />;
	}

	return <Image src={src} alt="thumbnail" {...props} />;
}
