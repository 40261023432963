export enum SharingType {
  FILE = 'file',
  PROJECT = 'project',
}

type SharingItem<T extends SharingType = SharingType> = {
  id: string
  type: T
}

export type Sharing<T extends SharingType = SharingType> =
  | SharingItem<T>
  | SharingItem<T>[]
