import { Cloud, CloudType, CloudValue } from 'shared/_model/cloud/types'

export function getCloudValue<T extends CloudType = CloudType>(
  cloud: Cloud,
  type: T
): Cloud[T] {
  return cloud[type]
}

export function getCloudType(cloud: Cloud): CloudType | undefined {
  const map: Record<CloudType, CloudValue> = {
    [CloudType.S3]: cloud.s3,
    [CloudType.TEMP]: undefined,
  }

  const entries = Object.entries(map) as [CloudType, CloudValue][]
  return entries.find(([, v]) => !!v)?.[0]
}
