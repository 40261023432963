import configs from './configs';

const beHost = configs.backendApiUri;
export const ENDPOINTS = {
  // members: {
  //   getMembers: `${beHost}/get-user-members`,
  //   registerNewMember: `${beHost}/register-new-member`,
  // },
  user: {
    getUserId: `${beHost}/get-user-id`,
    getUserProperties: `${beHost}/get-user-properties`,
    updateUserProperties: `${beHost}/update-user-properties`,
    changeUserPassword: `${beHost}/change-user-password`,
    tokenAuth: `${beHost}/token-auth`,
    tokenRefresh: `${beHost}/token-refresh`,
    deactivateUser: `${beHost}/deactivate-user`,
    activateUser: `${beHost}/activate-user`,
    register: `${beHost}/register`,
    resetPassword: `${beHost}/reset-password`,
    resetPasswordApply: `${beHost}/reset-password-apply`,
  },
  couch: {
    getSessionCookies: `${beHost}/couch/get-session-cookies`,
  },
  share: {
    tokenAuth: `${beHost}/sharing/token-auth`,
    tokenRefresh: `${beHost}/sharing/token-refresh`,
    getUserId: `${beHost}/sharing/get-user-id`,
    getSettings: `${beHost}/sharing/get-settings`,
    getSettingsByToken: `${beHost}/sharing/get-settings-by-token`,
    saveSettings: `${beHost}/sharing/save-settings`,
    delSettings: `${beHost}/sharing/del-settings`,

    getDocument: `${beHost}/sharing/get-document`,
    getDocuments: `${beHost}/sharing/get-documents`,
    updateDocument: `${beHost}/sharing/update-document`,
    delDocumentAndFile: `${beHost}/sharing/del-document-and-file`,
    createDocument: `${beHost}/sharing/create-document`,
  },
  roles: {
    getAllRoles: `${beHost}/role/get-all-roles`,
    getAllPermissions: `${beHost}/role/get-all-permissions`,
    addRole: `${beHost}/role/add-role`,
    updateRole: `${beHost}/role/update-role`,
    delRole: `${beHost}/role/del-role`,
    getByKey: `${beHost}/role/get-by-key`,
  },
  rss: {
    addSeriesRss: `${beHost}/rss/add-series-rss`,
    getSeriesRss: `${beHost}/rss`,
    checkActivationSeriesRss: `${beHost}/rss/check-activation-series-rss`,
    getSeriesTemplate: `${beHost}/rss/get-series-template`,
    setSeriesTemplate: `${beHost}/rss/set-series-template`,
    delSeriesTemplate: `${beHost}/rss/del-series-template`,
  },
  youtube: {
    getChannelInfo: `${beHost}/youtube/get-channel-info`,
    activate: `${beHost}/youtube/activate`,
    uploadVideo: `${beHost}/youtube/upload-video`,
    getChannelAnalytics: `${beHost}/youtube/get-channel-analytics`,
    getVideoAnalytics: `${beHost}/youtube/get-video-analytics`,
  },
  cloud: {
    s3: {
      remove: `${beHost}/s3/delete-object`,
      getDownloadFileUrl: `${beHost}/s3/get-download-file-url`,
      createMultipartUpload: `${beHost}/s3/create-multipart-upload`,
      listParts: `${beHost}/s3/list-parts`,
      prepareUploadPart: `${beHost}/s3/prepare-upload-part`,
      completeMultipartUpload: `${beHost}/s3/complete-multipart-upload`,
      abortMultipartUpload: `${beHost}/s3/abort-multipart-upload`,
    },
  },
  statistic: {
    getProjectStructureSizes: `${beHost}/tracking/get-project-structure-sizes`,
    getUserStructureSizes: `${beHost}/tracking/get-user-structure-sizes`,
    getAllUsersStructureSizes: `${beHost}/tracking/get-all-users-structure-sizes`,
    getAllUsersStatisticsLogin: `${beHost}/tracking/get-all-users-statistics-login`,
    getAllUsersStatisticsProjects: `${beHost}/tracking/get-all-users-statistics-projects`,
    getAllUsersAllFilesSize: `${beHost}/tracking/get-all-users-all-files-size`,
    getAllUsersAllFinalAudioFilesSize: `${beHost}/tracking/get-all-users-all-final-audio-files-size`,
    getAllUsersAllFinalVideoFilesSize: `${beHost}/tracking/get-all-users-all-final-video-files-size`,
  },
  getAllUsersDetails: `${beHost}/get-all-users-details`,
  communications: {
    sendByEmails: `${beHost}/communication/send-by-emails`,
  },
};
