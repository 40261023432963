import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DocType, TDocFile, TDocProject } from 'shared/constants/docs';
import { PROJECT_SCHEMA } from 'shared/constants/general';
import { emitUserError, emitUserInfo } from 'shared/constants/globals';
import { ROUTE } from 'shared/constants/routes_old';
import { SHARE_TYPES } from 'shared/constants/share';
import { PageContainer } from 'shared/Pages/common/PageContainer';
import { RecentContext } from 'shared/Providers/Recent';
import { UserContext } from 'shared/Providers/User';
import { TSelectedFile } from 'shared/utils/files/files';
import { prettyDate, prettySize } from 'shared/utils/format';
import ShareSettings from 'shared/components/ShareSettings/ShareSettings';
import { WindowContainer } from 'shared/components/WindowContainer';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useApiTrackingGetProjectAllFilesSize from 'shared/utils/api/use-api-tracking-get-project-all-files-size';
import {
	IconCloud,
	IconLocalStorage,
	IconMoreVert,
	ColoredIcon,
	IconShare,
	IconEdit,
	IconLocalStorageWithCheck,
	IconCloudWithCheck,
} from 'shared/_ui/icon';
import Loading from 'shared/_components/loading';
import {
	Box,
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	SxProps,
	Theme,
} from '@mui/material';
import { FieldTextField, FormTextField } from 'shared/_ui/form/form-text-field';
import ProjectFiles from 'shared/_model/project-files';
import ProjectFileCountIcon from 'shared/_model/project-details/ProjectFileCountIcon';
import { useProjectContext } from 'shared/_pages/project/provider';
import useMenu from 'shared/hooks/use-menu';
import { FormTagField } from 'shared/_ui/form/form-tag-field';
import { getShareProperty, useSharingId } from 'shared/_model/sharing/utils';
import CopyToClipboardValue from 'shared/_components/copy-to-clipboard-value';
import ProjectSyncMenuItem from 'app/_model/project/components/ProjectSyncMenuItem';

type TProjectDetailsProps = {
	// TODO: Remove unused props
	// projectId: string
	sharingId?: string;
	readProject: () => Promise<TDocProject>;
	saveProject?: (values: TDocProject) => Promise<void>;
	deleteProject?: () => Promise<string[]>;
	findFiles: () => Promise<TDocFile[]>;
	addFiles?: (files: TSelectedFile[]) => Promise<void>;
	removeFile?: (fileIds: string[]) => Promise<string[]>;
	saveFile?: (values: TDocFile) => Promise<void>;
};

const fieldSx: SxProps<Theme> = {
	'.MuiFormLabel-root': {
		color: (theme) => theme.palette.secondary.main,
	},
};

export default function ProjectDetails(props: TProjectDetailsProps): JSX.Element {
	const {
		permissions: { canUseCloud },
	} = useContext(UserContext);
	const history = useHistory();
	const { sharingId } = useSharingId();

	const { project, saveProject, stats } = useProjectContext();

	const { addRecent } = useContext(RecentContext);
	const rm = useRouteMatch();

	const { deleteProject } = props;

	const [isEdit, setIsEdit] = useState(false);
	const handleEnableEdit = useCallback(() => {
		setIsEdit(true);
	}, []);

	const [showShareSettings, setShowShareSettings] = useState(false);

	const { projectSize, doGetProjectSize } = useApiTrackingGetProjectAllFilesSize();

	useEffect(() => {
		if (project?._id) {
			doGetProjectSize(project?._id);
		}
	}, [project?._id, doGetProjectSize]);

	const handleSubmit = useCallback(
		async (values: Partial<TDocProject>) => {
			if (saveProject) {
				try {
					await saveProject(values);
					setIsEdit(false);
					emitUserInfo('Project updated!');
				} catch (e) {
					emitUserError((e as Error).message);
				}
			}
		},
		[saveProject]
	);

	const handleDelete = useCallback(async () => {
		if (deleteProject) {
			let answer = true;
			if (project.storeOnLocalDrive) {
				answer = window.confirm('Project will be removed.\nContinue?');
			}
			if (answer) {
				const errors = await deleteProject();
				if (!errors || errors.length === 0) {
					history.push(ROUTE.projects.root);
				} else {
					emitUserError(
						`Can not delete project. Reasons:\n\n${errors.reduce((acc, err) => `${acc}\n${err}`)}`
					);
				}
			}
		}
	}, [project.storeOnLocalDrive, deleteProject, history]);

	const handleShowShareSettings = useCallback(() => {
		setShowShareSettings((s) => !s);
	}, []);

	const formik = useFormik<Partial<TDocProject>>({
		initialValues: {},
		validationSchema: PROJECT_SCHEMA,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (project?._id && project.type) {
			addRecent({
				type: project.type,
				title: project.title,
				to: rm.url,
			});
		}
	}, [project?._id, project.type, project.title, rm.url, addRecent]);

	useEffect(() => {
		formik.setValues({
			description: project.description,
			tags: project.tags,
			title: project.title,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project._rev]);

	const { anchorEl, open, handleOpen, handleClose } = useMenu();

	return (
		<PageContainer
			title={project.title}
			// TODO: remove next lines if they are not needed
			// parentTitle="Projects"
			// parentLink={!sharingId ? ROUTE.projects.root : undefined}
		>
			<Loading isLoading={!project?._id}>
				<form onSubmit={formik.handleSubmit}>
					<Box display="flex" gap={2} alignItems="center">
						<ProjectFileCountIcon type={DocType.FILE_FINISH_VIDEO} count={stats.filesVideo} />
						<ProjectFileCountIcon type={DocType.FILE_FINISH_AUDIO} count={stats.filesAudio} />
						<Box ml="auto" display="flex">
							<Box display="flex" gap={1}>
								{isEdit && (
									<Button size="small" color="primary" fullWidth type="submit">
										Submit
									</Button>
								)}
								{isEdit && (
									<Button
										size="small"
										color="secondary"
										fullWidth
										onClick={() => {
											setIsEdit(false);
											formik.setValues(project);
										}}
									>
										Cancel
									</Button>
								)}
							</Box>
							<IconButton
								disabled={!!sharingId || !saveProject || isEdit}
								onClick={() => {
									saveProject?.({
										storeOnLocalDrive: !project.storeOnLocalDrive,
									});
								}}
							>
								<ColoredIcon
									Component={
										project.storeOnLocalDrive ? IconLocalStorageWithCheck : IconLocalStorage
									}
									color={project.storeOnLocalDrive ? 'success' : 'disabled'}
								/>
							</IconButton>
							<IconButton
								disabled={!!sharingId || !saveProject || isEdit}
								onClick={() => {
									saveProject?.({ storeInCloud: !project.storeInCloud });
								}}
							>
								<ColoredIcon
									Component={project.storeInCloud ? IconCloudWithCheck : IconCloud}
									color={project.storeInCloud ? 'success' : 'disabled'}
								/>
							</IconButton>
							<IconButton onClick={handleOpen}>
								<IconMoreVert />
							</IconButton>
							<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
								{!!saveProject && !isEdit && (
									<MenuItem
										onClick={() => {
											handleClose();
											handleEnableEdit();
										}}
									>
										<ListItemIcon>
											<IconEdit />
										</ListItemIcon>
										<ListItemText>Edit</ListItemText>
									</MenuItem>
								)}
								{!!saveProject && !isEdit && (
									<ProjectSyncMenuItem projectId={project._id} onClose={handleClose} />
								)}
								{!!saveProject && canUseCloud && !sharingId && (
									<MenuItem
										disabled={isEdit}
										onClick={() => {
											handleClose();
											handleShowShareSettings();
										}}
									>
										<ListItemIcon>
											<ColoredIcon
												Component={IconShare}
												color={
													getShareProperty(project.sharing).filter(
														(sh: any) => sh.type === SHARE_TYPES.project
													).length
														? 'success'
														: 'disabled'
												}
											/>
										</ListItemIcon>
										<ListItemText>Share</ListItemText>
									</MenuItem>
								)}
								{!!deleteProject && isEdit && (
									<MenuItem
										disabled={!!sharingId}
										onClick={() => {
											handleClose();
											handleDelete();
										}}
										sx={{ color: 'warning.main' }}
									>
										Delete
									</MenuItem>
								)}
							</Menu>
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" gap={1.5} pl={1}>
						<Box display="flex" gap={2} alignItems="center">
							<CopyToClipboardValue value={project?._id} />
						</Box>
						{isEdit && (
							<FormTextField
								label="Title:"
								name="title"
								fullWidth
								formik={formik}
								sx={fieldSx}
								labelProps={{ width: '2.875rem' }}
								spellCheck
							/>
						)}
						<FormTextField
							label="Notes:"
							name="description"
							fullWidth
							formik={formik}
							labelProps={{ width: '2.875rem' }}
							multiline
							placeholder="Add notes about your project"
							spellCheck
							readOnly={!isEdit}
							onReadOnlyClick={handleEnableEdit}
							sx={fieldSx}
						/>
						<FieldTextField
							label="Size:"
							fullWidth
							value={prettySize(projectSize)}
							labelProps={{ width: '2.875rem' }}
							sx={fieldSx}
						/>
						<FieldTextField
							label="Date:"
							value={prettyDate(project.created)}
							fullWidth
							labelProps={{ width: '2.875rem' }}
							sx={fieldSx}
						/>
						<FormTagField
							label="Tags:"
							name="tags"
							fullWidth
							formik={formik}
							labelProps={{ width: '2.875rem' }}
							readOnly={!isEdit}
							onReadOnlyClick={handleEnableEdit}
							sx={fieldSx}
							// showCopyButton // TODO: Implement copy tags functionality
						/>
					</Box>
					<ProjectFiles />
				</form>
			</Loading>
			{showShareSettings && (
				<WindowContainer width={500} closeAction={handleShowShareSettings}>
					<ShareSettings
						documentId={project._id}
						type={SHARE_TYPES.project}
						// onChange={() => setTimeout(() => handleRead(), 500)} // TODO: need tp subscribe to update event
					/>
				</WindowContainer>
			)}
		</PageContainer>
	);
}
