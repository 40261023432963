import {useCallback, useContext} from 'react';
import { TDocFile } from 'shared/constants/docs';
import { TSelectedFile } from 'shared/utils/files/files';
import { useProjectFilesApi } from 'shared/helpers/use-project-files-api';
import {UserContext} from "shared/Providers/User";

type TUseProjectFilesProps = {
  projectId: string
}
type TUseProjectFilesResult = {
  findFiles: () => Promise<TDocFile[]>
	/**
	 * @deprecated
	 * // TODO: Remove and use addFilesNew
	 */
	addFiles?: (files: TSelectedFile[]) => Promise<boolean>
	addFilesNew?: (files: TSelectedFile[]) => Promise<boolean>
  removeFile?: (fileIds: string[]) => Promise<string[]>
}

export const useProjectFiles = ({ projectId }: TUseProjectFilesProps): TUseProjectFilesResult => {
	const { findFiles, addFiles, removeFile } = useProjectFilesApi({ projectId });
	const { permissions: { canAddFiles, canRemoveFiles } } = useContext(UserContext);

	const handleAddFiles = useCallback(async (files: TSelectedFile[]) => await addFiles(files, { move: false }), [addFiles]);

	return {
		addFiles: canAddFiles ? handleAddFiles : undefined,
		addFilesNew: canAddFiles ? handleAddFiles : undefined,
		removeFile: canRemoveFiles ? removeFile : undefined,
		findFiles,
	};
};
