import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { readUserDoc } from 'shared/handlers/userDB';
import styles from './ActionEpisodeUploadToYoutube.module.css';
import { uploadEpisode } from 'shared/utils/youtube';
import Button from '../Button/Button';
import IconFileUploadToYoutube from '../StatusIcons/IconFileUploadToYoutube/IconFileUploadToYoutube';
import { UserContext } from 'shared/Providers/User';

// type TActionEpisodeUploadToYoutubeProps = {
//   disabled?: boolean
//   onChange?: () => Promise<void>
// }

export default function ActionEpisodeUploadToYoutube({
  disabled = false,
  seriesId,
  episodeId,
  youtubeId,
  onChange,
}) {
  const [enabled, setEnabled] = useState(true);
  const { permissions: { canPublishMedia } } = useContext(UserContext);
  const dispatch = useDispatch();

  const handleUpload = useCallback(async () => {
    setEnabled(false);
    try {
      const series = await dispatch(readUserDoc(seriesId));
      const channelId = series.youtube && series.youtube.channelId;

      const callback = async () => {
        if (onChange) {
            await onChange();
        }
      };
      uploadEpisode(channelId, episodeId, callback);
    } finally {
      setEnabled(true);
    }
  }, [dispatch, seriesId, episodeId, onChange]);

  if (!canPublishMedia) {
    return null;
  }

  if (youtubeId) {
    return (
      <div className={styles.withIdContainer}>
        <IconFileUploadToYoutube youtubeId={youtubeId} />
      </div>
    )
  }

  return (
    <Button
        tiny
        disabled={disabled || !enabled}
        onClick={!youtubeId && handleUpload}
        color={Button.COLOR.NONE}
    >
      <IconFileUploadToYoutube youtubeId={youtubeId} />
    </Button>
  );
}
