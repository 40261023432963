import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS } from 'shared/constants/endpoints';
import { api, emitUserError } from 'shared/constants/globals';
import { prettySize } from 'shared/utils/format';


const KEY_MAP_TOTAL = {
  all_users_files_size: 'Total GB across all users assets',
  all_users_final_videos_files_size: 'Total GB for all finished videos',
  all_users_final_audio_files_size: 'Total GB for all Finished audio',
  all_users_media_files_size: 'Total GB for all Raw media',
  all_users_audio_files_size: 'Total GB for all Raw Audio',
};
const KEY_MAP_USER = {
  user_all_files_size: 'Total GB across all projects my overall account',
  user_all_final_audio_files_size: 'Total GB of ALL my finished audio',
  user_all_final_videos_files_size: 'Total GB of ALL my finished videos',
};


interface TTotalStats {
  all_users_files_size: number
  all_users_final_videos_files_size: number
  all_users_final_audio_files_size: number
  all_users_media_files_size: number
  all_users_audio_files_size: number
}
interface TUserStats {
  user_all_files_size: number
  user_all_final_audio_files_size: number
  user_all_final_videos_files_size: number
}
type TTotalsStatsResponse = {
  val: string,
  key: keyof TTotalStats,
  title: string
}
type TUserStatsResponse = {
  val: string,
  key: keyof TUserStats,
  title: string
}

type TStats = {
  lastLogin?: string
  lastProjectCreated?: string
  loginCount?: string
  projectCount?: string
  totalSize?: string
  finishAudioSize?: string
  finishVideoSize?: string
}

type TUsersStatsProps = {
  autoRead?: boolean
}
type TUsersStatsResponse = {
  stats: Record<string, TStats>
  totalStats: TTotalsStatsResponse[]
  userStats: TUserStatsResponse[]
  readAllStats: () => Promise<void>
  readLoginStats: () => Promise<TLoginStatsResponse | undefined>
  readProjectStats: () => Promise<TProjectsStatsResponse | undefined>
  readFilesSizeStats: () => Promise<TFilesSizeStatsResponse | undefined>
  readFinalAudioStats: () => Promise<TFinalAudioStatsResponse | undefined>
  readFinalVideoStats: () => Promise<TFinalVideoStatsResponse | undefined>
  readTotalStats: () => Promise<void>
  readUserStats: () => Promise<void>
}

type TLoginStatsResponseRow = { count: number, last_login_date: number[], user_id: string }
type TLoginStatsResponse = { all_users_login_statistics?: TLoginStatsResponseRow[] }
type TProjectsStatsResponseRow = { count: number, last_project_created_date: number[], user_id: string }
type TProjectsStatsResponse = { all_users_projects_statistics?: TProjectsStatsResponseRow[] }
type TFilesSizeStatsResponseRow = { total_size: number, user_id: string }
type TFilesSizeStatsResponse = { all_users_all_files_size?: TFilesSizeStatsResponseRow[] }
type TFinalAudioStatsResponseRow = { total_size: number, user_id: string }
type TFinalAudioStatsResponse = { all_users_all_final_audio_files_size?: TFinalAudioStatsResponseRow[] }
type TFinalVideoStatsResponseRow = { total_size: number, user_id: string }
type TFinalVideoStatsResponse = { all_users_all_final_video_files_size?: TFinalVideoStatsResponseRow[] }


export const useUsersStats = (props?: TUsersStatsProps): TUsersStatsResponse => {
  const { autoRead = true } = (props ?? {});

  const [stats, setStats] = useState<Record<string, TStats>>({});
  const [totalStats, setTotalsStats] = useState<TTotalsStatsResponse[]>([]);
  const [userStats, setUserStats] = useState<TUserStatsResponse[]>([]);


  const readLoginStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersStatisticsLogin)) as TLoginStatsResponse;
      if (response?.all_users_login_statistics) {
        setStats(s => {
          return {
            ...s,
            ...(response.all_users_login_statistics ?? [])
              .reduce((acc, rec) => ({
                ...acc,
                [rec.user_id]: {
                  ...s[rec.user_id],
                  loginCount: rec.count,
                  lastLogin: moment(rec.last_login_date, 'X').toDate().toLocaleString(),
                }
              }), {})
          }
        })
        return response;
      }
    } catch (e) {
      emitUserError(`Get login statistics error\n${(e as Error).message}`)
    }
    return undefined;
  }, []);

  const readProjectStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersStatisticsProjects)) as TProjectsStatsResponse;
      if (response?.all_users_projects_statistics) {
        setStats(s => {
          return {
            ...s,
            ...(response.all_users_projects_statistics ?? [])
              .reduce((acc, rec) => ({
                ...acc,
                [rec.user_id]: {
                  ...s[rec.user_id],
                  projectCount: rec.count,
                  lastProjectCreated: moment(rec.last_project_created_date, 'X').toDate().toLocaleString(),
                }
              }), {})
          }
        })
        return response;
      }
    } catch (e) {
      emitUserError(`Get project statistics error\n${(e as Error).message}`)
    }
    return undefined;
  }, []);

  const readFilesSizeStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersAllFilesSize)) as TFilesSizeStatsResponse;
      if (response?.all_users_all_files_size) {
        setStats(s => {
          return {
            ...s,
            ...(response.all_users_all_files_size ?? [])
              .reduce((acc, rec) => ({
                ...acc,
                [rec.user_id]: {
                  ...s[rec.user_id],
                  totalSize: prettySize(rec.total_size),
                }
              }), {})
          }
        })
        return response;
      }
    } catch (e) {
      emitUserError(`Get files statistics error\n${(e as Error).message}`)
    }
    return undefined;
  }, []);

  const readFinalAudioStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersAllFinalAudioFilesSize)) as TFinalAudioStatsResponse;
      if (response?.all_users_all_final_audio_files_size) {
        setStats(s => {
          return {
            ...s,
            ...(response.all_users_all_final_audio_files_size ?? [])
              .reduce((acc, rec) => ({
                ...acc,
                [rec.user_id]: {
                  ...s[rec.user_id],
                  finishAudioSize: prettySize(rec.total_size),
                }
              }), {})
          }
        })
        return response;
      }
    } catch (e) {
      emitUserError(`Get finish audio statistics error\n${(e as Error).message}`)
    }
    return undefined;
  }, []);

  const readFinalVideoStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersAllFinalVideoFilesSize)) as TFinalVideoStatsResponse;
      if (response?.all_users_all_final_video_files_size) {
        setStats(s => {
          return {
            ...s,
            ...(response.all_users_all_final_video_files_size ?? [])
              .reduce((acc, rec) => ({
                ...acc,
                [rec.user_id]: {
                  ...s[rec.user_id],
                  finishVideoSize: prettySize(rec.total_size),
                }
              }), {})
          }
        })
        return response;
      }
    } catch (e) {
      emitUserError(`Get finish video statistics error\n${(e as Error).message}`)
    }
    return undefined;
  }, []);


  const readTotalStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getAllUsersStructureSizes)) as { structure_sizes: TTotalStats };
      setTotalsStats(
        (Object.keys(KEY_MAP_TOTAL) as (keyof TTotalStats)[])
          .map((key) => ({
            val: prettySize(response.structure_sizes[key]),
            key,
            title: KEY_MAP_TOTAL[key]
          }))
      );
    } catch (e) {
      emitUserError(`Get total statistics error\n${(e as Error).message}`)
    }
  }, []);

  const readUserStats = useCallback(async () => {
    try {
      const response = (await api.apiGet(ENDPOINTS.statistic.getUserStructureSizes)) as { structure_sizes: TUserStats };
      setUserStats(
        (Object.keys(KEY_MAP_USER) as (keyof TUserStats)[])
          .map((key) => ({
            val: prettySize(response.structure_sizes[key]),
            key,
            title: KEY_MAP_USER[key]
          }))
      );
    } catch (e) {
      emitUserError(`Get user statistics error\n${(e as Error).message}`)
    }
  }, []);


  const readAllStats = useCallback(async () => {
    await Promise.all([
      readLoginStats(),
      readProjectStats(),
      readFilesSizeStats(),
      readFinalAudioStats(),
      readFinalVideoStats(),
    ])
  }, [readLoginStats, readProjectStats, readFilesSizeStats, readFinalAudioStats, readFinalVideoStats]);

  useEffect(() => {
    if (autoRead) {
      if (Object.keys(stats).length === 0) {
        void readAllStats();
      }
      if (Object.keys(totalStats).length === 0) {
        void readTotalStats();
      }
      if (Object.keys(userStats).length === 0) {
        void readUserStats();
      }
    }
  }, [autoRead, stats, totalStats, userStats, readAllStats, readTotalStats, readUserStats])


  return {
    stats,
    totalStats,
    userStats,
    readAllStats,
    readLoginStats,
    readProjectStats,
    readFilesSizeStats,
    readFinalAudioStats,
    readFinalVideoStats,
    readTotalStats,
    readUserStats,
  };
}
