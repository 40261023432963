import { getDbName } from 'app/pouch-db';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TDocMinimum } from 'shared/constants/docs';
import useError from 'shared/helpers/use-error'
import { TDocument } from 'shared/model/doc-base'
import { actionCloseLocalDB, actionSetLocalDB } from 'shared/modules/db/dbActions'
import { UserContext } from '../User'
import WaitingScreen from '../WaitingScreen'
import useDbActions from './use-db-actions'
import { connectToDB } from './utils'

type TUserDbContext = Omit<ReturnType<typeof useDbActions>, 'close'> & {
  db?: PouchDB.Database<TDocument>; // TODO: do we need it?
  connected: boolean;
};

export const UserDbContext = createContext<TUserDbContext>({
  db: undefined,
  connected: false,
  readDoc: async () => undefined,
  readDocs: async () => undefined,
  readDocsPaged: async () => undefined,
  removeDoc: async () => false,
  removeDocs: async () => false,
  writeDoc: async () => undefined,
  writeDocs: async () => undefined,
});

export const UserDbProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const dispatch = useDispatch(); // TODO: remove
  const { user, logout } = useContext(UserContext);
  const { showError } = useError('UserDB');

  const [db, setDB] = useState<PouchDB.Database<TDocument>>();

  const { close: closeDb, writeDoc: writeDbDoc, writeDocs: writeDbDocs, ...restDbActions } = useDbActions(db);

  const close = useCallback(async () => {
    if (db) {
      setDB(undefined);
      dispatch(actionCloseLocalDB())
      await closeDb();
    }
  }, [db, closeDb, dispatch]);

  const writeDoc = useCallback<typeof writeDbDoc>(async <T extends TDocument>(doc: TDocMinimum<T>, callback?: (id?: string) => Promise<void>) => {
    const updatedDoc = { ...doc, createdBy: doc.createdBy || user?.id } as typeof doc;
    return writeDbDoc(updatedDoc, callback);
  }, [writeDbDoc, user?.id]);

  const writeDocs = useCallback<typeof writeDbDocs>(async <T extends TDocument>(docs: T[], callback?: (ids?: string[]) => Promise<void>) => {
    const updatedDocs = docs.map<T>((doc) => ({ ...doc, createdBy: doc.createdBy || user?.id }));
    return writeDbDocs(updatedDocs, callback);
  }, [writeDbDoc, user?.id]);

  const connect = useCallback(async () => {
    if (user?.databaseId) {
      const newDB = await connectToDB(await getDbName(user?.databaseId), showError);
      if (newDB) {
        setDB(newDB);
        dispatch(actionSetLocalDB(newDB));
      } else {
        logout();
      }
    }
  }, [user?.databaseId, showError, dispatch, logout]);

  useEffect(() => {
    // If db already connected - just return how to close it
    if (db) {
      return () => {
        void close();
      };
    }
    // Connect to DB
    connect();
    return undefined;
  }, [db, connect, close]);

  // useEffect(() => {
  //   db
  //     ?.changes({ since: 'now', live: true, include_docs: true })
  //     .on('change', (change) => {
  //       // console.log('User DB change', change);
  //       /* change object example:
  //       {
  //         id: "FPRu0dj3adKoaB1H",
  //         seq: 3841,
  //         changes: [ { rev: "3-c769f34399a647df9fa507082cba90e6" } ],
  //         doc: { // changed doc. file object example:
  //           _id: "FPRu0dj3adKoaB1H",
  //           _rev: "3-c769f34399a647df9fa507082cba90e6",
  //           cloud: {s3: {…}},
  //           created: "2019-11-12T14:53:20.128Z",
  //           meta: {type: "text/plain"},
  //           projectId: "PRJu0dj3aSKHdyJf",
  //           sharing: [{…}],
  //           size: 5094,
  //           title: "2.txt",
  //           type: "file-project",
  //           updated: "2019-11-19T14:12:16.611Z",
  //         },
  //       }
  //       */
  //       // TODO: Temporary off
  //       // setTimeout(() => dispatch(syncChanges(change)), 1000);
  //     })
  //     .on('complete', (info) => {
  //         // changes() was canceled
  //         console.log('complete', { info });
  //     })
  //     .on('error', (err) => {
  //         console.log('error', err);
  //     });
  // }, [db]);

  if (!db) {
    return <WaitingScreen description="Connecting to DB.." />;
  }

  return (
    <UserDbContext.Provider
      value={{
        db,
        connected: !!db,
        writeDoc,
        writeDocs,
        ...restDbActions,
      }}
    >
      {children}
    </UserDbContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDB = () => useContext(UserDbContext);
