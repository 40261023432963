import PropTypes from 'prop-types';
import React from 'react';
// import ActionAddFolderAsFile from 'app/components/ActionAddFolderAsFile';
import { Divider, IconButton, Menu } from '@mui/material';
import useMenu from 'shared/hooks/use-menu';
import { MoreVert } from '@mui/icons-material';
import CreateFolderMenuItem from 'app/components/ActionCreateFolder';
import FileSyncMenuItem from 'app/_model/sync/components/FileSyncMenuItem';
// import SelectFilesMenuItem from '../Actions/ActionSelectFiles'
import RemoveDocumentsMenuItem from 'shared/_model/docs/components/RemoveDocumentsMenuItem';
import SelectFilesMenuItem from 'shared/components/Actions/ActionSelectFiles';
// import RemoveDocumentsMenuItem from '../../_model/docs/components/RemoveDocumentsMenuItem';

export default function ActionFileTypeMenu({
	fileType,
	project,
	checkedFiles,
	checkDisabled,
	selectedAll,
	checkListsCheckAllAction,
	removeFileAction,
	removeFileCallback,
}) {
	const { anchorEl, open, handleOpen, handleClose } = useMenu();

	return (
		<div>
			<IconButton onClick={handleOpen} size="small">
				<MoreVert fontSize="small" />
			</IconButton>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<CreateFolderMenuItem fileType={fileType} projectId={project._id} callback={handleClose} />
				{/* <Divider /> */}
				{/* <SelectFilesMenuItem */}
				{/* 	disabled={checkDisabled} */}
				{/* 	action={checkListsCheckAllAction} */}
				{/* 	selectedAll={selectedAll} */}
				{/* /> */}
				{/* <FileSyncMenuItem */}
				{/* 	files={checkedFiles} */}
				{/* 	disabled={!project.storeInCloud} */}
				{/* 	callback={handleClose} */}
				{/* /> */}
				{/* /!* TODO: implement multiple download *!/ */}
				{/* /!* <DownloadFilesMenuItem files={checkedFiles} disabled={!project.storeInCloud} callback={handleClose} /> *!/ */}
				{/* <RemoveDocumentsMenuItem */}
				{/* 	docs={checkedFiles} */}
				{/* 	action={removeFileAction} */}
				{/* 	actionCallback={removeFileCallback} */}
				{/* 	callback={handleClose} */}
				{/* /> */}
			</Menu>
		</div>
	);
}

ActionFileTypeMenu.propTypes = {
	fileType: PropTypes.string,
	project: PropTypes.shape({
		_id: PropTypes.string,
		storeInCloud: PropTypes.bool,
	}),
	removeFileAction: PropTypes.func,
	removeFileCallback: PropTypes.func,
	canUseCloud: PropTypes.bool,

	checkedFiles: PropTypes.array,
	checkDisabled: PropTypes.bool,
	checkedAll: PropTypes.bool,
	checkListsCheckAllAction: PropTypes.func,
};
