import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import StatusIcon from '../StatusIcons/StatusIcon/StatusIcon';
import iconInPlaylistCheck from './icon_in_playlist_check.png';
import iconInPlaylist from './icon_in_playlist.png';
import * as epActions from '../../modules/episodes/actions';

export default function IconVideoInPlaylist({ videoId }) {
  const dispatch = useDispatch();
  const fetchEpisodesByVideoId = useCallback(fileId => dispatch(epActions.fetchEpisodesByVideoId([fileId])), [dispatch]);

  const [hasEpisodes, setHasEpisodes] = useState(false);

  const init = useCallback(async () => {
    if (videoId) {
      const docs = await fetchEpisodesByVideoId(videoId);
      setHasEpisodes(docs && docs.length > 0);
    }
  }, [videoId, fetchEpisodesByVideoId]);

  useEffect(() => {
    init();
  }, [init])

  return (
    <StatusIcon
      enabled={hasEpisodes}
      icon={hasEpisodes ? iconInPlaylistCheck : iconInPlaylist}
    />
  );
}
