import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { activateAnalyticsPermissions, getAnalytics, getAnalyticsKeys } from '../../utils/youtube';
import Spinner from '../Spinner/Spinner';
import styles from './YoutubeAnalytics.module.css';


YoutubeAnalytics.propTypes = {
  analyticType: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  id: PropTypes.string,
};

export default function YoutubeAnalytics({ showTitle = false, id, analyticType }) {
  // const { permissions: { canPublishMedia } } = useContext(UserContext);

  const [data, setData] = useState(getAnalyticsKeys());
  const [error, setError] = useState(false);

  const getStatistics = useCallback(async () => {
    if (id) {
      const data = await getAnalytics(analyticType, id, moment('1980-01-01').toDate(), new Date());
      if (data) {
        setData(data);
      } else {
        setError(true);
      }
    }
    return true;
  }, [id, analyticType]);

  const init = useCallback(async () => {
    await getStatistics();
  }, [getStatistics]);

  useEffect(() => {
    init();
  }, [init]);

  const handleGetPermissions = useCallback(async () => {
    activateAnalyticsPermissions(getStatistics);
  }, [getStatistics]);

  if (!id) {
    return null;
  }

  return (
    <>
      {showTitle && <h3 className={styles.title}>Analytics</h3>}
      {!error
        ? data.map(rec => (
          <Input
            key={rec.key}
            id={rec.key}
            labelClassName={styles.label}
            rootClassName={styles.itemContainer}
            type={Input.TYPE.LABEL}
            label={rec.name}
            field={{
              value: rec.value !== undefined
                ? rec.value
                : <Spinner />
            }}
          />
        ))
        : (
          <Button
            small
            color={Button.COLOR.PRIMARY}
            onClick={handleGetPermissions}
          >
            Get analytics permissions
          </Button>
        )
      }
    </>
  );
}
