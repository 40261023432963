import { initUppy } from '../../constants/handlers';
import { getSharingWeb } from '../../utils/localStorageUtils';
import { doCheckToken } from '../../utils/webSettings';
import { apiShareGetUserId, apiShareTokenAuth, apiShareTokenRefresh } from './api';

import { ACTION_TYPES } from './reducer';

export const SHARE_ACTIONS = {
	clear: () => ({ type: ACTION_TYPES.clear }),
	setSharingId: (sharingId) => ({ type: ACTION_TYPES.setSharingId, sharingId }),
	token: {
		success: (token, refreshToken) => ({
			type: ACTION_TYPES.token.success,
			token,
			refreshToken,
		}),
		refreshing: () => ({ type: ACTION_TYPES.token.refreshing, refreshing: true }),
	},
	ownerId: {
		success: (ownerId) => ({ type: ACTION_TYPES.ownerId.success, ownerId }),
	},
};

const doProcessToken = (response) => (dispatch) => {
	const result = doCheckToken(response);
	if (result) {
		dispatch(SHARE_ACTIONS.token.success(response.token, response.refreshToken));
	} else {
		dispatch(SHARE_ACTIONS.clear());
	}

	return result;
};

const webShareTokenAuth = (sharingId, password) => async (dispatch) => {
	const response = await apiShareTokenAuth(sharingId, password);
	return dispatch(doProcessToken(response));
};
export const webShareRefreshToken = () => async (dispatch) => {
	try {
		const result = await apiShareTokenRefresh();
		return dispatch(doProcessToken(result));
	} catch (e) {
		return dispatch(doProcessToken({}));
	}
};

const webShareGetUserId = () => async (dispatch) => {
	const response = await apiShareGetUserId();
	if (response) {
		dispatch(SHARE_ACTIONS.ownerId.success(response.user_id));
		return true;
	}
	return false;
};

export const onLogin = (sharingId, password) => async (dispatch) => {
	dispatch(SHARE_ACTIONS.setSharingId(sharingId));
	if (await dispatch(webShareTokenAuth(sharingId, password))) {
		if (await dispatch(webShareGetUserId())) {
			initUppy();
			return true;
		}
	}
	return false;
};
export const checkLogin = (sharingId) => async (dispatch) => {
	if (sharingId) {
		dispatch(SHARE_ACTIONS.setSharingId(sharingId));

		const web = getSharingWeb();
		if (web.ownerId && doCheckToken(web)) {
			initUppy();
		}
	}
};
