import React, { useEffect } from 'react';
import Spinner from 'shared/components/Spinner/Spinner';
import { useUsersStats } from 'shared/helpers/use-stats-hook';
import { PageContainer } from 'shared/Pages/common/PageContainer';
import styles from 'shared/Pages/common/Account/UserStats/user-stats.module.scss';


type TRowTotalsProps = {
  val: string
  title: string
}


const RowTotals: React.FC<TRowTotalsProps> = ({ val, title }) => (
  <div className={styles.rowTotal}>
    <div>{val}</div>
    <div>{title}</div>
  </div>
)


export const UserStats: React.FC = () => {
  const { userStats, readUserStats } = useUsersStats({ autoRead: false });

  useEffect(() => {
    void readUserStats()
  }, [readUserStats]);


  return (
    <PageContainer title="Stats" size="medium">
      {!userStats.length && (
        <div className={styles.spinner}>
          <Spinner darkBackground={true} />
        </div>
      )}
      {userStats.map(row => <RowTotals key={row.key} val={row.val} title={row.title} />)}
    </PageContainer>
  );
}
