import { DOC_TYPE_SERIES, newDocument } from '../../constants/docs';
import { LIST_OPTIONS, SERIES_TYPES } from '../../constants/pouchDB';
import { getUserDB } from '../../new/dbUtils';
import { apiRssAddSeriesRss } from '../../utils/api/methods/api';
import { errorMessage } from '../../utils/api/common';
import { fetchFileIdsBySeries } from '../../utils/files/filesWeb';
import { addFilter } from '../../utils/pouchDB/documents';
import {
    readUserDoc,
    updateUserDoc,
    removeUserDocs,
    findUserDocs,
    writeUserDoc
} from '../../handlers/userDB';
import { getUserId } from '../../utils/webSettings';
import { fetchEpisodesBySeriesId, fetchEpisodesByVideoId } from '../episodes/actions';
import { fetchSesonsBySeriesId } from '../seasons/actions';
import { ACTION_TYPES } from './reducer';


// TODO: Need to delete related seasons if we are deleting series

const ACTIONS = {
    list: {
        read: {
            success: list => ({ type: ACTION_TYPES.list.read.success, list }),
        },
    },
    current: {
        read: {
            success: current => ({ type: ACTION_TYPES.current.read.success, current }),
        },
    },
    deleted: seriesId => ({ type: ACTION_TYPES.deleted, seriesId }),
};


export const insert = values => async (dispatch) => {
    const doc = newDocument(DOC_TYPE_SERIES, values);
    return dispatch(writeUserDoc(doc));
};

export const fetch = id => (dispatch) => {
    const userDB = dispatch(getUserDB());

    if (userDB) {
        return userDB
            .get(id)
            .then((doc) => {
                // dispatch(ACTIONS.current.read.success(doc));
                return doc;
            })
            .catch(err => {
                console.error(err);
                throw err;
            });
    }
};

export const saveSeries = values => async dispatch => dispatch(updateUserDoc(values));

export const fetchSeries = opt => (dispatch, getState) => {
    let searchOpt = opt;
    if (!searchOpt) {
        const { series } = getState();
        searchOpt = series;
    }
    return dispatch(findUserDocs(searchOpt));
};

export const readSeriesList = () => async (dispatch) => {
    const list = await dispatch(fetchSeries());
    dispatch(ACTIONS.list.read.success(list));
};

export const findSeriesByTitle = (title, excludeIds) => async (dispatch) => {
    let opt = LIST_OPTIONS.series;

    if (excludeIds && excludeIds.length) {
        opt = addFilter(opt, '_id', excludeIds, '$nin');
    }
    if (title) {
        opt = addFilter(opt, 'title', new RegExp(`^${title}`, 'i'), '$regex');
    } else {
        opt = { ...opt, limit: 20 }; // Limit if title is not set
    }

    opt = { ...opt, fields: ['title', '_id'] };
    return dispatch(fetchSeries(opt));
};
export const findSeriesByFileId = fileId => async (dispatch) => {
    const docs = await dispatch(fetchEpisodesByVideoId([fileId]));

    if (docs && docs.length) {
        return dispatch(findUserDocs(
            addFilter(LIST_OPTIONS.series, '_id', docs.map(doc => doc.seriesId))
        )) || [];
    }

    return [];
};

export const addDistribution = async (seriesId) => {
    const response = await apiRssAddSeriesRss(seriesId);
    if (!(response && response.message
        && (
            response.message === 'Series has been added to distribution'
            || response.message === 'Series already exists')
    )) {
        throw new Error(errorMessage(response));
    }
    return true;
};

export const readDbSeriesType = seriesId => async (dispatch) => {
    const fileIds = await dispatch(fetchFileIdsBySeries(seriesId));

    if (fileIds && fileIds.length > 0) {
        const file = await dispatch(readUserDoc(fileIds[0]));
        if (file) {
            const map = Object.keys(SERIES_TYPES).reduce((acc, key) => ({
                ...acc,
                [SERIES_TYPES[key].fileType]: key
            }), {});

            if (map[file.type]) {
                return map[file.type];
            }
        }
    }

    return null;
};


export const removeSeries = (id) => async (dispatch) => {
    const episodes = await dispatch(fetchEpisodesBySeriesId(id));
    const seasons = await dispatch(fetchSesonsBySeriesId(id));

    await dispatch(removeUserDocs([
        id,
        ...episodes.map(doc => doc._id),
        ...seasons.map(doc => doc._id),
    ]));

    dispatch(ACTIONS.deleted(id));
    dispatch(readSeriesList());
};
