import { getFrameCanvas } from './canvas';
import { getDefaultHeight, getDefaultPosition, getDefaultQuality, getDefaultTimeout, getDefaultType, getDefaultWidth, validateErrors, validateHeight, validatePosition, validateQuality, validateSource, validateTimeout, validateWidth } from './options'
import { getTime } from './utils';

/**
 * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
 *
 * @param {HtmlElement} canvas
 * @param {int} width
 * @param {int} height
 * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
 */
function doHermiteResize(canvas: HTMLCanvasElement, width: number, height: number, resize_canvas = true) {
    const width_source = canvas.width;
    const height_source = canvas.height;
    width = Math.round(width);
    height = Math.round(height);

    const ratio_w = width_source / width;
    const ratio_h = height_source / height;
    const ratio_w_half = Math.ceil(ratio_w / 2);
    const ratio_h_half = Math.ceil(ratio_h / 2);

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error('Unable to create context("2d")');
    }
    const img = ctx.getImageData(0, 0, width_source, height_source);
    const img2 = ctx.createImageData(width, height);
    const data = img.data;
    const data2 = img2.data;

    for (let j = 0; j < height; j++) {
        for (let i = 0; i < width; i++) {
            const x2 = (i + j * width) * 4;
            let weight = 0;
            let weights = 0;
            let weights_alpha = 0;
            let gx_r = 0;
            let gx_g = 0;
            let gx_b = 0;
            let gx_a = 0;
            const center_y = (j + 0.5) * ratio_h;
            const yy_start = Math.floor(j * ratio_h);
            const yy_stop = Math.ceil((j + 1) * ratio_h);
            for (let yy = yy_start; yy < yy_stop; yy++) {
                const dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
                const center_x = (i + 0.5) * ratio_w;
                const w0 = dy * dy; //pre-calc part of w
                const xx_start = Math.floor(i * ratio_w);
                const xx_stop = Math.ceil((i + 1) * ratio_w);
                for (let xx = xx_start; xx < xx_stop; xx++) {
                    const dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                    const w = Math.sqrt(w0 + dx * dx);
                    if (w >= 1) {
                        //pixel too far
                        continue;
                    }
                    //hermite filter
                    weight = 2 * w * w * w - 3 * w * w + 1;
                    const pos_x = 4 * (xx + yy * width_source);
                    //alpha
                    gx_a += weight * data[pos_x + 3];
                    weights_alpha += weight;
                    //colors
                    if (data[pos_x + 3] < 255)
                        weight = weight * data[pos_x + 3] / 250;
                    gx_r += weight * data[pos_x];
                    gx_g += weight * data[pos_x + 1];
                    gx_b += weight * data[pos_x + 2];
                    weights += weight;
                }
            }
            data2[x2] = gx_r / weights;
            data2[x2 + 1] = gx_g / weights;
            data2[x2 + 2] = gx_b / weights;
            data2[x2 + 3] = gx_a / weights_alpha;
        }
    }
    //clear and resize canvas
    if (resize_canvas === true) {
        canvas.width = width;
        canvas.height = height;
    } else {
        ctx.clearRect(0, 0, width_source, height_source);
    }

    //draw
    ctx.putImageData(img2, 0, 0);
}

type TGetThumbnailHermiteOptions = {
  height: number;
  position: number;
  quality?: number;
  timeout: number;
  type: string;
  width: number;
}

type TGetThumbnailHermiteResult = {
  height: number;
  position: number;
  type: string;
  url: string;
  width: number;
  time: number;
}

function getOptions(options?: Partial<TGetThumbnailHermiteOptions>): TGetThumbnailHermiteOptions {
  const { width, height, type, quality, position, timeout } = options ?? {};
  return {
    height: getDefaultHeight(height),
    width: getDefaultWidth(width),
    type: getDefaultType(type),
    quality: getDefaultQuality(quality),
    position: getDefaultPosition(position),
    timeout: getDefaultTimeout(timeout),
  };
}

function validate(videoSrc: string, options?: Partial<TGetThumbnailHermiteOptions>): TGetThumbnailHermiteOptions {
  const opts = getOptions(options);

  validateErrors([
    validateSource(videoSrc),
    validatePosition(opts.position),
    validateTimeout(opts.timeout),
    validateQuality(opts.quality),
    validateHeight(opts.height),
    validateWidth(opts.width),
  ]);

  return opts;
}

export async function getThumbnailHermite(videoSrc: string, options: Partial<TGetThumbnailHermiteOptions>): Promise<TGetThumbnailHermiteResult|undefined> {
  // const { width, height, type, quality, position, timeout } = validate(videoSrc, options);
  const { width, height, type, quality, position, timeout } = validate(videoSrc, options);

  const targetRatio = width / height;

  try {
    const canvas = await getFrameCanvas(videoSrc, { position, timeout, ratio: targetRatio });
    if (canvas) {
      try {
        const start = window.performance.now();
        doHermiteResize(canvas, width, height, true);

        try {
          const url = canvas.toDataURL(type, quality);
          return {
            height,
            position,
            type,
            url,
            width,
            time: getTime(start),
          };
        } catch (e) {
          throw new Error(`Can't get dataUrl: ${e}`);
        }
      } catch (e) {
        throw new Error(`Can't downscale image: ${e}`);
      }
    }
  } catch (e) {
    console.error('Error getting video frame', e);
  }

  return undefined;

}
