import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readVideosList } from 'shared/modules/videos/actions';
import { CONTENT_TYPE } from 'shared/constants/general';
import ListContainer from 'shared/components/ListContainer/ListContainer';
import VideoListItemDetails from './VideoListItemDetails';

export default function VideosList() {
  const videos = useSelector(s => s.videos.list);
  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);

  const init = useCallback(async () => {
    await dispatch(readVideosList());
    setReady(true);
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ListContainer
      ready={ready}
      title="Videos"
      items={videos}
      actions={[
        // { label: 'Add Project', link: ROUTE.projects.create() }
      ]}
      details={VideoListItemDetails}
      type={CONTENT_TYPE.VIDEO}
    />
  );
}
