import {
	IconFileMedia, IconFileMisc, IconFileProject, IconMic, IconVideo,
} from 'shared/_ui/icon';
import { DocType } from 'shared/constants/docs';

// eslint-disable-next-line import/prefer-default-export
export const FILE_TYPE_ICON_MAP = {
	[DocType.FILE_AUDIO]: IconMic,
	[DocType.FILE_MEDIA]: IconFileMedia,
	[DocType.FILE_MISC]: IconFileMisc,
	[DocType.FILE_PROJECT]: IconFileProject,
	[DocType.FILE_FINISH_VIDEO]: IconVideo,
	[DocType.FILE_FINISH_AUDIO]: IconMic,
	// TODO: Solve TS problem
} as const; // satisfies Record<DocTypeFiles, FC>;
