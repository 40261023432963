import React, { useContext } from 'react'
import { UserContext } from 'shared/Providers/User'
import { Cloud } from 'shared/_model/cloud/types'
import CloudIcon from 'shared/icons/cloud-icon'

const useCloudStatusIcon = (cloud: Cloud) => {
  let Component

  if (cloud && 's3' in cloud && cloud?.s3) {
    Component = CloudIcon
  }

  return {
    Component: Component ?? CloudIcon,
    // disabled: !(cloud && Object.keys(cloud).length > 0)
    disabled: Component ? undefined : 'disabled',
  }
}

type CloudStatusIconProps = Parameters<typeof CloudIcon>[0] & {
  cloud: Cloud
}

export default function CloudStatusIcon({
  cloud,
  fontSize = 'large',
}: CloudStatusIconProps): JSX.Element | null {
  const {
    permissions: { canUseCloud },
  } = useContext(UserContext)
  const { Component, disabled } = useCloudStatusIcon(cloud)

  if (!canUseCloud) {
    return null
  }

  return (
    <Component fontSize={fontSize} color={disabled ? 'disabled' : undefined} />
  )
}
