import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { DOCUMENT_DESCRIPTIONS, DOC_TYPE_FILE_AUDIO, DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO, DOC_TYPE_FILE_MEDIA, DOC_TYPE_FILE_MISC, DOC_TYPE_FILE_PROJECT, TDocumentTypeFile } from 'shared/constants/docs'
import { DOC_TYPES_FILES } from 'shared/constants/pouchDB'


const DOC_TYPES_PLACEHOLDERS: Record<TDocumentTypeFile, string> = {
  [DOC_TYPE_FILE_MEDIA]: 'Add raw video clips, images, photos, etc',
  [DOC_TYPE_FILE_AUDIO]: 'Add raw audio clips, music, etc',
  [DOC_TYPE_FILE_PROJECT]: 'Add editable project files',
  [DOC_TYPE_FILE_FINISH_VIDEO]: 'Add any finalized or finished videos',
  [DOC_TYPE_FILE_FINISH_AUDIO]: 'Add any finalized or finished audio',
  [DOC_TYPE_FILE_MISC]: 'Add any other files that don’t fit any other folder',
};


type FileTypeSelectProps = {
  value: TDocumentTypeFile;
  setValue: (type: TDocumentTypeFile) => void;
  sx: Parameters<typeof Select>[0];//.sx
}

export default function FileTypeSelect({ value, setValue, sx }: FileTypeSelectProps): JSX.Element {
  const handleChange = useCallback((e: SelectChangeEvent<TDocumentTypeFile>) => {
    setValue(e.target.value as TDocumentTypeFile);
  }, [setValue]);

  const typeSelectItems = useMemo(() => {
    return DOC_TYPES_FILES.map((type: TDocumentTypeFile) => (
      <MenuItem value={type} key={type}>
        <strong>{DOCUMENT_DESCRIPTIONS[type].title}</strong>{` (${DOC_TYPES_PLACEHOLDERS[type]})`}
      </MenuItem>
    ));
  }, []);

  return (
    <Select<TDocumentTypeFile>
      variant="standard"
      size="small"
      value={value}
      onChange={handleChange}
      renderValue={(val) => DOCUMENT_DESCRIPTIONS[val].title}
      sx={sx}
    >
      {typeSelectItems}
    </Select>
  );
}
