import cn from 'classnames';
import React, { useCallback } from 'react';
import styles from 'shared/components/Input/ToggleSwitch/toggle-switch.module.scss';


type TToggleSwitchProps = {
  id?: string
  name?: string
  value: boolean
  size?: 'small' | 'big'
  onClick?: (e: any) => void
  onChange?: (e: any) => void
  className?: string
  readOnly?: boolean
} & Record<string, any>;


export const ToggleSwitch: React.FC<TToggleSwitchProps> = (props) => {
  const {
    id,
    name,
    value,
    size = 'small',
    onChange,
    className,
    readOnly = false
    // onStateChanged,
    // ...restProps
  } = props;


  const switchClasses = cn(styles.switch, {
    [styles[`switch--big`]]: size === 'big',
    [styles[`switch--small`]]: size === 'small',
  }, className)

  const togglerClasses = cn(styles['switch-toggle'],
    {
      [styles['switch-toggle--on']]: value,
      [styles['switch-toggle--off']]: !value,
      [styles.readOnly]: readOnly,
    }
  )

  const toggleSwitch = useCallback(() => {
    if (!readOnly && onChange) {
      onChange({ target: {name, id, value: !value} });
    }
  }, [id, name, value, readOnly, onChange])

  return (
    <div className={switchClasses} onClick={toggleSwitch}>
      <div className={togglerClasses} />
    </div>
  )
}
