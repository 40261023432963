export function getDefaultType(type?: string): string {
  return type ?? 'image/jpeg';
}

export function getDefaultPosition(position?: number): number {
  return position ?? 0.25;
}

export function getDefaultQuality(quality?: number): number {
  return quality ?? 1;
}

export function getDefaultTimeout(timeout?: number): number {
  return timeout ?? 30 * 1000;
}

export function getDefaultWidth(width?: number): number {
  return width ?? 192;
}

export function getDefaultHeight(height?: number): number {
  return height ?? 108;
}

export function validateWidth(width?: number): string|undefined {
  if (width && width < 0) {
    return '"width" option must be positive';
  }
  return undefined;
}

export function validateHeight(value?: number): string|undefined {
  if (value && value < 0) {
    return '"height" option must be positive';
  }
  return undefined;
}

export function validateQuality(quality?: number): string|undefined {
  if (quality && (quality < 0 || quality > 1)) {
    return '"quality" option must be between [0..1]';
  }
  return undefined;
}
export function validatePosition(position?: number): string|undefined {
  if (position && (position < 0 || position > 1)) {
    return '"position" option must be between [0..1]';
  }
  return undefined;
}
export function validateTimeout(timeout?: number): string|undefined {
  if (timeout && timeout < 0) {
    '"timeout" option must be positive';
  }
  return undefined;
}
export function validateRatio(ratio?: number): string|undefined {
  if (ratio && ratio < 0) {
    return '"ratio" option must be positive';
  }
  return undefined;
}
export function validateSource(src: string): string|undefined {
  if (!src) {
    return 'Video source parameter must not be empty';
  }
  return undefined;
}

export function validateErrors(errors: (string|undefined)[]): void {
  const errs = errors.filter(e => !!e);
  if (errs.length) {
    throw new Error(`Validation error: \n${errs.join('\n')}`);
  }
}
