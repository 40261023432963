import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import { SERIES_TYPES } from '../../../../constants/pouchDB';
import * as episodesActions from '../../../../modules/episodes/actions';
import { EPISODE_SCHEMA } from '../../../../constants/general';
import { readUserDoc } from '../../../../handlers/userDB';
import styles from './EpisodeCreate.module.css';
import 'react-widgets/dist/css/react-widgets.css'; // TODO: need for Combobox

export default function EpisodeCreate({ seriesId, seasonId, onChange }) {
  const dispatch = useDispatch();

  const [episode, setEpisode] = useState(EPISODE_SCHEMA.default());
  const [seriesType, setSeriesType] = useState(null);
  const [files, setFiles] = useState([]);
  const [busy, setBusy] = useState(false);

  const init = useCallback(async () => {
    const values = await dispatch(episodesActions.newEpisodeValues(seriesId, seasonId));
    const series = await dispatch(readUserDoc(seriesId));
    setEpisode(ep => ({ ...ep, ...values, seriesId, seasonId }));
    setSeriesType(series.seriesType);
  }, [dispatch, seriesId, seasonId]);

  useEffect(() => {
    init();
  }, [init]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    await dispatch(episodesActions.insert(values));

    setSubmitting(false);
    await dispatch(episodesActions.readEpisodesList(seriesId, seasonId));

    if (onChange) {
      await onChange();
    }
  }, [dispatch, seriesId, seasonId, onChange]);

  const fetchFiles = useCallback(debounce(async (value) => {
    try {
      const result = await dispatch(episodesActions.searchFiles(value, SERIES_TYPES[seriesType].fileType));
      setFiles(result);
      setBusy(false);
    } catch (e) {
      console.error(e);
      setFiles([]);
      setBusy(false);
    }
  }, 500), [dispatch, seriesType]);

  const handleTitleChange = useCallback((value) => {
    if (value && (typeof value === 'object')) {
      setEpisode(ep => ({ ...ep, videoId: value._id, title: value.title }));
      setBusy(false);
    } else if (value && value.length > 0) {
      setBusy(true);
      fetchFiles(value);
    } else {
      setFiles([]);
    }
  }, [fetchFiles]);

  if (!episode.number) {
    return null;
  }

  const disabled = !seriesType;
  const fileLabel = seriesType ? SERIES_TYPES[seriesType].name : 'Video';

  return (
    <div className={styles.container}>
      <h1 className="margin-top-none">New Episode</h1>
      {!seriesType
      && (episode.number || '')
      && <div className={styles.error}>You should define series type first</div>
      }
      <Formik
        initialValues={episode}
        enableReinitialize
        validationSchema={EPISODE_SCHEMA}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => (
          <Form>
            <Field
              name="videoId"
              component={Input}
              type={Input.TYPE.COMBOBOX}
              label={fileLabel}
              placeHolder="Type to find finished video"
              busy={busy}
              onChange={handleTitleChange}
              data={files}
              valueField="_id"
              textField="title"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
              disabled={disabled}
            />
            <Field
              name="number"
              component={Input}
              label="Number:"
              readOnly
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
            />
            <Field
              name="description"
              type="textarea"
              component={Input}
              label="Description:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              disabled={disabled}
              spellCheck
            />
            <Field
              name="keywords"
              component={Input}
              type={Input.TYPE.MULTI_SELECT}
              maxLength={500}
              label="Keywords:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              disabled={disabled}
              showCopyButton
              inputProps={{ spellCheck: true }}
            />
            <FormButtons>
              <Button type="submit" disabled={isSubmitting || disabled} color={Button.COLOR.PRIMARY}>
                Create
              </Button>
            </FormButtons>
          </Form>
        )}
      />
    </div>
  );
}

EpisodeCreate.propTypes = {
  seriesId: PropTypes.string.isRequired,
  // TODO: seasonId required while we save doc, but id season is empty - we should create one to series
  seasonId: PropTypes.string,
  onChange: PropTypes.func,
};
