import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { finishUpload, prepareUpload } from 'app/utils/upload-files';
import { synchronizer, uppy } from '../../constants/globals';
import { saveCloudInfo, saveThumbCloudInfo } from '../uppyUploader/uppyHandler';
import { makeThumbnail } from './thumbnails';

const runStartUpload = ({ file, doc }) => async (dispatch) => {
    const fn = async (syncEvents) => {
        uppy.addFile({
            name: `[file] ${file.name} (${doc._id})`,
            type: file.type,
            data: file,
            meta: {
                doc,
                syncEvents,
                onUploadSuccess: async (uppyFile, data) => {
                    const doc = await dispatch(saveCloudInfo(uppyFile, data));
                    await finishUpload(uppyFile);
                    const thumb = await makeThumbnail(doc);
                    if (thumb) {

                      uppy.addFile({
                          name: `[thumb] ${file.name} (${doc._id})`,
                          type: thumb.blob.type,
                          data: thumb.blob,
                          meta: {
                              uploadType: `thumb`,
                              doc,
                              syncEvents,
                              onUploadSuccess: async (uppyThumbFile, _thumbData) => {
                                  await dispatch(saveThumbCloudInfo(uppyThumbFile));
                                  await finishUpload(uppyThumbFile);
                              },
                          },
                      });
                    }
                },
            },
        });
    };

    await synchronizer.addItem({
        id: doc._id,
        obj: doc,
        title: doc.title,
        actions: [{
            fn,
            title: 'Uploading ...',
            type: 'upload',
            ignorePreviousErrors: true,
            autoComplete: false,
        }]
    });
};

export const runUploadFile = fileObjects => async (dispatch) => {
    await synchronizer.addItems(fileObjects
      .filter(({ doc }) => (doc.systemType ?? 'file') === 'file')
      .map(({ file, doc }) => {
        const fn = async (action) => {
            await prepareUpload(file, action);
            await dispatch(runStartUpload({ file, doc }));
        };
        return {
            id: doc._id,
            obj: doc,
            title: doc.title,
            actions: [{
                fn,
                title: 'Preparing ...',
                icon: faUpload,
                type: 'prepare'
            }]
        };
    }));
};

// const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
//     const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
//     return {
//         width: srcWidth * ratio,
//         height: srcHeight * ratio
//     };
// };
// const resizeImage = (src, maxWidth, maxHeight, fileObj) => {
//     const canvas = document.createElement('canvas');
//     const img = new Image();
//     const ctx = canvas.getContext('2d');
//     img.src = src;
//     img.onload = () => {
//         const resize = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
//         canvas.width = resize.width;
//         canvas.height = resize.height;
//         ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
//         fileObj.image = canvas.toDataURL(fileObj.file.type, 0.8);
//     };
// };
// const getPreviewImage = (fileObj) => {
//     if (fileObj.file instanceof FileFS) {
//         return fileObj.file.getBase64String();
//     }
//
//     const reader = new FileReader();
//     reader.onload = (e) => {
//         resizeImage(e.target.result, 80, 80, fileObj);
//     };
//     reader.readAsDataURL(fileObj.file);
//     return reader.result;
// };
// const isImage = fileObj => ['image/jpeg', 'image/png', 'image/gif'].includes(fileObj.file.type);
// const uploadFactory = (file, doc) => {
//     const fileObj = new FileObject(file, doc);
//     if (isImage(fileObj)) {
//         getPreviewImage(fileObj);
//     }
//     return fileObj;
// };
