// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import React
  // , { useContext, useMemo }
  from 'react';
// import DropDown from '../../../../components/DropDown/DropDown';
// import Index from '../../../../components/Index/Index';
// import { UserDataContext } from '../../../../Providers/UserData';
// import styles from './information-menu.module.scss';
// import cn from 'classnames';


export const InformationMenu: React.FC = () => {
  return null; // TODO: Temporary removed
  // const { information: { wrongDuration } } = useContext(UserDataContext);
  //
  // const count = useMemo(() => {
  //   return wrongDuration.count;
  // }, [wrongDuration.count]);
  //
  //
  // const items = useMemo(() => {
  //   const result = [];
  //   if (wrongDuration.count) {
  //     result.push({
  //       label: wrongDuration.message,
  //       onClick: wrongDuration.busy ? undefined : wrongDuration.calculate,
  //     })
  //   }
  //   return result;
  // }, [wrongDuration.count, wrongDuration.busy, wrongDuration.message, wrongDuration.calculate]);
  //
  //
  // return (
  //   <>
  //     {items.length > 0 && (
  //       <DropDown noIcon alignRight items={items}>
  //         <Index icon={faInfoCircle} className={cn(styles.menuButton, {
  //           [styles.active]: count
  //         })} />
  //       </DropDown>
  //     )}
  //   </>
  // );
}
