import { faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import EventEmitter from 'events';
import Api from '../utils/api/Api';
import Synchronizer from '../utils/synchronizer/Synchronizer';
import UppyWrapper from '../utils/uppyUploader/UppyWrapper';

export const synchronizer = Synchronizer();
export const api = Api();
export const uppy = UppyWrapper();
export const userEmitter = new EventEmitter();

export const USER_EVENTS_NAMES = { message: 'message' };

export const USER_EVENT_COLORS = {
    info: 'white',
    warn: 'orange',
    error: 'coral'
};
export const emitUserEvent = (message, title, color = USER_EVENT_COLORS.info, icon = null, timeout = undefined) => {
    userEmitter.emit(USER_EVENTS_NAMES.message, { message, color, title, icon, timeout });
    if (color === USER_EVENT_COLORS.error) {
        console.error(title, message);
    }
};
export const emitUserError = (message, title = 'Error') => {
    emitUserEvent(message, title, USER_EVENT_COLORS.error, faTimesCircle);
};
export const emitUserWarn = (message, title = 'Warning') => {
    emitUserEvent(message, title, USER_EVENT_COLORS.warn, faExclamationCircle);
};
export const emitUserInfo = (message, title = 'Info') => {
    emitUserEvent(message, title, USER_EVENT_COLORS.info, faInfoCircle);
};
