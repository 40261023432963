import { TextField, TextFieldProps } from '@mui/material'
import { Field, FieldProps } from 'formik';


const FieldWrapper: React.FC<FieldProps & TextFieldProps> = ({ field, form, ...props }) => (
  <TextField
    autoComplete="off"
    {...props}
    {...field}
    error={!!form.errors[field.name]}
    helperText={(form.errors[field.name] as string ?? props.helperText) ?? null}
  />
);

export default function FormikTextField(props: FieldProps & TextFieldProps): JSX.Element {
  return <Field component={FieldWrapper} {...props} />
}
