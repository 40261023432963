import React from 'react';
import { Field } from 'formik';
import YoutubePlayer from '../../../../../../components/YoutubePlayer/YoutubePlayer';
import { DOC_TYPE_FILE_FINISH_VIDEO } from '../../../../../../constants/docs';
import styles from './FileMetadata.module.css';
import Input from '../../../../../../components/Input/Input';
import { useUserContext } from 'shared/Providers/User';
import { useCurrentFile } from 'shared/Providers/CurrentFile';
import { useEditDocumentContext } from 'shared/hooks/use-edit-document';

export default function FileMetadata() {
  const { file, saveFile } = useCurrentFile();
  const { permissions: { canPublishMedia } } = useUserContext();
  const { isEdit, toggleEdit } = useEditDocumentContext();

  const readOnly = !saveFile;

  return (
    <div className={styles.editContainer}>
      <div>
        {canPublishMedia && file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
          !isEdit && file.youtube && file.youtube.youtubeId
            ? <YoutubePlayer youtubeId={file.youtube.youtubeId} />
            : (
              <Field
                name="youtube.youtubeId"
                component={Input}
                label="Youtube Embed:"
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                readOnly={!isEdit}
                editClick={!readOnly && toggleEdit}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
              />
            )
        )}
        <Field
          name="title"
          component={Input}
          label="Title:"
          labelWidth={Input.LABEL_WIDTH.RIGHT}
          labelClassName={styles.label}
          readOnly={!isEdit}
          editClick={!readOnly && toggleEdit}
          spaceBottom={Input.SPACE_BOTTOM.SMALL}
          spellCheck
        />
        <Field
          name="description"
          component={Input}
          type={Input.TYPE.TEXTAREA}
          label="Description:"
          labelWidth={Input.LABEL_WIDTH.RIGHT}
          labelClassName={styles.label}
          readOnly={!isEdit}
          editClick={!readOnly && toggleEdit}
          spaceBottom={Input.SPACE_BOTTOM.SMALL}
          spellCheck
        />
        <Field
          name="keywords"
          component={Input}
          type={Input.TYPE.MULTI_SELECT}
          maxLength={500}
          label="Keywords:"
          labelWidth={Input.LABEL_WIDTH.RIGHT}
          labelClassName={styles.label}
          spaceBottom={Input.SPACE_BOTTOM.SMALL}
          readOnly={!isEdit}
          editClick={!readOnly && toggleEdit}
          showCopyButton
          inputProps={{ spellCheck: true }}
        />
        {file.meta && Object.keys(file.meta).map(curMetaField => (
          <Field
            key={curMetaField}
            name={'file.meta.$curMetaField}'}
            component={Input}
            label={`${curMetaField}:`}
            labelWidth={Input.LABEL_WIDTH.RIGHT}
            labelClassName={styles.label}
            field={{
              value: file.meta[curMetaField]
            }}
            readOnly
            spaceBottom={Input.SPACE_BOTTOM.SMALL}
          />
        ))}
      </div>
    </div>
  );
}
