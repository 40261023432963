import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTE } from 'shared/constants/routes_old';
import LoginContainer from 'shared/components/LoginContainer/LoginContainer';
import ApplyResetPassword from './ApplyResetPassword';
import Login from './Login';
import Register from './Register';
import RegisterConfirmed from './RegisterConfirmed';
import RegisterSuccess from './RegisterSuccess';
import ResetPassword from './ResetPassword';

const LoginLayout = () => (
	<Switch>
		{/* {!getLocalHelpScreens()[KEY_GREETINGS] && <Route component={HelpGreetings} />} */}
		<LoginContainer>
			<Switch>
				<Route path={ROUTE.register} exact component={Register} />
				<Route path={ROUTE.registerSuccess} component={RegisterSuccess} />
				<Route path={ROUTE.registerConfirmed} component={RegisterConfirmed} />
				<Route path={ROUTE.resetPassword} component={ResetPassword} />
				<Route path={ROUTE.applyResetPassword} component={ApplyResetPassword} />

				<Route component={Login} />
			</Switch>
		</LoginContainer>
	</Switch>
);

export default LoginLayout;
