// In Web app we should use `import PouchDB from 'pouchdb';`
import PouchDB from 'pouchdb'
import PouchDbFind from 'pouchdb-find'

PouchDB.plugin(PouchDbFind);

export async function getDbName(databaseId: string): Promise<string> {
  return `DB/${databaseId}`;
}

export default PouchDB;
