import { useParams } from 'react-router'
import { Sharing, SharingType } from 'shared/_model/sharing/types'

export const getShareProperty = (sharing: Sharing | undefined) => {
  if (!sharing) {
    return []
  }

  if (!Array.isArray(sharing)) {
    return [sharing]
  }

  return sharing
}

export const isShareSet = (sharing: Sharing | undefined, type: SharingType) => {
  const shareItem = getShareProperty(sharing).filter((sh) => sh.type === type)
  return !!shareItem.length
}

export const useSharingId = () => {
  const { sharingId } = useParams<{ sharingId?: string }>()
  return { sharingId }
}
