import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import Combobox from 'react-widgets/lib/Combobox';
import DropdownList from 'react-widgets/lib/DropdownList';
import styles from './Input.module.css';
import MultiSelectWrapper from './items/MultiSelectWrapper';
import Switch from './Switch';
import { ToggleSwitch } from './ToggleSwitch';
import ValidationIcon from './ValidationIcon';
import Textarea from './Textarea';
import RadioButtons from './RadioButtons';
import FileUploader from './FileUploader';

const LABEL_WIDTH = {
  FULL: 'full',
  RIGHT: 'right',
};

const SPACE_BOTTOM = {
  SMALL: 'small',
  MEDIUM: 'medium',
  NONE: 'none',
};

const TYPE = {
  TEXTAREA: 'textarea',
  RADIO_BUTTONS: 'radio_buttons',
  FILE: 'file',
  COMBOBOX: 'combobox',
  MULTI_SELECT: 'multi_select',
  DROPDOWN: 'DropdownList',
  SWITCH: 'switch',
  CHECKBOX: 'check',
  SELECT: 'select',
  LABEL: 'label',
  PASSWORD: 'password',
};

const stringify = (value) => {
  if (isUndefined(value)) {
    return '';
  }
  if (typeof value === 'string') {
    return value;
  }
  return String(value);
};

const Input = (props) => {
  const {
    spaceBottom,
    labelWidth,
    labelOnTheRight = false,
    labelClassName,
    rootClassName,
    wrapperClassName,
    label,
    className,
    type,
    required, // just remove this value from rest.
    field, // { name, value, onChange, onBlur }
    noValidate,
    noValidateIcon,
    editClick,
    form: {
      touched,
      errors,
      setFieldValue = null
      // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    },
    ...rest
  } = props;

  let input;

  if (!rest.id) {
    rest.id = `input_${field.name}`;
  }

  const fieldError = get(errors, field.name);
  const touchedValue = get(touched, field.name);
  const fieldTouched = (Array.isArray(touchedValue) ? true : touchedValue) || props.form.submitCount > 0;

  const inputClassName = cn(
    {
      [styles.input]: (
        type !== TYPE.RADIO_BUTTONS
        && type !== TYPE.MULTI_SELECT
        && type !== TYPE.COMBOBOX
        && type !== TYPE.DROPDOWN
        && type !== TYPE.SWITCH
      ),
      [styles.rwCenter]: (
        rest.readOnly && (
          type === TYPE.MULTI_SELECT
          || type === TYPE.COMBOBOX
          || type === TYPE.DROPDOWN
        )
      ),
      [styles.inputDisabled]: rest.disabled,
      [styles.inputInvalid]: fieldTouched && fieldError,
    },
    className
  );

  const wrapperCN = cn(styles.inputWrapper, {
      [styles.noValidate]: noValidate || noValidateIcon,
      [styles.editable]: rest.readOnly && !!editClick,
      [styles.flexCenter]: type === TYPE.SWITCH || type === TYPE.CHECKBOX,
      [styles.flex0]: labelOnTheRight,
    },
    wrapperClassName,
  );

  const labelCN = cn(styles.label, {
    [styles[`labelWidth${labelWidth}`]]: !!labelWidth,
    [styles.flex1]: labelOnTheRight,
    [styles.pointer]: type === TYPE.CHECKBOX
  }, labelClassName);

  const onCreate = rest.onCreate || field.onCreate;
  const onChange = rest.onChange || field.onChange;
  let curValue = null;

  switch (type) {
  case TYPE.SELECT:
    input = (
      <select
        key={rest.id}
        {...field}
        {...rest}
        value={field.value || ''}
        className={inputClassName}
      />
    );
    break;
  case !rest.readOnly && TYPE.COMBOBOX:
    input = (
      <Combobox
        key={rest.id}
        {...rest}
        className={inputClassName}
      />
    );
    break;
  case TYPE.MULTI_SELECT:
    curValue = Array.isArray(field.value)
      ? field.value
      : (field.value && field.value.split(',')) || [];
    input = (
      <MultiSelectWrapper
        key={rest.id}
        {...field}
        value={curValue}
        {...rest}
        className={inputClassName}
        onCreate={rest.allowCreate !== false && setFieldValue
          ? ((val) => {
            if (val && curValue.indexOf(val) === -1) {
              curValue = [...curValue, val];
              setFieldValue(field.name, rest.multiSelectArray === true ? curValue : curValue.join(','));
            }
          })
          : onCreate}
        onChange={setFieldValue
          ? (val => setFieldValue(field.name, rest.multiSelectArray === true || !Array.isArray(val) ? val : val.join(',')))
          : onChange}
      />
    );
    break;
  case !rest.readOnly && TYPE.DROPDOWN:
    input = (
      <DropdownList
        key={rest.id}
        {...field}
        {...rest}
        className={inputClassName}
      />
    );
    break;
  case TYPE.TEXTAREA:
    input = (
      <Textarea
        key={rest.id}
        spellCheck={false}
        {...field}
        {...rest}
        value={stringify(field.value)}
        className={inputClassName}
      />
    );
    break;
  case TYPE.SWITCH:
    input = (
      <ToggleSwitch
        key={rest.id}
        {...field}
        {...rest}
        // className={inputClassName}
        onChange={(e) => {
          // // e.target.value is ALWAYS string. Fix this issue.
          if (!rest.disabled && !rest.readOnly) {
            const event = {
              target: e.target
            };
            if (setFieldValue) {
              setFieldValue(field.name, e.target.value);
            }
            // field.onChange(event);
            if (onChange) {
              onChange(event);
            }
          }
        }}
      />
    );
    break;
  case TYPE.CHECKBOX:
    input = (
      <Switch
        key={rest.id}
        {...field}
        {...rest}
        checkbox={type === TYPE.CHECKBOX}
        className={inputClassName}
        onChange={(e) => {
          // // e.target.value is ALWAYS string. Fix this issue.
          if (!rest.disabled && !rest.readOnly) {
            const event = {
              target: {
                id: e.target.id,
                name: e.target.name,
                value: e.target.checked,
              }
            };
            if (setFieldValue) {
              setFieldValue(field.name, e.target.checked);
            }
            // field.onChange(event);
            if (onChange) {
              onChange(event);
            }
          }
        }}
      />
    );
    break;
  case TYPE.RADIO_BUTTONS:
    input = (
      <RadioButtons
        key={rest.id}
        {...field}
        {...rest}
        value={field.value}
        className={inputClassName}
        onChange={(e) => {
          // e.target.value is ALWAYS string. Fix this issue.
          if (!rest.disabled && !rest.readOnly) {
            const selectedOption = (rest.options || [])
              .filter(option => e.target.value === String(option.value))
              .pop();
            if (selectedOption) {
              onChange({
                target: {
                  id: e.target.id,
                  name: e.target.name,
                  value: selectedOption.value,
                }
              });
            } else {
              onChange(e);
            }
          }
        }}
      />
    );
    break;
  case TYPE.FILE:
    input = (
      <FileUploader
        key={rest.id}
        {...field}
        {...rest}
        form={props.form}
        value={field.value || ''}
        className={inputClassName}
      />
    );
    break;
  case TYPE.LABEL:
    input = (
      <Textarea
        key={rest.id}
        {...field}
        {...rest}
        readOnly
        className={inputClassName}
      />
      // <label
      //     htmlFor={rest.id}
      //     className={cn(styles.label, styles.labelWidthBasic, className)}
      // >
      //     <span className={styles.labelText}>{isUndefined(field.value) ? '' : field.value}</span>
      // </label>
    );
    break;
  default:
    // DropdownList properties
    delete rest.valueField;
    delete rest.textField;

    input = rest.readOnly ? (
      <Textarea
        key={rest.id}
        spellCheck={false}
        {...field}
        {...rest}
        value={stringify(field.value)}
        className={inputClassName}
      />
    ) : (
      <input
        key={rest.id}
        type={type || 'text'}
        spellCheck={false}
        {...field}
        {...rest}
        value={isUndefined(field.value) ? '' : field.value}
        className={inputClassName}
      />
    );
  }


  const labelNode = !!label && (
    <label htmlFor={rest.id} className={labelCN}>
      <span className={styles.labelText}>{label}</span>
    </label>
  );

  const inputNode = (
    <div
      className={wrapperCN}
      onClick={(rest.readOnly && !!editClick) ? editClick : undefined}
    >
      {!noValidateIcon && <ValidationIcon key={`${rest.id}_icon`} {...props} {...rest} {...{
        fieldError,
        fieldTouched
      }} />}
      {input}
      {fieldTouched && fieldError && (
        <div className={cn(styles.error, styles.errorBelow)}>
          {fieldError}
        </div>
      )}
    </div>
  );

  return (
    <div className={cn(styles.inputRoot, spaceBottom && styles[`spaceBottom${spaceBottom}`], rootClassName)}>
      {labelOnTheRight ? (<>
        {inputNode}
        {labelNode}
      </>) : (<>
        {labelNode}
        {inputNode}
      </>)}
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  spaceBottom: PropTypes.string,
  labelOnTheRight: PropTypes.bool,
  labelWidth: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  type: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onCreate: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  noValidate: PropTypes.bool,
  noValidateIcon: PropTypes.bool,
  editClick: PropTypes.func,
  inputProps: PropTypes.object,
  // readFile: PropTypes.func.isRequired,
};

Input.defaultProps = {
  form: {},
  noValidateIcon: false
};

Input.LABEL_WIDTH = LABEL_WIDTH;
Input.SPACE_BOTTOM = SPACE_BOTTOM;
Input.TYPE = TYPE;

export default Input;
