import { Dialog, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { DOC_GROUP_TYPE_FILE, DocTypeFiles, DOCUMENT_DESCRIPTIONS } from 'shared/constants/docs';

type Props = {
	onClose: (value?: DocTypeFiles) => void;
};

const LIST_DATA = Object.values(DOCUMENT_DESCRIPTIONS)
	.filter((d) => d.group === DOC_GROUP_TYPE_FILE)
	.map(({ type, title, description }) => ({ type: type as DocTypeFiles, title, description }));

export default function FileTypesSelectDialog({ onClose }: Props) {
	const handleListItemClick = (value?: DocTypeFiles) => {
		onClose(value);
	};

	return (
		<Dialog onClose={() => onClose()} open sx={{ py: 2 }}>
			<List sx={{}}>
				{LIST_DATA.map(({ type, title, description }) => (
					<ListItem disablePadding disableGutters key={title}>
						<ListItemButton
							onClick={() => handleListItemClick(type)}
							sx={{ py: 0 }}
						>
							<ListItemText
								primary={title}
								secondary={description}
								sx={{ '.MuiListItemText-primary': { fontWeight: 'bold' } }}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Dialog>
	);
}
