import { PropsWithChildren, useCallback } from 'react';
import FinishVideo from 'shared/Pages/common/FilePage/FinishVideo/FinishVideo';
import { useParams } from 'react-router-dom';
import { isFinishFile } from 'shared/constants/pouchDB';
import CurrentFileProvider, { useCurrentFile } from 'shared/Providers/CurrentFile'
import { Button, Stack, Tabs } from '@mui/material'
import useTabs from 'shared/components/Tabs/use-tabs'
import TabPanel from 'shared/components/Tabs/TabPanel'
import FileMetadata from 'shared/Pages/common/FilePage/FinishVideo/Pages/FileMetadata/FileMetadata'
import FileNotes from 'shared/Pages/common/FilePage/FinishVideo/Pages/FileNotes'
import YoutubeAnalytics from 'shared/components/YoutubeAnalytics/YoutubeAnalytics'
import FileSeriesList from 'shared/Pages/common/FilePage/FinishVideo/Pages/FileSeriesList/FileSeriesList'
import { ANALYTICS_TYPES } from 'shared/utils/youtube'
import { useSharingContext } from 'shared/Providers/Sharing'
import Tab from 'shared/components/Tabs/Tab'
import { Form, Formik, useFormikContext } from 'formik'
import { FILE_SCHEMA } from 'shared/constants/general'
import { PageContainer } from 'shared/Pages/common/PageContainer'
import { getTitleCategoryName } from 'shared/Pages/common/FilePage/utile'
import { ShareSettingsButton } from 'shared/components/ShareSettingsButton'
import EditDocumentProvider, { useEditDocumentContext } from 'shared/hooks/use-edit-document'
import useToggle from 'shared/hooks/use-toggle'
import { WindowContainer } from 'shared/components/WindowContainer'
import ShareSettings from 'shared/components/ShareSettings/ShareSettings'
import { SharingType } from 'shared/_model/sharing/types'


function FilePageContainer({ children }: PropsWithChildren) {
  // TODO: Add Skeleton for FilePage
  // const { status } = useCurrentFile();
  // if (status === 'loading') {
  //   return <>Skeleton</>;
  // }

  return <>{children}</>;
}

function FilePageFormActions({ toggleShowShareSettings }: {toggleShowShareSettings: () => unknown}) {
  const { isSubmitting, dirty, isValid, resetForm } = useFormikContext();
  const { isEdit, toggleEdit } = useEditDocumentContext();
  const { file } = useCurrentFile();

  const handleCancel = useCallback(() => {
    toggleEdit(resetForm);
  }, [toggleEdit, resetForm]);

  return (
    <Stack spacing={1} direction="row">
      <ShareSettingsButton
        disabled={isEdit}
        sharing={file.sharing}
        type={SharingType.FILE}
        onClick={toggleShowShareSettings}
      />
      {!isEdit && (
        <Button size="small" onClick={() => toggleEdit()} color="primary" disabled={isSubmitting}>
          Edit
        </Button>
      )}
      {isEdit && (
        <>
          <Button size="small" onClick={handleCancel} color="secondary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button size="small" type="submit" disabled={isSubmitting || !dirty || !isValid}>
            Save
          </Button>
        </>
      )}
    </Stack>
  );
}

function FilePageForm() {
  const { file, saveFile } = useCurrentFile();
  const [tab, handleTabChange] = useTabs();
  const { sharingId } = useSharingContext();
  const canShare = !sharingId;

  const [showShareSettings, toggleShowShareSettings] = useToggle()

  const hasYoutube = !!file.youtube;

  return (
    <>
      {showShareSettings && canShare && (
        <WindowContainer closeAction={toggleShowShareSettings}>
          <ShareSettings
            documentId={file._id}
            type={SharingType.FILE}
          />
        </WindowContainer>
      )}
      <Form>
        <PageContainer
          title={file.title}
          parentTitle={getTitleCategoryName(file.type)}
          actions={!!saveFile && <FilePageFormActions toggleShowShareSettings={toggleShowShareSettings} />}
        >
          <FinishVideo />
          <Tabs value={tab} onChange={handleTabChange} sx={{ mt: 5, mb: 7 }}>
            <Tab label="Metadata" />
            {!sharingId && <Tab label="Notes" />}
            {!sharingId && <Tab label="Series" />}
            {!sharingId && hasYoutube && <Tab label="Analytics" />}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <FileMetadata />
          </TabPanel>
          {!sharingId && (
            <>
              <TabPanel value={tab} index={1}>
                <FileNotes />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <FileSeriesList fileId={file._id} />
              </TabPanel>
              {hasYoutube && (
                <TabPanel value={tab} index={3}>
                  <YoutubeAnalytics
                    analyticType={ANALYTICS_TYPES.video}
                    id={file.youtube && file.youtube.youtubeId}
                  />
                </TabPanel>
              )}
            </>
          )}
        </PageContainer>
      </Form>
    </>
  );
}

function FilePageInner() {
  const { file, saveFile } = useCurrentFile();
  const { resetEdit } = useEditDocumentContext();

  const handleSubmit = useCallback(async (values: Partial<typeof file>) => {
    await saveFile?.(values);
    resetEdit();
  }, [saveFile]);

  if (!file._id) {
    return null;
  }

  if (!isFinishFile(file.type)) {
    return null;
  }

  return (
    <FilePageContainer>
      <Formik
        initialValues={file}
        validationSchema={FILE_SCHEMA}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FilePageForm />
      </Formik>
    </FilePageContainer>
  );
}

export function FilePage(): JSX.Element {
  const { fileId } = useParams<{ fileId: string}>();

  return (
    <CurrentFileProvider fileId={fileId}>
      <EditDocumentProvider>
        <FilePageInner />
      </EditDocumentProvider>
    </CurrentFileProvider>
  )
}
