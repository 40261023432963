import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { WindowContainer } from '../../../components/WindowContainer';
import { CONTENT_TYPE} from '../../../constants/general';
import { ROUTE } from '../../../constants/routes_old';
import { readSeriesList } from '../../../modules/series/actions';
import SeriesCreate from '../Series/SeriesCreate/SeriesCreate';
import ListContainer from '../../../components/ListContainer/ListContainer';
import SeriesListItemDetails from './SeriesListItemDetails';

export default function SeriesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const series = useSelector(state => state.series.list);
  const [ready, setReady] = useState(false);

  const init = useCallback(async () => {
    await dispatch(readSeriesList());
    setReady(true);
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <ListContainer
        ready={ready}
        title="Series"
        items={series}
        actions={[
          { label: 'Add Series', link: ROUTE.series.create() }
        ]}
        details={SeriesListItemDetails}
        type={CONTENT_TYPE.SERIES}
      />
      <Route
        exact
        path={ROUTE.series.create()}
        component={() => (
          <WindowContainer closeAction={() => { history.goBack() }}>
            <SeriesCreate />
          </WindowContainer>
        )}
      />
    </>
  );
}
