import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDB } from 'shared/Providers/UserDB'
import { DOC_TYPE_FILE_FINISH_VIDEO, TDocFile } from 'shared/constants/docs'
import { getDownloadUrl } from 'shared/utils/cloud/cloud'
import { findDocs } from 'shared/utils/pouchDB/general'
import Thumbnails from '../Thumbnails'

const getPlayUrl = async (cloud: unknown): Promise<string> => {
  const urlResponse = await getDownloadUrl(cloud);
  if (urlResponse && urlResponse.url) {
    return urlResponse.url;
  }
  return '';
};

export default function VideoFiles(): JSX.Element {
  const { db } = useDB();
  const [files, setFiles] = useState<TDocFile[]>([]);
  const [playURL, setPlayURL] = useState('');
  const [thumbnailsOpen, setThumbnailsOpen] = useState(false);

  const find = useCallback(async () => {
    if (db) {
      const found = await findDocs(
        {
          startId: null,
          limit: null, // TODO: set something after implementing pagination
          sortBy: [{ created: 'desc' }],
          options: {
              selector: {
                  $and: [
                      { type: { $eq: DOC_TYPE_FILE_FINISH_VIDEO } }
                  ]
              }
          },
          fields: ['_id', 'title', 'created', 'image', 'cloud', 'youtube', 'sharing', 'thumbnail']
        },
        db
      );
      setFiles(found);
      console.log('tst: found', found);
    }
  }, [db]);

  useEffect(() => {
    find();
  }, [find]);

  return (
    <Box>
      {files.map((f) => {
        return (
          <Card key={f._id} sx={{ width: '100%', mt: 1 }}>
            <CardContent>
              <Typography variant="h4" display="inline-block">
                {f.title}
              </Typography>
              <Button onClick={async () => {
                setPlayURL(await getPlayUrl(f.cloud));
                setThumbnailsOpen(true);
              }} sx={{ float: 'right' }}>
                Thumbnails
              </Button>
              <Typography variant="body1" display="inline-block" sx={{ float: 'right', mr: 2 }}>
                {f._id}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
      {thumbnailsOpen
        && <Thumbnails videoSrc={playURL} handleClose={() => { setThumbnailsOpen(false); setPlayURL(''); }}/>
      }
    </Box>
  );
}
