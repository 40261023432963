import useApiSimple, { TApiError } from "./useApiSimple"


type TRGetUserMembersSuccess = { members: string[] }
type TCGetUserMembers = string[]

type TRGetUserMembers = TRGetUserMembersSuccess | TApiError

type TOptions = {
  lazy?: boolean;
  onError?: (error: TApiError) => void;
}

const validate = (response?: TRGetUserMembers): response is TRGetUserMembersSuccess =>
  !!(response && ('members' in response));

const convert = (response?: TRGetUserMembers): TCGetUserMembers | undefined => {
  if (validate(response)) {
    return response.members;
  }
  return undefined;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiGetUserMembers(options?: TOptions) {
  const { response, error, loading, doFetch } = useApiSimple<TRGetUserMembers, TCGetUserMembers>(
    { slug: '/api/get-user-members', lazy: options?.lazy ?? true },
    { validate, convert, onError: options?.onError }
  );

  return {
    members: response,
    error,
    loading,
    doGetUserMembers: doFetch,
  };
}
