import { wait } from '../common';
import processResponse from './ApiResponse';
import Request from './Request';
import { getFreshToken, isTokenRefresh, needToRefreshToken } from '../webSettings';

class Api {
    opts = {
        funcRefreshToken: async () => { throw new Error('Function for refresh token not set'); },
        funcRefreshTokenState: () => { throw new Error('Function for refresh token state not set'); },
    };

    setOptions = (opts) => {
        this.opts = { ...this.opts, ...opts };
    };


    sendDefaultRequest = async (method, url, data) => {
        const token = await getFreshToken();
        const request = new Request(method, url, data, token);
        const response = await request.execute();
        // console.info('response', { response });

        const apiResponse = await processResponse(response);
        // console.log('sendDefaultRequest', { apiResponse, isTokenRefresh: isTokenRefresh(url), method, url, data });
        if (needToRefreshToken(apiResponse)) {
            await this.opts.funcRefreshTokenState();
            this.opts.funcRefreshToken().then(); // will not need to wait
            await wait(500);
            if (!isTokenRefresh(url)) {
                return this.sendDefaultRequest(method, url, data);
            }
        } else if (apiResponse.ok) {
            return apiResponse.body;
        }

        throw apiResponse.body;
    };


    apiGet = async (url, data = undefined) => this.sendDefaultRequest('get', url, data);

    apiPost = async (url, data) => this.sendDefaultRequest('post', url, data);

    apiPut = async (url, data) => this.sendDefaultRequest('put', url, data);

    apiPatch = async (url, data) => this.sendDefaultRequest('patch', url, data);

    apiDelete = async (url, data) => this.sendDefaultRequest('delete', url, data);
}

export default () => new Api();
