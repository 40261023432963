import { Checkbox, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import React, { useCallback } from 'react'


type TPermissionsSelectProps<TPermission extends string> = {
  id: string,
  value: TPermission[],
  setValue: (permissions: TPermission[]) => void
  valueMap: Record<TPermission, string>
  placeHolder?: string
  label?: string
};

export const PermissionsSelect = <T extends string>({ id, value, setValue, valueMap, placeHolder, label = 'Permissions', ...props }: TPermissionsSelectProps<T> & SelectProps<T[]>): JSX.Element => {
  const renderValue = useCallback((selected: T[]) => selected.length
    ? selected.map(val => valueMap[val]).join(', ')
    : placeHolder, []);

  const handleChange = useCallback((event: SelectChangeEvent<T[]>) => {
    const list = Object.keys(valueMap) as T[];
    setValue((event.target.value as T[]).sort((a,b) => list.indexOf(a) - list.indexOf(b)));
  }, []);

  return (
    <>
      <InputLabel id={`${id}-label`} shrink={!!placeHolder}>{label}</InputLabel>
      <Select<T[]>
        labelId={`${id}-label`}
        id={id}
        value={value ?? []}
        multiple
        label={label}
        displayEmpty={!!placeHolder}
        renderValue={renderValue}
        onChange={handleChange}
        sx={placeHolder ? { '& .MuiOutlinedInput-notchedOutline > legend': { maxWidth: '100%' }} : {}}
        {...props}
      >
        {!!placeHolder && (<MenuItem disabled>
          <Checkbox checked={true} />
          <ListItemText primary={placeHolder} />
        </MenuItem>)}
        {Object.entries(valueMap).map(([val, title]) => (
          <MenuItem key={val} value={val}>
            <Checkbox checked={!!value?.includes(val as T)} />
            <ListItemText primary={title as string} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
