import { getFileUrl } from 'app/utils/rest';

window.URL = window.URL || window.webkitURL;

export const getVideoDuration = async (fileOrUrl: File | string): Promise<number | undefined> => new Promise((resolve, reject) => {
  try {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.ondurationchange = () => { // onloadedmetadata
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration)
    }
    video.onerror = function () {
      resolve(0)
    }
    video.src = typeof fileOrUrl === 'string' ? fileOrUrl : getFileUrl(fileOrUrl)
  } catch (e) {
    reject(e)
  }
})
