import React from 'react';
import Folder from './Blocks/Folder/Folder';
import HowItWorks from './Blocks/HowItWorks/HowItWorks';
import Hr from './Blocks/Hr/Hr';
import OrganizeBackup from './Blocks/OrganizeBackup/OrganizeBackup';
import OrganizePublish from './Blocks/OrganizePublish/OrganizePublish';
import HomeBlock from './Components/HomeBlock/HomeBlock';
import Header from './Header/Header';
import About from './Blocks/About/About';
import Download from './Blocks/Download/Download';
import TheFirst from './Blocks/TheFirst/TheFirst';
import styles from './Home.module.css';

const Home = () => (
	<div className={styles.root}>
		<div className={styles.headerContainer}>
			<div className={styles.header}>
				<Header />
			</div>
		</div>
		<div className={styles.first}>
			<HomeBlock>
				<TheFirst />
			</HomeBlock>
		</div>
		<HomeBlock>
			<HowItWorks />
		</HomeBlock>
		<HomeBlock>
			<Hr />
		</HomeBlock>
		<HomeBlock>
			<OrganizeBackup />
		</HomeBlock>
		<HomeBlock>
			<Hr />
		</HomeBlock>
		<HomeBlock>
			<Folder />
		</HomeBlock>
		<HomeBlock>
			<Hr />
		</HomeBlock>
		<HomeBlock>
			<About />
		</HomeBlock>
		<HomeBlock fullWidth className={styles.gray}>
			<OrganizePublish />
		</HomeBlock>
		<HomeBlock>
			<Download />
		</HomeBlock>
	</div>
);

export default Home;
