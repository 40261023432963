import React from 'react';
import { Card } from '@mui/material';

export default function MediaRecordApi() {
  return (
    <Card sx={{ p: 2 }}>
      MediaRecordApi
    </Card>
  )
}
