import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import CloudStatusIcon from 'shared/components/IconUploadedToCloud'
import DriveStatusIcon from 'app/components/IconFileOnLocalDrive'
import YoutubeStatusIcon from 'shared/components/StatusIcons/IconFileUploadToYoutube'
import ShareStatusIcon from 'shared/components/StatusIcons/ShareStatusIcon'
import { prettySize } from 'shared/utils/format'
import { DOC_TYPE_FILE_FINISH_AUDIO, DOC_TYPE_FILE_FINISH_VIDEO } from '../../../constants/docs'
import { isFinishFile } from '../../../constants/pouchDB'
import { ROUTE } from '../../../constants/routes_old'
import { SHARE_TYPES } from '../../../constants/share'
import ActionFileMenu from '../../ActionFileMenu'
import S3img from '../../S3img/S3img'
import styles from './ProjectListItemFile.module.css'

const FILE_ROUTES = {
	[DOC_TYPE_FILE_FINISH_VIDEO]: ROUTE.videos,
	[DOC_TYPE_FILE_FINISH_AUDIO]: ROUTE.audios,
}

// TODO: delete this
export default function ProjectListItemFile({
	sharingId,
	projectProps,
	file,
	removeFile,
	saveFile,
	callback,
}) {
	const isFinish = isFinishFile(file.type)
	console.log('tst: file', file)

	return (
		<div className={cn(styles.mediaItem, styles.videoItem)} key={file._id}>
			{isFinish && (
				<S3img src={file.thumbnail?.cloud?.s3 || file.image} className={styles.previewImage} />
			)}
			<div className={styles.nameGroup}>
				<span className={styles.mediaName}>
					{isFinish ? (
						<Link
							to={
								!sharingId
									? FILE_ROUTES[file.type].id(file._id)
									: ROUTE.share.file.id(sharingId, file._id)
							}>
							<h4>{file.title}</h4>
						</Link>
					) : (
						file.title
					)}
					{isFinish && (
						<p>
							Created: {new Date(file.created).toLocaleDateString()}, Size: {prettySize(file.size)}
						</p>
					)}
					{!!file.description && <p className={styles.description}>{file.description}</p>}
				</span>
				{!isFinish && (
					<>
						<span className={styles.date}>{new Date(file.created).toLocaleDateString()}</span>
						<span className={styles.size}>{prettySize(file.size)}</span>
					</>
				)}
			</div>
			<span className={styles.mediaId}>{file._id}</span>
			<span className={styles.mediaStatus}>
				<CloudStatusIcon cloud={file.cloud} />
				<DriveStatusIcon projectId={file.projectId} fileId={file._id} />
				{file.type === DOC_TYPE_FILE_FINISH_VIDEO && (
					<YoutubeStatusIcon youtubeId={file?.youtube?.youtubeId} />
				)}
				{isFinish && <ShareStatusIcon sharing={file.sharing} type={SHARE_TYPES.file} />}
			</span>
			<ActionFileMenu
				file={file}
				project={projectProps}
				removeFileAction={removeFile}
				removeFileCallback={callback}
				saveFileAction={saveFile}
				editFileCallback={callback}
			/>
		</div>
	)
}

ProjectListItemFile.propTypes = {
	sharingId: PropTypes.string,
	file: PropTypes.object,
	removeFile: PropTypes.func,
	callback: PropTypes.func,
}
