import { useDB } from 'shared/Providers/UserDB';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import { DOC_TYPES_FILES, TDocFile } from 'shared/constants/docs';

export function useWatchChangesProjectFiles(props: {
	projectId: string;
	readFiles: () => Promise<void>;
}) {
	const { projectId, readFiles } = props;
	const { db } = useDB();

	useEffect(() => {
		if (db) {
			const readFilesDebounced = debounce(readFiles, 1000);

			const filesChanges = db
				.changes<TDocFile>({
					live: true,
					since: 'now',
					// include_docs: true,
					selector: {
						projectId,
						type: { $in: DOC_TYPES_FILES },
					},
				})
				.on('change', (change) => {
					console.log('tst: change', projectId, change);
					readFilesDebounced();
				});

			return () => {
				filesChanges.cancel();
			};
		}
		return undefined;
	}, [db, readFiles, projectId]);
}
