import React from 'react';

const k = 6.244 / 6.2442;

export const IconStoreOnLocalDrive: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 20, fill = '#00a14b', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg  {...rest} width={aspectWidth} height={height} version="1.1" viewBox="0 0 6.244 6.2442" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.1889 0 0 -.1889 3.1221 3.5681)" fill={fill}>
        <path d="m0 0c-0.652 0-1.181 0.529-1.181 1.181v16.527c0 0.652 0.529 1.181 1.181 1.181 0.651 0 1.181-0.529 1.181-1.181v-16.527c0-0.652-0.53-1.181-1.181-1.181" />
      </g>
      <g transform="matrix(.1889 0 0 -.1889 3.1221 3.5681)" fill={fill}>
        <path d="m0 0c-0.26 0-0.522 0.085-0.737 0.257l-5.903 4.722c-0.507 0.409-0.589 1.15-0.184 1.66 0.409 0.503 1.152 0.59 1.66 0.185l5.164-4.132 5.166 4.132c0.515 0.405 1.254 0.321 1.659-0.185 0.406-0.51 0.326-1.251-0.183-1.66l-5.904-4.722c-0.217-0.172-0.478-0.257-0.738-0.257" />
      </g>
      <g fill={fill}>
      <path d="m2.6761 4.9062h0.89197v0.44599h-0.89197z" />
      <path d="m4.014 4.9062h1.3381v0.44599h-1.3381z" />
      <path d="m0.44598 5.7982h5.352v-1.338h-5.352zm5.5751 0.44599h-5.7982c-0.12297 0-0.2229-0.0999-0.2229-0.22309v-1.7839c0-0.12316 0.09993-0.22309 0.2229-0.22309h5.7982c0.12297 0 0.2229 0.0999 0.2229 0.22309v1.7839c0 0.12316-0.0999 0.22309-0.2229 0.22309" />
      </g>
    </svg>
  );
};
