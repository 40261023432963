import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import styles from './SeriesSearch.module.css';
import Button from '../../../../../../components/Button/Button';
import { insertVideoToSeries } from '../../../../../../modules/episodes/actions';
import { findSeriesByTitle } from '../../../../../../modules/series/actions';
import Input from '../../../../../../components/Input/Input';

export default function SeriesSearch({ fileId, excludeIds, addSeriesCallback }) {
  const dispatch = useDispatch();
  const updateList = useCallback((title, excludeIds) => dispatch(findSeriesByTitle(title, excludeIds)), [dispatch]);
  const addToSeries = useCallback(async (seriesId, fileId) => dispatch(insertVideoToSeries(seriesId, fileId)), [dispatch]);

  const [busy, setBusy] = useState(false);
  const [seriesId, setSeriesId] = useState('');
  const [list, setList] = useState([]);
  const [title, setTitle] = useState('');

  const fetchSeries = useCallback(debounce(async (value) => {
    if (value) {
      const list = await updateList(value, excludeIds);
      setBusy(false);
      setList(list);
    }
  }, 500), [excludeIds, updateList]);

  const handleTitleChange = useCallback(async (value) => {
    if ((typeof value === 'object') && (value !== null)) {
      setBusy(false);
      setList([]);
      setSeriesId(value._id);
      setTitle(value.title);
    } else if (typeof value === 'string') {
      setBusy(true);
      setTitle(value);
      fetchSeries(value);
    } else {
      setList([]);
    }
  }, [fetchSeries]);

  useEffect(() => {
    handleTitleChange('');
  }, [handleTitleChange]);

  const handleAddToSeries = useCallback(async (values, { setSubmitting }) => {
    const { seriesId } = values;

    await addToSeries(seriesId, fileId);

    setBusy(false);
    setList([]);
    setSeriesId('');
    setTitle('');

    setSubmitting(false);

    if (addSeriesCallback) {
      addSeriesCallback();
    }

    handleTitleChange('');
  }, [addToSeries, fileId, addSeriesCallback, handleTitleChange]);

  return (
    <Formik
      initialValues={{
        seriesId,
        title
      }}
      enableReinitialize
      validationSchema={object()
        .shape({
          seriesId: string()
            .default('')
            .required('Required')
        })}
      onSubmit={handleAddToSeries}
      render={({ isSubmitting }) => (
        <Form>
          <div className={styles.searchContainer}>
            <Field
              name="seriesId"
              component={Input}
              type={Input.TYPE.COMBOBOX}
              label="Add to Series:"
              placeHolder="Type to find series"
              busy={busy}
              onChange={handleTitleChange}
              data={list}
              valueField="_id"
              value={title}
              textField="title"
              required
              rootClassName={styles.searchInput}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              color={Button.COLOR.PRIMARY}
            >
              Add to series
            </Button>
          </div>
        </Form>
      )}
    />
  );
}

SeriesSearch.propTypes = {
  fileId: PropTypes.string.isRequired,
  excludeIds: PropTypes.array,
  addSeriesCallback: PropTypes.func,
};
