import React from 'react';
import imageArrow from './image_arrow.svg';
import styles from './ListPlaceholderSeries.module.css';

export default function ListPlaceholderSeries() {
  const flag = false;

  if (flag) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.textLine}>
          Now that you have a finished video or audio you
          can add them to a series.
        </h2>
        <h2 className={styles.textLine}>
          To create a series click `Add Series`
        </h2>
      </div>
      <img className={styles.imgArrow} src={imageArrow} alt="arrow" />
    </div>
  );
}
