import { DocTypeFiles } from 'shared/constants/docs';

type Props = {
	type?: DocTypeFiles;
	handleClose: () => void;
};

export default function CreateFolderMenuItem(_props: Props) {
	return null;
}
