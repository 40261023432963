import { LIST_OPTIONS } from '../../constants/pouchDB';
import { addFilter } from '../../utils/pouchDB/documents';
import { GENERAL_ACTION_TYPES } from '../general';

export const ACTION_TYPES = {
    read: {
        success: 'EPISODES_READ_SUCCESS'
    },
    setSeason: 'EPISODES_SET_SEASON'
};

const initialState = {
    ...LIST_OPTIONS.episodes,
    list: [],
    seriesId: '',
    seasonId: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_TYPES.read.success: {
        return {
            ...state,
            list: action.list
        };
    }

    case ACTION_TYPES.setSeason: {
        return {
            ...addFilter(
                addFilter(state, 'seriesId', action.seriesId, '$eq'),
                'seasonId',
                action.seasonId || null,
                action.seasonId ? '$eq' : '$gt'
            ),
            list: (action.seriesId === state.seriesId && action.seasonId === state.seasonId)
                ? state.list
                : [],
            seriesId: action.seriesId,
            seasonId: action.seasonId
        };
    }

    case GENERAL_ACTION_TYPES.authClear: {
        return { ...initialState };
    }

    default: {
        return state;
    }
    }
};

export default reducer;
