import React from 'react';

const k = 27.287 / 23.6;

export const IconStoreInCloud: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 20, fill = '#00a14b', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} viewBox="0 0 27.287 23.6" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill}>
        <path d="m21.049 19.636-4.6329-0.04378 0.01946-2.0598 4.6336 0.04445c2.2709 0.02155 4.1362-1.8083 4.1584-4.0792 0.02013-2.1403-1.5693-3.9187-3.6971-4.1341l-0.851-0.08682-0.070851-0.85238c-0.29942-3.5936-3.247-6.3293-6.8566-6.364-3.6089-0.034734-6.6086 2.6447-6.9774 6.2314l-0.086835 0.85099-0.85169 0.069469c-2.1327 0.17575-3.7555 1.9229-3.7763 4.0632-0.021535 2.2709 1.809 4.1369 4.0792 4.1584l4.1188 0.0396-0.02015 2.0591-4.1181-0.0396c-3.4067-0.032651-6.1515-2.8302-6.1188-6.2376 0.027787-2.9288 2.0577-5.3679 4.8434-5.9729 0.81487-4.2445 4.521-7.3234 8.9274-7.2817 4.4078 0.04307 8.0535 3.1921 8.7878 7.4519 2.7725 0.65787 4.7558 3.1351 4.7274 6.0646-0.03264 3.4074-2.8309 6.1515-6.2376 6.1188" />
        <path d="m16.25 14.654-2.8482-2.9032-2.9032 2.8482-1.4415-1.47 4.3724-4.291 4.2905 4.3743z"/>
        <path d="m12.357 11.759h2.0598v11.841l-2.0591-0.01949z" />
      </g>
    </svg>
  );
};
