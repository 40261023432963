import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { DOC_TYPE_PROJECT } from '../../constants/docs';
import { SHARE_LINK_SCHEMA } from '../../constants/general';
import { ROUTE } from '../../constants/routes_old';
import { frontEndLocation } from '../../constants/system';
import { apiShareDelSettings, apiShareGetSettings, apiShareSaveSettings } from '../../modules/share/api';
import { updateSharesDoc } from '../../utils/share';
import Button from '../Button/Button';
import CopyValueButton from '../CopyValueButton/CopyValueButton';
import Spinner from '../Spinner/Spinner';
import styles from './ShareSettings.module.css';
import Input from '../Input/Input';

/**
 *
 * @param {Object} param0
 * @param {string} param0.documentId
 * @param {'file'|'project'} param0.type
 * @param {() => unknown} [param0.onChange]
 * @returns {JSX.Element}
 */
export default function ShareSettings({ documentId, type, onChange = undefined }) {
  const dispatch = useDispatch();
  // const [linkPrev, setLinkPrev] = useState(),
  const [link, setLink] = useState(SHARE_LINK_SCHEMA.default());
  const [loaded, setLoaded] = useState(false);

  const getSettings = useCallback(async () => {
    try {
      const response = await apiShareGetSettings(documentId);
      setLink(stateLink => ({
        ...response,
        pass: stateLink.pass ? stateLink.pass : stateLink?.pass, // TODO:
      }));
      setLoaded(true);
    } catch (e) {
      setLoaded(true);
      // setLinkPrev(undefined);
      setLink(SHARE_LINK_SCHEMA.default());
    }
  }, [documentId]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const updateUserDocs = useCallback(async () => {
    await dispatch(updateSharesDoc(documentId, type, link?.id));
  }, [dispatch, documentId, type, link?.id]);

  const handleDisableShare = useCallback(async (setSubmitting) => {
    setSubmitting(true);

    try {
      await apiShareDelSettings(link?.id);
      await getSettings();
      await updateUserDocs();

      if (onChange) {
        await onChange();
      }
    } finally {
      setSubmitting(false);
    }
  }, [link?.id, getSettings, updateUserDocs, onChange]);

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await apiShareSaveSettings({ ...values, documentId, type });
      setLink(stateLink => ({ ...stateLink, pass: values.pass }));

      await getSettings();
      await updateUserDocs();

      if (onChange) {
        await onChange();
      }
    } catch (e) {
      // nothing
    } finally {
      setSubmitting(false);
    }
  }, [documentId, type, getSettings, updateUserDocs, onChange]);

  const shareLink = `${frontEndLocation}${ROUTE.share.id(link.id)}`;

  return (
    <div>
      <h3 className={styles.header}>
        Share settings
      </h3>
      <Formik
        initialValues={link}
        validationSchema={SHARE_LINK_SCHEMA}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({ isSubmitting, setSubmitting }) => (
          <Form>
            {link.id && (
              <div>
                <div className={styles.flexRow}>
                  <div className={styles.flex1}>Link</div>
                  <CopyValueButton
                    value={shareLink}
                    className={styles.copyButton}
                  />
                </div>
                <Input
                  spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  field={{
                    value: shareLink,
                    onChange: () => {
                    }
                  }}
                />
              </div>
            )}
            {type === DOC_TYPE_PROJECT && (
              <Field
                name="canEdit"
                label="Edit project:"
                component={Input}
                type={Input.TYPE.SWITCH}
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                // disabled={!link.enabled}
              />
            )}
            {type === DOC_TYPE_PROJECT && (
              <Field
                name="canAddFiles"
                label="Add files:"
                component={Input}
                type={Input.TYPE.SWITCH}
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                // disabled={!link.enabled}
              />
            )}
            {type === DOC_TYPE_PROJECT && (
              <Field
                name="canRemoveFiles"
                label="Remove files:"
                component={Input}
                type={Input.TYPE.SWITCH}
                labelWidth={Input.LABEL_WIDTH.RIGHT}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.SMALL}
                // disabled={!link.enabled}
              />
            )}
            <Field
              name="accessType"
              label="Who has access:"
              component={Input}
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              // disabled={!link.enabled}
              readOnly
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
            />
            <Field
              name="pass"
              label={`${link.enabled ? 'Change' : 'Set'} password:`}
              component={Input}
              autoComplete="off"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
            />
            <div className={styles.saveWrapper}>
              <Button
                small
                type="submit"
                className={styles.toggleEdit}
                disabled={isSubmitting}
              >
                {link.enabled ? 'Save' : 'Enable'}
              </Button>
              {link.enabled && (
                <Button
                  small
                  color={Button.COLOR.WARNING}
                  onClick={() => handleDisableShare(setSubmitting)}
                  className={styles.toggleEdit}
                  disabled={isSubmitting}
                >
                  Disable
                </Button>
              )}
            </div>
          </Form>
        )}
      />
      {!loaded && <Spinner darkBackground />}
    </div>
  );
}
