import React, {
	createContext, PropsWithChildren, useCallback, useContext, useEffect,
} from 'react';
import { UserContext } from 'shared/Providers/User';
import { TUseUserInformationResult, useUserInformation } from 'shared/Providers/UserData/use-user-information.hook';
import { useDocument } from 'shared/helpers/use-document';
import { isEqual, merge } from 'lodash';
import { getEmptyUser, TDocUser } from 'shared/model/user';
import { actionSetDbUser } from 'shared/modules/user/userActions';
import { useDispatch } from 'react-redux';
import WaitingScreen from '../WaitingScreen';

type TUserDataContext = {
  user: TDocUser
  information: TUseUserInformationResult
  updateUser: (values: Partial<TDocUser>) => Promise<void>
};

export const UserDataContext = createContext<TUserDataContext>({
	user: getEmptyUser(),
	information: {
		wrongDuration: {
			count: 0,
			update: () => undefined,
			updating: false,
			calculate: () => undefined,
			calculating: undefined,
			message: '',
			busy: false,
		},
	},
	updateUser: () => Promise.resolve(undefined),
});

export const UserDataProvider = ({ children }: PropsWithChildren): JSX.Element => {
	const dispatch = useDispatch();
	const { user: userWeb } = useContext(UserContext);
	const { read, doc: userDB, writeDoc } = useDocument<TDocUser>(userWeb?.id);
	const information = useUserInformation();

	const updateUser = useCallback(async (values: Partial<TDocUser>) => {
		// Extend user with default model and save if it has a difference
		const newUser = merge(
			{},
			getEmptyUser(userWeb!.id),
			userDB,
			values,
			{ title: values?.name || userWeb?.name }, // Copy name to title
		);

		// If you only need to remove undefined and are working with simple objects: JSON.parse(JSON.stringify(object))
		const {
			updated: _, created: ____, _rev: _____, ...a
		} = JSON.parse(JSON.stringify(userDB ?? {}));
		const {
			updated: __, created: ___, _rev: ______, ...b
		} = JSON.parse(JSON.stringify(newUser));
		if (!isEqual(a, b)) {
			const saved = await writeDoc(newUser);
			dispatch(actionSetDbUser(saved));
		} else {
			dispatch(actionSetDbUser(newUser));
		}
	}, [userDB, writeDoc, userWeb?.id, userWeb?.name, dispatch]);

	const init = useCallback(async () => {
		if (read) {
			await updateUser({
				databaseId: userWeb?.databaseId,
				email: userWeb?.email,
				name: userWeb?.name,
				title: userWeb?.name, // Copy name to title
			});
		}
	}, [read, updateUser, userWeb?.databaseId, userWeb?.email, userWeb?.name]);

	useEffect(() => {
		init();
	}, [init]);

	if (!userDB?._rev) {
		return <WaitingScreen description="Reading user settings ..." />;
	}

	return (
  <UserDataContext.Provider value={{ user: userDB, information, updateUser }}>
  {children}
		</UserDataContext.Provider>
	);
};

export const useUserData = (): TUserDataContext => useContext(UserDataContext);
