import { Box, styled } from '@mui/material';

export const ProjectFileGroupActionsContainer = styled(Box, {
	name: 'ProjectFileGroupActionsContainer',
})(({ theme }) => ({
	gridArea: 'file_type_actions',
}));

export const ProjectFileGroupContainer = styled(Box, {
	name: 'ProjectFileGroupContainer',
})(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: theme.spacing(2),
	minHeight: theme.spacing(10),
	[`& [data-box-id="file_type_actions"]`]: {
		position: 'absolute',
		right: 0,
	},
	[theme.breakpoints.up('md')]: {
		position: 'relative',
		justifyContent: 'center',
		marginBlock: theme.spacing(3),
	},
}));

