import React from 'react';


const k = 300 / 300;


export const IconFilter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 24, fill = '#000000', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} fill={fill} viewBox="0 0 32 32"
         xmlns="http://www.w3.org/2000/svg"
         version="1.1" x="0px" y="0px"
    >
      <g>
        <path
          d="M4,7h5c0,0,0.1,0,0.1,0c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3c0,0,0.1,0,0.1,0h11c0.6,0,1-0.4,1-1s-0.4-1-1-1H17   c0,0-0.1,0-0.1,0c-0.4-1.7-2-3-3.9-3S9.6,3.3,9.1,5C9.1,5,9,5,9,5H4C3.4,5,3,5.4,3,6S3.4,7,4,7z M13,4c1.1,0,2,0.9,2,2s-0.9,2-2,2   s-2-0.9-2-2S11.9,4,13,4z" />
        <path
          d="M4,17h12c0,0,0.1,0,0.1,0c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3c0,0,0.1,0,0.1,0h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4   c0,0-0.1,0-0.1,0c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3c0,0-0.1,0-0.1,0H4c-0.6,0-1,0.4-1,1S3.4,17,4,17z M20,14c1.1,0,2,0.9,2,2   s-0.9,2-2,2s-2-0.9-2-2S18.9,14,20,14z" />
        <path
          d="M4,27h5c0,0,0.1,0,0.1,0c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3c0,0,0.1,0,0.1,0h11c0.6,0,1-0.4,1-1s-0.4-1-1-1H17   c0,0-0.1,0-0.1,0c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3c0,0-0.1,0-0.1,0H4c-0.6,0-1,0.4-1,1S3.4,27,4,27z M13,24c1.1,0,2,0.9,2,2   s-0.9,2-2,2s-2-0.9-2-2S11.9,24,13,24z" />
      </g>
    </svg>
  );
};
