import { Box, Typography } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import CopyToClipboardButton from 'shared/_components/copy-to-clipboard-button';

type ButtonProps = ComponentProps<typeof CopyToClipboardButton>;
type CopyToClipboardValueProps = Omit<ButtonProps, 'value'> & {
	value: ReactNode;
	valueText?: ButtonProps['value'];
	sxButton?: ButtonProps['sx'];
};

export default function CopyToClipboardValue({
	value,
	valueText,
	onCopy,
	sx,
	sxButton,
	...props
}: CopyToClipboardValueProps) {
	const copyValue = typeof value === 'string' ? value : valueText;

	return (
		<Box display="flex" alignItems="center" sx={sx} minWidth={0}>
			<Typography sx={{ lineHeight: 1 }} noWrap>{value || valueText}</Typography>
			<CopyToClipboardButton
				value={copyValue || ''}
				onCopy={onCopy}
				disabled={!copyValue}
				sx={sxButton}
				{...props}
			/>
		</Box>
	);
}
