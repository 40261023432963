import { Box, styled } from '@mui/material';

export const ProjectFilesGroupContainer = styled(Box, {
	name: 'ProjectFilesListContainer',
	shouldForwardProp: (propName: string) => !['isFinish', 'addFiles'].includes(propName),
})<{ isFinish: boolean; addFiles: boolean }>(({ theme, addFiles, isFinish }) => ({
	display: 'grid',
	gridTemplateColumns: isFinish ? '1fr' : '1fr max-content',
	gridTemplateAreas: isFinish
		? '"files"'
		: `
			"${!isFinish ? 'title' : ''} ${addFiles ? 'file_type_actions' : ''}"
			"${!isFinish ? 'files' : ''} ${addFiles ? 'files' : ''}"
		`,
	[`& [data-box-id="file_type_actions"]`]: {
		marginTop: theme.spacing(3),
	},
	[theme.breakpoints.up('lg')]: {
		[`& [data-box-id="file_type_actions"]`]: {
			marginTop: theme.spacing(5),
		},
		gridTemplateAreas: isFinish
			? '"files"'
			: `
			"${!isFinish ? 'title' : ''} files ${addFiles ? 'file_type_actions' : ''}"
			"${!isFinish ? 'title' : ''} files ${addFiles ? 'file_type_actions' : ''}"
			"${!isFinish ? 'title' : ''} files ${addFiles ? 'file_type_actions' : ''}"
			`,
		gridTemplateColumns: !isFinish && `calc(128px + ${theme.spacing(2)}) 1fr max-content`,
	},
}));

export const ProjectFilesListContainer = styled(Box, {
	name: 'ProjectFilesListContainer',
	shouldForwardProp: (propName: string) => !['hasBorder'].includes(propName),
})<{ hasBorder?: boolean }>(({ theme, hasBorder }) => ({
	gridArea: 'files',
	paddingBottom: theme.spacing(2),
	borderBottom: hasBorder ? '1px solid' : undefined,
	borderColor: hasBorder ? theme.palette.grey[200] : undefined,
	[`& [data-box-id="file_type_actions"]`]: {
		marginTop: theme.spacing(3),
	},
	[theme.breakpoints.up('lg')]: {
		[`& [data-box-id="file_type_actions"]`]: {
			marginTop: theme.spacing(5),
		},
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
		minHeight: theme.spacing(20),
	},
}));

export const ProjectFilesFileTypeTitleContainer = styled(Box, {
	name: 'ProjectFilesFileTypeTitleContainer',
	shouldForwardProp: (propName: string) => !['hasBorder'].includes(propName),
})<{ hasBorder?: boolean }>(({ theme, hasBorder }) => ({
	gridArea: 'title',
	display: 'flex',
	gap: 2,
	justifyContent: 'left',
	paddingBlock: theme.spacing(4),
	[theme.breakpoints.up('lg')]: {
		justifyContent: 'space-between',
		paddingRight: theme.spacing(2),
		borderBottom: hasBorder ? '1px solid' : undefined,
		borderColor: hasBorder ? theme.palette.grey[200] : undefined,
	},
}));
