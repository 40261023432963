import React, { useEffect, useState } from 'react';
import { api, emitUserError } from 'shared/constants/globals';
import Input from 'shared/components/Input/Input';
import styles from 'shared/components/SeriesRssSettings/SeriesRssContent/series-rss-content.module.scss';


type TSeriesRssContentProps = {
  uri: string
}


export const SeriesRssContent: React.FC<TSeriesRssContentProps> = ({ uri }) => {
  const [content, setContent] = useState('fetching ...');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        // @ts-ignore
        const fetchUri = uri;
        // const fetchUri = isWeb ? uri.replace(configs.backendApiUri, '/api') : uri; // TODO: How to use proxy in electron
        setContent(await api.apiGet(fetchUri))
      } catch (e) {
        emitUserError((e as Error).message, 'Fetch RSS content error');
      }
    }
    if (uri) {
      void fetchContent();
    }
  }, [uri]);

  return (
    <Input
      spaceBottom={Input.SPACE_BOTTOM.SMALL}
      type={Input.TYPE.TEXTAREA}
      field={{ value: content, onChange: () => undefined }}
      className={styles.content}
      noValidate
    />
  );
}
