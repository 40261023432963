import ApiError from './api-error'
import useApiSimple, { TApiError } from "./useApiSimple"


type TRGetUserIdSuccess = { id: string }

type TRGetUserId = TRGetUserIdSuccess | TApiError


const validate = (response?: TRGetUserId): response is TRGetUserIdSuccess => !!(response && ('id' in response) && !!response?.id);

const convert = (response?: TRGetUserId) => validate(response) ? response.id : undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiGetUserId(onError?: (error: ApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRGetUserId, string|undefined>(
    { slug: '/api/get-user-id', lazy: true },
    { validate, convert, onError },
  );

  return {
    id: response,
    error,
    loading,
    doGetUserId: doFetch,
  };
}
