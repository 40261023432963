import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useHistory, useParams } from 'react-router';
import LoginContainer from '../../../components/LoginContainer/LoginContainer';
import { ROUTE } from '../../../constants/routes_old';
import { onLogin } from '../../../modules/share/actions';
import Input from '../../../components/Input/Input';
import FormButtons from '../../../components/FormButtons/FormButtons';
import Button from '../../../components/Button/Button';

export default function SharedLogin() {
	const history = useHistory();
	const { sharingId } = useParams();
	const dispatch = useDispatch();

	const handleSubmit = useCallback(
		async ({ sharingId, password }, { setSubmitting }) => {
			try {
				if (await dispatch(onLogin(sharingId, password))) {
					history.push(ROUTE.share.project(sharingId));
				}
			} finally {
				setSubmitting(false);
			}
		},
		[dispatch, history]
	);

	return (
		<LoginContainer>
			<Formik
				initialValues={{ password: '', sharingId }}
				validationSchema={object().shape({
					sharingId: string().required().min(1),
					password: string().required().min(1),
				})}
				enableReinitialize
				onSubmit={handleSubmit}
				render={({ isSubmitting }) => (
					<Form>
						<Field
							name="sharingId"
							component={Input}
							labelWidth={Input.LABEL_WIDTH.FULL}
							label="Sharing Id"
							readOnly
							required
						/>
						<Field
							name="password"
							type="password"
							component={Input}
							label="Password"
							required
							labelWidth={Input.LABEL_WIDTH.FULL}
						/>
						<FormButtons>
							<Button type="submit" disabled={isSubmitting} color={Button.COLOR.PRIMARY}>
								Sign in
							</Button>
						</FormButtons>
					</Form>
				)}
			/>
		</LoginContainer>
	);
}
