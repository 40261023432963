import { ENDPOINTS } from '../../constants/endpoints';
import { api, emitUserError } from '../../constants/globals';
import { ROUTE } from '../../constants/routes_old';
import { frontEndLocation } from '../../constants/system';
import { getUserId } from '../../utils/webSettings';
import { GENERAL_ACTION_TYPES } from '../general';

export const ACTIONS = {
	clear: () => ({ type: GENERAL_ACTION_TYPES.authClear }),
	error: (type, message, unknownMessage) => ({
		type,
		message: message
			? typeof (message) === 'string'
				? message
				: message.message
					? message.message
					: JSON.stringify(message)
			: unknownMessage || 'Login unknown error',
	}),
	auth: {
		error: (message) => ACTIONS.error(GENERAL_ACTION_TYPES.authClear, message),
	},
};

/**
 * @param {{user_id?: string, name: string, email: string, roles?: string[]}}
 * @param {string[]} roles
 */
export const updateUserProperties = async ({
	user_id, name, email, roles,
}) => {
	await api.apiPost(ENDPOINTS.user.updateUserProperties, {
		user_id,
		name,
		email,
		roles,
	});
};

export const webRegister = async ({ name, password, email }) => {
	try {
		// const response =
		await api.apiPost(ENDPOINTS.user.register, {
			name,
			password,
			email,
			confirmation_url: `${frontEndLocation}${ROUTE.registerConfirmed}`,
		});
		// console.log('Register response:', response);
		// if (response) {
		//     if (response.message === 'User was created') {
		//         result = '';
		//     } else if (response.message) {
		//         result = response.message;
		//         emitUserEvent(response.message, 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
		//         throw new Error(response.message);
		//     } else {
		//         emitUserEvent(response, 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
		//         throw new Error(response);
		//     }
		// } else {
		//     emitUserEvent('', 'Unable to register', USER_EVENT_COLORS.error, faTimesCircle);
		//     result = 'Response message is empty';
		// }
	} catch (e) {
		emitUserError(e.message, 'Unable to register');
		throw e;
	}
};

export const logout = () => (dispatch) => {
	dispatch(ACTIONS.clear());
	// TODO: Maybe we need to clean all Redux state
};

export const updateUserPassword = async ({ newPassword, password }) => {
	await api.apiPost(ENDPOINTS.user.changeUserPassword, {
		new_password: newPassword,
		password,
	});
};

export const apiDeactivateUser = (userId) => async (dispatch) => {
	await api.apiPost(ENDPOINTS.user.deactivateUser, { user_id: userId });
	if (userId === getUserId()) {
		dispatch(logout());
	}
};

export const apiActivateUser = async (userId) => {
	await api.apiPost(ENDPOINTS.user.activateUser, { user_id: userId });
	// alert('User deactivated');
	// dispatch(logout());
};

export const apiResetPassword = async (email) => api.apiPost(ENDPOINTS.user.resetPassword, {
	email,
	reset_password_url: `${frontEndLocation}${ROUTE.applyResetPassword}`,
});

export const apiResetPasswordApply = async (token, password) => api.apiPost(ENDPOINTS.user.resetPasswordApply, {
	token,
	password,
});
