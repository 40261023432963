import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import ListContainer from '../../../../components/ListContainer/ListContainer';
import SeasonActionMenu from '../../../../components/Seasons/SeasonActionMenu/SeasonActionMenu';
import { WindowContainer } from '../../../../components/WindowContainer';
import { CONTENT_TYPE} from '../../../../constants/general';
import { ROUTE } from '../../../../constants/routes_old';
import { readSeasonsList } from '../../../../modules/seasons/actions';
import EpisodeList from '../EpisodeList/EpisodeList';
import SeasonCreate from '../SeasonCreate/SeasonCreate';
import SeasonListItemDetails from './SeasonListItemDetails';

const ItemIcons = ({ item, ...rest }) => <SeasonActionMenu item={item} {...rest} />;
ItemIcons.propTypes = {
  item: PropTypes.shape({}),
  removeCallback: PropTypes.func,
};


export default function SeasonList({ seriesId }) {
  const dispatch = useDispatch();
  const seasons = useSelector(({ seasons }) => seasons);

  const [ready, setReady] = useState(false);
  const [showCreateNewSeason, setShowCreateNewSeason] = useState(false);

  const update = useCallback(async () => {
    await dispatch(readSeasonsList(seriesId));
    setReady(true);
  }, [dispatch, seriesId]);

  useEffect(() => {
    update();
  }, [update]);

  const toggleCreateNewSeason = useCallback(async () => {
    setShowCreateNewSeason(s => !s);
  }, []);

return (
    <>
      <ListContainer
        ready={ready}
        items={seasons.list}
        type={CONTENT_TYPE.SEASON}
        actions={[
          { label: 'Add Season', onClick: toggleCreateNewSeason },
          // { label: 'Add Episode', link: ROUTE.series.episodes.create(seriesId) }
        ]}
        details={SeasonListItemDetails}
        title="Seasons"
        titleLevel={2}
        titleClassName="left"
        seriesId={seriesId}
        icons={props => <ItemIcons removeCallback={update} {...props} />}
        footer={item => (
          <Route
            path={ROUTE.series.seasons.episodes.root(seriesId, item._id)}
            component={() => <EpisodeList seriesId={seriesId} seasonId={item._id} />}
          />
        )}
      />
      {showCreateNewSeason && (
        <WindowContainer closeAction={toggleCreateNewSeason}>
          <SeasonCreate
            seriesId={seriesId}
            onChange={toggleCreateNewSeason}
          />
        </WindowContainer>
      )}
    </>
  );
}

SeasonList.propTypes = {
  seriesId: PropTypes.string.isRequired,
};
