import React, { useCallback, useContext } from 'react'
import { Button, Menu, MenuItem, Tooltip } from '@mui/material'
import useMenu from 'shared/hooks/use-menu'
import { Link, useHistory } from 'react-router-dom'
import { ArrowDropDown } from '@mui/icons-material'
import { UserContext } from 'shared/Providers/User'
import { r } from 'shared/constants/routes'


type LogoutMenuItemProps = {
  handleClose: () => void
}
function LogoutMenuItem({ handleClose }: LogoutMenuItemProps): JSX.Element {
  const { logout } = useContext(UserContext);
  const history = useHistory();

  const onClick = useCallback(async () => {
    handleClose();
    await logout();
    history.push(r.login.path)
  }, [logout]);

  return <MenuItem onClick={onClick}>Logout</MenuItem>;
}

export default function AccountMenu(): JSX.Element {
  const { user } = useContext(UserContext);
  const { anchorEl, open, handleOpen, handleClose } = useMenu();

  return (
    <>
      <Tooltip title="Account settings">
        <Button
          onClick={handleOpen}
          size="small"
          variant="text"
          endIcon={<ArrowDropDown />}
          sx={{ textTransform: 'unset' }}
        >{user?.name || user?.email}</Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem component={Link} to={r.account.path} onClick={handleClose}>Account</MenuItem>
        <LogoutMenuItem handleClose={handleClose} />
      </Menu>
    </>
  );
}
