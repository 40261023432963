import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { ButtonNew } from 'shared/components/ButtonNew';
import styles from 'shared/components/WindowContainer/window-container.module.scss';


type TWindowContainerProps = PropsWithChildren<{
  width?: number | string
  height?: number | string
  hideCloseButton?: boolean
  closeAction?: () => void
  noBackground?: boolean
  windowClassName?: string
  backgroundClassName?: string
}>


export const WindowContainer: React.FC<TWindowContainerProps> = (props) => {
  const {
    width,
    height,
    children,
    hideCloseButton = false,
    closeAction,
    windowClassName,
    backgroundClassName,
    noBackground = false
  } = props;


  return (
    <>
      <div
        className={cn(styles.background, {
          [styles.noBackground]: noBackground
        }, backgroundClassName)}
      >
        <div
          className={cn(styles.window, windowClassName)}
          style={{ width, height }}
        >
          {!hideCloseButton && (
            <ButtonNew
              size="tiny"
              color="none"
              icon={faTimes}
              className={styles.closeButton}
              onClick={closeAction}
            />
          )}
          {children}
        </div>
      </div>
    </>
  );
}
