import { DOC_TYPE_EPISODE, newDocument } from '../../constants/docs';
import { EPISODE_SCHEMA, SEASON_SCHEMA } from '../../constants/general';
import { LIST_OPTIONS } from '../../constants/pouchDB';
import { getUserDB } from '../../new/dbUtils';
import { addFilter } from '../../utils/pouchDB/documents';
import { writeUserDoc, readUserDoc, readUserDocs, findUserDocs, removeUserDocs } from '../../handlers/userDB';
import { insert as insertSeason } from '../seasons/actions';
import { ACTION_TYPES } from './reducer';


// TODO: Need to delete related links if we are deleting episode

const ACTIONS = {
    read: {
        success: (list) => ({ type: ACTION_TYPES.read.success, list })
    },
    setSeason: (seriesId, seasonId) => ({ type: ACTION_TYPES.setSeason, seriesId, seasonId })
};


const fetchSeasonNo1 = seriesId => async (dispatch) => {
    const opt = {
        ...addFilter(
            addFilter(LIST_OPTIONS.seasons, 'seriesId', seriesId, '$eq'),
            'number',
            1,
            '$eq'),
        fields: ['_id', 'number', 'keywords']
    };
    const docs = await dispatch(findUserDocs(opt));

    if (docs.length) {
        return docs[0];
    }

    return null;
};

export const newEpisodeValues = (seriesId, seasonIdProps) => async (dispatch) => {
    let seasonId = seasonIdProps;
    let number = 0;
    let seasonNumber = 1;
    let keywords = '';
    let series = null;
    let season = null;

    if (!seriesId && !seasonId) {
        throw new Error('Series or Season should be specified');
    }

    if (seasonId) {
        season = await dispatch(readUserDoc(seasonId));
    } else {
        season = await dispatch(fetchSeasonNo1(seriesId));
    }
    if (season) {
        seasonId = season._id;
        seasonNumber = season.number;
        keywords = season.keywords;
    } else {
        series = await dispatch(readUserDoc(seriesId));
        if (series) {
            keywords = series.keywords;
        } else {
            throw new Error(`Season|Series did not found by id "${seasonId}"`);
        }
    }

    if (seasonId) {
        const opt = {
            ...addFilter(LIST_OPTIONS.episodes, 'seasonId', seasonId, '$eq'),
            fields: ['number']
        };
        const docs = await dispatch(findUserDocs(opt));
        docs.forEach((doc) => {
            if (doc.number) {
                number = Math.max(number, doc.number);
            }
        });
    }

    return {
        keywords,
        seasonId,
        number: number + 1,
        seasonNumber
    };
};


export const insert = values => async (dispatch) => {
    const { seasonId } = values;
    let newValues = {...values};

    if (!seasonId) {
        const opt = {
            ...addFilter(
                addFilter(LIST_OPTIONS.seasons, 'seriesId', values.seriesId, '$eq'),
                'number',
                1,
                '$eq'),
            fields: ['_id', 'number']
        };
        const docs = await dispatch(findUserDocs(opt));

        if (docs.length) {
            newValues = {
                ...newValues,
                seasonId: docs[0]._id,
                seasonNumber: docs[0].number
            };
        } else {
            const season = await dispatch(insertSeason({
                ...SEASON_SCHEMA.default(),
                seriesId: values.seriesId,
                number: 1,
                title: 's01'
            }));
            newValues = {
                ...newValues,
                seasonId: season._id,
                seasonNumber: season.number
            };
        }
    }

    if (!newValues.title && newValues.videoId) {
        const file = await dispatch(readUserDoc(newValues.videoId));
        if (file && file.title) {
            newValues = {
                ...newValues,
                title: file.title
            };
        }
    }

    const doc = newDocument(DOC_TYPE_EPISODE, newValues);
    return dispatch(writeUserDoc(doc));
};

export const insertVideoToSeries = (seriesId, videoId) => async (dispatch) => {
    const newValues = await dispatch(newEpisodeValues(seriesId, null));
    const episode = {
        ...EPISODE_SCHEMA.default(),
        ...newValues,
        seriesId,
        videoId
    };
    return dispatch(insert(episode));
};

export const fetchEpisodes = (seriesId, seasonId) => async (dispatch, getState) => {
    const { episodes: {seasonId: currentSeasonId, seriesId: currentSeriesId} } = getState();
    if (currentSeasonId !== seasonId || currentSeriesId !== seriesId) {
      dispatch(ACTIONS.setSeason(seriesId, seasonId));
    }
    return dispatch(findUserDocs(getState().episodes));
};
export const readEpisodesList = (seriesId, seasonId) => async (dispatch) => {
    const episodes = await dispatch(fetchEpisodes(seriesId, seasonId));

    const ids = episodes
        .map(ep => ep.videoId)
        .filter(id => !!id);

    const files = (ids.length ? await dispatch(readUserDocs(ids)) : [])
        .reduce((acc, file) => ({ ...acc, ...(file && { [file._id]: file }) }), {});

    dispatch(ACTIONS.read.success(episodes.map(ep => ({ ...ep, fileDoc: files[ep.videoId] }))));
};

export const fetch = id => (dispatch) => {
    const userDB = dispatch(getUserDB());

    return userDB
        .get(id)
        .then((doc) => {
            return doc;
        })
        .catch((err) => {
            throw err;
        });
};

/**
 * Remove episodes
 * @param {string|string[]} ids season id(s)
 * @param {function} cb callback
 */
export const removeEpisodes = (ids, cb) => async (dispatch) => {
    await dispatch(removeUserDocs(typeof ids === 'string' ? [ids] : ids));

    if (cb) {
        await cb();
    }
};

export const searchFiles = (title, fileType) => async (dispatch) => {
    const opt = {
        ...addFilter(
            addFilter(
                LIST_OPTIONS.videos,
                'type',
                fileType,
                '$eq'
            ),
            'title',
            new RegExp(`^${title}`, 'i'),
            '$regex'
        ),
        fields: ['title', '_id']
    };
    return dispatch(findUserDocs(opt));
};

export const fetchEpisodesByVideoId = videoIds => async (dispatch) => {
    const optEpisodes = {
        ...addFilter(LIST_OPTIONS.episodes, 'videoId', videoIds),
        fields: ['seriesId', 'seasonId', '_id']
    };
    return dispatch(findUserDocs(optEpisodes));
};
export const fetchEpisodesBySeriesId = seriesId => async (dispatch) => {
    const optEpisodes = {
        ...addFilter(LIST_OPTIONS.episodes, 'seriesId', seriesId, '$eq'),
        fields: ['_id']
    };
    return dispatch(findUserDocs(optEpisodes));
};
export const fetchEpisodesBySeasonIds = seasonIds => async (dispatch) => {
    const optEpisodes = {
        ...addFilter(LIST_OPTIONS.episodes, 'seasonId', seasonIds),
        fields: ['_id']
    };
    return dispatch(findUserDocs(optEpisodes));
};
