import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONTENT_TYPE } from '../../../constants/general';
import ListContainer from '../../../components/ListContainer/ListContainer';
import { readAudiosList } from '../../../modules/audios/actions';
import AudioListItemDetails from './AudioListItemDetails';

export default function AudiosList() {
  const dispatch = useDispatch();
  const audios = useSelector(s => s.audios.list);

  const [ready, setReady] = useState(false);

  const init = useCallback(async () => {
    await dispatch(readAudiosList());
    setReady(true);
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <ListContainer
        ready={ready}
        title="Audio"
        items={audios}
        actions={[
          // { label: 'Add Project', link: ROUTE.projects.create() }
        ]}
        details={AudioListItemDetails}
        type={CONTENT_TYPE.AUDIO}
      />
    </>
  );
}
