import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, ListItemText, MenuItem, Select, Skeleton, Typography } from '@mui/material'
import { getThumbnail, getThumbnailHermite, getThumbnailLanczos, getThumbnailSmooth, getVideoFrame } from './video-frame-extractor'


type TThumbnailsParams = {
  videoSrc: string;
  handleClose: () => void;
}

const DEFAULT_WIDTH = 108;
const SCALE = 1;

type TThumbnailState = {
  height: number;
  position: number;
  type: string;
  url: string;
  width: number;
};

export default function Thumbnails({ videoSrc, handleClose }: TThumbnailsParams): JSX.Element {
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [frame, setFrame] = useState<TThumbnailState>();
  const [thumbnail, setThumbnail] = useState<TThumbnailState>();
  const [thumbnailSmooth, setThumbnailSmooth] = useState<TThumbnailState>();
  const [lanczos, setLanczos] = useState<TThumbnailState>();
  const [hermite, setHermite] = useState<TThumbnailState>();
  const [unnamed, setUnnamed] = useState<TThumbnailState>();

  useEffect(() => {
    getThumbnail(videoSrc, { width, height: width }).then(t => {
      console.log('tst: got thumbnail:', t?.url?.length, t);
      setThumbnail(t);
    });
    getThumbnailSmooth(videoSrc, { width, height: width }).then(t => {
      console.log('tst: got thmsmooth:', t?.url?.length, t);
      setThumbnailSmooth(t);
    });
    getVideoFrame(videoSrc, { ratio: 1 }).then(t => {
      console.log('tst: got vid_frame:', t?.url?.length, t);
      setFrame(t);
    });
    getThumbnailLanczos(videoSrc, { width, height: width }).then(t => {
      console.log('tst: got Lanczos:', t?.url?.length, t);
      setLanczos(t);
    });
    getThumbnailHermite(videoSrc, { width, height: width }).then(t => {
      console.log('tst: got Hermite:', t?.url?.length, t);
      setHermite(t);
    });
  }, [videoSrc, width]);

  // const { url: _, ...rest } = thumbnail ?? {};
  // const { url: __, ...restSmooth } = thumbnailSmooth ?? {};
  // const { url: ___, ...restFrame } = frame ?? {};
  // const { url: ____, ...restLanczos } = lanczos ?? {};
  // const { url: _____, ...restHermite } = hermite ?? {};
  // const { url: ______, ...restUnnamed } = unnamed ?? {};

  return (
    <Dialog open onClose={handleClose} sx={{ '.MuiDialog-paper': { maxWidth: "95%" } }}>
      <DialogTitle variant="h2">Thumbnail demo</DialogTitle>
      <Select
        value={width}
        labelId={'width-label'}
        id={'width'}
        label="Target width"
        onChange={(event) => {
          setWidth(event.target.value as number);
          setThumbnail(undefined);
          setThumbnailSmooth(undefined);
          setLanczos(undefined);
          setHermite(undefined);
        }}
      >
        {[108, 150, 200, 250, 300, 350, 400].map((w) => (
          <MenuItem key={w} value={w}><ListItemText primary={w} /></MenuItem>
        ))}
      </Select>
      <DialogContent>
        {[
          { thumb: thumbnail, title: '1 step scale'},
          { thumb: thumbnailSmooth, title: 'smooth scale'},
          { thumb: frame, title: 'original frame'},
          { thumb: lanczos, title: 'Lanczos scale'},
          { thumb: hermite, title: 'Hermite scale'},
        ].map(({ thumb, title }) => {
          const { url: _, ...rst } = thumb ?? {};

          return (
            <Card key={title} sx={{ verticalAlign: 'top', display: 'inline-block', maxWidth: (width || 192) * SCALE }}>
              <CardHeader title={title} />
              {thumb
                ? (<CardMedia
                  sx={{ height: (thumb.height || 192) / (thumb.width || 192) * width * SCALE, border: '1px solid blue' }}
                  image={thumb.url}
                  title="Thumbnail" />)
                : <Skeleton height={width * SCALE} />}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {thumb ? JSON.stringify(rst, undefined, 2) : <Skeleton />}
                </Typography>
              </CardContent>
            </Card>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
