import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useParams, useRouteMatch } from 'react-router'
import currentFileReducer, { ICurrentFileReducerState } from 'shared/Providers/CurrentFile/reducer'
import { useRecentContext } from 'shared/Providers/Recent'
import { useDB } from 'shared/Providers/UserDB'
import { TDocFile } from 'shared/constants/docs'
import { apiShareGetDocument } from 'shared/modules/share/api'

interface ICurrentFileContext extends ICurrentFileReducerState {
  readFile: () => Promise<TDocFile>;
  saveFile?: (values: Partial<TDocFile>) => Promise<TDocFile>;
}

const EMPTY_FILE = {} as TDocFile;

export type DocProviderStateType = 'loading' | 'loaded' | 'updating';

const initialReducerState: ICurrentFileReducerState = {
  status: 'loading',
  file: EMPTY_FILE,
  isNew: false,
};

export const CurrentFileContext = createContext<ICurrentFileContext>({
  ...initialReducerState,
  isNew: true,
  readFile: () => Promise.resolve(EMPTY_FILE),
  saveFile: undefined,
  path: undefined,
})

interface ICurrentFileProviderProps extends PropsWithChildren {
  fileId: string;
}

export default function CurrentFileProvider({ children, fileId }: ICurrentFileProviderProps): JSX.Element {
  const { sharingId } = useParams<{ sharingId?: string, fileId: string }>();
  const currentRoute = useRouteMatch();

  const [state, dispatch] = useReducer(currentFileReducer, initialReducerState);

  const { addRecent } = useRecentContext();
  const { readDoc, writeDoc } = useDB();

  const readFile = useCallback(async (): Promise<TDocFile> => {
    console.log('tst: context', 'readFile');
    let f = EMPTY_FILE;
    if (fileId) {
      const getFileFunc = sharingId ? apiShareGetDocument : readDoc;
      f = await getFileFunc(fileId) as TDocFile;
    }

    dispatch({ file: f, status: 'loaded' });

    return f;
  }, [sharingId, readDoc, fileId]);

  const saveFile = useCallback(async ({_rev, ...values}: Partial<TDocFile>) => {
    if (!sharingId) {
      dispatch({ status: 'updating' });
      await writeDoc<TDocFile>({ _id: fileId, ...values })
      return await readFile()
    }
    return EMPTY_FILE;
  }, [sharingId, fileId, writeDoc, readFile]);

  const value = useMemo(() => ({
    ...state,
    readFile,
    saveFile: sharingId ? undefined : saveFile,
  }), [state, sharingId, readFile, saveFile]);

  console.log('tst: context', value);

  useEffect(() => {
    void readFile().then(f => {
      if (f?.type) {
        addRecent({
          type: f.type,
          title: f.title,
          to: currentRoute.url
        })
      }
    })
  }, [addRecent, readFile, currentRoute.url]);


  return (
    <CurrentFileContext.Provider value={value}>
      {children}
    </CurrentFileContext.Provider>
  );
}

export const useCurrentFile = (): ICurrentFileContext => useContext(CurrentFileContext);
