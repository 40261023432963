import React, { createContext, PropsWithChildren, useState } from 'react';
import { Dialog, TDialogProps } from 'shared/components/Dialog';

type TDialogContext = {
	isShown: boolean;
	setShowDialog: (show: boolean) => void;
	setDialogProps: (props: TDialogProps) => void;
};

export const DialogContext = createContext<TDialogContext>({
	isShown: false,
	setShowDialog: () => undefined,
	setDialogProps: () => undefined,
});

export const DialogProvider = ({ children }: PropsWithChildren): JSX.Element => {
	const [isShown, setShowDialog] = useState<boolean>(false);
	const [dialogProps, setDialogProps] = useState<TDialogProps>({});

	return (
		<DialogContext.Provider value={{ isShown, setShowDialog, setDialogProps }}>
			{children}
			{isShown && <Dialog {...dialogProps} />}
		</DialogContext.Provider>
	);
};
