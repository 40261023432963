import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../StatusIcons/StatusIcon/StatusIcon';
import iconCloud from './icon_cloud.svg';
import { UserContext } from 'shared/Providers/User';

const getIcon = (cloud) => {
    if (cloud.s3) {
        return iconCloud;
    }
};

export default function IconUploadedToCloud({ cloud }) {
  const { permissions: { canUseCloud } } = useContext(UserContext);

  if (!canUseCloud) {
    return null;
  }

  return (
    <StatusIcon
      enabled={cloud && Object.keys(cloud).length > 0}
      icon={cloud && Object.keys(cloud).length > 0
        ? getIcon(cloud)
        : iconCloud} />
  );
}

IconUploadedToCloud.propTypes = {
    cloud: PropTypes.object,
};
