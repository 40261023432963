import PouchDB from 'app/pouch-db';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import configs from 'shared/constants/configs'
import useError from 'shared/helpers/use-error'
import { TDocument } from 'shared/model/doc-base'
import { actionCloseRemoteDB, actionSetRemoteDB, actionSetSyncHandler } from 'shared/modules/db/dbActions'
import { syncOnChange } from 'shared/new/dbSyncUtils'
import useApiCouchGetSessionCookies, { TCCouchGetSessionCookies } from 'shared/utils/api/use-api-couch-get-session-cookies'
import { UserContext } from '../User'
import { useDB } from '../UserDB'
import useDbActions from '../UserDB/use-db-actions'
import { connectToDB } from '../UserDB/utils'
import WaitingScreen from '../WaitingScreen'

type TRemoteDBContext = {
  connected: boolean;
  replicating: boolean;
  syncing: boolean;
}

const RemoteDbContext = createContext<TRemoteDBContext>({
  connected: false,
  replicating: false,
  syncing: false,
});

function getHeadersRefreshInterval(expires: number | undefined) {
  let result = 6000; // 100min
  if (expires) {
    result = (expires - Date.now()) / 1000;
  }
  return (result - 2 * 60) * 1000;
}

export default function RemoteDbProvider({ children }: PropsWithChildren): JSX.Element {
  const { doCouchGetSessionHeaders } = useApiCouchGetSessionCookies();
  const { db } = useDB();
  const dispatch = useDispatch(); // TODO: remove
  const { user, logout } = useContext(UserContext);
  const { showError } = useError('RemoteDB');

  const [remoteDB, setRemoteDB] = useState<PouchDB.Database<TDocument>>();
  const { close: closeDb } = useDbActions(remoteDB);
  const headers = useRef<TCCouchGetSessionCookies|undefined>();
  const getHeadersInterval = useRef<ReturnType<typeof setInterval>>();

  const fetchHeaders = useCallback(async () => {
    console.debug('tst: RemoteDB fetchHeaders');
    headers.current = await doCouchGetSessionHeaders();
  }, [headers, getHeadersInterval, doCouchGetSessionHeaders]);

  const stopRefreshHeaders = useCallback(() => {
    if (getHeadersInterval.current) {
      console.debug('tst: RemoteDB stopRefreshHeaders');
      clearInterval(getHeadersInterval.current);
      getHeadersInterval.current = undefined;
    }
  }, [getHeadersInterval]);

  const startRefreshHeaders = useCallback(() => {
    console.debug('tst: RemoteDB startRefreshHeaders');
    stopRefreshHeaders();
    getHeadersInterval.current = setInterval(
      fetchHeaders,
      getHeadersRefreshInterval(headers.current?.expires)
    );
  }, [stopRefreshHeaders, headers, getHeadersInterval, fetchHeaders]);

  const close = useCallback(async () => {
    console.debug('tst: RemoteDB close');
    stopRefreshHeaders();
    if (remoteDB) {
      setRemoteDB(undefined);
      dispatch(actionCloseRemoteDB())
      await closeDb();
    }
  }, [remoteDB, stopRefreshHeaders, closeDb, dispatch]);

  const connect = useCallback(async () => {
    if (user?.databaseId) {
      const dbUrl = `${configs.couchDbUri}/${user?.databaseId}`;
      console.debug('tst: RemoteDB connect', dbUrl);

      await fetchHeaders();
      startRefreshHeaders();

      const newDB = await connectToDB(dbUrl, showError, {
        fetch: async (url, opts) => {
          if (headers.current) {
            let optsHeaders = opts?.headers;
            if (opts && !optsHeaders) {
              optsHeaders = new Headers([['cookie', headers.current.session]]);
              opts.headers = optsHeaders;
            }
            if (optsHeaders) {
              if (Array.isArray(optsHeaders)) {
                optsHeaders.push(['cookie', headers.current.session]);
              } else if (((optsHeaders): optsHeaders is Headers => typeof optsHeaders['set'] === 'function')(optsHeaders)) {
                optsHeaders.set('cookie', headers.current.session);
              } else {
                optsHeaders['cookie'] = headers.current.session;
              }
            }
          } else {
            showError('No headers received for remote DB');
          }
          return PouchDB.fetch(url, opts);
        }
      });

      if (newDB) {
        setRemoteDB(newDB);
        dispatch(actionSetRemoteDB(newDB));
      } else {
        logout(); // TODO: Remove this to be able to work offline
      }
    }
  }, [user?.databaseId, fetchHeaders, startRefreshHeaders, headers, showError, dispatch, logout]);

  useEffect(() => {
    // If db already connected - just return how to close it
    if (remoteDB) {
      return () => {
        void close();
      };
    }
    // Connect to DB
    connect();
    return undefined;
  }, [remoteDB, connect, close]);


  // TODO: Move replication/sync to another hook?
  const sync = useRef<PouchDB.Replication.Replication<TDocument>|PouchDB.Replication.Sync<TDocument>|undefined>();
  const [replicating, setReplicating] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const startReplicate = useCallback(() => {
    if (db && remoteDB) {
      console.debug('tst: startReplicate');
      setReplicating(true);

      // Assign replicate object to sync ref to be able to cancel it
      sync.current = db.replicate.from(remoteDB);
      sync.current
        .on('complete', (ignoreInfo) => {
          console.debug('DB Replicate complete', ignoreInfo);
          setReplicating(false);

          // Replicate is complete
          // Assign sync object to sync ref to be able to cancel it
          sync.current = db.sync(remoteDB, { live: true, retry: true });
          sync.current.on('change', (info) => {
            console.debug('DB Sync change', info);
            dispatch(syncOnChange(info));
          })
          .on('paused', (err) => {
            setSyncing(false);
            console.debug('DB Sync paused', err);
          })
          .on('denied', (err) => {
            console.debug('DB Sync denied', err);
          })
          .on('error', (err) => {
            console.error('DB Sync error', err);
          })
          .on('active', () => {
            setSyncing(true);
            console.debug('DB Sync active');
          })
          .on('complete', (info) => {
            setSyncing(false);
            console.debug('DB Sync complete', info);
          })

          dispatch(actionSetSyncHandler(sync.current));
        })
        .on('paused', (err) => {
          // setReplicating(false);
          console.debug('DB Replicate paused', err);
        })
        .on('denied', (err) => {
          // setReplicating(false);
          console.debug('DB Replicate denied', err);
        })
        .on('error', (err) => {
          console.error('DB Replicate error', err);
        })
        .on('active', () => {
          // setReplicating(true);
          console.debug('DB Replicate active');
        });
    }
  }, [db, remoteDB, sync]);

  const stopReplicate = useCallback(() => {
    console.debug('tst: stopReplicate', sync.current);
    if (sync.current) {
      sync.current.cancel();
      sync.current = undefined;
    }
  }, [sync]);

  useEffect(() => {
    startReplicate();
    return () => {
      stopReplicate();
    };
  }, [startReplicate, stopReplicate]);

  if (!remoteDB) {
    return <WaitingScreen description="Connecting to remote DB ..." />
  }

  if (replicating) {
    return <WaitingScreen description="Syncing with remote DB ..." />
  }

  // return <WaitingScreen description="Remote DB DONE" />

  return (
    <RemoteDbContext.Provider value={{
      connected: !!remoteDB,
      replicating,
      syncing,
      // remoteDB,
    }}>
      {children}
    </RemoteDbContext.Provider>
  );
}

export const useRemoteDB = (): TRemoteDBContext => useContext(RemoteDbContext);
