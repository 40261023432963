import CloudProvider from 'shared/_model/cloud/provider/CloudProvider';
import CloudProviderS3 from 'shared/_model/cloud/provider/CloudProviderS3';
import { Cloud, CloudType, CloudValueS3 } from 'shared/_model/cloud/types';
import { getCloudValue } from 'shared/_model/cloud/utils';

// eslint-disable-next-line import/prefer-default-export
export function getCloudProvider(cloud: Cloud, type: CloudType) {
	const value = getCloudValue(cloud, type);
	if (!value) {
		return undefined;
	}

	const MAP = {
		[CloudType.S3]: () => new CloudProviderS3(value as CloudValueS3),
		[CloudType.TEMP]: () => undefined as unknown as CloudProvider,
		// TODO: Solve TS problem
	} as const; // satisfies Record<CloudType, () => CloudProvider>

	const fn = MAP[type];

	return fn();
}
