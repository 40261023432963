import React, { useCallback } from 'react';
import { useUserData } from 'shared/Providers/UserData';
import HelpPages from '../../../../components/HelpPages/HelpPages';
import { WindowContainer } from '../../../../components/WindowContainer';
import ProjectHelpPage1 from './Pages/Page1/ProjectHelpPage1';
import ProjectHelpPage2 from './Pages/Page2/ProjectHelpPage2';
import ProjectHelpPage3 from './Pages/Page3/ProjectHelpPage3';
import ProjectHelpPage4 from './Pages/Page4/ProjectHelpPage4';
import ProjectHelpPage5 from './Pages/Page5/ProjectHelpPage5';
import ProjectHelpPage6 from './Pages/Page6/ProjectHelpPage6';
import ProjectHelpPage7 from './Pages/Page7/ProjectHelpPage7';

export default function ProjectHelpPages() {
	const { user: { helpFlags: { openFirstProject: flag } }, updateUser } = useUserData();

	const handleDone = useCallback(async () => {
		await updateUser({ helpFlags: { openFirstProject: true } });
	}, [updateUser]);

	if (flag) {
		return null;
	}

	return (
  	<WindowContainer closeAction={handleDone}>
  		<HelpPages
				width="600px"
  			height="523px"
  			pages={[
  				<ProjectHelpPage1 key="page1" />,
  				<ProjectHelpPage4 key="page4" title="How It Works:" />,
  				<ProjectHelpPage6 key="page6" title="How It Works:" />,
  				<ProjectHelpPage2 key="page2" title="How It Works:" />,
  				<ProjectHelpPage3 key="page3" title="How It Works:" />,
  				<ProjectHelpPage5 key="page5" title="How It Works:" />,
  				<ProjectHelpPage7 key="page7" title="How It Works:" />,
				]}
  			callback={handleDone}
			/>
		</WindowContainer>
	);
}
