import { useCallback, useContext, useEffect, useState } from 'react';
import { getEmptyDoc, TDocType } from 'shared/model'
import { TDocument } from 'shared/model/doc-base'
import { UserContext } from 'shared/Providers/User'
import { useDB } from 'shared/Providers/UserDB';

type TUseDocumentOptionsParam<T extends TDocument> = {
  lazy?: boolean;
  typeNew?: TDocType// T['type'];
}
type TUseDocumentResponse<T extends TDocument> = {
  read: boolean;
  doc: T|undefined;
  readDoc: () => Promise<T | undefined>;
  writeDoc: (values: Partial<T>) => Promise<T | undefined>;
};

export function useDocument<T extends TDocument>(id?: string, { lazy = false, typeNew }: TUseDocumentOptionsParam<T> = {}): TUseDocumentResponse<T> {
  const { readDoc: readDbDoc, writeDoc: writeDbDoc } = useDB()

  const { user } = useContext(UserContext);
  const [doc, setDocState] = useState<T|undefined>();
  const [read, setRead] = useState(false);

  const setDoc = useCallback((d?: T) => {
    if (d) {
      setDocState(d)
    }
  }, [])

  const readDoc = useCallback(async () => {
    if (id) {
      let found = await readDbDoc<T>(id) as T;
      if (!found && typeNew) {
        found = getEmptyDoc<T>(typeNew, user!.id);
      }
      setDoc(found);
      setRead(true);
      return found;
    }
    return undefined;
  }, [id, readDbDoc, typeNew, user!.id])

  const writeDoc = useCallback(async (values: Partial<T>) => {
    if (id) {
      const modified = await writeDbDoc<T>({ ...values, _id: id })
      setDoc(modified)
      return modified as T
    }
    return undefined;
  }, [id, writeDbDoc, typeNew])

  useEffect(() => {
    if (!lazy && id) {
      void readDoc()
    }
  }, [lazy, id, readDoc])


  return {
    read,
    doc,
    readDoc,
    writeDoc,
  }
}
