import { ENDPOINTS } from '../../../constants/endpoints';
import { api } from '../../../constants/globals';

export const apiRssAddSeriesRss = async (seriesId) => {
    try {
        return await api.apiPost(ENDPOINTS.rss.addSeriesRss, { series_id: seriesId });
    } catch (e) {
        return { message: e.message };
    }
};
