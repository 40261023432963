import { Box, styled, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ProjectFileContainer = styled(Box, {
	name: 'ProjectFileContainer',
	shouldForwardProp: (propName: string) => !['hasDescription', 'isFinish'].includes(propName),
})<{ isFinish?: boolean; hasDescription: boolean }>(({ isFinish, hasDescription, theme }) => ({
	'&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' },
	transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	justifyContent: 'space-between',
	display: 'grid',
	paddingLeft: theme.spacing(2),

	alignItems: 'center',
	columnGap: theme.spacing(2),
	gridTemplateAreas: `
			"${isFinish ? 'image' : ''} title icons"
			"${isFinish ? 'image' : ''} dateSize icons"
			"${isFinish ? 'image' : ''} id icons"
			${hasDescription ? `"${isFinish ? 'image' : ''} description icons"` : ''}
			`,
	gridTemplateColumns: `${isFinish ? 'auto' : ''} 1fr auto`,

	[theme.breakpoints.up('md')]: {
		gridTemplateAreas: `
			"${isFinish ? 'image' : ''} title id icons"
			"${isFinish ? 'image' : ''} dateSize id icons"
			${hasDescription ? `"${isFinish ? 'image' : ''} description id icons"` : ''}
			`,
		gridTemplateColumns: `${isFinish ? 'auto' : ''} 1fr auto auto`,
	},
	[theme.breakpoints.up('lg')]: {
		gridTemplateAreas: `
			"${isFinish ? 'image' : ''} title id dateSize icons"
			${hasDescription ? `"${isFinish ? 'image' : ''} description id dateSize icons"` : ''}
			`,
		gridTemplateColumns: `${isFinish ? 'auto' : ''} 1fr auto auto auto`,
	},
}));

export const ProjectFileImageContainer = styled(Box, {
	name: 'ProjectFileImageContainer',
})(({ theme }) => ({
	gridArea: 'image',
	flex: 'none',
	width: theme.spacing(14.5),
	height: theme.spacing(14.5),
	marginRight: theme.spacing(2),
	[theme.breakpoints.up('md')]: {
		width: theme.spacing((18 * 16) / 9),
		height: theme.spacing(18),
	},
}));

export const ProjectFileTitle = styled('div', {
	name: 'ProjectFileTitle',
})({
	gridArea: 'title',
	minWidth: 0,
});

export const ProjectFileDateSizeContainer = styled('div', {
	name: 'ProjectFileDateSizeContainer',
})(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(1),
	gridArea: 'dateSize',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	[theme.breakpoints.up('md')]: {
		gap: theme.spacing(2),
	},
	[theme.breakpoints.up('lg')]: {
		gap: theme.spacing(4),
		marginRight: theme.spacing(4 * 4.75),
	},
}));

export const ProjectFileDate = ({ children }: PropsWithChildren) => {
	return (
		<Typography
			color="secondary"
			noWrap
			variant="body1"
			component="span"
			sx={(theme) => ({
				[theme.breakpoints.up('lg')]: {
					minWidth: '8.25rem', // ~132px for font-size: 14px
					textAlign: 'left',
				},
			})}
		>
			{children}
		</Typography>
	);
};

export const ProjectFileSize = ({ children }: PropsWithChildren) => {
	return (
		<Typography
			color="secondary"
			noWrap
			variant="body1"
			component="span"
			sx={(theme) => ({
				[theme.breakpoints.up('md')]: {
					minWidth: '8.265ex', // ~60px for font-size: 14px
					textAlign: 'right',
				},
			})}
		>
			{children}
		</Typography>
	);
};

export const ProjectFileDescription = styled(Typography, {
	name: 'ProjectFileDescription',
})(() => ({
	gridArea: 'description',
	fontSize: '0.75rem', // 12px
}));
ProjectFileDescription.defaultProps = {
	...ProjectFileDescription.defaultProps,
	noWrap: true,
};

export const ProjectFileIconsContainer = styled(Box, {
	name: 'ProjectFileIconsContainer',
})(({ theme }) => ({
	gridArea: 'icons',
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.up('md')]: {
		flexDirection: 'row-reverse',
	},
}));

export const ProjectFileFinishIconsContainer = styled(Box, {
	name: 'ProjectFileFinishIconsContainer',
	shouldForwardProp: (propName: string) => !['isFinish'].includes(propName),
})<{ isFinish: boolean }>(({ isFinish, theme }) => ({
	display: 'flex',
	gap: theme.spacing(2),
	alignItems: 'center',
	marginRight: '34px',
	marginLeft: 'auto',
	[theme.breakpoints.down('md')]: isFinish ? undefined : { display: 'none' },
	[theme.breakpoints.up('md')]: {
		marginRight: 0,
	},
}));
