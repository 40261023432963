import { Divider, Typography } from '@mui/material';
import { Fragment, memo, ReactNode } from 'react';
import ProjectFile from 'shared/_model/project-files/project-file';
import { IconFileMedia, IconFileMisc, IconFileProject, IconMic } from 'shared/_ui/icon';
import { DocType, DocTypeFiles, TDocFile, TDocumentTypeFile } from 'shared/constants/docs';
import { isFinishFile } from 'shared/constants/pouchDB';
import {
	ProjectFilesFileTypeTitleContainer, ProjectFilesGroupContainer,
	ProjectFilesListContainer,
} from 'shared/_model/project-files/project-files-group/styles';
import AddFileButton from 'app/_model/project/components/AddFileButton';
import { useProjectContext } from 'shared/_pages/project/provider';
import { ProjectFileGroupActionsContainer } from 'shared/_model/project-details/styles';
import ActionFileGroupMenu from 'shared/_model/project-files/ActionFileGroupMenu';

const ICON_MAP: Record<TDocumentTypeFile, typeof IconFileMedia | null> = {
	'file-media': IconFileMedia,
	'file-audio': IconMic,
	'file-misc': IconFileMisc,
	'file-project': IconFileProject,
	'file-final-audio': null,
	'file-video': null,
};
export const TITLE_MAP: Record<DocTypeFiles, ReactNode> = {
	[DocType.FILE_MEDIA]: 'Media',
	[DocType.FILE_AUDIO]: 'Audio',
	[DocType.FILE_MISC]: 'Other',
	[DocType.FILE_PROJECT]: 'Project files',
	[DocType.FILE_FINISH_AUDIO]: 'Finished Audio',
	[DocType.FILE_FINISH_VIDEO]: 'Finished Video',
};

type FileTypeTitleProps = {
	type: TDocumentTypeFile;
	hasBorder?: boolean;
};

const FileTypeTitle = memo(({ type, hasBorder }: FileTypeTitleProps) => {
	const Icon = ICON_MAP[type];
	const title = TITLE_MAP[type];

	return (
		<ProjectFilesFileTypeTitleContainer hasBorder={hasBorder}>
			{!!Icon && (
				<Typography>
					<Icon
						sx={(theme) => ({
							[theme.breakpoints.up('lg')]: {
								height: '2.5rem',
								width: '2.5rem',
							},
						})}
					/>
				</Typography>
			)}
			{!!title && (
				<Typography
					mb={0}
					component="h3"
					noWrap
					sx={(theme) => ({
						color: theme.palette.secondary.main,
						lineHeight: '1.5rem',
						[theme.breakpoints.up('lg')]: {
							lineHeight: '2.5rem',
						},
					})}
				>
					{title}
				</Typography>
			)}
		</ProjectFilesFileTypeTitleContainer>
	);
});

type ProjectFilesGroupProps = {
	divider?: boolean;
	files: TDocFile[];
	type: DocTypeFiles;
};

export default function ProjectFilesGroup({
	files,
	type,
	divider = false,
}: ProjectFilesGroupProps) {
	const isFinish = isFinishFile(type);
	const { addFiles } = useProjectContext();

	return (
		<ProjectFilesGroupContainer isFinish={isFinish} addFiles={!!addFiles}>
			{!isFinish && <FileTypeTitle type={type} hasBorder={divider} />}
			{!isFinish && (
				<ProjectFileGroupActionsContainer data-box-id="file_type_actions">
					{!!addFiles && <AddFileButton type={type} variation="group" />}
					<ActionFileGroupMenu type={type} />
				</ProjectFileGroupActionsContainer>
			)}
			<ProjectFilesListContainer hasBorder={divider}>
				{files.map((f, index) => (
					<Fragment key={f._id}>
						<ProjectFile file={f} />
						{index !== files.length - 1 && (
							<Divider
								sx={(theme) => ({
									my: theme.spacing(2),
									borderColor: theme.palette.grey[200],
								})}
							/>
						)}
					</Fragment>
				))}
			</ProjectFilesListContainer>
		</ProjectFilesGroupContainer>
	);
}
