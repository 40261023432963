import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconStoreInCloud } from '../../../resources/svg/IconStoreInCloud';
import Input from '../../Input/Input';
import styles from './button-storeIn-cloud.module.css';
import * as projectActions from '../../../modules/projects/actions';
import { UserContext } from 'shared/Providers/User';

export default function ButtonStoreInCloud({ disabled = false, storeOnLocalDrive, storeInCloud, onChange, projectId }) {
  const dispatch = useDispatch();
  const { permissions: { canUseCloud } } = useContext(UserContext);

  const onClick = useCallback(async () => {
    await dispatch(projectActions.saveProject({ storeInCloud: !storeInCloud, _id: projectId }));

    if (onChange) {
      await onChange();
    }
  }, [dispatch, storeInCloud, onChange, projectId]);

  return (
      <>
      <div className={styles.icon}>
        <IconStoreInCloud {...{
          ...(storeInCloud ? {} : {fill: "#9e9e9e"})
        }}
        />
      </div>
          {/* <Input
              type={Input.TYPE.SWITCH}
              readOnly={disabled || !canUseCloud || (storeInCloud && !storeOnLocalDrive)}
              form={{ touched: null, errors: null }}
              field={{ name: 'storeInCloud', value: storeInCloud }}
              rootClassName={styles.inputRoot}
              wrapperClassName={styles.inputWrapper}
              onChange={onClick}
          /> */}
      </>
  );
}

ButtonStoreInCloud.propTypes = {
  projectId: PropTypes.string.isRequired,
  storeOnLocalDrive: PropTypes.bool.isRequired,
  storeInCloud: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
