import React, { ReactNode } from 'react';
import { Box, Link } from '@mui/material'
import { PageContainerBreadcrumbs, TextItem, TextItemLast } from 'shared/Pages/common/PageContainer/styles'
import { SizeType } from 'shared/Pages/common/PageContainer/types'

type TPageContainerProps = {
  parentTitle?: string
  parentLink?: string,
  title?: string
  actions?: ReactNode
  size?: SizeType
  children: ReactNode
}

// Copy of Header font-sizes
const FONT_SIZE_MAP: Record<SizeType, string> = {
  small: '1.375rem',
  medium: '1.5rem',
  large: '1.75rem',
};

function getTitleLevel(size: SizeType) {
  switch (size) {
    case 'large':
      return 1;
    case 'medium':
      return 2;
    default:
      return 3;
  }
}

interface IPreviousItemProps {
  fontSize: string;
  link?: string;
  children: ReactNode;
}

function PreviousItem({ fontSize, link, children }: IPreviousItemProps): JSX.Element {
  if (link) {
    return (
      <Link underline="hover" color="var(--lightText)" href={link}>
        {children}
      </Link>
    );
  }

  return <TextItem color="var(--lightText)" sx={{ fontSize }}>{children}</TextItem>;
}

export function PageContainer(props: TPageContainerProps): JSX.Element {
  const { size = 'large', parentTitle, parentLink, title, actions, children } = props

  const fontSize = FONT_SIZE_MAP[size]

  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <PageContainerBreadcrumbs separator="»" sx={{ fontSize }}>
          {parentTitle && (
            <PreviousItem fontSize={fontSize} link={parentLink}>
              {parentTitle}
            </PreviousItem>
          )}
          <TextItemLast color="text.primary" sx={{ fontSize }} variant={`h${getTitleLevel(size)}`}>
            {title}
          </TextItemLast>
        </PageContainerBreadcrumbs>
        {!!actions && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 'none',
            }}
          >
            {actions}
          </Box>
        )}
      </Box>
      {children}
    </Box>
  )
}
