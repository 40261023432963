import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ENDPOINTS } from 'shared/constants/endpoints';
import { api, emitUserError } from 'shared/constants/globals';
import { addDistribution } from 'shared/modules/series/actions';
import { SeriesContext } from 'shared/Providers/Series';
import Button from 'shared/components/Button/Button';
import CopyValueButton from 'shared/components/CopyValueButton/CopyValueButton';
import Input from 'shared/components/Input/Input';
import { ModalWindow } from 'shared/components/ModalWindow';
import styles from 'shared/components/SeriesRssSettings/series-rss-settings.module.scss';
import { SeriesRssContent } from 'shared/components/SeriesRssSettings/SeriesRssContent';


type TSeriesRssSettingsProps = {
  onClose: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
const validationSchema = object({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  template: string().required()
}).defined();

interface ISeriesRssSettingsValues {
  template: string;
}

export const SeriesRssSettings: React.FC<TSeriesRssSettingsProps> = (props) => {
  const dispatch = useDispatch();
  const { onClose } = props;

  const { series, rssURI, rssActivated, readRssActivated } = useContext(SeriesContext);


  const [template, setTemplate] = useState('');
  const [showRss, setShowRss] = useState(false);


  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const readTemplate = useCallback(async () => {
    try {
      // @ts-ignore
      const res = await api.apiGet(ENDPOINTS.rss.getSeriesTemplate, { series_id: series._id })
      if (res?.content) {
        setTemplate(res?.content)
      }
    } catch (e) {
      emitUserError((e as Error).message, 'Read rss template error')
    }
  }, [series._id]);

  useEffect(() => {
    void readTemplate();
  }, [readTemplate]);

  const handleSave = useCallback(async (values: ISeriesRssSettingsValues, { setSubmitting }: FormikHelpers<ISeriesRssSettingsValues>) => {
    setSubmitting(true);
    try {
      await api.apiPost(ENDPOINTS.rss.setSeriesTemplate, {
        series_id: series._id,
        content: values.template,
      });
      await readTemplate()
    } catch (e) {
      emitUserError((e as Error).message, 'Save rss template error')
    } finally {
      setSubmitting(false)
    }
  }, [series._id, readTemplate]);

  const handleDelete = useCallback(async () => {
    try {
      await api.apiPost(ENDPOINTS.rss.delSeriesTemplate, {
        series_id: series._id,
      });
      await readTemplate();
    } catch (e) {
      emitUserError((e as Error).message, 'Del rss template error')
    }
  }, [series._id, readTemplate]);

  const toggleShowRss = useCallback(() => {
    setShowRss(s => !s)
  }, [])


  const [activating, setActivating] = useState(false);
  const handleActivate = useCallback(async () => {
    setActivating(true);
    try {
      // @ts-ignore
      const res = await addDistribution(series._id);
      if (res) {
        await readRssActivated();
      }
    } catch (e) {
      emitUserError((e as Error).message, 'Activate RSS error');
    } finally {
      setActivating(false);
    }
  }, [dispatch, series._id, readRssActivated]);


  return (
    <ModalWindow
      caption={`${series.seriesType === 'video' ? 'M' : ''}RSS`}
      onClose={handleClose}
    >
      {showRss && (
        <ModalWindow onClose={toggleShowRss}>
          <SeriesRssContent uri={rssURI} />
        </ModalWindow>
      )}
      {!rssActivated && (
        <>
          <div className={styles.buttons}>
            RSS feed is not activated
          </div>
          <div className={styles.buttons}>
            <Button small disabled={activating} onClick={handleActivate}>
              Activate Feed
            </Button>
          </div>
        </>
      )}
      {rssActivated && (
        <>
          <div>
            <div className={styles.flexRow}>
              <div className={styles.flex1}>Link</div>
              <CopyValueButton value={rssURI} className={styles.copyButton} />
            </div>
            <Input
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              field={{ value: rssURI, onChange: () => undefined }}
            />
          </div>
          <Formik<ISeriesRssSettingsValues>
            initialValues={{ template }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSave}
            render={({ isSubmitting, dirty }) => (
              <Form>
                <Field
                  name="template"
                  component={Input}
                  type={Input.TYPE.TEXTAREA}
                  label="Template:"
                  labelWidth={Input.LABEL_WIDTH.FULL}
                  spaceBottom={Input.SPACE_BOTTOM.SMALL}
                  className={styles.template}
                />
                <div className={styles.buttons}>
                  <Button small disabled={isSubmitting} onClick={handleDelete}>Reset to default</Button>
                  <Button small disabled={!dirty || isSubmitting} type="submit">Save</Button>
                  <Button small disabled={isSubmitting} onClick={toggleShowRss}>View Feed</Button>
                </div>
              </Form>
            )}
          />
        </>
      )}
    </ModalWindow>
  )
}
