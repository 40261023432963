export default class ApiError extends Error {

  name = 'APIError';
  status: number;
  aborted: boolean;
  needsAuthentication: boolean;

	constructor(status: number, message?: string, aborted?: boolean) {
		super(message);
		/**
		 * Because webpack will compress the class name, we cannot use
		 * this.constructor.name
		 */
		this.name = 'APIError';
		this.status = status;
    this.aborted = aborted ?? false;

		/**
		 * Whether the user must re-authenticate before trying to rerun the API request.
		 *
		 * Description:
		 * ------------
		 * The following HTTP response status codes indicate Rover session token is no longer valid:
		 *
		 *  - 401 Unauthorized
		 *  - 403 Forbidden
		 *  - 440 Login Time-out
		 *
		 * This could happen because of the following reasons:
		 *
		 *  1) An administrator has manually removed the session token
		 *
		 *  2) The session token may have been forcibly removed
		 *     by a misconfigured microservice
		 *
		 *  3) A bug in the app may have caused the token refresh to
		 *     be out of sync
		 *
		 * Because of that, we will add an additional property to indicate
		 * the request resulted from an authentication failure
		 */
		this.needsAuthentication = [401, 403, 440].includes(status);

		// // Dispatch event
		// this.emit();
	}

	/**
	 * Emits a event to the AuthContext component so that
	 * the AppErrors snackbar can render it
	 */
	// emit() {
	// 	const event = new Event(this.name, {
	// 		error: this,
	// 		message: this.message,
	// 	});
	// 	event.message = this.message;
	// 	event.needsAuthentication = this.needsAuthentication;
	// 	dispatchEvent(event);
	// 	return event;
	// }
}
