import React, { createContext, PropsWithChildren, useContext } from 'react';
import useLogin from 'shared/Providers/User/use-login';
import { TUser, TUserPermission } from 'shared/model/user-types';
import { emptyUser } from 'shared/model/user-utils';
import { TToken } from 'shared/model/token-types';
import LoginLayout from 'shared/Pages/common/Login/LoginLayout';
import WaitingScreen from '../WaitingScreen';

type TUserContext = {
	token?: TToken;
	setToken: (t?: TToken) => void;
	user?: TUser;
	isLoggingIn: boolean;
	isRestoring: boolean;
	id: string; // TODO: move to `user`?
	databaseId: string; // TODO: move to `user`?
	permissions: Record<TUserPermission, boolean>;
	canSeeExperiments: boolean;
	login: (email: string, password: string) => Promise<void>;
	logout: () => Promise<void>;
};

export const UserContext = createContext<TUserContext>({
	token: undefined,
	setToken: () => undefined,
	user: { ...emptyUser },
	isLoggingIn: false,
	isRestoring: true,
	id: '',
	databaseId: '',
	permissions: { ...emptyUser.permissions },
	canSeeExperiments: false,
	login: async () => undefined,
	logout: async () => undefined,
});

export const UserProvider = ({ children }: PropsWithChildren): JSX.Element => {
	const { isLoggingIn, isRestoring, login, logout, user, token, setToken } = useLogin();
	console.log('tst: UserProvider', isLoggingIn, !!token, !!user);

	if (isRestoring) {
		return <WaitingScreen description="Restoring session ..." />;
	}

	return (
		<UserContext.Provider
			value={{
				token,
				setToken,
				user,
				isLoggingIn,
				isRestoring,
				id: user?.id ?? '', // TODO: remove, use `user.id` instead
				databaseId: user?.databaseId ?? '', // TODO: remove, use `user.databaseId` instead
				permissions: user?.permissions ?? ({} as Record<TUserPermission, boolean>), // TODO: remove, use `user.permissions` instead
				canSeeExperiments: !!user?.roles?.find((r) => r.key === 'SuperAdmin'),
				login,
				logout,
			}}
		>
			{!user?.databaseId ? <LoginLayout /> : children}
		</UserContext.Provider>
	);
};

export const useUserContext = (): TUserContext => useContext(UserContext);
