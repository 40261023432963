import { useCallback, useState } from 'react'

type UseToggleType = [
  /** Hook state */
  boolean,
  /** Toggle function */
  () => void,
  /** Reset function */
  () => void,
]

export default function useToggle(initialState = false): UseToggleType {
  const [state, setState] = useState(initialState);

  const toggleState = useCallback(() => {
    setState(e => !e);
  }, []);

  const resetState = useCallback(() => {
    setState(false);
  }, []);

  return [
    state,
    toggleState,
    resetState
  ];
}
