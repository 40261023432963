import { DOC_TYPE_PROJECT, DOC_TYPE_SERIES, getDocType } from '../constants/docs';
import { readProjectsList } from '../modules/projects/actions';
import { readSeriesList } from '../modules/series/actions';
import { actionClearSyncHandler } from '../modules/db/dbActions';
import { getSyncHandler } from './dbUtils';

export const cancelSyncDB = () => async (dispatch) => {
    const syncHandler = dispatch(getSyncHandler());
    if (syncHandler) {
        try {
            syncHandler.cancel();
            dispatch(actionClearSyncHandler());
            return true;
        } catch (err) {
            console.error('Canceling sync', err);
        }
        return false;
    }

    return !!dispatch(getSyncHandler());
};

export const syncOnChange = info => async (dispatch) => {
    console.info('sync change', { info });
    if (info.direction === 'pull' && info.change && info.change.ok === true && info.change.docs_written > 0) {
        const types = {};
        info.change.docs.forEach((doc) => {
            types[getDocType(doc)] = true;
            // if (doc._deleted) {
            //     // TODO: deleted ids should be proceeded not in the sync handler but in db.changes() listener
            // }
        });

        // TODO: update lists if one of the items was in it
        if (types[DOC_TYPE_SERIES]) {
            dispatch(readSeriesList());
        } else if (types[DOC_TYPE_PROJECT]) {
            dispatch(readProjectsList());
        }
        // TODO: actions on sync different types of docs
    }
};
