import { getDownloadUrl } from '../cloud/cloud';
import { getThumbnail } from 'shared/Pages/Experiments/Thumbnails/video-frame-extractor';
import { wait } from '../common';

const WIDTH = 192;
const HEIGHT = WIDTH * 9 / 16;

export async function makeThumbnail(doc) {
  if (!doc?.cloud) {
    return;
  }

  let videoSrc;
  const urlResponse = await getDownloadUrl(doc.cloud);
  if (urlResponse && urlResponse.url) {
    videoSrc = urlResponse.url;
  }

  if (!videoSrc) {
    return;
  }

  try {
    const thumb = await Promise.race([
      (async () => {
        await wait(30000);
        throw new Error('Timeout making thumbnail');
      })(),
      getThumbnail(videoSrc, { width: WIDTH, height: HEIGHT }),
    ]);

    return thumb;
  } catch(e) {
    console.error(e);
  }

  // const thumb = await getThumbnail(videoSrc, { width: WIDTH, height: HEIGHT });
  return undefined;
  // return thumb;
}
