import * as qs from 'qs/lib';

// const processResult = async (response) => {
//     if (!response || !response.ok) {
//         throw response;
//     }
//     // console.info('response', response);
//     switch (response.status) {
//     case 200:
//     case 201:
//         return await processResponse(response);
//     default:
//         throw response;
//     }
// };
// const processResponse = async response => (
//     (response.headers.get('Content-Type').indexOf('text/plain') === -1)
//         ? response.json()
//         : response.text()
// );

export default class Request {
  requestUrl = null;

  queryParams = {};

  _headers = {};

  _token = null;

  constructor(method, url, data, token = '') {
      this._token = token;
      this.buildRequestUrl(method, url, data);
      this.params = {
          method,
          body: data && method !== 'get' ? JSON.stringify(this.queryParams) : null,
          cache: 'no-cache',
          mode: 'cors',
          timeout: 60000,
      };
  }

  setHeader(name, value) {
      this._headers[name] = value;
  }

  get headers() {
      return this._headers;
  }

  set token(value) {
      this._token = value;
  }

  get token() {
      return this._token;
  }

  buildRequestUrl(method, url, data) {
      this.requestUrl = url;

      // Build the url. In case of get request data should be in url string.
      if (data) {
      // Some data items can be used in url as placeholders in form %NAME%.
      // Those data items should be removed from query string.
          Object.entries(data).forEach(([key, value]) => {
              const paramName = `%${key}%`;
              if (this.requestUrl.indexOf(paramName) !== -1) {
                  this.requestUrl = this.requestUrl.replace(paramName, value);
              } else {
                  this.queryParams[key] = value;
              }
          });

          if (method === 'get' && Object.keys(this.queryParams).length) {
              const separator = this.requestUrl.split('?')[1] ? '&' : '?';
              this.requestUrl = `${this.requestUrl}${separator}${qs.stringify(this.queryParams)}`;
          }
      }
  }

  buildRequestHeaders() {
      if (this.token) {
          this.setHeader('Authorization', `Bearer ${this.token}`);
      }
      // if (apiToken.getUser()) {
      // this.setHeader('x-pm-user', apiToken.getUser());
      // this.setHeader('x-pm-usertype', apiToken.getUserType());
      // this.setHeader('x-pm-timestamp', apiToken.getTimestamp());
      // this.setHeader('x-pm-token', apiToken.getToken());
      // }

      return new Headers(this.headers);
  }

  async execute() {
      this.params.headers = this.buildRequestHeaders();
      // console.info('request', {
      //   method: this.method,
      //   requestUrl: this.requestUrl,
      //   params: this.params
      // });

    return fetch(this.requestUrl, this.params);
      // try {
      // } catch (e) {
      //     throw e;
      // }


      // try {
      //     const response = await fetch(this.requestUrl, this.params);
      //     return await processResult(response);
      // } catch (e) {
      //     if (e.status === 401) {
      //         // window.parent.postMessage('BF.AUTHENTICATION_FAILED', '*');
      //     }
      //     if (e.status === 400) {
      //         return await processResponse(e);
      //     }
      //     // console.error('Request error', e);
      //     throw e;
      // }
  }
}
