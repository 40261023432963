import { Box, Checkbox, Link, Typography } from '@mui/material';
import CopyToClipboardValue from 'shared/_components/copy-to-clipboard-value';
import CloudImage from 'shared/_model/cloud/components/CloudImage';
import ShareFileIcon from 'shared/_model/cloud/components/ShareFileIcon';
import FileCloudIcon from 'shared/_model/project-files/FileCloudIcon';
import FileDriveIcon from 'shared/_model/project-files/FileDriveIcon';
import YoutubeFileIcon from 'shared/_model/publish/components/YoutubeFileIcon';
import { SharingType } from 'shared/_model/sharing/types';
import { useSharingId } from 'shared/_model/sharing/utils';
import { DocTypeFiles, DocType, TDocFile } from 'shared/constants/docs';
import { isFinishFile } from 'shared/constants/pouchDB';
import { ROUTE } from 'shared/constants/routes_old';
import { prettyDate, prettySize } from 'shared/utils/format';
import ActionFileMenu from 'shared/_model/project-files/ActionFileMenu';
import { useProjectContext } from 'shared/_pages/project/provider';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { IconEmpty } from 'shared/_ui/icon';
import {
	ProjectFileContainer,
	ProjectFileDate,
	ProjectFileDateSizeContainer,
	ProjectFileDescription,
	ProjectFileFinishIconsContainer,
	ProjectFileIconsContainer,
	ProjectFileImageContainer,
	ProjectFileSize,
	ProjectFileTitle,
} from 'shared/_model/project-files/project-file/styles';

type ProjectFileProps = {
	file: TDocFile;
};

function getFileLink(id: string, type: DocTypeFiles) {
	switch (type) {
		case DocType.FILE_FINISH_AUDIO:
			return ROUTE.audios.id(id);
		case DocType.FILE_FINISH_VIDEO:
			return ROUTE.videos.id(id);
		default:
			return ROUTE.files.id(id);
	}
}

export default function ProjectFile({ file }: ProjectFileProps) {
	const { _id: id, created, description, size, title } = file;
	const isFinish = isFinishFile(file.type);
	const isFinishVideo = file.type === DocType.FILE_FINISH_VIDEO;
	const { sharingId } = useSharingId();

	const { selectedFileIds, setSelectedFileIds } = useProjectContext();
	const handleSelectChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setSelectedFileIds(file._id, event.target.checked);
		},
		[file._id, setSelectedFileIds]
	);

	const fileLink = !sharingId ? getFileLink(id, file.type) : ROUTE.share.file.id(sharingId, id);
	const thumbnailCloud = file.thumbnail?.cloud;
	const fileImage = (file as any).image;
	const image = useMemo(() => {
		return thumbnailCloud ?? (fileImage ? { s3: fileImage } : undefined);
	}, [thumbnailCloud, fileImage]);

	return (
		<ProjectFileContainer isFinish={isFinish} hasDescription={!!description}>
			{isFinish && (
				<ProjectFileImageContainer>
					<CloudImage image={image} />
				</ProjectFileImageContainer>
			)}
			<ProjectFileTitle>
				{isFinish ? (
					<Link href={fileLink} noWrap display="block">
						{title}
					</Link>
				) : (
					<Typography noWrap display="block">
						{title}
					</Typography>
				)}
			</ProjectFileTitle>
			<ProjectFileDateSizeContainer>
				<ProjectFileDate>{prettyDate(created)}</ProjectFileDate>
				<ProjectFileSize>{prettySize(size || 0)}</ProjectFileSize>
			</ProjectFileDateSizeContainer>
			<CopyToClipboardValue
				value={id}
				sx={(theme) => ({
					gridArea: 'id',
					[theme.breakpoints.up('md')]: {
						marginRight: theme.spacing(4 * 2),
						textAlign: 'right',
					},
				})}
			/>
			{description && <ProjectFileDescription>{description}</ProjectFileDescription>}
			<ProjectFileIconsContainer>
				<Box display="flex" alignItems="center">
					<Box display="flex" gap={2} alignItems="center">
						<Checkbox checked={selectedFileIds.includes(file._id)} onChange={handleSelectChange} />
						<FileDriveIcon file={file} />
						<FileCloudIcon cloud={file.cloud} />
					</Box>
					<ActionFileMenu file={file} />
				</Box>
				<ProjectFileFinishIconsContainer isFinish={isFinish}>
					{isFinishVideo ? <YoutubeFileIcon youtube={file?.youtube} /> : <IconEmpty />}
					{isFinish ? (
						<ShareFileIcon sharing={file.sharing} type={SharingType.FILE} />
					) : (
						<IconEmpty />
					)}
				</ProjectFileFinishIconsContainer>
			</ProjectFileIconsContainer>
		</ProjectFileContainer>
	);
}
