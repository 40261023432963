import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { newDocument, TDocFile, DocType } from 'shared/constants/docs';
import { useProjectFiles } from 'shared/helpers/use-project-files';
import { TDocProject } from 'shared/model/project';
import { useUserContext } from 'shared/Providers/User';
import { useDB } from 'shared/Providers/UserDB';
import { formatDateApi } from 'shared/utils/format';
import { TSelectedFile } from 'shared/utils/files/files';
import { useWatchChangesProjectFiles } from 'shared/_model/sync/useWatchChanges';

type PartialFile = { _id: string } & Omit<Partial<TDocFile>, '_id'>;

type ProjectContextValue = {
	isLoading: boolean;
	isLoadingFiles: boolean;
	saveProject?: (values: Partial<TDocProject>) => Promise<void>;
	project: TDocProject;
	files: TDocFile[];
	readFiles: () => Promise<void>;
	/**
	 * @deprecated
	 * // TODO: Use new one
	 */
	addFiles?: (files: TSelectedFile[]) => Promise<void>;
	addFilesNew?: (files: TSelectedFile[], method: 'copy' | 'move') => Promise<void>;
	removeFile?: (fileIds: string[]) => Promise<string[]>;
	saveFile?: (values: PartialFile) => Promise<void>;
	selectedFileIds: string[];
	setSelectedFileIds: (ids?: string | string[], isSelect?: boolean) => void;
	stats: {
		filesVideo: number;
		filesAudio: number;
	};
};

const initialValue: ProjectContextValue = {
	isLoading: true,
	isLoadingFiles: true,
	saveProject: undefined,
	readFiles: async () => undefined,
	addFiles: undefined,
	addFilesNew: undefined,
	removeFile: undefined,
	saveFile: undefined,
	project: {} as TDocProject,
	files: [],
	selectedFileIds: [],
	setSelectedFileIds: () => undefined,
	stats: {
		filesVideo: 0,
		filesAudio: 0,
	},
};

const ProjectContext = createContext<ProjectContextValue>(initialValue);

export const useProjectContext = () => useContext(ProjectContext);

type ProjectProviderProps = PropsWithChildren & {
	projectId: string;
};

export default function ProjectProvider({ children, projectId }: ProjectProviderProps) {
	const {
		permissions: { canEditProject, canUseCloud },
	} = useUserContext();
	const { db, readDoc, writeDoc } = useDB();
	const [isLoadingProject, setIsLoadingProject] = useState(true);
	const [project, setProject] = useState<TDocProject>({} as TDocProject);
	const [isLoadingFiles, setIsLoadingFiles] = useState(true);
	const [files, setFiles] = useState<TDocFile[]>([]);
	const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

	const handleSelectFiles = useCallback(
		(ids?: string | string[], isSelect = true) => {
			setSelectedFileIds((oldIds: string[]) => {
				// Get recent list of id
				const fileIds = files.map((f) => f._id);

				// Filter out ids if files are already removed
				let newIds = oldIds.filter((id) => fileIds.includes(id));

				if (ids === undefined) {
					// Select/unselect all if no id have sent
					newIds = isSelect ? fileIds : [];
				} else if (isSelect) {
					// add ids to selected list
					newIds = newIds.concat(ids);
				} else {
					// remove ids from selected list
					newIds = newIds.filter((id) => !ids.includes(id));
				}

				return newIds;
			});
		},
		[files]
	);

	const { addFiles, addFilesNew, removeFile, findFiles } = useProjectFiles({ projectId });

	const readProject = useCallback(async () => {
		if (!db) {
			return;
		}
		setIsLoadingProject(true);

		const found = await readDoc<TDocProject>(projectId);

		if (found) {
			setProject(found);
			console.debug('Found project', found);
		} else {
			// Create new project
			setProject(
				newDocument(DocType.PROJECT, {
					created: formatDateApi(new Date()),
					title: '',
					description: '',
					storeInCloud: canUseCloud,
					storeOnLocalDrive: true,
				})
			);
			console.debug('New project created', found);
		}

		setIsLoadingProject(false);
	}, [db, readDoc, canUseCloud, projectId]);

	const saveProject = useCallback(
		async (values: Partial<TDocProject>) => {
			setIsLoadingProject(true);

			const updated = await writeDoc<TDocProject>({ ...values, _id: projectId });

			if (updated) {
				setProject(updated);
				console.debug('Project updated', updated);
			}

			setIsLoadingProject(false);
		},
		[writeDoc, projectId]
	);

	const readFiles = useCallback(async () => {
		setIsLoadingFiles(true);

		const found = await findFiles();
		setFiles(found);
		console.debug('Found project files', found);

		setIsLoadingFiles(false);
	}, [findFiles]);

	useWatchChangesProjectFiles({ projectId, readFiles });

	const handleRemoveFile = useCallback<Exclude<typeof removeFile, undefined>>(
		async (fileIds) => {
			const result = await removeFile?.(fileIds);
			// TODO: Re-read only removed file and just remove it from the list if success
			await readFiles();
			return result ?? [];
		},
		[readFiles, removeFile]
	);

	const handleAddFile = useCallback<Exclude<typeof addFiles, undefined>>(
		async (params) => {
			await addFiles?.(params);
			// TODO: Read only new added file
			await readFiles();
		},
		[addFiles, readFiles]
	);

	const handleAddFileNew = useCallback<Exclude<typeof addFilesNew, undefined>>(
		async (files, method) => {
			await addFilesNew?.(files, method);
			// TODO: Read only new added file
			await readFiles();
		},
		[addFilesNew, readFiles]
	);

	const handleSaveFile = useCallback(
		async (values: PartialFile) => {
			await writeDoc(values);
			// TODO: Read only modified files
			await readFiles();
		},
		[readFiles, writeDoc]
	);

	const init = useCallback(async () => {
		await readProject();
		await readFiles();
	}, [readProject, readFiles]);

	useEffect(() => {
		init();
	}, [init, projectId]);

	const value = useMemo<ProjectContextValue>(
		() => ({
			isLoading: isLoadingProject,
			isLoadingFiles,
			files,
			project,
			saveProject: canEditProject ? saveProject : undefined,
			readFiles,
			addFiles: addFiles ? handleAddFile : undefined,
			addFilesNew: addFiles ? handleAddFileNew : undefined,
			removeFile: removeFile ? handleRemoveFile : undefined,
			saveFile: canEditProject ? handleSaveFile : undefined,
			selectedFileIds,
			setSelectedFileIds: handleSelectFiles,
			stats: {
				filesVideo: files.filter((f) => f.type === 'file-video').length,
				filesAudio: files.filter((f) => f.type === 'file-final-audio').length,
			},
		}),
		[
			isLoadingProject,
			isLoadingFiles,
			files,
			project,
			canEditProject,
			saveProject,
			readFiles,
			addFiles,
			addFilesNew,
			handleAddFile,
			removeFile,
			handleRemoveFile,
			handleSaveFile,
			selectedFileIds,
			handleSelectFiles,
		]
	);

	return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
}
