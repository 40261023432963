import React from 'react';
import { SvgIcon } from '@mui/material';

export default function ShareIcon(props:Parameters<typeof SvgIcon>[0]): JSX.Element {
  return (
    <SvgIcon {...props}>
      <g strokeWidth=".47769">
        <path d="m16.012 12.526c-0.63247 0-1.2572 0.11905-1.8457 0.35742-0.12468 0.05207-0.19869 0.2579-0.04297 0.32812 1.2468 0.57514 2.1095 1.7466 2.4258 3.0684 0.2078 0.86319 0.13672 1.7827 0.13672 2.6641v1.0137c0 0.09602 0.08415 0.17969 0.17969 0.17969h2.9004c1.0629 0 2.1285 0.0076 3.1914 0 0.44856-0.0029 0.81437-0.2963 0.96484-0.71094 0.11152-0.31067 0.07189-0.67895 0.07031-1.0059 4.43e-4 -0.0059 2e-3 -0.01139 2e-3 -0.01758-0.0057-2.0794-1.0206-4.1694-2.8555-5.2246-0.75237-0.43279-1.6003-0.64948-2.4688-0.65234z"/>
        <path d="m17.748 5.9835c-0.80806 0.030951-1.5989 0.40126-2.123 1.041-0.76479 0.93341-0.87482 2.275-0.25 3.3145 0.62244 1.0318 1.8451 1.5677 3.0273 1.3398 1.1613-0.22308 2.1035-1.1963 2.2793-2.3652 0.02143-0.13773 0.0331-0.28003 0.03516-0.41992 0.0023-0.011625 0.0041-0.023824 0.0039-0.037109-0.007641-1.1976-0.75975-2.2793-1.8828-2.6992-0.35155-0.13196-0.72254-0.1879-1.0898-0.17383z"/>
        <path d="m6.9805 12.194c-0.5503 0-1.0971-0.01147-1.6445 0.06641-0.94344 0.13232-1.8557 0.48564-2.6387 1.0273-1.6046 1.1121-2.6164 2.9388-2.6914 4.8906-0.010509 0.2933-0.0039062 0.58456-0.0039062 0.875 0.0023885 0.45858 0.2535 0.89542 0.67578 1.0898 0.26464 0.12181 0.52867 0.11328 0.80859 0.11328h12.994c0.01528-3.08e-4 0.02974-3e-3 0.04492-0.0039h2e-3c0.50186-0.02942 0.94629-0.35967 1.0898-0.84961 0.08599-0.29283 0.04687-0.63221 0.04687-0.93555 0-0.92529-0.18776-1.8321-0.57422-2.6719-0.80352-1.7422-2.4322-3.051-4.3067-3.4551-0.60142-0.12946-1.2054-0.14648-1.8145-0.14648z"/>
        <path d="m7.9785 3.7452c-1.1732-0.040238-2.3511 0.46131-3.1113 1.3867-1.0242 1.2473-1.1744 3.0459-0.33984 4.4355 0.8374 1.3867 2.4843 2.1033 4.0684 1.7871 1.5401-0.30572 2.7831-1.5994 3.0215-3.1523 0.02893-0.18806 0.04612-0.37646 0.04883-0.56445 0.0031-0.015576 0.0041-0.032957 0.0039-0.050781-0.0081-1.6074-1.0266-3.0597-2.5352-3.6172-0.37451-0.13805-0.76516-0.2112-1.1562-0.22461z"/>
      </g>
    </SvgIcon>
  );
}
