import React, { useCallback, useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE } from '../../../constants/routes_old';
import { checkLogin } from '../../../modules/share/actions';
import SharedLogin from './SharedLogin';
import SharedLoggedApp from './SharedLoggedApp';

export default function SharedApp() {
	const { sharingId } = useParams();
	const dispatch = useDispatch();
	const isLoggedIn = useSelector((s) => s.share.isLoggedIn);

	const init = useCallback(async () => {
		await dispatch(checkLogin(sharingId));
	}, [dispatch, sharingId]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<main>
			<Route path={ROUTE.share.id()} component={isLoggedIn ? SharedLoggedApp : SharedLogin} />
		</main>
	);
}
