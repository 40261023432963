import { tokenAuthParams } from 'app/utils/api/constants'
import { useCallback } from 'react'
import { TToken } from 'shared/model/token-types'
import ApiError from './api-error'
import useApiSimple, { TApiError } from "./useApiSimple"


type TRTokenAuthSuccess = { token: string, ['refresh-token']: string }

type TRTokenAuth = TRTokenAuthSuccess | TApiError
type TDTokenAuth = { email: string, password: string };


const validate = (response?: TRTokenAuth): response is TRTokenAuthSuccess =>
  !!(response && ('token' in response) && response?.token && response?.['refresh-token']);

const convert = (response?: TRTokenAuth): TToken | undefined => {
  if (validate(response)) {
    const { token, 'refresh-token': refreshToken } = response;
    return { token, refreshToken };
  }
  return undefined;
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiTokenAuth(onError?: (error: ApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRTokenAuth, TToken, TDTokenAuth>(
    { slug: '/api/token-auth', method: 'post', lazy: true  },
    { validate, convert, onError }
  );

  const doTokenAuth = useCallback(
    async (email: string, password: string) => await doFetch({
      email,
      password,
      ...tokenAuthParams,
    }),
    [doFetch]
  );

  return {
    token: response,
    error,
    loading,
    doTokenAuth,
  };
}
