import { Badge, SxProps, Theme } from '@mui/material';
import { ComponentProps } from 'react';

type IconWithBadgeProps = ComponentProps<typeof Badge> & {
	icon: JSX.Element;
};

export default function IconWithBadge({ icon, ...props }: IconWithBadgeProps) {
	const { color: colorProp, sx = {} } = props;
	const badgeSx = sx?.['.MuiBadge-badge' as keyof typeof sx] || ({} as { [k: string]: unknown });

	const borderColor = badgeSx.color || (colorProp ? `${colorProp}.main` : undefined);

	const sxCompiled: SxProps<Theme> = [
		...(Array.isArray(sx) ? sx : [sx]),
		{ '.MuiBadge-badge': { borderColor, ...(badgeSx || {}) } },
	];

	return (
		<Badge
			variant="outlined"
			max={9}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			{...props}
			sx={sxCompiled}
		>
			{icon}
		</Badge>
	);
}

// Examples:
// <IconWithBadge icon={<IconLocalStorage />} badgeContent={123} />
// <IconWithBadge icon={<IconLocalStorage />} badgeContent={123} color="warning" />
// <IconWithBadge icon={<IconLocalStorage />} badgeContent={123} sx={{ '.MuiBadge-badge': { color: 'blue' } }} />
