import { getDefaultPosition, getDefaultTimeout, validateErrors, validatePosition, validateRatio, validateSource, validateTimeout } from './options'

type TGetFrameCanvasOptions = {
  position: number;
  timeout: number;
  ratio?: number;
}

function getOptions(options?: Partial<TGetFrameCanvasOptions>): TGetFrameCanvasOptions {
  const { ratio, position, timeout } = options ?? {};
  return {
    ratio,
    position: getDefaultPosition(position),
    timeout: getDefaultTimeout(timeout),
  };
}

function validate(videoSrc: string, options?: Partial<TGetFrameCanvasOptions>): TGetFrameCanvasOptions {
  const opts = getOptions(options);

  validateErrors([
    validateSource(videoSrc),
    validatePosition(opts.position),
    validateTimeout(opts.timeout),
    validateRatio(opts.ratio),
  ]);

  return opts;
}

export async function getFrameCanvas(videoSrc: string, options?: Partial<TGetFrameCanvasOptions>): Promise<HTMLCanvasElement|undefined> {
  const { ratio: ratioParam, position, timeout } = validate(videoSrc, options);

  const video = document.createElement('video');
  video.setAttribute('crossorigin', "anonymous");
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Unable to create context("2d")');
  }
  let w = 0, h = 0, ratio = 0;

  const tmt = setTimeout(() => {
    console.error(`Timeout exceeded getting frame from ${videoSrc}`);
  }, timeout);

  //add loadedmetadata which will helps to identify video attributes......
  video.addEventListener('loadedmetadata', () => {
    ratio = video.videoWidth / video.videoHeight;
    const targetRatio = ratioParam || video.videoWidth / video.videoHeight;

    w = video.videoWidth;
    h = video.videoHeight;

    if (targetRatio < ratio) {
      w = h * targetRatio;
    } else {
      h = w / targetRatio;
    }

    canvas.width = w;
    canvas.height = h;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Seek the video to 25%
    video.currentTime = video.duration * position;
  }, false);

  const clear = () => {
    video.remove();
    clearTimeout(tmt);
  }

  return new Promise<HTMLCanvasElement>((resolve) => {
    video.addEventListener("seeked", () => {
      // Draw image without resize but with crop
      context.drawImage(
        video,
        -(video.videoWidth - w) / 2,
        -(video.videoHeight - h) / 2,
        video.videoWidth,
        video.videoHeight
      );

      clear();
      resolve(canvas);
    });

    video.src = videoSrc;
  }).catch((e) => {
    console.error('Error frame canvas', e);
    clear();
    return undefined;
  });
}
