import { array, boolean, number, object, string } from 'yup'
import configs from './configs'
import { DOC_TYPE_USER } from './docs'
import { SERIES_TYPES } from './pouchDB'
import { SHARE_LINK_ACCESS_TYPES } from './share'

// ROUTE.account.pages = {
//     settings: `${ROUTE.account.root}/settings`,
//     test: `${ROUTE.account.root}/test`,
// };

export const CONTENT_TYPE = {
	PROJECT: 'project',
	SERIES: 'series',
	SEASON: 'season',
	EPISODE: 'episode',
	VIDEO: 'video',
	AUDIO: 'audio',
}

export const SHARE_LINK_SCHEMA = object().shape({
	id: string().default(''),
	documentId: string().default(''),
	type: string().default(''),
	enabled: boolean().required('Required').default(false),
	accessType: string().required('Required').default(SHARE_LINK_ACCESS_TYPES.password).min(1),
	pass: string().default('').required('1 char min').min(1),
	// .when('enabled', {
	//     is: true,
	//     then: fieldSchema => fieldSchema.required('1 char min').min(1),
	// }),
	canEdit: boolean().required('Required').default(false),
	canAddFiles: boolean().required('Required').default(false),
	canRemoveFiles: boolean().required('Required').default(false),
	expirationEnabled: boolean().required('Required').default(false),
	expirationDate: string().default(''),
})

const DOCUMENT_BASE_SCHEMA = {
	type: string().default(''),
	created: string().default(''),
	updated: string().default(''),
}

const KEYWORDS_TAG_MAX_LENGTH = 100
const KEYWORDS_SCHEMA = string()
	.default('')
	// eslint-disable-next-line no-template-curly-in-string
	.max(500, 'Maximum length is ${max}')
	// eslint-disable-next-line prefer-arrow-callback
	.test('tagMaxLength', 'error', function tagMaxLength(value) {
		if (value && typeof value === 'string') {
			const res = value.split(',').filter((val) => val && val.length > KEYWORDS_TAG_MAX_LENGTH)
			if (res.length) {
				const { path, createError } = this
				return createError({
					path,
					message: `Some of tags has length more then ${KEYWORDS_TAG_MAX_LENGTH}: ("${res.join(
						'", "'
					)}")`,
				})
			}
		}
		return true
	})

export const PROJECT_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	title: string().required('Required').min(1).max(40),
	description: string(),
	sponsored: boolean().default(false),
	sponsoredBy: string(),
	sponsoredSeries: boolean().default(false),
	invoiceNumber: string(),
	amount: number().default(0),
	storeOnLocalDrive: boolean().default(true),
	storeInCloud: boolean().default(false),
	// TODO: created field should be added to every doc automatically
	// created: string()
	// 	.default('')
	// 	.test('empty-check', 'Required', function emptyCheck(value) {
	// 		console.log('created value 0', value)
	// 		if (!value) {
	// 			console.log('created value 1', value)
	// 			const { path, createError } = this
	// 			return createError({
	// 				path,
	// 				message: `Required`,
	// 			})
	// 		}
	// 		return true
	// 	}),
	tags: array(string()),
	// files: array().default([]).of(object().shape({
	//     type: string(),
	//     id: string(),
	// })),
	// filesCount: object().default([]).of(object().shape({
	//     ...DOC_TYPES_FILES.reduce((acc, fileType) => ({
	//         ...acc,
	//         [fileType]: number().default(0)
	//     }), {}),
	// })),
	// ...EMPTY_FILE_ARRAYS
})

export const EPISODE_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	title: string().default('').required('Required').min(1),
	description: string().default(''),
	keywords: KEYWORDS_SCHEMA,
	number: number().default(0).required('Required'),
	seasonNumber: number().default(0),
	seriesId: string().default('').required('Required'),
	seasonId: string().default(''),
	videoId: string().default('').required('Required'),
})

console.warn('SERIES_TYPES', SERIES_TYPES)
export const SERIES_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	title: string().default('').required('Required').min(1).max(250),
	description: string().default('').required('Required'),
	keywords: KEYWORDS_SCHEMA,
	sponsored: boolean().default(false),
	seasons: boolean().default(false),
	seriesType: string()
		.default('video')
		.required()
		.oneOf(Object.keys(SERIES_TYPES), 'Choose Series type'),
	youtube: object().shape({
		categoryId: number(),
		channelId: string().default(''),
	}),
})

export const SEASON_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	title: string().default('').required('Required').min(1).max(250),
	description: string().default(''),
	keywords: KEYWORDS_SCHEMA,
	sponsored: boolean().default(false),
	number: number().default(0).required('Required'),
	seriesId: string().default('').required('Required'),
})

export const USER_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	_id: string().default(''),
	type: string().default(DOC_TYPE_USER),
	helpFlags: object().shape({
		openFirstProject: boolean().default(false),
		createFirstProject: boolean().default(false),
	}),
})

export const FILE_SCHEMA = object().shape({
	...DOCUMENT_BASE_SCHEMA,
	type: string(),
	title: string().required('Required').min(1).max(255),
	filename: string().default(''),
	description: string(),
	keywords: KEYWORDS_SCHEMA,
	// TODO: if meta is undefined validator does not show error but also does not allow to save file
	// meta: object().shape({
	//     type: string().default(''),
	//     lastModified: number().default(''),
	// }),
})
