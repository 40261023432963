import { useCallback } from 'react'
import { ROUTE } from 'shared/constants/routes_old'
import { frontEndLocation } from 'shared/constants/system'
import useApiSimple, { TApiError } from "./useApiSimple"


export type TMemberPermission = 'CanEditProject' | 'CanAddFiles' | 'CanRemoveFiles';

type TRRegisterNewMemberSuccess = { message: string }

type TRRegisterNewMember = TRRegisterNewMemberSuccess | TApiError
type TDRegisterNewMember = {
  confirmation_url: string;
  email: string;
  permissions?: TMemberPermission[];
};


const validate = (response?: TRRegisterNewMember): response is TRRegisterNewMemberSuccess =>
  !!(response && !('status' in response));

const convert = (response?: TRRegisterNewMember): boolean | undefined => {
  if (validate(response)) {
    return true;
  }
  return undefined;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiRegisterNewMember(onError?: (error: TApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRRegisterNewMember, boolean, TDRegisterNewMember>(
    { slug: '/api/register-new-member', method: 'post', lazy: true  },
    { validate, convert, onError }
  );

  const doRegisterNewMember = useCallback(
    (
      email: string,
      permissions: TMemberPermission[]
    ) => doFetch({
      confirmation_url: `${frontEndLocation}${ROUTE.applyResetPassword}`,
      email,
      permissions,
    }),
    [doFetch]
  );

  return {
    memberAdded: response,
    error,
    loading,
    doRegisterNewMember,
  };
}
