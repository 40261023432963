import React, { useContext, useMemo } from 'react';
import { UserContext } from 'shared/Providers/User';
import { isSharing } from 'shared/utils/webSettings';
import { ShareIcon } from 'shared/resources/svg/ShareIcon';
import { Button } from '@mui/material'
import { getShareProperty } from 'shared/_model/sharing/utils'
import { Sharing, SharingType } from 'shared/_model/sharing/types'


type TShareSettingsButtonProps = {
  type: SharingType
  sharing?: Sharing
  disabled?: boolean
  onlyIcon?: boolean
  onClick?: () => void
};


export const ShareSettingsButton: React.FC<TShareSettingsButtonProps> = (props) => {
  const {
    type,
    disabled = false,
    onlyIcon = false,
    onClick = () => undefined,
    sharing,
  } = props

  const { permissions: { canUseCloud } } = useContext(UserContext);

  const show = useMemo(() => {
    return canUseCloud && !isSharing()
  }, [canUseCloud])

  const color = useMemo(() => {
    if (!show) {
      return '#00a14b';
    }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return getShareProperty(sharing).filter(sh => sh.type === type).length ? '#00a14b' : '#d6d6d6';
  }, [sharing, type, show]);


  if (!show) {
    return <></>;
  }

  if (onlyIcon) {
    return <ShareIcon fill={color} />;
  }

  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={<ShareIcon fill={color} height={17} />}>
      Share
    </Button>
  )
}
