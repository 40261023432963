import React from 'react';
import MediaRecordApi from './MediaRecordApi';
import VideoFiles from './VideoFiles'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

export default function Experiments(): JSX.Element {

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h3">Video files</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VideoFiles />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h3">Media recorder</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MediaRecordApi />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
