import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import useMenu from 'shared/hooks/use-menu'
import ToggleActiveMenuItem from './ToggleActiveMenuItem'

type ManageUserMenuProps = {
  isActive: boolean;
  disabledToggleActive?: boolean;
  toggleActive: () => void;
}

export default function ManageUserMenu({ isActive, disabledToggleActive, toggleActive}: ManageUserMenuProps): JSX.Element {
  const { anchorEl, open, handleOpen, handleClose } = useMenu();

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <MoreVert fontSize="small"/>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ToggleActiveMenuItem
          isActive={isActive}
          disabled={disabledToggleActive}
          toggleActive={toggleActive}
          callback={handleClose}
        />
      </Menu>
    </>
  );
}
