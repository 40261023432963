import { useCallback } from 'react'
import { TToken } from 'shared/model/token-types'
import ApiError from './api-error'
import useApiSimple, { TApiError } from "./useApiSimple"


type TRTokenRefreshSuccess = { token: string, ['refresh-token']: string }

type TRTokenRefresh = TRTokenRefreshSuccess | TApiError
type TDTokenRefresh = { refresh_token: string };


const validate = (response?: TRTokenRefresh): response is TRTokenRefreshSuccess =>
  !!(response && ('token' in response) && response?.token && response?.['refresh-token']);

const convert = (response?: TRTokenRefresh): TToken | undefined => {
  if (validate(response)) {
    const { token, 'refresh-token': refreshToken } = response;
    return { token, refreshToken };
  }
  return undefined;
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiTokenRefresh(onError?: (error: ApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRTokenRefresh, TToken, TDTokenRefresh>(
    { slug: '/api/token-refresh', method: 'post', lazy: true  },
    { validate, convert, onError }
  );

  const doTokenRefresh = useCallback(
    (refreshToken: string) => doFetch({ refresh_token: refreshToken }),
    [doFetch]
  );

  return {
    token: response,
    error,
    loading,
    doTokenRefresh,
  };
}
