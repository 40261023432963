// https://codesandbox.io/s/mui-snack-stack-jnrnwq?file=/src/SnackStackProvider.tsx
import React, { useState, PropsWithChildren, useContext, createContext, useCallback } from 'react';

export type ToastProps = {
	/**
	 * Key to render multiple toasts.
	 * This is being set automatically unless specified manually.
	 */
	key?: number;
	/**
	 * Alert title
	 */
	title?: string;
	/**
	 * Alert message
	 */
	message?: string;
	/**
	 * Custom component or html-layout
	 */
	children?: React.ReactElement;
	/**
	 * Indicates when the alert will disappear in ms. Defaults to 5000.
	 * Pass 0 for infinite duration.
	 */
	duration?: number;
	/**
	 * Alert color
	 */
	severity?: 'success' | 'info' | 'warning' | 'error';
	/**
	 * Alert position on the screen
	 */
	position?: {
		vertical?: 'top' | 'bottom';
		horizontal?: 'left' | 'right' | 'center';
	};
	/**
	 * On Close callback
	 */
	onClose?: () => void;
};

export type SnackStackContextProps = {
	toastsPack: ToastProps[];
	setToastsPack: (toasts: ToastProps[]) => void;
	addToast: (toast: ToastProps) => number;
	removeToast: (key: ToastProps['key']) => void;
};

const SnackStackContext = createContext<SnackStackContextProps>({
	toastsPack: [],
	setToastsPack: () => {},
	addToast: () => -1,
	removeToast: () => {},
});

export default function SnackStackProvider({ children }: PropsWithChildren): JSX.Element {
	const [toastsPack, setToastsPack] = useState<ToastProps[]>([]);

	const addToast = useCallback((toast: ToastProps) => {
		const key = toast.key || Date.now();

		setToastsPack((prev) => {
			// Prevent duplicated toasts
			if (prev.find((item) => item.key === key)) {
				return prev;
			}

			return [{ ...toast, key }, ...(prev.length < 3 ? prev : prev.slice(0, -1))];
		});

		return key;
	}, []);

	const removeToast = useCallback((key: ToastProps['key']) => {
		setToastsPack((prev) => prev.filter((toast) => toast.key !== key));
	}, []);

	return (
		<SnackStackContext.Provider value={{ toastsPack, setToastsPack, addToast, removeToast }}>
			{children}
		</SnackStackContext.Provider>
	);
}

export const useSnackStack = () => useContext(SnackStackContext);
