import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { MouseEvent, PropsWithChildren, useMemo } from 'react';
import styles from 'shared/components/ButtonNew/button.module.scss';
import { Icon } from 'shared/components/Icon';
import { Link } from 'react-router-dom';
import cn from 'classnames';


type TCommonProps = {
  id?: string
  color?: 'primary' | 'secondary' | 'transparent' | 'none' | 'warning'
  disabled?: boolean
  className?: string
  size?: 'big' | 'tiny'
  noTextTransform?: boolean
  icon?: IconProp // TODO: should be `icon` or `iconImage`
  iconImage?: string
  iconComponent?: React.ReactNode
  iconPosition?: 'before' | 'after'
  title?: string
}

type TButtonProps = TCommonProps & {
  name?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void
}
type TButtonLinkProps = TCommonProps & {
  link: string
}
type TButtonHrefProps = TCommonProps & {
  href: string
  target?: '_blank' | '_self'
}


const useButton = (props: PropsWithChildren<Partial<TButtonLinkProps | TButtonProps>>) => {
  const {
    color,
    size,
    disabled,
    noTextTransform,
    children,
    icon,
    iconImage,
    iconComponent,
    className,
    iconPosition = 'before'
  } = props;

  const buttonClassName = useMemo(() => {
    return cn(
      styles.button,
      {
        [styles.secondary]: color === 'secondary',
        [styles.none]: color === 'none',
        [styles.transparent]: color === 'transparent',
        [styles.warning]: color === 'warning',
        [styles.big]: size === 'big',
        [styles.tiny]: size === 'tiny',
        [styles.disabled]: disabled,
        [styles.noTextTransform]: noTextTransform,
        [styles.iconBefore]: !!children && (!!icon || !!iconImage || !!iconComponent) && iconPosition === 'before',
        [styles.iconAfter]: !!children && (!!icon || !!iconImage || !!iconComponent) && iconPosition === 'after',
        [styles.onlyIcon]: !children && (!!icon || !!iconImage || !!iconComponent),
      },
      className,
    )
  }, [color, size, disabled, noTextTransform, children, icon, iconImage, iconPosition, iconComponent, className]);

  const iconElement = useMemo(() => {
    if (icon) {
      return <Icon icon={icon}  />//className={styles.icon} /> TODO: why 'styles.icon' here?
    }
    if (iconImage) {
      return <img src={iconImage} alt="icon" />
    }
    if (iconComponent) {
      return iconComponent
    }
    return undefined;
  }, [icon, iconImage, iconComponent]);

  const childrenElement = useMemo(() => {
    if (iconPosition === 'before') {
      return (
        <>
          {iconElement}
          {!!children && <span>{children}</span>}
        </>
      )
    }
    return (
      <>
        {!!children && <span>{children}</span>}
        {iconElement}
      </>
    )
  }, [iconPosition, iconElement, children]);


  return { buttonClassName, iconElement, childrenElement };
}


export const ButtonHref = (props: PropsWithChildren<TButtonHrefProps>): JSX.Element => {
  const {
    id,
    href,
    target = '_self',
    ...rest
  } = props;

  const { buttonClassName, childrenElement } = useButton(props);


  return (
    <a
      {...rest}
      className={buttonClassName}
      href={href}
      target={target}
      rel="noopener noreferrer"
      id={id}
    >
      {childrenElement}
    </a>
  );
}

export const ButtonLink = (props: PropsWithChildren<TButtonLinkProps>): JSX.Element => {
  const {
    id,
    link,
    ...rest
  } = props;

  const { buttonClassName, childrenElement } = useButton(props);


  return (
    <Link
      {...rest}
      className={buttonClassName}
      to={link}
      id={id}
    >
      {childrenElement}
    </Link>
  );
}

export const ButtonNew = (props: PropsWithChildren<TButtonProps>): JSX.Element => {
  const {
    type,
    id,
    name,
    disabled,
    onClick,
    icon,
    iconImage,
    iconComponent,
    iconPosition,
    noTextTransform,
    ...rest
  } = props;

  const { buttonClassName, childrenElement } = useButton(props);


  const handleClick = useMemo(() => {
    if (onClick) {
      return async (e?: MouseEvent<HTMLButtonElement>) => {
        if (e) {
          // Disable event bubbling, especially with a Form
          e.preventDefault();
          e.stopPropagation();
        }

        if (disabled) {
          return;
        }

        if (onClick) {
          await onClick(e);
        }
      }
    }
    return undefined
  }, [disabled, onClick]);

  return (
    <button
      {...rest}
      type={type}
      name={name}
      className={buttonClassName}
      onClick={handleClick}
      disabled={disabled}
      id={id}
    >
      {childrenElement}
    </button>
  );
}
