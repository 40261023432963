import React from 'react';

type TActionDeleteProjectFilesFromCloudProps = {
  disabled?: boolean;
  projectId: string;
  onChange?: () => Promise<void>;
};

const ActionDeleteProjectFilesFromCloud: React.FC<TActionDeleteProjectFilesFromCloudProps> = () => null

export default ActionDeleteProjectFilesFromCloud;
