import { matchPath } from 'react-router-dom'


type TParamsBase = Record<string, string | undefined>
export type TRoute<TParams extends TParamsBase = TParamsBase> = {
  (params?: TParams): string
  path: string
}

type TRouteMatchResult = {
  route: TRoute
  url: string
  params?: Record<string, string>
}


const route = <TParams extends TParamsBase = TParamsBase>(path = '/') => {
  const Route: TRoute<TParams> = function (params?: TParams): string {

    // generatePath("/user/:id/:entity(posts|comments)", {
    //   id: 1,
    //   entity: "posts"
    // });


    let result = ''
    const parts = path.split('/')
    parts.forEach(part => {
      if (part.startsWith(':')) {
        const isRequired = !part.endsWith('?')
        const key = isRequired ? part.slice(1) : part.slice(1, -1)
        const paramValue = (params ?? ({} as TParamsBase))[key]
        if (isRequired && paramValue === undefined) {
          console.error(
            'Missed parameter ',
            key,
            ' to build route "',
            path,
            '"',
          )
          return '/'
        }
        if (paramValue !== undefined) {
          result += `/${paramValue}`
        }
      } else if (part) {
        result += `/${part}`
      }
      return undefined;
    })
    return result || '/'
  }
  Route.path = path
  return Route
}


export const r = {
  experiments: route('/experiments'),
  home: route('/'),
  login: route('/login'),
  logout: route('/logout'),
  account: route('/account'),
  register: route('/register'),
  registerConfirmed: route('/register/confirmed'),
  registerSuccess: route('/register/success'),
  resetPassword: route('/resetPassword'),
  applyResetPassword: route('/applyResetPassword'),

  members: route('/members'),
  users: route('/users'),
  roles: route('/roles'),
  communications: route('/communications'),
  stats: route('/stats'),

  videos: route('/videos'),
  video: route<{ fileId?: string }>('/videos/:fileId'),
  audios: route('/audio'),
  audio: route<{ fileId?: string }>('/audio/:fileId'),
  shareRoot: route('/share/:sharingId'), // TODO: does we need this?
  share: ((path, type) => {
    return Object.assign(route<typeof type>(path), {
      project: route<typeof type>(`${path}/project`),
      file: route<typeof type & { fileId?: string }>('/file/:fileId'),
    });
  })('/share/:sharingId', {} as { sharingId?: string })
}

export const searchRoute = (location: string, parent: Record<string, TRoute> = r): TRouteMatchResult | undefined => {
  const inspectRoute = (route: TRoute): TRouteMatchResult | undefined => {
    const mp = matchPath(location, { path: route.path, exact: true });
    if (mp) {
      return { route, url: mp.url, params: mp.params };
    } else {
      for (const child of Object.values(route)) {
        if (typeof child === 'function') {
          return inspectRoute(child)
        }
      }
    }
    return undefined;
  }

  for (const route of Object.values(parent)) {
    // console.log(`route`, typeof route)
    const result = inspectRoute(route);
    if (result) {
      return result;
    }
  }

  return undefined;
}

export const mathRoute = (routeLocation: TRoute, route: TRoute, exact = false): boolean => {
  return !!matchPath(routeLocation.path, { path: route.path, exact });
}
