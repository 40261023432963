import cn from 'classnames';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { ButtonNew } from 'shared/components/ButtonNew';
import { Icon } from 'shared/components/Icon';
import { Search } from 'shared/components/Search';
import Spinner from 'shared/components/Spinner/Spinner';
import { User } from 'shared/components/User';
import { useFilter } from 'shared/helpers/use-filter';
import { useRoles } from 'shared/helpers/use-roles-hook';
import { useUsersManagement } from 'shared/helpers/use-users-management-hook';
import { PageContainer } from 'shared/Pages/common/PageContainer';
import styles from 'shared/Pages/common/UsersPermissions/users-permissions.module.scss';


type TRowProps = {
  isActive?: boolean
  userId: string
  name: string
  email: string
  roles: ReactNode[]
  setUser?: (userId: string) => void
}
const Row: React.FC<TRowProps> = (props) => {
  const {
    isActive = true,
    userId,
    name,
    email,
    roles,
    setUser
  } = props;

  return (
    <div
      className={cn(styles.row, { [styles.notActive]: !isActive })}
      onClick={() => setUser && setUser(userId)}
    >
      <div style={{ minWidth: '7em', width: '7em' }}>{userId}</div>
      <div className={styles.fields}>
        <div>{name}</div>
        <div>{email}</div>
      </div>
      <div className={styles.roles}>
        {roles && roles.map((r, ind) => <div key={ind}>{r}</div>)}
      </div>
    </div>
  );
}


export const UsersPermissions: React.FC = () => {
  const [showDeactivated, setShowDeactivated] = useState(false);
  const toggleShowDeactivated = useCallback(() => {
    setShowDeactivated(s => !s);
  }, []);

  const { users, readUser } = useUsersManagement();
  const { roles } = useRoles();

  const rolesList = useMemo(() => roles.map(({ name }) => name), [roles]);
  const rolesKeys = useMemo(() => roles.map(({ key }) => key), [roles]);

  const [showUserId, setShowUserId] = useState('');

  const { value, filter, loading, onChange, onBlur } = useFilter({ minLength: 0 });

  const list = useMemo(() => {
    return users
      .filter(user => {
        if (!showDeactivated && !user.is_active) {
          return false;
        }
        return (!filter || (
          user.user_id.includes(filter)
          || user.name.includes(filter)
          || user.email.includes(filter)
        ));
      })
  }, [users, showDeactivated, filter]);
  console.log('list', list);

  const handleRowClick = useCallback((userId: string) => {
    setShowUserId(s => s === userId ? '' : userId);
  }, []);


  return (
    <PageContainer title="Users">
      <div className={styles.filterPanel}>
        <Search
          inputProps={{
            placeholder: 'search',
            id: 'UserSearch',
            size: '',
            wrapperClassName: styles.search,
          }}
          loading={loading}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          containerClassName={styles.flex1}
        />
        <ButtonNew
          color='transparent'
          size='tiny'
          // noTextTransform
          onClick={toggleShowDeactivated}
        >
          {showDeactivated ? 'all users' : 'active users'}
        </ButtonNew>
      </div>
      <strong>
        <Row
          // userId="User Id"
          // name="Name"
          // email="Email"
          userId=" "
          name=" "
          email=" "
          roles={rolesList}
        />
      </strong>
      {users.length === 0 && (
        <div className={styles.spinner}>
          <Spinner big />
        </div>
      )}
      {list.map(user => (
        <Fragment key={user.user_id}>
          <Row
            userId={user.user_id}
            isActive={user.is_active}
            name={user.name}
            email={user.email}
            roles={rolesKeys.map((key) => user.roles?.find((r) => r.key === key) ? <Icon icon={faCheck} /> :
              <span>&nbsp;</span>)}
            setUser={handleRowClick}
          />
          {showUserId === user.user_id && <User id={user.user_id} callback={() => readUser(user.user_id)} />}
        </Fragment>
      ))}
    </PageContainer>
  );
}
