import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TDocFile } from 'shared/constants/docs';
import { DOC_TYPES_FILES } from 'shared/constants/pouchDB';
// import { writeUserDoc } from '../../handlers/userDB';
// import { getDownloadUrl } from '../../utils/cloud/cloud';
// import { asyncForEach, wait } from '../../utils/common';
// import { getVideoDuration } from '../../utils/media';
import { findDocs } from 'shared/utils/pouchDB/general';
import { UserDbContext } from 'shared/Providers/UserDB';


export type TUseWrongMediaDurationResult = {
  count: number
  update: () => void
  updating: boolean
  calculate: () => void
  calculating: number|undefined
  message: string
  busy: boolean
}

// TODO: Is it still need? Remove?
export const useWrongMediaDuration = (): TUseWrongMediaDurationResult => {
  const dispatch = useDispatch();

  const [count] = useState<number>(0);
  const [updating] = useState<boolean>(false);
  const [calculating] = useState<number|undefined>(undefined);
  // const [count, setCount] = useState<number>(0);
  // const [updating, setUpdating] = useState<boolean>(false);
  // const [calculating, setCalculating] = useState<number|undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { db } = useContext(UserDbContext);
  const findIds = useMemo(() => {
    if (!db) { return () => []}

    return async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const docs: TDocFile[] = await findDocs({
        startId: null,
        limit: null,
        sortBy: ['_id'],
        options: {
          selector: {
            $and: [
              { type: { $in: DOC_TYPES_FILES } },
            ]
          }
        },
      }, db);

      return docs.filter(({ meta }) => meta?.duration === undefined);
    }
  }, [db]);

  const update = useCallback(async () => {
    // setUpdating(true)
    //
    // const ids = await findIds();
    //
    // setCount(ids.length)
    // setUpdating(false)
  }, [findIds])

  useEffect(() => {
    // void update()
  }, [update]);


  const calculate = useCallback(async () => {
    // setCalculating(0)
    //
    // const docs = await findIds();
    //
    // await asyncForEach(docs, async ({ _id, cloud, meta }, ind) => {
    //   if (docs.length < 500 || ind % 50 === 0) {
    //     setCalculating(100 * ind/docs.length)
    //   }
    //
    //   const urlResponse = await getDownloadUrl(cloud);
    //   if (urlResponse && urlResponse.url) {
    //     await dispatch(writeUserDoc({
    //       _id,
    //       meta: {
    //         ...meta,
    //         duration: await getVideoDuration(urlResponse.url)
    //       },
    //     }))
    //   }
    // })
    // setCalculating(100);
    //
    // await wait(200);
    // setCalculating(undefined);
    //
    // await update();
  }, [findIds, dispatch, update])

  const message = useMemo(() => {
    if (updating) {
      return 'Checking ...'
    }
    if (calculating) {
      return `Calculating ${calculating ? `${Math.round(calculating)}%` : '...'}`
    }
    return count ? `Found ${count} media files with undefined duration` : '';
  }, [count, updating, calculating]);

  return {
    count,
    busy: updating || !!calculating,
    updating,
    update,
    calculate,
    calculating,
    message,
  };
}
