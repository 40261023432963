import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Switch, Route, useParams } from 'react-router-dom';
import { emitUserInfo, uppy } from '../../../constants/globals';
import { ROUTE } from '../../../constants/routes_old';
import { SHARE_TYPES } from '../../../constants/share';
import { FilePage } from '../../common/FilePage';
import { apiShareGetSettingsByToken } from '../../../modules/share/api';
import SharedProject from '../Project/SharedProject';

import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

export default function SharedLoggedApp() {
  const { sharingId } = useParams();

  const [settings, setSettings] = useState({});
  const [loaded, setLoaded] = useState(false);

  const init = useCallback(async () => {
    try {
      const result = await apiShareGetSettingsByToken();
      setSettings(result);
    } catch (e) {
      emitUserInfo(e.message, 'Error loading settings');
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    uppy.useStatusBar();
  }, []);

  useEffect(() => {
    // uppy.useStatusBar();
    init();
  }, [init]);

  if (!loaded) {
    return null;
  }

  if (!settings?.documentId) {
    return <>Error</>;
  }

  const typeProject = (settings.type === SHARE_TYPES.project);
  const typeFile = (settings.type === SHARE_TYPES.file);

  return (
    <Switch>
      {typeProject && (
        <Route
          path={ROUTE.share.project()}
          component={() => (
            <SharedProject
              settings={settings}
              sharingId={sharingId}
              history={history}
            />
          )}
        />
      )}
      <Route path={ROUTE.share.file.id()} component={FilePage} />
      {typeProject && <Redirect to={ROUTE.share.project(sharingId)} />}
      {typeFile && <Redirect to={ROUTE.share.file.id(sharingId, settings.documentId)} />}
    </Switch>
  );
}
