import { TToken } from 'shared/model/token-types'
import { TUser } from 'shared/model/user-types'

export const LOCAL_STORAGE_KEY_USER = 'user';
export const LOCAL_STORAGE_KEY_TOKEN = 'token';

type TKey =
  typeof LOCAL_STORAGE_KEY_USER
  | typeof LOCAL_STORAGE_KEY_TOKEN;
type TValue = TToken | TUser;

export function getLocalStorageItem(key: typeof LOCAL_STORAGE_KEY_TOKEN): TToken | undefined;
export function getLocalStorageItem(key: typeof LOCAL_STORAGE_KEY_USER): TUser | undefined;
export function getLocalStorageItem(key: TKey): TValue | undefined {
  const value = localStorage.getItem(key)
  if (value) {
    try {
      return JSON.parse(value) as TValue;
    } catch (e) {
      console.error(`Parsing local storage "${key}"`, `${e}`)
    }
  }
  return undefined
}


export function setLocalStorageItem(key: typeof LOCAL_STORAGE_KEY_TOKEN, value?: TToken): void;
export function setLocalStorageItem(key: typeof LOCAL_STORAGE_KEY_USER, value?: TUser): void;
export function setLocalStorageItem(key: TKey, value?: TValue): void {
  if (value === null || value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
