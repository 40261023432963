import { useCallback } from 'react'
import { TApiUser } from 'shared/helpers/types-api'
import ApiError from './api-error'
import useApiSimple, { TApiError } from "./useApiSimple"


type TRGetUserPropertiesSuccess = TApiUser;

type TRGetUserProperties = TRGetUserPropertiesSuccess | TApiError


const validate = (response?: TRGetUserProperties): response is TRGetUserPropertiesSuccess => !!(response && ('email' in response) && !!response.email);

const convert = (response?: TRGetUserProperties) => validate(response) ? response : undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiGetUserProperties(onError?: (error: ApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRGetUserProperties, TRGetUserPropertiesSuccess>(
    { slug: '/api/get-user-properties', lazy: true },
    { validate, convert, onError },
  );

  const doGetUserProperties = useCallback(
    (userId: string) => doFetch(undefined, { user_id: userId }),
    [doFetch]
  );

  return {
    userProperties: response,
    error,
    loading,
    doGetUserProperties,
  };
}
