import { YouTube } from '@mui/icons-material'
import { Link } from '@mui/material'
import React, { useContext } from 'react';
import { UserContext } from 'shared/Providers/User'

type WrapperProps = {
  youtubeId?: string;
  children: JSX.Element;
};

function Wrapper({ children, youtubeId }: WrapperProps): JSX.Element {
  if (!youtubeId) {
    return children;
  };

  return (
    <Link href={`https://youtu.be/${youtubeId}`} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
}

type DriveStatusIconProps = Parameters<typeof YouTube>[0] & { youtubeId?: string };

export default function YoutubeStatusIcon({ youtubeId, fontSize = 'large' }: DriveStatusIconProps): JSX.Element | null {
  const { permissions: { canPublishMedia } } = useContext(UserContext);

  if (!canPublishMedia) {
    return null
  }

  return (
    <Wrapper youtubeId={youtubeId}>
      <YouTube
        fontSize={fontSize}
        color={!youtubeId ? 'disabled' : undefined}
        titleAccess={youtubeId}
        sx={{ ...(youtubeId ? { color: 'red' } : {}) }}
      />
    </Wrapper>
  );
}
