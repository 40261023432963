import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import { useCallback, useEffect, useRef, useState } from 'react'
import { IconCopy } from 'shared/_ui/icon'

type CopyToClipboardButtonProps = IconButtonProps & {
  value: string
  onCopy?: () => void
  disabled?: boolean
}

export default function CopyToClipboardButton({
  value,
  onCopy,
  disabled,
  ...props
}: CopyToClipboardButtonProps) {
  const [open, setOpen] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleClick = useCallback(() => {
    // TODO: Use native Electron function https://www.electronjs.org/docs/latest/api/clipboard
    copy(value)
    setOpen(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setOpen(false)
    }, 1500)
    onCopy?.()
  }, [value, onCopy])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Tooltip
      PopperProps={{ disablePortal: true }}
      placement="top"
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="copied">
      <IconButton
        size="inherit"
        {...props}
        onClick={handleClick}
        disabled={disabled}>
        <IconCopy />
      </IconButton>
    </Tooltip>
  )
}
