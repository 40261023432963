import { TDocFile } from 'shared/constants/docs'

export type DocProviderStateType = 'loading' | 'loaded' | 'updating';

export interface ICurrentFileReducerState {
  status: DocProviderStateType;
  file: TDocFile;
  isNew: boolean;
  path?: string;
}

export default function currentFileReducer(state: ICurrentFileReducerState, action: Partial<ICurrentFileReducerState>): ICurrentFileReducerState {
  if (Object.keys(action)) {
    const file = action?.file ?? state.file;
    return {
      ...state,
      ...action,
      isNew: !file._rev,
    };
  }
  return state
}
