import { newDocument } from '../../constants/docs';
import { getVideoDuration } from '../media';


/**
 * Returned new file document
 * @param {string} type Type of the document
 * @param {File|FileFS|{name:string,size:number,type:string}} file Chosen file or founded FileFS
 * @param {string} projectId Project Id
 * */
export const getNewDocumentFile = async (type, file, projectId) => {
  console.group('new file:')
  console.log('title: ', file.name)
  console.log('filename: ', file.name)
  console.log('size: ', file.size)
  console.log('systemType: ', file.systemType ?? 'file')
  console.groupEnd()
  return newDocument(type, {
    title: file.name,
    filename: file.name,
    size: file.size,
    systemType: file.systemType ?? 'file',

    meta: {
      type: file.type,
      lastModified: file.lastModified,
      duration: ((file.systemType ?? 'file') !== 'file') ? 0 : await getVideoDuration(file),
    },
    projectId
  });
};


export const addFilter = (listOptions, key, value, rule = (Array.isArray(value) ? '$in' : '$eq')) => ({
    ...listOptions,
    options: {
        ...listOptions.options,
        selector: {
            $and: [
                ...listOptions.options.selector.$and.filter(item => Object.keys(item)[0] !== key),
                { [key]: { [rule]: value } }
            ]
        }
    }
});
export const addFilters = (listOptions, filters) => filters
    .reduce((acc, { key, value, rule }) => addFilter(acc, key, value, rule), listOptions);
