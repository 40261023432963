import { useCallback } from 'react'
import { TDocMinimum } from 'shared/constants/docs'
import { TDocument } from 'shared/model/doc-base'
import { removeReservedFields } from 'shared/handlers/userDB'
import useError from '../../helpers/use-error'

type TDB = PouchDB.Database<TDocument>
type TDBError = PouchDB.Core.Error
type TAllDocsOptions = Omit<
  PouchDB.Core.AllDocsOptions,
  'keys' | 'include_docs'
>

const ERROR_NOT_FOUND = 'not_found'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDbActions(db: TDB | undefined) {
  const { showError } = useError('DB')

  const isDbDefined = useCallback(
    (database?: TDB): database is TDB => {
      if (database) {
        return true
      }
      showError('Database is not connected')
      return false
    },
    [showError]
  )

  const handleDbError = useCallback(
    (e: unknown): void => {
      if (typeof e === 'object' && (e as TDBError)?.name !== ERROR_NOT_FOUND) {
        showError(e)
      }
    },
    [showError]
  )

  const close = useCallback(async () => {
    if (isDbDefined(db)) {
      try {
        await db.close()
      } catch (e) {
        showError(e)
      }
      // dispatch(actionCloseLocalDB());
    }
  }, [db, showError, isDbDefined])

  const readDoc = useCallback(
    async <T extends TDocument>(id: string) => {
      if (isDbDefined(db)) {
        try {
          const doc = await db.get<T>(id)
          console.debug('tst: readDoc', doc)
          return doc
        } catch (e) {
          handleDbError(e)
        }
      }
      return undefined
    },
    [db, isDbDefined, handleDbError]
  )

  const readDocsPaged = useCallback(
    async <T extends TDocument>(ids: string[], options?: TAllDocsOptions) => {
      if (isDbDefined(db)) {
        try {
          const docs = await db.allDocs<T>({
            ...options,
            keys: ids,
            include_docs: true,
          })
          console.debug('tst: readDocsPaged', docs)
          return docs
        } catch (e) {
          handleDbError(e)
        }
      }
      return undefined
    },
    [db, isDbDefined, handleDbError]
  )

  const readDocs = useCallback(
    async <T extends TDocument>(ids: string[], options?: TAllDocsOptions) => {
      const paged = await readDocsPaged<T>(ids, options)
      return paged?.rows
        ?.map(({ doc }) => doc)
        ?.filter(
          (
            doc
          ): doc is PouchDB.Core.ExistingDocument<
            TDocument & T & PouchDB.Core.AllDocsMeta
          > => !!doc
        )
    },
    [readDocsPaged]
  )

  const writeDoc = useCallback(
    async <T extends TDocument>(
      doc: TDocMinimum<T>,
      callback?: (id?: string) => Promise<void>
    ) => {
      if (isDbDefined(db)) {
        const found = await readDoc<T>(doc._id)

        const currentDate = new Date().toISOString()

        const response = await db.put<T>(
          removeReservedFields({
            ...found,
            ...doc,
            updated: currentDate,
            created: found?.created ?? doc.created ?? currentDate,
            createdBy: found?.createdBy ?? doc.createdBy,
          } as Exclude<typeof found, undefined>)
        )

        if (!response?.ok) {
          handleDbError(
            `Could not put document\n${JSON.stringify(
              doc
            )}\nResponse:\n${JSON.stringify(response)}`
          )
        }

        if (callback) {
          await callback(response.id)
          return undefined
        }

        return readDoc<T>(response.id)
      }
      return undefined
    },
    [readDoc, db, readDoc, handleDbError]
  )

  const writeDocs = useCallback(
    async <T extends TDocument>(
      docs: T[],
      callback?: (ids?: string[]) => Promise<void>
    ) => {
      if (isDbDefined(db)) {
        const currentDate = new Date().toISOString()
        const updatedDocs = docs.map((doc) =>
          removeReservedFields({
            ...doc,
            updated: currentDate,
            created: doc.created ?? currentDate,
          })
        )
        const results = await db.bulkDocs<T>(updatedDocs)

        if (!results) {
          handleDbError('Error while writing in DB. Response is empty')
          return undefined
        }

        const successIds = results
          .map((res) => {
            if ('ok' in res && res.ok) {
              return res.id
            }
            handleDbError(
              `Error while writing in DB. Response:\n${JSON.stringify(res)}`
            )
            return undefined
          })
          .filter((id): id is string => !!id)

        if (successIds.length) {
          if (callback) {
            callback(successIds)
            return undefined
          }

          return readDocs<T>(successIds)
        }
      }
      return undefined
    },
    [isDbDefined, db, handleDbError, readDocs]
  )

  const removeDoc = useCallback(
    async (id: string, callback?: (id?: string) => Promise<void>) => {
      if (isDbDefined(db)) {
        const foundDoc = await readDoc(id)
        if (foundDoc?._id) {
          const response = await db.remove(foundDoc)
          if (!response || response.ok !== true) {
            handleDbError(
              `Error while removing document with id "${id}"\nResponse:\n${JSON.stringify(
                response
              )}`
            )
            return false
          }
          if (callback) {
            callback(id)
          }
        }
        return true
      }
      return false
    },
    [isDbDefined, db, readDoc, handleDbError]
  )

  const removeDocs = useCallback(
    async (ids: string[], callback?: (ids?: string[]) => Promise<void>) => {
      if (isDbDefined(db)) {
        if (ids.length) {
          const docs = await readDocs(ids)
          if (!docs?.length) {
            handleDbError("Didn't find any documents to delete")
            return false
          }

          if (docs.length !== ids.length) {
            const foundIds = docs.map((doc) => doc._id);
            handleDbError(
              `Cannot find several ids in DB: ${ids
                .filter((id) => !foundIds.includes(id))
                .join(', ')}`
            );
          }

          await writeDocs(
            docs.map((doc) => ({ ...doc, _deleted: true })),
            callback
          );
        }
        return true;
      }
      return false;
    },
    [isDbDefined, db, readDocs]
  );

  return {
    close,
    readDoc,
    readDocs,
    readDocsPaged,
    writeDoc,
    writeDocs,
    removeDoc,
    removeDocs,
  };
}
