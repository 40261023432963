import React from 'react';
import { TSelectedFile } from 'shared/utils/files/files.web'


type TProjectCreateImportProps = {
  addFiles: (newFiles: TSelectedFile[]) => void
  setMinimumDate: (date: Date) => void
}

const ProjectCreateImport: React.FC<TProjectCreateImportProps> = () => <></>;

export default ProjectCreateImport;
