import { Field } from 'formik';
import React from 'react';
import Input from 'shared/components/Input/Input';
import { useEditDocumentContext } from 'shared/hooks/use-edit-document'
import styles from 'shared/Pages/common/FilePage/FinishVideo/Pages/FileNotes/file-notes.module.scss';
import { useCurrentFile } from 'shared/Providers/CurrentFile'


export default function FileNotes(): JSX.Element {
  const { saveFile } = useCurrentFile()
  const { isEdit, toggleEdit } = useEditDocumentContext();

  return (
    <div className={styles.fields}>
      <Field name="notes"
        type="textarea"
        component={Input}
        noValidateIcon
        label="Notes:"
        labelWidth={Input.LABEL_WIDTH.FULL}
        placeholder="Add notes about your file"
        spellCheck
        readOnly={!isEdit}
        editClick={!!saveFile && toggleEdit}
        spaceBottom={Input.SPACE_BOTTOM.SMALL}
        className={styles.notes} />
    </div>
  )
}
