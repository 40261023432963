import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router';

interface ISharingContext {
	sharingId: string;
}

export const SharingContext = createContext<ISharingContext>({ sharingId: '' });

export default function SharingProvider({ children }: PropsWithChildren): JSX.Element {
	const { sharingId } = useParams<{ sharingId?: string }>();
	const rm = useRouteMatch();
	console.log('tst: sharingId', { sharingId, rm });

	const value = useMemo(
		() => ({
			sharingId: sharingId || '',
		}),
		[sharingId]
	);

	return <SharingContext.Provider value={value}>{children}</SharingContext.Provider>;
}

export const useSharingContext = (): ISharingContext => useContext(SharingContext);
