import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { uppy } from '../../constants/globals';
import Button from '../Button/Button';

import styles from './Input.module.css';

const isFileTypeAllowed = (file, allowedFileTypes) => {
    if (!allowedFileTypes) {
        return true;
    }
    const escapedString = allowedFileTypes.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    return new RegExp(`(${escapedString.replace(/,/g, '|')})$`).test(file.name.toLowerCase());
};

const isFileSizeOk = (file, maxFileSize) => {
    if (!maxFileSize) {
        return true;
    }
    return file.size <= maxFileSize;
};


export default function FileUploader({
  fileTypes = '.png,.jpg,.jpeg,.gif',
  hideDragAndDropHelpMessage = false,
  maxFileSize = 10485760,
  showPreview = false,
  previewCallback = null,
  form,
  name,
  className,
  icon,
  message,
  uploadSuccess,
}) {
  // const [isDragOver, setIsDragOver] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [previewSource, setPreviewSource] = useState('');

  const setError = useCallback((error) => {
    form.setFieldTouched(name, true);
    window.setTimeout(() => form.setFieldError(name, error));
  }, [form, name]);

  const uploadFile = useCallback((file) => {
    setUploading(true);
    setUploadProgress(0);

    file.isRawUpload = true;

    uppy.addFile({
        name: file.name, // TODO: Fix aws key
        type: file.type,
        data: file,
    });
    uppy.upload().then((result) => {
      setUploading(false);
      setUploaded(true);
      setUploadProgress(100);

      const fieldValue = {
        key: result.successful[0].s3Multipart.key,
        uploaded: new Date().toISOString()
      };

      form.setFieldValue(name, fieldValue);
      setError('');

      console.log(result.successful[0].s3Multipart.key);

      if (uploadSuccess) {
        uploadSuccess(fieldValue);
      }

      return result;
    }).catch(() => {
      setUploading(false);
    });
  }, [form, name, uploadSuccess, setError]);

  const previewFile = useCallback((file) => {
    setUploading(true);

    const reader = new FileReader();
    reader.onload = (evt) => {
      setUploading(false);
      if (previewCallback) {
        previewCallback(evt.target.result, () => uploadFile(file));
      } else {
        setUploaded(true);
        setPreviewSource(evt.target.result);
      }
    };
    reader.readAsDataURL(file);
  }, [previewCallback, uploadFile]);

  const onChange = useCallback((e) => {
    const file = e.target.files[0];

    if (!isFileTypeAllowed(file, fileTypes)) {
      setError('Sorry, this file type is not allowed, please try again with a correct format.');
      return false;
    }

    if (!isFileSizeOk(file, maxFileSize)) {
      setError('Sorry, this file is too big, please try again with a smaller file.');
      return false;
    }

    if (showPreview) {
      previewFile(file);
    } else {
      uploadFile(file);
    }

    return true;
  }, [fileTypes, maxFileSize, showPreview, setError, previewFile, uploadFile]);

  return (
      <div className={cn(styles.fileUploader, className)}>
          {!!icon && (
              <p>
                  <img
                      src={icon}
                      alt={`File names ending in ${fileTypes}`}
                  />
              </p>
          )}
          { !uploaded && !!message && <p>{message}</p> }
          <p>
              {!hideDragAndDropHelpMessage
                  && <span>You can drag and drop the file from your computer to here.</span>
              }
              {/* { isDragOver && <span>Now drop it!</span> } */}
          </p>
          { uploading && (uploadProgress <= 100) && <p>Saving...</p> }
          { uploaded && (
              <div id="successElement">
                  {
                      !previewSource && (
                          <p>Your file has been saved.</p>
                      )
                  }
                  {
                      !!previewSource && (
                          <img
                              alt="Preview"
                              className={styles.fileUploaderButton}
                              src={previewSource}
                          />
                      )
                  }
              </div>
          )}
          {!uploading && (
              <Button
                  small
                  color={Button.COLOR.PRIMARY}
                  className={styles.fileUploaderButton}
              >
                  Browse
              </Button>
          )}
          {!uploading && (
              <input
                  type="file"
                  onChange={onChange}
              />
          )}
      </div>
  );
}

FileUploader.defaultProps = {
    fileTypes: '.png,.jpg,.jpeg,.gif',
    hideDragAndDropHelpMessage: false,
    maxFileSize: 10485760,
    showPreview: false,
    previewCallback: null,
};

FileUploader.propTypes = {
    className: PropTypes.string,
    fileTypes: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.any,
    hideDragAndDropHelpMessage: PropTypes.bool,
    maxFileSize: PropTypes.number,
    showPreview: PropTypes.bool,
    previewCallback: PropTypes.func,
    uploadSuccess: PropTypes.func,
    form: PropTypes.object.isRequired,
    name: PropTypes.string,
};
