import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { r } from 'shared/constants/routes';
import { actionAuthError } from 'shared/modules/general';
import { actionSetToken } from 'shared/modules/user/userActions';
import useApiTokenRefresh from 'shared/utils/api/use-api-token-refresh';
import { UserContext } from '../User';
import { getExpireIn } from './session-utils';

export const SessionContext = createContext({});

export default function SessionProvider({ children }: PropsWithChildren): JSX.Element {
	const dispatch = useDispatch(); // TODO: old logic. remove.
	const matchShare = useRouteMatch<{ sharingId: string }>(r.share.path); // TODO: check share session
	useEffect(() => {
		const sharingId = matchShare?.params?.sharingId;
		console.log('tst: sharingId', sharingId);
		// setSharingId();
	}, [matchShare?.params?.sharingId]);
	// const { params: { sharingId }} = matchShare ?? {};

	const { token, setToken, logout } = useContext(UserContext);
	const { loading, error, doTokenRefresh } = useApiTokenRefresh();

	// Logout if there is an error
	useEffect(() => {
		console.log('tst: SessionProvider error', error);
		if (error) {
			logout();
		}
	}, [error, logout]);

	// TODO: remove. just debug
	useEffect(() => {
		console.debug('tst: SessionProvider loading', loading);
	}, [loading]);

	// Refresh token
	const refreshToken = useCallback(
		async (refreshToken: string) => {
			const tkn = await doTokenRefresh(refreshToken);
			if (tkn) {
				setToken(tkn);
				if (tkn) {
					dispatch(actionSetToken(tkn.token, tkn.refreshToken));
				} else {
					dispatch(actionAuthError(tkn));
				}
			}
		},
		[doTokenRefresh]
	);

	// Set refresh token interval
	useEffect(() => {
		// Refresh token every periodically
		if (token?.refreshToken) {
			console.log('tst: session effect start');
			const timeout = setTimeout(() => {
				refreshToken(token?.refreshToken);
			}, getExpireIn(token?.token));

			return () => {
				console.log('tst: session effect close');
				clearTimeout(timeout);
			};
		}
		return undefined;
	}, [refreshToken, token?.refreshToken]);

	// //  Token map
	// const [tokens, setTokens] = useState<Record<string, TTokens>>({});
	// const [logging, setLogging] = useState(true);
	// const [shareId, setShareId] = useState<string|undefined>();

	// const saveTokens = useCallback((userId, token, refreshToken) => {
	//   if (userId) {
	//     setTokens((tokens) => ({
	//       ...tokens,
	//       [userId]: { token, refreshToken },
	//     }));
	//   }
	// }, []);

	// const { logout, user, setUser } = useContext(UserContext);
	// const mainInterval = useRef();
	// const finalInterval = useRef();
	// const [open, setOpen] = useState(false);
	// const [seconds, setSeconds] = useState(0);

	// const refresh = useCallback(async () => {
	// 	try {
	// 		const { data } = await httpRequests.post(`/api/session/refresh`, {
	// 			refreshToken: user?.token?.refreshToken,
	// 		});
	// 		setUser(data);
	// 		setOpen(false);
	// 	} catch (e) {
	// 		console.error('REFRESHING', e.message);
	// 	}
	// }, [setUser, user?.token?.refreshToken]);

	// // Check if session is about to expire (few minutes left)
	// const finalIntervalCallback = useCallback(() => {
	// 	const left = (user?.token.expiresAt - Date.now()) / 1000; // in seconds
	// 	setSeconds(left);
	// 	if (left <= 0) {
	// 		if (finalInterval.current) {
	// 			clearInterval(finalInterval.current);
	// 			finalInterval.current = undefined;
	// 		}
	// 		logout();
	// 		setOpen(false);
	// 	}
	// }, [logout, user?.token.expiresAt]);

	// // Check how min left and start final countdown
	// const mainIntervalCallback = useCallback(() => {
	// 	const left = (user?.token.expiresAt - Date.now()) / 1000; // in seconds

	// 	// Start more accurate timer if few minutes left
	// 	if (left < FEW_MINUTES) {
	// 		setSeconds(left);
	// 		setOpen(true);
	// 		if (mainInterval.current) {
	// 			clearInterval(mainInterval.current);
	// 			mainInterval.current = undefined;
	// 		}
	// 		finalInterval.current = setInterval(finalIntervalCallback, ACCURATE_INTERVAL);
	// 	}
	// }, [finalIntervalCallback, user?.token.expiresAt]);

	// useEffect(() => {
	// 	// Start counting after receiving new token
	// 	mainInterval.current = setInterval(mainIntervalCallback, MAIN_INTERVAL);

	// 	// Clear intervals on component dismount
	// 	return () => {
	// 		if (mainInterval.current) {
	// 			clearInterval(mainInterval.current);
	// 			mainInterval.current = undefined;
	// 		}
	// 		if (finalInterval.current) {
	// 			clearInterval(finalInterval.current);
	// 			finalInterval.current = undefined;
	// 		}
	// 	};
	// }, [mainIntervalCallback]);

	return (
		<SessionContext.Provider value={{}}>
			{children}
			{/* {open && <RefreshTokenDialog handleLogout={logout} handleRefresh={refresh} seconds={seconds} />} */}
		</SessionContext.Provider>
	);
}
