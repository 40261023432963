import { boolean, object, string } from 'yup'
import { DOC_BASE_SCHEMA, TDocument } from './doc-base'

export const DOC_TYPE_USER = 'user';

export type TDocUser = TDocument & {
  type: typeof DOC_TYPE_USER;
  databaseId: string;
  name: string;
  email: string;
  // phone?: string;
  settings: {
    filesCopyMethod?: 'copy' | 'move';
  };
  helpFlags: {
    openFirstProject?: boolean;
    createFirstProject?: boolean;
  };
}

// export const USER_SCHEMA = object(DOC_BASE_SCHEMA.fields).shape({
export const USER_SCHEMA = object({
  ...DOC_BASE_SCHEMA.fields,
  type: string<typeof DOC_TYPE_USER>().required().equals([DOC_TYPE_USER]).default(DOC_TYPE_USER),
  databaseId: string().required().default(''),
  name: string().required().default('I am new user'),
  email: string().email('Must be a valid email').required('Email is requires'),
  // phone: string(),
  helpFlags: object({
    openFirstProject: boolean().required().default(false),
    createFirstProject: boolean().required().default(false),
  }).required(),
  settings: object({
    filesCopyMethod: string<'copy'|'move'|undefined>().equals(['copy', 'move']).default(undefined),
  }).required(),
}).required();

console.log('tst: yup: USER_SCHEMA', USER_SCHEMA.default());

export function getDocUserNewId(userId: string): string {
  return userId;
}

export function getEmptyUser(userId?: string): TDocUser {
  const user = USER_SCHEMA.default();
  if (!userId) {
    return user;
  }

  return {
    ...user,
    _id: getDocUserNewId(userId),
  };
}
