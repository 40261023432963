import { TDocument } from './doc-base'
import { DOC_TYPE_USER, getEmptyUser, TDocUser } from './user'

export type TDoc = TDocUser;
export type TDocType = typeof DOC_TYPE_USER;

// export function getEmptyDoc(type: typeof DOC_TYPE_USER, userId: string): TDocUser;
export function getEmptyDoc<T extends TDocument>(type: TDocType, userId: string): T {
  switch (type) {
    case DOC_TYPE_USER:
      return <unknown>getEmptyUser(userId) as T; // TODO: how to convert to T type properly?
    // default: break;
  }
}
