import { FormikHelpers } from 'formik';
import {
	useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	DOC_TYPE_FILE_MEDIA,
	DOC_TYPE_PROJECT,
	newDocument,
	TDocProject,
	TDocumentTypeFile,
} from 'shared/constants/docs';
import { ROUTE } from 'shared/constants/routes_old';
import { useDialogCopyMoveFiles } from 'shared/helpers/use-dialog-copy-move-files';
import { useProjectFilesApi } from 'shared/helpers/use-project-files-api';
import { insertProject } from 'shared/modules/projects/actions';
import { UserContext } from 'shared/Providers/User';
import { useUserData } from 'shared/Providers/UserData';
import { TSelectedFile } from 'shared/utils/files/files';
import { formatDateApi } from 'shared/utils/format';

type TUseProjectCreateResult = {
  canUseCloud: boolean
  showCreatedDate: boolean
  project: TDocProject
  files: TSelectedFile[]
  handleSubmit: (values: TDocProject, actions: FormikHelpers<TDocProject>) => Promise<void>
  handleAddFiles: (newFiles: TSelectedFile[]) => void
  handleSelectFile: (fl: TSelectedFile) => void
  handleImportFiles: (newFiles: TSelectedFile[]) => void
  handleDeleteFiles: (files: TSelectedFile[]) => void
  chosenType: TDocumentTypeFile
  setChosenType: (t: TDocumentTypeFile) => void
  showFiles: boolean
}

const useProjectCreate = (): TUseProjectCreateResult => {
	const { permissions: { canUseCloud } } = useContext(UserContext);
	const { user, user: { settings: { filesCopyMethod } }, updateUser } = useUserData();
	const [chosenType, setChosenTypeState] = useState<TDocumentTypeFile>(DOC_TYPE_FILE_MEDIA);
	const [showFiles, setShowFiles] = useState(false);

	const project = useMemo(() => newDocument(DOC_TYPE_PROJECT, {
		// _id: '',
		// _rev: '',
		created: formatDateApi(new Date()),
		// updated: formatDateApi(new Date()),
		// type: 'project',
		title: '',
		description: '',
		storeInCloud: canUseCloud,
		storeOnLocalDrive: true,
	}), [canUseCloud]);

	const { isComplete: isCompleteDialog, copyMethod, showDialog } = useDialogCopyMoveFiles();

	const userCopyMethod = useMemo(() => filesCopyMethod ?? copyMethod, [filesCopyMethod, copyMethod]);

	const { addFiles } = useProjectFilesApi({ projectId: project._id });

	const [submitValues, setSubmitValues] = useState<{ values?: TDocProject, formikHelpers?: FormikHelpers<TDocProject>}>({});
	const [runSubmit, setRunSubmit] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access

	const [newFiles, setNewFiles] = useState<TSelectedFile[]>([]);
	const [showCreatedDate, setShowCreatedDate] = useState(false);

	const setChosenType = useCallback((t: TDocumentTypeFile) => {
		setChosenTypeState(t);
		setNewFiles((fls) => fls.map((f) => (f.typeConfirmed === true ? f : { ...f, type: t })));
	}, []);

	const handleAddFiles = useCallback((newFiles: TSelectedFile[]): void => {
		setNewFiles((fls) => [
			...fls,
			...newFiles.map((f) => ({ ...f, type: chosenType })),
		]);
		setShowFiles(true);
	}, [chosenType]);
	const handleImportFiles = useCallback((newFiles: TSelectedFile[]): void => {
		setShowCreatedDate(true);
		handleAddFiles(newFiles);
	}, [handleAddFiles]);

	const handleDeleteFiles = useCallback((fls: TSelectedFile[]) => {
		setNewFiles((s) => s.filter((fl) => !fls.includes(fl)));
	}, []);

	const doSubmit = useCallback(async () => {
		let doRoute = false;
		try {
			await dispatch(insertProject(submitValues.values));
			if (newFiles.length) {
				await addFiles(newFiles, { move: userCopyMethod === 'move' ?? false });
			}
			await updateUser({
				helpFlags: {
					createFirstProject: true,
				},
			});
			doRoute = true;
		} catch (e) {
			console.error('Error while creating project', e);
		} finally {
			submitValues.formikHelpers?.setSubmitting(false);
			setRunSubmit(false);
		}
		if (doRoute) {
			history.push(ROUTE.projects.id(project._id));
		}
	}, [dispatch, submitValues, newFiles, userCopyMethod, project, addFiles, history]);

	useEffect(() => {
		if (runSubmit && (userCopyMethod || !newFiles.length)) {
			setRunSubmit(false);
			void doSubmit();
		}
	}, [doSubmit, runSubmit, userCopyMethod, newFiles.length]);

	const handleSubmit = useCallback(async (values: TDocProject, formikHelpers: FormikHelpers<TDocProject>) => {
		setRunSubmit(false);
		setSubmitValues({ values, formikHelpers });
		formikHelpers.setSubmitting(true);
		if (newFiles.length && !userCopyMethod) {
			showDialog();
		} else {
			setRunSubmit(true);
		}
	}, [newFiles.length, showDialog, userCopyMethod]);

	useEffect(() => {
		if (isCompleteDialog && userCopyMethod) {
			setRunSubmit(true);
		}
	}, [isCompleteDialog, userCopyMethod]);

	const handleSelectFile = useCallback((fl: TSelectedFile) => {
		setNewFiles((s) => s.map((f) => (f === fl ? { ...f, selected: !f.selected } : f)));
	}, []);

	return {
		canUseCloud,
		showCreatedDate,
		project,
		files: newFiles,
		handleSubmit,
		handleSelectFile,
		handleAddFiles,
		handleImportFiles,
		handleDeleteFiles,
		chosenType,
		setChosenType,
		showFiles,
	};
};

export default useProjectCreate;
