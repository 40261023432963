import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ProjectDetails } from 'shared/components/ProjectDetails';
import { emitUserError } from 'shared/constants/globals';
import {
    apiShareDelDocumentAndFile,
    apiShareGetDocument,
    apiShareGetDocuments,
    apiShareUpdateDocument
} from 'shared/modules/share/api';
import { asyncForEach } from 'shared/utils/common';
import { addFilesToProject } from 'shared/utils/files/filesShare';

const getProjectFiles = async () => {
  try {
    return await apiShareGetDocuments();
  } catch (e) {
    emitUserError(e.message, 'Unable to fetch Project files');
  }
  return null;
};

const getDocument = async (documentId) => {
  try {
    return await apiShareGetDocument(documentId);
  } catch (e) {
    emitUserError(e.message, 'Unable to fetch Project');
  }
  return null;
};

export default function SharedProject({ settings }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sharingId } = useParams();

  const getProject = useCallback(async () => await getDocument(settings.documentId), [settings.documentId]);

  useEffect(() => {
      getProject();
  }, [getProject]);

  const updateProject = useCallback(async (values) => {
    const project = await getProject();
    if (project) {
      try {
        await apiShareUpdateDocument({ ...project, ...values });
      } catch (e) {
        emitUserError(e.message, 'Unable to update Project');
      }
    }
  }, [getProject]);

  const deleteDocument = useCallback(async (ids) => {
    const project = await getProject();
    if (project) {
      try {
        await asyncForEach(ids, apiShareDelDocumentAndFile);
      } catch (e) {
        emitUserError(e.message, 'Unable to delete document');
      }
    }
  }, [getProject]);

  const addFiles = useCallback((files) => dispatch(addFilesToProject(settings.documentId, files)), [dispatch, settings.documentId]);

  return (
    <ProjectDetails
        sharingId={sharingId}
        canUseCloud
        readProject={getProject}
        findFiles={getProjectFiles}
        addFiles={settings.canAddFiles ? addFiles : undefined}
        saveProject={settings.canEdit ? updateProject : undefined}
        removeFile={settings.canRemoveFiles ? deleteDocument : undefined}
        history={history}
    />
  );
}
