import { Cloud } from 'shared/_model/cloud/types';
import { Sharing, SharingType } from 'shared/_model/sharing/types';
import { TDocument } from 'shared/model/doc-base';
import { TDocUser } from 'shared/model/user';
import { TPathType } from 'shared/utils/files/files';
import { generateId } from 'shared/utils/guids';
import { getUserId } from 'shared/utils/webSettings';

// type TPathType = any;
// const getUserId = () => '';
// const generateId = (n: number) => `${n}`;

export const DOC_GROUP_TYPE_FILE = 'file';

export enum DocType {
	FILE_AUDIO = 'file-audio',
	FILE_MEDIA = 'file-media',
	FILE_MISC = 'file-misc',
	FILE_PROJECT = 'file-project',
	FILE_FINISH_VIDEO = 'file-video',
	FILE_FINISH_AUDIO = 'file-final-audio',
	USER = 'user',
	PROJECT = 'project',
	SERIES = 'series',
	SEASON = 'season',
	EPISODE = 'episode',
}

export const DOC_TYPES_FILES = [
	DocType.FILE_MEDIA,
	DocType.FILE_AUDIO,
	DocType.FILE_PROJECT,
	DocType.FILE_MISC,
	DocType.FILE_FINISH_VIDEO,
	DocType.FILE_FINISH_AUDIO,
] as const;

export type DocTypeFiles = typeof DOC_TYPES_FILES[number];

export const DOC_TYPE_USER = 'user';
export const DOC_TYPE_PROJECT = DocType.PROJECT;
export const DOC_TYPE_SERIES = 'series';
export const DOC_TYPE_SEASON = 'season';
export const DOC_TYPE_EPISODE = 'episode';
export const DOC_TYPE_FILE_AUDIO = DocType.FILE_AUDIO; // 'file-audio';
export const DOC_TYPE_FILE_MEDIA = DocType.FILE_MEDIA; // 'file-media';
export const DOC_TYPE_FILE_MISC = DocType.FILE_MISC; // 'file-misc';
export const DOC_TYPE_FILE_PROJECT = DocType.FILE_PROJECT; // 'file-project';
export const DOC_TYPE_FILE_FINISH_VIDEO = DocType.FILE_FINISH_VIDEO; // 'file-video';
export const DOC_TYPE_FILE_FINISH_AUDIO = DocType.FILE_FINISH_AUDIO; // 'file-final-audio';

export type TDocumentTypeFile =
	| typeof DOC_TYPE_FILE_AUDIO
	| typeof DOC_TYPE_FILE_MEDIA
	| typeof DOC_TYPE_FILE_MISC
	| typeof DOC_TYPE_FILE_PROJECT
	| typeof DOC_TYPE_FILE_FINISH_VIDEO
	| typeof DOC_TYPE_FILE_FINISH_AUDIO;

export type TDocumentType =
	| TDocumentTypeFile
	| typeof DOC_TYPE_USER
	| typeof DOC_TYPE_PROJECT
	| typeof DOC_TYPE_SERIES
	| typeof DOC_TYPE_SEASON
	| typeof DOC_TYPE_EPISODE;

type TTypeDescription = Readonly<{
	type: TDocumentType;
	group: string;
	isFinishFile: boolean;
	prefix: string;
	prefixSize: number;
	title: string;
	description?: string;
	folderNames?: readonly string[];
	// folderNameOld?: string // TODO: very old folder name. I think only Peter has it on his drive
}>;

type TTypeDescriptionProps = Partial<Omit<TTypeDescription, 'type' | 'prefix'>> & {
	type: TDocumentType;
	prefix: string;
};
const docDescription = (props: TTypeDescriptionProps): TTypeDescription => {
	const { type, group, isFinishFile = false, prefixSize = 7, title = '' } = props;
	return {
		...props,
		group: group || type,
		isFinishFile,
		prefixSize,
		title,
	};
};

export const DOCUMENT_DESCRIPTIONS = Object.freeze<
	Record<TDocumentType, TTypeDescription>
>({
	// ...docTypeDescription({
	//   type: 'link',
	//   prefix: 'ln',
	//   prefixSize: 7
	// }), // TODO: Delete it ???
	[DOC_TYPE_USER]: docDescription({
		type: DOC_TYPE_USER,
		prefix: '',
	}),
	[DOC_TYPE_PROJECT]: docDescription({
		type: DOC_TYPE_PROJECT,
		prefix: 'PRJ',
	}),
	[DOC_TYPE_SERIES]: docDescription({
		type: DOC_TYPE_SERIES,
		prefix: 'SRS',
	}),
	[DOC_TYPE_SEASON]: docDescription({
		type: DOC_TYPE_SEASON,
		prefix: 'SEA',
	}),
	[DOC_TYPE_EPISODE]: docDescription({
		type: DOC_TYPE_EPISODE,
		prefix: 'EPS',
	}),
	[DOC_TYPE_FILE_MEDIA]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_MEDIA,
		prefix: 'FME',
		title: 'Media',
		description: '(Raw video clips, images, photos, etc)',
		folderNames: Object.freeze(['Raw Media', 'media']),
	}),
	[DOC_TYPE_FILE_AUDIO]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_AUDIO,
		prefix: 'FAU',
		title: 'Audio',
		description: '(Raw audio clips, music, etc)',
		folderNames: Object.freeze(['Raw Audio', 'audio']),
	}),
	[DOC_TYPE_FILE_PROJECT]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_PROJECT,
		prefix: 'FPR',
		title: 'Project files',
		description: '(Editable project files)',
		folderNames: Object.freeze(['Project Files', 'project']),
	}),
	[DOC_TYPE_FILE_MISC]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_MISC,
		prefix: 'FMI',
		title: 'Other files',
		description: '(Any other files that don’t fit any other folder)',
		folderNames: Object.freeze(['Other Files', 'Miscellaneous']),
	}),
	[DOC_TYPE_FILE_FINISH_VIDEO]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_FINISH_VIDEO,
		prefix: 'VID',
		isFinishFile: true,
		title: 'Finished Videos',
		description: '(Any finalized or finished videos)',
		folderNames: Object.freeze(['Finished Video', 'video']),
	}),
	[DOC_TYPE_FILE_FINISH_AUDIO]: docDescription({
		group: DOC_GROUP_TYPE_FILE,
		type: DOC_TYPE_FILE_FINISH_AUDIO,
		prefix: 'AUD',
		isFinishFile: true,
		title: 'Finished Audio',
		description: '(Any finalized or finished audio)',
		folderNames: Object.freeze(['Finished Audio', 'finalAudio']),
	}),
});

export const newId = (type: TDocumentType, userId: string): string => {
	const docDescr = DOCUMENT_DESCRIPTIONS[type];
	return `${docDescr.prefix}${userId}${generateId(docDescr.prefixSize)}`;
};

export type Youtube = {
	youtubeId: string;
};

// export type TCloud = {
//   s3?: Record<string, any>
// }

export type TDocMinimum<T extends TDocument> = Partial<T> & { _id: string };
// export type TDocUser = {
//   _id: string
//   _rev: string
//   type: TDocumentType
//   created: string
//   updated: string
//   name: string
//   email: string
//   phone: string
//   helpFlags: {
//     openFirstProject: boolean
//     createFirstProject: boolean
//   }
// }
export type TDocumentWithTitle = TDocument & {
	title: string;
};
export type TDocProject = TDocumentWithTitle & {
	type: typeof DOC_TYPE_PROJECT;
	description?: string;
	// sponsored: boolean
	// sponsoredBy?: string
	// sponsoredSeries: boolean
	// invoiceNumber?: string
	// amount?: number
	storeOnLocalDrive: boolean;
	storeInCloud: boolean;
	sharing?: Sharing<SharingType.PROJECT>;
	tags?: string[];
};
export type TDocFile = TDocumentWithTitle & {
	type: TDocumentTypeFile;
	filename: string;
	size: number;
	projectId: string;
	systemType: TPathType;
	meta: {
		type: string;
		lastModified: string;
		[key: string]: any;
	};
	cloud?: Cloud;
	sharing?: Sharing<SharingType.FILE>;
	description?: string;
	notes?: string;
	youtube?: Youtube;
	thumbnail?: {
		type: string;
		size: number;
		cloud: Cloud;
	};
	path?: string;
};
export type TDocSeries = TDocumentWithTitle & {
	type: typeof DOC_TYPE_SERIES;
	description: string;
	keywords: string;
	seriesType: 'video' | 'audio';
	sponsored: boolean;
	seasons: boolean;
	youtube?: {
		categoryId?: number;
		channelId?: string;
	};
	image?: string;
};
export type TDocSeason = TDocumentWithTitle & {
	type: typeof DOC_TYPE_SEASON;
	description: string;
	keywords: string;
	seriesId: string;
	number: number;
	sponsored: boolean;
};
export type TDocEpisode = TDocumentWithTitle & {
	type: typeof DOC_TYPE_EPISODE;
	description: string;
	keywords: string;
	seriesId: string;
	seasonId: string;
	seasonNumber: number;
	number: number;
	videoId: string;
};

export type TDoc = TDocProject | TDocFile | TDocSeries | TDocSeason | TDocEpisode | TDocUser;

export function newDocument(
	type: TDocumentTypeFile,
	props: Omit<Partial<TDocFile>, '_id'>
): TDocFile;
export function newDocument(
	type: typeof DOC_TYPE_PROJECT,
	props: Omit<Partial<TDocProject>, '_id'>
): TDocProject;
export function newDocument(
	type: typeof DOC_TYPE_SERIES,
	props: Omit<Partial<TDocSeries>, '_id'>
): TDocSeries;
export function newDocument(
	type: typeof DOC_TYPE_SEASON,
	props: Omit<Partial<TDocSeason>, '_id'>
): TDocSeason;
export function newDocument(
	type: typeof DOC_TYPE_EPISODE,
	props: Omit<Partial<TDocEpisode>, '_id'>
): TDocEpisode;
export function newDocument(
	type: typeof DOC_TYPE_USER,
	props: Omit<Partial<TDocUser>, '_id'>
): TDocUser;
export function newDocument(
	type: TDocumentType | DocType,
	props: Omit<Partial<TDoc>, '_id'>
): TDoc {
	const curDate = new Date().toISOString();
	const userId = getUserId();

	return {
		...props,
		_id: newId(type, userId),
		_rev: '',
		type,
		created: props?.created ?? curDate,
		updated: curDate,
		createdBy: userId,
	} as TDoc;
}

export const getDocType = ({ _id, type }: Pick<TDoc, '_id' | 'type'>): string => {
	if (type) {
		return type;
	}

	return (
		Object.values(DOCUMENT_DESCRIPTIONS).find(
			({ prefix }) => prefix && prefix === _id.slice(0, prefix.length)
		)?.type ?? ''
	);
};
