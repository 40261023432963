import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Session = {
	userId: string;
	isLoggedIn: boolean;
	isShared: boolean;
};

type SessionContextValue = {
	isShared: boolean;
	currentSession: Session;
	sessions: Record<string, Session>;
};

function readLatestSession(isShared: boolean) {
	return EMPTY_SESSION;
}

function restoreSession(userId: string, isShared: boolean) {
	if (isShared) {
		// restore shared session by share Id (and/or userId)
		return EMPTY_SESSION;
	}
	// restore shared session by userId
	return EMPTY_SESSION;
}

const EMPTY_SESSION: Session = {
	isLoggedIn: false,
	isShared: false,
	userId: '',
};

const INITIAL_STATE: SessionContextValue = {
	currentSession: EMPTY_SESSION,
	sessions: {},
	isShared: false,
};

const AuthContext = createContext<SessionContextValue>(INITIAL_STATE);
export const useAuthContext = () => useContext(AuthContext);

export default function SessionProviderNew({ children }: PropsWithChildren) {
	const { pathname } = useLocation();
	const isShared = pathname.startsWith('shared');

	const [currentSession, setCurrentSession] = useState<Session>(readLatestSession(isShared));

	const value = useMemo<SessionContextValue>(
		() => ({
			isShared,
			sessions: {},
			currentSession,
		}),
		[isShared]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
