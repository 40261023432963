import { ChangeEvent, useCallback, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material'
import { IconEdit } from 'shared/_ui/icon'
import { TDocFile } from 'shared/constants/docs'

type Props = {
  doc: Pick<TDocFile, '_id' | 'title' | 'description'>
  action: ((values: Pick<TDocFile, '_id'>) => Promise<void>) | undefined
  actionCallback?: () => void
  callback?: () => void
}

type EditDialogProps = {
  doc: Props['doc']
  handleClose: () => void
  handleSave: (values: Pick<Props['doc'], 'description'>) => Promise<void>
}
function EditDialog({ doc, handleClose, handleSave }: EditDialogProps) {
  const [submitting, setSubmitting] = useState(false)
  const [description, setDescription] = useState(doc.description)
  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e?.target?.value)
    },
    []
  )

  const clickSave = useCallback(() => {
    setSubmitting(true)
    handleSave({ description })
    setSubmitting(false)
  }, [handleSave, description])

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{doc.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          multiline
          minRows={5}
          maxRows={20}
          margin="dense"
          id="description"
          label="Notes"
          type="email"
          fullWidth
          value={description}
          onChange={handleDescriptionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={submitting} onClick={clickSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function EditFileDescriptionMenuItem({
  doc,
  action,
  actionCallback,
  callback,
}: Props) {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    if (callback) {
      callback()
    }
  }, [callback])

  const handleSave = useCallback(
    async (values: Pick<Props['doc'], 'description'>) => {
      if (action) {
        // setSubmitting(true);
        try {
          await action({ _id: doc._id, ...values })
          if (actionCallback) {
            actionCallback()
          }
        } finally {
          // setSubmitting(false);
        }
      }
      handleClose()
    },
    [doc, action, actionCallback, handleClose]
  )

  if (!action) {
    return null
  }

  return (
    <MenuItem onClick={handleOpen}>
      <ListItemIcon>
        <IconEdit />
      </ListItemIcon>
      <ListItemText>Notes</ListItemText>
      {open && (
        <EditDialog
          doc={doc}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
    </MenuItem>
  )
}
