import { useCallback } from 'react'
import ApiError from 'shared/utils/api/api-error'
import { useSnackStack } from '../Providers/SnackStack'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useError(kind = '') {
  const { addToast } = useSnackStack()

  const showError = useCallback((err: ApiError|Error|unknown) => {
    let message: string;
    const title = `${kind} Error`.trim();

    if (err instanceof ApiError) {
      message = `${err.status}: ${err.message}`.trim();
    } if (err instanceof Error) {
      message = err.message;
    } else {
      message = `${err}`;
    }

    console.error(title, message, err);
    addToast({ message, severity: 'error', title });
  }, [addToast])

  return { showError };
}
