export type TLocalSettings = {
  projectPath?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function validateProjectPath(projectPath: string | undefined, userId: string): Promise<boolean> {
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function readLocalSettings(userId: string): Promise<TLocalSettings> {
  return ({});
}
