import { asyncForEach } from './common';
// import { moveLocalSettings } from '../new/_remove/todo';
// import { isWeb } from '../constants/modules';

const getLocalObjectKey = (objectName) => {
	const result = localStorage.getItem(objectName);
	return result ? JSON.parse(result) : {};
};
const setKeyToLocalObject = (objectName, key, value) => {
	localStorage.setItem(
		objectName,
		JSON.stringify({
			...getLocalObjectKey(objectName),
			[key]: value,
		})
	);
};

const LOCAL_SETTINGS_NAME_USER_LOCAL_SETTINGS = 'userLocalSettings';
const LOCAL_SETTINGS_NAME_HELP_SCREENS = 'helpScreens';
const LOCAL_SETTINGS_NAME_HELP_USER_SCREENS = 'helpUserScreens';

export const getLocalWebKey = () => 'userWeb';

export const getLocalSettingsKey = (userId) =>
	!userId ? '' : `${LOCAL_SETTINGS_NAME_USER_LOCAL_SETTINGS}${userId}`;
// const clearLocalSettings = (userId) => {
//     localStorage.removeItem(getLocalSettingsKey(userId));
// };
export const getLocalSettings = (userId) => {
	// clearLocalSettings(userId);
	// if (!isWeb) {
	//   moveLocalSettings(userId); // TODO: Remove it in future
	// }
	const result = localStorage.getItem(getLocalSettingsKey(userId));
	return result ? JSON.parse(result) : null;
};
export const setLocalSettings = (userId, userLocalSettings) => {
	if (userId) {
		localStorage.setItem(getLocalSettingsKey(userId), JSON.stringify(userLocalSettings));
	}
};

export const KEY_GREETINGS = 'greetings';
export const KEY_FIRST_REGISTER = 'registerFirst';
export const getLocalHelpScreens = () => getLocalObjectKey(LOCAL_SETTINGS_NAME_HELP_SCREENS);
export const setLocalHelpScreens = (key, value) =>
	setKeyToLocalObject(LOCAL_SETTINGS_NAME_HELP_SCREENS, key, value);

export const KEY_LOCAL_USER_SETTINGS_TEXT = 'localSettingsText';
/**
 * @param {string} userId
 * @returns {Record<string,boolean>|undefined}
 */
export const getLocalUserHelpScreens = (userId) =>
	getLocalObjectKey(`${LOCAL_SETTINGS_NAME_HELP_USER_SCREENS}_${userId}`);
export const setLocalUserHelpScreens = (userId, key, value) =>
	setKeyToLocalObject(`${LOCAL_SETTINGS_NAME_HELP_USER_SCREENS}_${userId}`, key, value);

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);
export const setLocalStorageItem = (key, value) => {
	if (value === undefined || value === null) {
		localStorage.removeItem(key);
	} else {
		localStorage.setItem(key, JSON.stringify(value));
	}
};
export const getLocalStorageItem = (key, checkLifeTime = true) => {
	const value = localStorage.getItem(key);
	const result = value ? JSON.parse(value) : null;

	if (checkLifeTime && result && result.meediCleanAfter < Date.now()) {
		localStorage.removeItem(key);
		return null;
	}

	return result;
};

/** Garbage collector */
export const cleanOldLocalValues = async () => {
	const keys = Object.keys(localStorage);
	const now = Date.now();
	await asyncForEach(keys, (key) => {
		try {
			const { meediCleanAfter } = JSON.parse(localStorage.getItem(key));
			if (meediCleanAfter && meediCleanAfter < now) {
				console.log(`removed ${key}`, JSON.parse(localStorage.getItem(key)));
				localStorage.removeItem(key);
			}
		} catch (e) {
			// nothing
		}
	});
};

// Manage user Web settings
export const getUserWeb = () => getLocalStorageItem(getLocalWebKey(), false);
export const setUserWeb = (userWeb) => setLocalStorageItem(getLocalWebKey(), userWeb);
// export const setUserWeb = ({ id, ...rest }) => setLocalStorageItem(getLocalWebKey(), rest);
export const removeUserWeb = () => {
	// console.trace('removeUserWeb', getUserWeb());
	removeLocalStorageItem(getLocalWebKey());
};

// Manage share Web settings
export const setSharingId = (sharingId) => sessionStorage.setItem('sessionSharingId', sharingId);
export const getSharingId = () => sessionStorage.getItem('sessionSharingId');
export const removeSharingId = () => {
	// console.trace('removeSharingId', getSharingId());
	sessionStorage.removeItem('sessionSharingId');
};

const sharingWebKey = (sharingId) => `shareWeb${sharingId}`;
export const getSharingWeb = () => getLocalStorageItem(sharingWebKey(getSharingId()), true) || {};
export const setSharingWeb = (shareWeb) => {
	const sharingId = getSharingId();
	if (sharingId) {
		setLocalStorageItem(sharingWebKey(sharingId), shareWeb);
	}
	// throw new Error('Sharing ID is not set');
};
export const removeSharingWeb = () => {
	const sharingId = getSharingId();
	if (sharingId) {
		removeLocalStorageItem(sharingWebKey(sharingId));
	}
};
