export enum CloudType {
  S3 = 's3',
  // TODO: An example for future cloud options
  TEMP = 'temp',
}

type CloudValueCommon = { uploaded: string }

export type CloudValueS3 = CloudValueCommon & { key: string }
export type CloudValueTemp = CloudValueCommon & { temp: 'temp' }

export type Cloud = {
  [CloudType.S3]?: CloudValueS3
  [CloudType.TEMP]?: CloudValueTemp
}

export type CloudValue = Cloud[keyof Cloud]
