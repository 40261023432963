import { TDocFile } from 'shared/constants/docs'

type FileProp = Pick<TDocFile, '_id' | 'projectId' | 'cloud' | 'path' | 'systemType'>

type FileSyncMenuItemProps = {
	disabled?: boolean;
	files: FileProp[];
	callback?: () => void;
	handleClose: () => void;
}

export default function FileSyncMenuItem(_: FileSyncMenuItemProps): JSX.Element|null {
  return null;
}
