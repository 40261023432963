import { Container } from '@mui/material'
import LocalSettings from 'app/components/LocalSettings/LocalSettings'
import { cleanTempFiles } from 'app/utils/desktop'
import { readLocalSettings, TLocalSettings, validateProjectPath } from 'app/utils/local-settings-utils'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { actionSetLocalSettings } from 'shared/modules/user/userActions'
import { UserContext } from '../User'
import WaitingScreen from '../WaitingScreen'


type TLocalSettingsContext = TLocalSettings & {
  isRead: boolean;
  isValid: boolean;
  setProjectPath: (folder: string) => Promise<void>;
}

export const LocalSettingsContext = createContext<TLocalSettingsContext>({
  isRead: false,
  isValid: false,
  setProjectPath: async () => undefined,
});


export default function LocalSettingsProvider({ children }: PropsWithChildren): JSX.Element {
  const dispatch = useDispatch();   // TODO: old logic. remove.
  const { user } = useContext(UserContext);
  const [isRead, setIsRead] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [projectPath, setProjectPathState] = useState<string|undefined>();
  console.debug('tst: LocalSettings read', user?.id, isRead, isValid, projectPath);

  const readSettings = useCallback(async () => {
    if (user?.id) {
      const s = await readLocalSettings(user?.id);
      setProjectPathState(s?.projectPath);
      const valid = await validateProjectPath(s?.projectPath, user?.id);
      console.log('tst: valid', valid);
      setIsValid(valid);
      if (valid) {
        await cleanTempFiles();
      }
      setIsRead(true);
      dispatch(actionSetLocalSettings({ read: true, ...s }));
    }
  }, [user?.id, dispatch]);

  useEffect(() => {
    void readSettings();
  }, [readSettings]);

  // const moveDataFolder = useCallback(async (oldFolder: string, newFolder: string) => {
  //   if (oldFolder === newFolder) {
  //     return true;
  //   }
  // }, []);

  const setProjectPath = useCallback(async (folder?: string) => {
    console.debug('tst: LocalSettings setProjectPath', folder);
    // if (projectPath && folder && projectPath !== folder) {
    //   await moveDataFolder(projectPath, folder);
    // }
    setProjectPathState(folder);
    const valid = await validateProjectPath(folder, user!.id);
    setIsValid(valid);
    if (valid) {
      await cleanTempFiles();
    }
    dispatch(actionSetLocalSettings({ read: true, projectPath: folder }));
  }, [user?.id, dispatch]);

  if (!isRead) {
    return <WaitingScreen description="Searching projects folder ..." />
  }

  return (
		<LocalSettingsContext.Provider value={{ isRead, isValid, projectPath, setProjectPath }}>
			{!isValid
        ? (
          <Container maxWidth="md" sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <LocalSettings />
          </Container>
        )
        : children}
		</LocalSettingsContext.Provider>
	);
}

export const useLocalSettings = (): TLocalSettingsContext => useContext(LocalSettingsContext);
