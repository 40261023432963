import { ComponentProps } from 'react'
import { Sharing, SharingType } from 'shared/_model/sharing/types'
import { isShareSet } from 'shared/_model/sharing/utils'
import { ColoredIcon, IconShare } from 'shared/_ui/icon'
import { useUserContext } from 'shared/Providers/User'
import { isSharing } from 'shared/utils/webSettings'

type Props = Omit<ComponentProps<typeof ColoredIcon>, 'color' | 'Component'> & {
  sharing: Sharing | undefined
  type: SharingType
}

export default function ShareFileIcon({ sharing, type, ...rest }: Props) {
  const {
    permissions: { canUseCloud },
  } = useUserContext()

  if (!canUseCloud) {
    return null
  }
  if (isSharing()) {
    return null
  }

  const isSet = isShareSet(sharing, type)

  return (
    <ColoredIcon
      {...rest}
      Component={IconShare}
      color={isSet ? 'success' : 'disabled'}
    />
  )
}
