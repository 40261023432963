import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ListContainer from '../../../../../../components/ListContainer/ListContainer';
import { CONTENT_TYPE } from '../../../../../../constants/general';
import { findSeriesByFileId } from '../../../../../../modules/series/actions';
import SeriesSearch from './SeriesSearch';

export default function FileSeriesList({ fileId }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({ series: [], ready: false });

  const readList = useCallback(async () => {
    if (fileId) {
      setState({
        series: await dispatch(findSeriesByFileId(fileId)) || [],
        ready: true,
      });
    }
  }, [dispatch, fileId]);

  useEffect(() => {
    readList();
  }, [readList]);

  return (
    <>
      <SeriesSearch
        fileId={fileId}
        addSeriesCallback={readList}
        excludeIds={state.series.map(doc => doc._id)}
      />
      <ListContainer title="" ready={state.ready} items={state.series} type={CONTENT_TYPE.SERIES} />
    </>
  );
}

FileSeriesList.propTypes = {
  fileId: PropTypes.string.isRequired,
};
