import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { object, string } from 'yup';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
} from '@mui/material';
import { PermissionsSelect } from 'shared/components/SelectPermissions';
import FormikTextField from 'shared/components/formik/FormikTextField';
import useApiRegisterNewMember, {
	TMemberPermission,
} from 'shared/utils/api/use-api-register-new-member';
import { useSnackStack } from 'shared/Providers/SnackStack';

type TNewMember = {
	email: string;
	permissions?: TMemberPermission[];
};

const PERMISSION_CAN_READ_PROJECTS = 'can read projects';
const PERMISSIONS_MAP: Record<TMemberPermission, string> = {
	CanEditProject: 'can edit projects',
	CanAddFiles: 'can add files',
	CanRemoveFiles: 'can remove files',
};
const PERMISSIONS = Object.keys(PERMISSIONS_MAP) as TMemberPermission[];
const initialValues = {
	email: '',
	permissions: PERMISSIONS,
};

type TNewMemberProps = {
	handleClose: () => void;
};

const NEW_MEMBER_SCHEMA = object().shape({
	email: string().required('Mail is required').email('Invalid email format'),
});

export function NewMember({ handleClose }: TNewMemberProps): JSX.Element {
	const { doRegisterNewMember } = useApiRegisterNewMember();
	const { addToast } = useSnackStack();

	const handleSubmit = useCallback(
		async (values: TNewMember, { setSubmitting }: FormikHelpers<TNewMember>) => {
			// setSubmitting(true);
			const { email, permissions } = values;
			try {
				const result = await doRegisterNewMember(email, permissions ?? []);
				if (result) {
					addToast({ message: 'Member created', severity: 'success' });
					handleClose();
				}
			} finally {
				setSubmitting(false);
			}
		},
		[doRegisterNewMember, addToast, handleClose]
	);

	return (
		<Dialog open onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle variant="h2">New member</DialogTitle>
			<Formik<TNewMember>
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validationSchema={NEW_MEMBER_SCHEMA}
				render={({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
					<Box component={Form} sx={{ '& .MuiTextField-root': { mb: 2 } }}>
						<DialogContent>
							<Box>
								<Field fullWidth name="email" component={FormikTextField} label="Email" required />
								<FormControl fullWidth>
									<PermissionsSelect<TMemberPermission>
										id="new-member-permissions-select"
										value={values.permissions ?? []}
										setValue={(permissions) => {
											setFieldValue('permissions', permissions);
										}}
										valueMap={PERMISSIONS_MAP}
										label="Permissions"
										placeHolder={PERMISSION_CAN_READ_PROJECTS}
									/>
								</FormControl>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button disabled={isSubmitting} onClick={handleClose} color="secondary">
								Cancel
							</Button>
							<Button disabled={!isValid || !dirty || isSubmitting} type="submit">
								Submit
							</Button>
						</DialogActions>
					</Box>
				)}
			/>
		</Dialog>
	);
}
