import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getS3Url } from '../../utils/cloud/cloudS3';
import placeholder16x9 from './16x9.svg';

export const PLACEHOLDER_TYPES = {
  t16x9: '16x9'
};

export default function S3img({ src: srcProp, placeholder = PLACEHOLDER_TYPES.t16x9, ...props }) {
  const [src, setSrc] = useState(null);

  const getSrc = useCallback(async () => {
    if (srcProp) {
      try {
        const data = await getS3Url(srcProp || {});
        setSrc(data.url);
        return data;
      } catch (e) {
        setSrc('');
      }
    }
  }, [srcProp]);

  useEffect(() => {
    // const prevSrc = prevProps.src || {};
    // const curSrc = this.props.src || {};
    // if (prevSrc.key !== curSrc.key) {
    //   getSrc();
    // }
    getSrc();
  }, [getSrc]);

  const srcImg = useMemo(() => {
    let res = src;
    if (!res && placeholder) {
      switch (placeholder) {
      case PLACEHOLDER_TYPES.t16x9:
        res = placeholder16x9;
        break;
      default:
      }
    }
    return res;
  }, [src, placeholder]);

  return <img alt="" {...props} src={srcImg} />;
}

S3img.propTypes = {
  src: PropTypes.shape({ key: PropTypes.string.isRequired }),
  placeholder: PropTypes.string,
};
