import { doCheckToken } from '../../utils/webSettings';
import { fsEnsureDirSync, fsLstatSync } from 'app/_temp/fse';
import { isWeb } from '../../constants/modules';

export const validateWebSettings = (web) => !!(
	web
		&& web.id
		&& web.properties
		&& web.properties.received
		&& doCheckToken(web)
);

const validateLocalProjectPath = (projectPath, createFolders = false) => {
	if (isWeb) {
		return true;
	}

	if (projectPath) {
		try {
			const stat = fsLstatSync(projectPath);
			if (stat.isDirectory()) {
				return true;
			}
		} catch (e) {
			if (createFolders) {
				try {
					fsEnsureDirSync(projectPath);
					return true;
				} catch (err) {
					// nothing
				}
			}
			// nothing
		}
	}

	return false;
};
export const validateLocalSettings = (local) => isWeb || !!(
	local
        && local.projectPath
        && validateLocalProjectPath(local.projectPath)
);
