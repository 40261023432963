import { getCloudProvider } from 'shared/_model/cloud/provider'
import CloudProvider from 'shared/_model/cloud/provider/CloudProvider'
import { Cloud, CloudType, CloudValue } from 'shared/_model/cloud/types'
import { getCloudValue, getCloudType } from 'shared/_model/cloud/utils'

export default class CloudReader {
  #cloud: Cloud | undefined

  #type: CloudType | undefined

  #value: CloudValue | undefined = undefined

  #provider: CloudProvider | undefined = undefined

  constructor(cloud: Cloud | undefined) {
    if (cloud) {
      this.#cloud = cloud
      this.#type = getCloudType(cloud)
      if (this.#type) {
        this.#value = getCloudValue(cloud, this.#type)
        this.#provider = getCloudProvider(cloud, this.#type)
      }
    }
  }

  public get cloud() {
    return this.#cloud
  }

  public get type() {
    return this.#type
  }

  public get value() {
    return this.#value
  }

  public async getUrl(force?: boolean): Promise<string> {
    const url = await this.#provider?.getUrl(force)
    return url ?? ''
  }
}
