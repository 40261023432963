import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { getDownloadUrl, getDownloadUrlFunc } from 'shared/utils/cloud/cloud'
import { useUserContext } from 'shared/Providers/User'
import { TDocFile } from 'shared/constants/docs'
import { IconDownload } from 'shared/_ui/icon'

type TUseDownloadFiles = Pick<TDocFile, 'cloud'>[]
type TUseDownloadFilesResponse = {
  downloadFilesAllowed: boolean
  downloadFilesCount: number
  runDownloadFiles: () => Promise<void>
}

function useDownloadFiles(
  files: TUseDownloadFiles,
  callback?: () => void
): TUseDownloadFilesResponse {
  const {
    permissions: { canUseCloud },
  } = useUserContext()

  const filteredFiles = useMemo(() => {
    return files.filter(
      ({ cloud }) =>
        cloud && Object.keys(cloud).length && getDownloadUrlFunc(cloud)
    )
  }, [files])

  const runDownloadFiles = useCallback(async () => {
    if (!canUseCloud) {
      return
    }
    // TODO: Doesn't support to download several files
    files.forEach(async ({ cloud }) => {
      const response = await getDownloadUrl(cloud)
      if (response) {
        window.location.href = response.url
      }
    })

    if (callback) {
      callback()
    }
  }, [canUseCloud, files, callback])

  return {
    downloadFilesAllowed: canUseCloud,
    downloadFilesCount: filteredFiles.length,
    runDownloadFiles,
  }
}

type Props = {
  files: TUseDownloadFiles
  disabled?: boolean
  callback?: () => void
}

export default function DownloadFilesMenuItem({
  files,
  disabled = false,
  callback,
}: Props) {
  const { downloadFilesAllowed, runDownloadFiles, downloadFilesCount } =
    useDownloadFiles(files, callback)

  if (!downloadFilesAllowed) {
    return null
  }

  return (
    <MenuItem
      onClick={runDownloadFiles}
      disabled={disabled || !downloadFilesCount}>
      <ListItemIcon>
        <IconDownload />
      </ListItemIcon>
      <ListItemText>Download</ListItemText>
    </MenuItem>
  )
}
