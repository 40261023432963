import './shared/constants/globals';
import { App } from 'shared/Pages/common/App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';

import 'sanitize.css/sanitize.css';
import './index.global.css';
import { cleanOldLocalValues } from 'shared/utils/localStorageUtils';
import { CommonProviders } from 'shared/Providers';
import useInitApp from 'shared/hooks/use-init-app';
import { MuiThemeProvider } from 'shared/Providers/MuiTheme';
import store, { history } from './store';

function InnerApp() {
	const { isDone } = useInitApp();

	if (!isDone) {
		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				Loading ...
			</div>
		);
	}

	return <App />;
}

cleanOldLocalValues().then(); // No need to wait

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				{/* TODO: Remove it later (need to use useHistory everywhere) */}
				<MuiThemeProvider>
					<CommonProviders>
						<InnerApp />
					</CommonProviders>
				</MuiThemeProvider>
			</ConnectedRouter>
		</Provider>
	</BrowserRouter>
);
