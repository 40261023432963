import { useCallback, useEffect, useState } from 'react';
import { ENDPOINTS } from 'shared/constants/endpoints';
import { api, emitUserError } from 'shared/constants/globals';
import { TApiUser } from 'shared/helpers/types-api';


type TUsersManagementProps = {
  autoRead?: boolean
  autoReadStats?: boolean
}
type TUsersManagementResponse = {
  users: TApiUser[]
  readUsers: () => Promise<void>
  readUser: (userId: string) => Promise<TApiUser|undefined>
}


export const useUsersManagement = (props?: TUsersManagementProps): TUsersManagementResponse => {
  const { autoRead = true } = (props ?? {});

  const [users, setUsers] = useState<TApiUser[]>([]);


  const readUsers = useCallback(async () => {
    try {
      // {users: [{ user_id: "05y25t", name: "", email: "e@ma.il" }] }
      const response = (await api.apiGet(ENDPOINTS.getAllUsersDetails)) as ({ users: TApiUser[] } | undefined);
      if (response?.users) {
        setUsers(response?.users.sort((val1, val2) => (val1.user_id < val2.user_id ? -1 : 1)));
      }
    } catch (e) {
      emitUserError(`Get users error\n${(e as Error).message}`)
    }
  }, [])

  const readUser = useCallback(async (userId: string) => {
    try {
      // @ts-ignore
      const response = (await api.apiGet(ENDPOINTS.user.getUserProperties, { user_id: userId })) as (TApiUser | undefined);
      if (response?.roles) {
        setUsers(s => s.map(u => {
          if (u.user_id === userId) {
            return {...u, ...response};
          } else {
            return u;
          }
        }))
        return response;
      }
    } catch (e) {
      emitUserError(`Get user '${userId}' error\n${(e as Error).message}`)
    }
    return undefined;
  }, [])


  useEffect(() => {
    if (autoRead && users.length === 0) {
      void readUsers();
    }
  }, [autoRead, users.length, readUsers])


  return {
    users,
    readUsers,
    readUser,
  };
}
