import { Cloud } from 'shared/_model/cloud/types';
import { getCloudType } from 'shared/_model/cloud/utils';
import { DisabledIcon, IconCloud, IconCloudWithCheck, SuccessIcon } from 'shared/_ui/icon';

type FileCloudIconProps = { cloud: Cloud | undefined };

export default function FileCloudIcon({ cloud }: FileCloudIconProps) {
	const type = cloud ? getCloudType(cloud) : undefined;

	if (!type) {
		return <DisabledIcon Component={IconCloud} />;
	}

	return <SuccessIcon Component={IconCloudWithCheck} />;
}
