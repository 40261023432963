import React from 'react';

const k = 15.172 / 7.9375;

export const IconFileTypeProject: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 30, fill = 'black', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} fill={fill} viewBox="0 0 15.172 7.9375" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.3127 0.72793h1.8702v0.96982h-1.8702zm-4.1259 0h1.8708v0.96982h-1.8708zm-0.18675 1.6975h6.4426v-2.4255h-6.4426z"/>
      <path d="m10.472 3.4291h1.8702v0.97005h-1.8702zm-7.5519 0h1.8708v0.97005h-1.8708zm-0.17621 1.7303h9.8507v-2.4255h-9.8507z"/>
      <path d="m13.065 6.2397h1.8708v0.97072h-1.8708zm-4.0856 0h1.8708v0.97072h-1.8708zm-0.25042 1.6978h6.4426v-2.4248h-6.4426z"/>
    </svg>
  );
};
