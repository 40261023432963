// TODO: Temp component
import React from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';

type WaitingScreenProps = {
  description: string
}

export default function WaitingScreen({ description }: WaitingScreenProps): JSX.Element {
  return (
    <Container
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        p: 3,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        textAlign="center"
        m="auto"
      >
        <CircularProgress />
        <Typography>{description}</Typography>
      </Box>
    </Container>
  );
}
