import { initUppy } from '../constants/handlers';

export const checkLoginLocalSettings = async () => {
	// TODO: Hack. pouchDB didn't want to connect to remoteDB first time.
	// 	Only if you click Ctrl+R and reload the window - it connects successfully
	// const tHandler = remote && setTimeout(() => {
	//   remote.getCurrentWindow().reload();
	// }, 15000); // 5 seconds should be enough
	initUppy();
};
