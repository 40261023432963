import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import FormButtons from '../../../../components/FormButtons/FormButtons';
import * as seasonsActions from '../../../../modules/seasons/actions';
import { SEASON_SCHEMA } from '../../../../constants/general';
import styles from './SeasonCreate.module.css';

export default function SeasonCreate({ seriesId, onChange }) {
  const dispatch = useDispatch();

  const [season, setSeason] = useState(SEASON_SCHEMA.default());

  const init = useCallback(async () => {
    const values = await dispatch(seasonsActions.newSeasonValues(seriesId));
    setSeason(s => ({ ...s, ...values }));
  }, [dispatch, seriesId]);

  useEffect(() => {
    init();
  }, [init])

  const handleSubmit = useCallback(async (values, { setSubmitting }) => {
    await dispatch(seasonsActions.insert(values));

    setSubmitting(false);
    await dispatch(seasonsActions.readSeasonsList(seriesId));

    if (onChange) {
      await onChange();
    }
  }, [dispatch, seriesId, onChange]);

  return (
    <div className={styles.container}>
      <h1 className="margin-top-none">New Season</h1>
      <Formik
        initialValues={season}
        enableReinitialize
        validationSchema={SEASON_SCHEMA}
        onSubmit={handleSubmit}
        render={({ isSubmitting, setFieldValue }) => (
          <Form>
            <Field
              name="title"
              component={Input}
              label="Name:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
              spellCheck
            />
            <Field
              name="description"
              type="textarea"
              component={Input}
              label="Description:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spellCheck
            />
            <Field
              name="keywords"
              component={Input}
              type={Input.TYPE.MULTI_SELECT}
              maxLength={500}
              label="Keywords:"
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              showCopyButton
              inputProps={{ spellCheck: true }}
            />
            <Field
              name="number"
              component={Input}
              label="Number:"
              readOnly
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              required
            />
            <Field
              name="sponsored"
              label="Sponsored:"
              component={Input}
              type={Input.TYPE.SWITCH}
              labelWidth={Input.LABEL_WIDTH.RIGHT}
              labelClassName={styles.label}
              spaceBottom={Input.SPACE_BOTTOM.SMALL}
              onChange={e => setFieldValue('sponsored', e.target.value)}
            />
            <FormButtons>
              <Button
                type="submit"
                disabled={isSubmitting}
                color={Button.COLOR.PRIMARY}
              >
                Create
              </Button>
            </FormButtons>
          </Form>
        )}
      />
    </div>
  );
}

SeasonCreate.propTypes = {
  seriesId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
