import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ListFilter } from '../ListFilter';
import LoadingPlaceholder from '../LoadingPlaceholder/LoadingPlaceholder';
import styles from './ListContainer.module.css';
import Button from '../Button/Button';
import ListItem from './ListItem';
import ListPlaceholder from './placeholders/ListPlaceholder';

const Actions = ({ actions }) => {
  const visibleActions = actions.filter(act => act.visible !== false);
  if (visibleActions.length) {
    return (
      <div
        className={styles.actionContainer}
      >
        {visibleActions.map(({ component, label, ...actionProps }, key) => (component
            ? component
            : (
              <Button
                key={key}
                {...actionProps}
              >
                {label}
              </Button>
            )
        ))}
      </div>
    );
  }
  return '';
};
Actions.propTypes = {
  actions: PropTypes.array,
};

const ListContainer = ({ title, items, actions = [], type, titleLevel = 1, titleClassName = undefined, ...rest }) => {
  const HeadTag = `h${titleLevel}`;
  const showTitle = (actions && actions.length) || title;
  return (
    <>
      <div className={styles.header}>
        {showTitle && (
          <HeadTag key="header" className={cn(styles.title, titleClassName)}>
            <Actions actions={actions} />
            {title}
            {title && (
              <span className={styles.count}>
                            (
                {items.length}
                )
                        </span>
            )}
          </HeadTag>
        )}
      </div>
      {rest.filterProps && (<div className={styles.filter}>
        {<ListFilter {...rest.filterProps} />}
      </div>)}
      {rest.ready === false
        ? <LoadingPlaceholder />
        : (
          <>
            {items.length === 0 && <ListPlaceholder type={type} />}
            {items.map(item => <ListItem item={item} type={type} key={item._id} {...rest} />)}
          </>
        )}
    </>
  );
};

ListContainer.propTypes = {
  title: PropTypes.node.isRequired,
  titleLevel: PropTypes.number,
  titleClassName: PropTypes.string,
  items: PropTypes.array.isRequired,
  actions: PropTypes.array,
  type: PropTypes.string.isRequired,
  ready: PropTypes.bool,
};

export default ListContainer;
