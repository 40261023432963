import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { TDocFile, TDocProject } from 'shared/constants/docs'
import useMenu from 'shared/hooks/use-menu'
import EditFileDescriptionMenuItem from '../../_model/project-files/EditFileDescriptionMenuItem'
import RemoveDocumentsMenuItem from '../../_model/docs/components/RemoveDocumentsMenuItem'
import FileSyncMenuItem from 'app/_model/sync/components/FileSyncMenuItem'
import DownloadFilesMenuItem from 'shared/_model/cloud/components/DownloadFilesMenuItem'

type TActionFileMenuProps = {
  project: TDocProject
  file: TDocFile
  removeFileAction?: () => void
  removeFileCallback?: () => void
  saveFileAction?: () => Promise<void>
  editFileCallback?: () => void
}

export default function ActionFileMenu(props: TActionFileMenuProps): JSX.Element {
  const { project, file, removeFileAction, removeFileCallback, saveFileAction, editFileCallback } = props;

  const { anchorEl, open, handleOpen, handleClose } = useMenu();

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <MoreVert fontSize="small"/>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <FileSyncMenuItem files={[file]} disabled={!project.storeInCloud} handleClose={handleClose} />
        <DownloadFilesMenuItem files={[file]} disabled={!project.storeInCloud} callback={handleClose} />
        <RemoveDocumentsMenuItem docs={file} action={removeFileAction} actionCallback={removeFileCallback} callback={handleClose} />
        <EditFileDescriptionMenuItem
          doc={file}
          action={saveFileAction}
          actionCallback={editFileCallback}
          callback={handleClose}
        />
      </Menu>
    </>
  );
}
