import { useCallback, useEffect, useMemo } from "react"
import useError from 'shared/helpers/use-error'
import ApiError from "./api-error"
import useApi, { TUseApi } from "./useApi"

export type TApiError = { message: string } | ApiError;

type TUseApiSimpleParams<R, E, C> = {
  validate?: (response?: R|E|ApiError) => response is R;
  convert?: (response?: R|E|ApiError) => C|undefined;
  getError?: (response?: R|E|ApiError) => ApiError;
  onError?: (error: ApiError) => void;
}

function defaultValidate(r: unknown) {
  return !!r
}
function defaultConvert<R, C>(r: R | ApiError): C | undefined {
  if (r instanceof ApiError) {
    return undefined
  }
  return r ? ((r as unknown) as C) : undefined
}
const defaultGetError = (response?: unknown): ApiError => {
  let message = '';
  // if (response && typeof response === 'object') {
  //   if ('message' in response && typeof (response as {message: string})?.message === 'string') {
  //     message = (response as {message: string}).message;
  //   } else {
  //     message = JSON.stringify(response);
  //   }
  // }
  if (response && typeof response === 'object') {
    if ('message' in response && typeof (response as {message: string})?.message === 'string') {
      message = (response as {message: string}).message;
    } else {
      message = JSON.stringify(response);
    }
  }
  return new ApiError(500, message);
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiSimple<
  TR, // response from API
  TC = TR, // converted response (from convert(response))
  TD extends Record<string, unknown> = Record<string, unknown>, // request data
  TP extends Record<string, string> = Record<string, string>, // request parameters
  TE extends Record<string, unknown> = {message: string} // api response error
>(params: Parameters<TUseApi<TR|TE,TD,TP>>[0], helpers: TUseApiSimpleParams<TR,TE,TC> = {}) {
  // We'll not pass onError to useApi.
  const { response, error: apiError, loading, doFetch } = useApi<TR|TE,TD,TP>(params);

  const { showError } = useError('Api');

  const {
    validate = defaultValidate,
    convert = defaultConvert,
    getError = defaultGetError,
    onError = showError,
  } = helpers;

  const isValid = validate(response);

  const doFetchSimple = useCallback(async (data?: TD, params?: TP) => {
    const res = await doFetch(data, params);
    return validate(res) ? convert(res) : undefined;
  }, [doFetch, validate, convert]);

  const error = useMemo(() => {
    if (loading) {
      return undefined;
    }
    if (apiError) {
      return apiError;
    }
    if (isValid) {
      return undefined;
    }
    if (response === undefined) {
      return undefined;
    }
    return getError(response);
  }, [apiError, loading, isValid, getError, response]);

  useEffect(() => {
    if (error && !error.aborted && onError) {
      onError(error);
    }
  }, [error, onError]);

  return {
    response: isValid ? convert(response) : undefined,
    error,
    loading,
    doFetch: doFetchSimple,
  };
}
