import { ComponentProps, FC, memo, SVGProps } from 'react';
import { SvgIcon, useTheme } from '@mui/material';

import { ReactComponent as CheckCircleSvg } from 'shared/_assets/icons/check-circle.svg';
import { ReactComponent as CloudSvg } from 'shared/_assets/icons/cloud.svg';
import { ReactComponent as CloudWithCheckSvg } from 'shared/_assets/icons/cloud-with-check.svg';
import { ReactComponent as CopySvg } from 'shared/_assets/icons/copy.svg';
import { ReactComponent as DeleteSvg } from 'shared/_assets/icons/delete.svg';
import { ReactComponent as DownloadSvg } from 'shared/_assets/icons/download.svg';
import { ReactComponent as EditSvg } from 'shared/_assets/icons/edit.svg';
import { ReactComponent as EmptySvg } from 'shared/_assets/icons/empty.svg';
import { ReactComponent as FileMediaSvg } from 'shared/_assets/icons/file-media.svg';
import { ReactComponent as FileMiscSvg } from 'shared/_assets/icons/file-misc.svg';
import { ReactComponent as FileProjectSvg } from 'shared/_assets/icons/file-project.svg';
import { ReactComponent as LocalStorageSvg } from 'shared/_assets/icons/local-storage.svg';
import { ReactComponent as LocalStorageWithCheckSvg } from 'shared/_assets/icons/local-storage-with-check.svg';
import { ReactComponent as MicSvg } from 'shared/_assets/icons/mic.svg';
import { ReactComponent as MoreVertSvg } from 'shared/_assets/icons/more-vert.svg';
import { ReactComponent as PlusSvg } from 'shared/_assets/icons/plus.svg';
import { ReactComponent as ShareSvg } from 'shared/_assets/icons/share.svg';
import { ReactComponent as SyncSvg } from 'shared/_assets/icons/sync.svg';
import { ReactComponent as VideoSvg } from 'shared/_assets/icons/video.svg';
import { ReactComponent as YoutubeSvg } from 'shared/_assets/icons/youtube.svg';

import { grey } from '@mui/material/colors';
import { MoreVert } from '@mui/icons-material';

type IconSize = 'medium';
type IconProps = ComponentProps<typeof SvgIcon> & {
	size?: IconSize | string | number;
};

const SIZE_MAP: Record<IconSize, string> = {
	medium: '0.7rem',
} as const;

const getIcon = (component: FC<SVGProps<SVGSVGElement> & { title?: string }>) => {
	const C = memo(({ size, ...props }: IconProps) => {
		let { sx } = props;
		if (size) {
			const sizeValue = SIZE_MAP[size as keyof typeof SIZE_MAP] ?? size;
			sx = { ...sx, width: sizeValue, height: sizeValue };
		}

		return <SvgIcon inheritViewBox {...props} sx={sx} component={component} />;
	});
	C.displayName = component.name;
	return C;
};

export const IconCheckCircle = getIcon(CheckCircleSvg);
export const IconCloud = getIcon(CloudSvg);
export const IconCloudWithCheck = getIcon(CloudWithCheckSvg);
export const IconCopy = getIcon(CopySvg);
export const IconDelete = getIcon(DeleteSvg);
export const IconDownload = getIcon(DownloadSvg);
export const IconEdit = getIcon(EditSvg);
export const IconEmpty = getIcon(EmptySvg);
export const IconFileMedia = getIcon(FileMediaSvg);
export const IconFileMisc = getIcon(FileMiscSvg);
export const IconFileProject = getIcon(FileProjectSvg);
export const IconLocalStorage = getIcon(LocalStorageSvg);
export const IconLocalStorageWithCheck = getIcon(LocalStorageWithCheckSvg);
export const IconMic = getIcon(MicSvg);
export const IconMoreVert = MoreVert;
export const IconPlus = getIcon(PlusSvg);
export const IconShare = getIcon(ShareSvg);
export const IconSync = getIcon(SyncSvg);
export const IconVideo = getIcon(VideoSvg);
export const IconYoutube = getIcon(YoutubeSvg);

type ColorType = 'primary' | 'success' | 'disabled' | 'warning';
type ColorTypeAny = ColorType | Omit<string, ColorType>;
type ColoredIconProps = Omit<IconProps, 'color'> & {
	Component: ReturnType<typeof getIcon>;
	color: ColorTypeAny;
};
function useIconColor(type: ColorTypeAny) {
	const theme = useTheme();
	const MAP: Record<ColorType, string> = {
		disabled: grey[300],
		primary: theme.palette.primary.main,
		success: theme.palette.success.main,
		warning: theme.palette.warning.main,
	};
	return MAP[type as ColorType] ?? type;
}
export function ColoredIcon({ Component, color: colorProp, ...props }: ColoredIconProps) {
	const color = useIconColor(colorProp);
	return <Component {...props} sx={{ ...props.sx, color }} />;
}
export function PrimaryIcon(props: Omit<ColoredIconProps, 'color'>) {
	return <ColoredIcon color="primary" {...props} />;
}
export function SuccessIcon(props: Omit<ColoredIconProps, 'color'>) {
	return <ColoredIcon color="success" {...props} />;
}
export function DisabledIcon(props: Omit<ColoredIconProps, 'color'>) {
	return <ColoredIcon color="disabled" {...props} />;
}
export function WarningIcon(props: Omit<ColoredIconProps, 'color'>) {
	return <ColoredIcon color="warning" {...props} />;
}

// Examples:
// <SuccessIcon Component={IconCloud} />
// <DisabledIcon Component={IconCloud} />
// <ColoredIcon Component={IconCloud} color="success" />
// <ColoredIcon Component={IconCloud} color="orange" />
