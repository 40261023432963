import cn from 'classnames';
import { Field, Form, Formik } from 'formik';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import React, { useCallback, useEffect, useState } from 'react';
import { object, string, array } from 'yup';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Spinner from '../../../../components/Spinner/Spinner';
import { apiAllUsersDetails, apiSendByEmails } from '../../../../utils/emailCommunications';
import { PageContainer } from '../../PageContainer';
import styles from './EmailCommunications.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function EmailCommunications() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [users, setUsers] = useState([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [showEmails, setShowEmails] = useState(false);

  const readAllUsers = useCallback(async ({ setFieldValue, setSubmitting } = {}) => {
    if (setSubmitting) {
      setSubmitting(true);
    }

    try {
      const users = (await apiAllUsersDetails())
        .filter((u) => !!u.email)
        .map(({ name, email }) => ({ name, email }));

      if (setFieldValue) {
        setFieldValue('users', users);
        setShowEmails(false);
      } else {
        setUsers(users);
        setUsersLoaded(true);
        setShowEmails(false);
      }
    } finally {
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  }, []);

  useEffect(() => {
    readAllUsers();
  }, [readAllUsers]);

  const toggleShowEmails = useCallback(() => {
    setShowEmails(s => !s);
  }, []);

  const clearUsers = useCallback(({ setFieldValue }) => {
    setFieldValue('users', []);
  }, []);

  const handleSend = useCallback(async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { subject, users } = values;
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const response = await apiSendByEmails(subject, content, users.map(usr => usr.email));
      alert(response);
    } finally {
      setSubmitting(false);
    }
  }, [editorState]);

  if (!usersLoaded) {
    return null;
  }

  return (
    <PageContainer title="Communications">
      <Formik
        initialValues={{ subject: '', users }}
        validationSchema={object().shape({
          subject: string().required(),
          users: array().required()
        })}
        onSubmit={handleSend}
        render={({ values, setFieldValue, setSubmitting, isSubmitting }) => (
          <Form>
            <div className={styles.header}>
              {showEmails
                ? (
                  <>
                    <Field
                      name="users"
                      component={Input}
                      type={Input.TYPE.MULTI_SELECT}
                      label="To:"
                      labelWidth={Input.LABEL_WIDTH.RIGHT}
                      labelClassName={styles.label}
                      spaceBottom={Input.SPACE_BOTTOM.NONE}
                      textField="name"
                      allowCreate={false}
                      data={users}
                      itemComponent={({ item }) => (
                        <span>
                                                      <strong>{item.name}</strong>
                          {' <'}
                          {item.email}
                          {'>'}
                                                  </span>
                      )}
                      rootClassName={styles.input}
                      // tagComponent={TagItem}
                    />
                    <div className={cn(styles.buttons, !showEmails && styles.row)}>
                      <Button
                        small
                        disabled={isSubmitting}
                        onClick={() => readAllUsers({ setFieldValue, setSubmitting })}
                        className={styles.button}
                      >
                        All
                      </Button>
                      <Button
                        small
                        disabled={!users.length || isSubmitting}
                        onClick={() => clearUsers({ setFieldValue, setSubmitting })}
                        className={styles.button}
                      >
                        Clear
                      </Button>
                    </div>
                  </>
                )
                : (
                  <Field
                    label="То:"
                    name="labelTo"
                    placeholder="example@email.com"
                    component={Input}
                    labelClassName={styles.label}
                    type={Input.TYPE.LABEL}
                    editClick={toggleShowEmails}
                    field={{
                      name: 'labelTo',
                      value: (
                        <>
                          {usersLoaded ? values.users.length : <Spinner withSpace />}
                          {' '}
                          users
                        </>
                      ),
                    }}
                    spaceBottom={Input.SPACE_BOTTOM.NONE}
                    readOnly
                    rootClassName={styles.input}
                  />
                )}
            </div>
            <div className={styles.header}>
              <Field
                label="Subject"
                name="subject"
                placeholder="subject"
                component={Input}
                labelClassName={styles.label}
                spaceBottom={Input.SPACE_BOTTOM.NONE}
                rootClassName={styles.input}
                spellCheck
              />
              <div className={styles.buttons}>
                <Button
                  small
                  disabled={isSubmitting}
                  type="submit"
                  className={cn(styles.button, styles.send)}
                >
                  Send
                </Button>
              </div>
            </div>
          </Form>
        )}
      />
      <Editor
        editorState={editorState}
        wrapperClassName={styles.wrapper}
        toolbarClassName={styles.toolbar}
        editorClassName={styles.editor}
        onEditorStateChange={setEditorState}
        spellCheck
      />
    </PageContainer>
  );
}
