import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TDocFile } from 'shared/constants/docs';
import * as projectActions from 'shared/modules/projects/actions';
import { scanProjectFiles } from 'app/_temp/new_file_search';
import { TSelectedFile } from 'shared/utils/files/files';
import { addFilesToProject } from 'shared/utils/files/filesCommon';

type TUseProjectFilesApiProps = {
  projectId: string
}
type TUseProjectFilesApiResult = {
  findFiles: () => Promise<TDocFile[]>
  addFiles: (files: TSelectedFile[], options: { move: boolean }) => Promise<boolean>
  removeFile: (fileIds: string[]) => Promise<string[]>
}

// eslint-disable-next-line import/prefer-default-export
export const useProjectFilesApi = ({ projectId }: TUseProjectFilesApiProps): TUseProjectFilesApiResult => {
	const dispatch = useDispatch();

	const findFiles = useCallback(async () => await dispatch<Promise<TDocFile[]>>(scanProjectFiles(projectId, false)), [dispatch, projectId]);

	const addFiles = useCallback(async (files: TSelectedFile[], options: { move: boolean }) => {
		await dispatch(addFilesToProject(projectId, files, options));
		return true;
	}, [dispatch, projectId]);

	const removeFile = useCallback(async (fileIds: string[]) => dispatch<Promise<string[]>>(projectActions.removeFile(fileIds, projectId)), [dispatch, projectId]);

	return {
		findFiles,
		addFiles,
		removeFile,
	};
};
