import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { removeEpisodes } from 'shared/modules/episodes/actions';
import useMenu from 'shared/hooks/use-menu';
import { IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import RemoveDocumentsMenuItem from 'shared/_model/docs/components/RemoveDocumentsMenuItem';

export default function EpisodeActionMenu({ item, removeCallback }) {
  const dispatch = useDispatch();
  const removeAction = useCallback(async (ids, cb) => await dispatch(removeEpisodes(ids, cb)), [dispatch]);

  const { anchorEl, open, handleOpen, handleClose } = useMenu();

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <MoreVert fontSize="small"/>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <RemoveDocumentsMenuItem docs={[item]} action={removeAction} actionCallback={removeCallback} callback={handleClose} />
      </Menu>
    </>
  );
}

EpisodeActionMenu.propTypes = {
    item: PropTypes.shape({}),
    removeCallback: PropTypes.func,
};
