import { FONT_FAMILY_HEADER } from 'shared/Providers/MuiTheme'
import { styled } from '@mui/material/styles';
import { Breadcrumbs, Typography } from '@mui/material'

const fontSxProps = { fontFamily: FONT_FAMILY_HEADER, fontWeight: 300 };

export const PageContainerBreadcrumbs = styled(Breadcrumbs)({
  ...fontSxProps,
  minWidth: '10%',
  width: '100%',
  backgroundColor: 'transparent',
  '.MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '.MuiBreadcrumbs-li:last-child': {
    display: 'flex',
    overflow: 'hidden',
  }
});

export const TextItem = styled(Typography)({
  display: 'inline-block',
  ...fontSxProps,
});

export const TextItemLast = styled(TextItem)({
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: 'unset',
});
