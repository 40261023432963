import React from 'react';


export const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { width = 72, height = 72, stroke = '#fff', fill = '#000', ...rest } = props;
  return (
    // @ts-ignore
    <svg width={width} height={height} version="1.2" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="27" cy="27" r="26.5" stroke={stroke} fill={fill} strokeWidth="1" />
      <g transform="matrix(.42751 0 0 .42751 -1.8487 -1.665)">
        {/*<path d="m130.14 67.051c0 34.605-28.055 62.656-62.656 62.656-34.605 0-62.656-28.051-62.656-62.656 0-34.602 28.051-62.656 62.656-62.656 34.602 0 62.656 28.055 62.656 62.656" />*/}
        {/*<path d="m0.0010188 5.1875e-4c0-34.605-28.055-62.656-62.656-62.656-34.605 0-62.656 28.051-62.656 62.656 0 34.602 28.051 62.656 62.656 62.656 34.602 0 62.656-28.055 62.656-62.656z"*/}
        {/*      transform="matrix(1 0 0 -1 130.14 67.051)"*/}
        {/*      fill="none" stroke="#fff" stroke-miterlimit="10" />*/}
        <g fill={stroke}>
          <path
            d="m55.906 58.625h-6.2188l-3.6992 21.754c-0.09766 0.49609-0.17188 0.99219-0.22266 1.4844-0.04687 0.49219-0.07422 0.93359-0.07422 1.332 0 1.2305 0.24609 2.0703 0.74219 2.5117 0.49219 0.44531 1.2305 0.66797 2.2188 0.66797 0.44531 0 0.92578-0.05859 1.4453-0.18359 0.51562-0.125 0.97266-0.26172 1.3672-0.40625l-0.58984 4.8086c-0.64453 0.24609-1.332 0.44531-2.0742 0.58984-0.73828 0.14844-1.457 0.22266-2.1445 0.22266-2.418 0-4.1953-0.61328-5.3281-1.8516-1.1367-1.2305-1.7031-2.8828-1.7031-4.957 0-0.88672 0.09766-1.8945 0.29688-3.0312l3.9961-22.941h-5.1055l0.8125-4.8828h4.957l1.7031-9.918h5.8477l-1.6289 9.918h6.2891z" />
          <path
            d="m70.555 42.121c0 1.2852-0.40625 2.3828-1.2188 3.293-0.8125 0.91406-1.8398 1.3711-3.0703 1.3711-1.0859 0-1.9258-0.35938-2.5156-1.0742-0.59375-0.71484-0.89062-1.5391-0.89062-2.4766 0-1.1836 0.39453-2.2578 1.1836-3.2188 0.78906-0.96484 1.8008-1.4453 3.0352-1.4453 1.0859 0 1.9336 0.33203 2.5547 1 0.61328 0.66406 0.92188 1.5156 0.92188 2.5508m-9.0273 48.695h-5.9922l6.4375-37.074h5.9922z" />
          <path d="m82.914 90.816h-7.1055l-3.1797-37.074h6.1406l1.5547 30.711h0.14844l12.504-30.711h6.4414z" />
        </g>
        <path d="m130 29h-43v-12h43z" fill="#f00" />
        <g fill={stroke}>
          <path
            d="m99.848 25.52c0.14844 0.01953 0.32812 0.01953 0.58984 0.01953 0.6875 0 1.3008-0.26172 1.3008-0.96875 0-0.68359-0.61328-0.95312-1.3711-0.95312h-0.51953zm0-2.9883h0.5c0.80078 0 1.2305-0.33203 1.2305-0.83203 0-0.50781-0.39063-0.77734-1.082-0.77734-0.32812 0-0.51953 0.01953-0.64843 0.03906zm-1.5117-2.582c0.40234-0.07813 1.2109-0.13672 1.9727-0.13672 0.92969 0 1.5 0.08984 1.9883 0.37891 0.46875 0.25 0.80859 0.71094 0.80859 1.3164 0 0.60156-0.35156 1.1602-1.1094 1.4414v0.01953c0.76954 0.21094 1.3398 0.78906 1.3398 1.6602 0 0.60938-0.28125 1.0898-0.69922 1.4297-0.48828 0.39062-1.3086 0.60938-2.6484 0.60938-0.75 0-1.3125-0.05078-1.6523-0.10156z" />
          <path d="m108.39 23.75h-2.4805v1.5977h2.7695v1.25h-4.2969v-6.7383h4.1562v1.25h-2.6289v1.3984h2.4805z" />
          <path d="m111.07 21.141h-1.8086v-1.2812h5.1758v1.2812h-1.8359v5.457h-1.5312z" />
          <path
            d="m117.8 23.727-0.41797-1.4258c-0.12109-0.40234-0.23828-0.90234-0.33984-1.3008h-0.0195c-0.10156 0.39844-0.19921 0.91016-0.3125 1.3008l-0.39843 1.4258zm-1.707 1.1406-0.48047 1.7305h-1.5781l2.0586-6.7383h1.9961l2.0898 6.7383h-1.6367l-0.51954-1.7305z" />
        </g>
      </g>
    </svg>
  );
};
