import React from 'react';

const k = 23.84 / 28.01;

export const IconFileTypeMisc: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { height = 30, fill = 'black', ...rest } = props;

  const aspectWidth = (height as number) * k;

  return (
    // @ts-ignore
    <svg {...rest} width={aspectWidth} height={height} fill={fill} viewBox="0 0 23.84 28.01" xmlns="http://www.w3.org/2000/svg">
      <path d="m23.51 6.54-6.22-6.21c-0.21-0.22-0.49-0.33-0.78-0.33h-11.24c-0.62 0-1.11 0.49-1.11 1.11v3.05h-3.05c-0.61 0-1.11 0.49-1.11 1.11v21.63c0 0.61 0.49 1.11 1.11 1.11h17.46c0.6 0 1.11-0.49 1.11-1.11v-3.05h3.05c0.6 0 1.11-0.49 1.11-1.11v-15.42c-0.01-0.29-0.12-0.57-0.33-0.78zm-3.45-0.33h-2.44v-2.44zm-6.51 1.9c0 0.61-0.49 1.11-1.11 1.11h-3.01c-0.61 0-1.11-0.49-1.11-1.11 0-0.61 0.49-1.11 1.11-1.11h3.01c0.61 0.01 1.11 0.5 1.11 1.11zm-4.12 9.47h9.13c0.6 0 1.11 0.49 1.11 1.11 0 0.6-0.5 1.11-1.11 1.11h-9.13c-0.61 0-1.11-0.5-1.11-1.11 0-0.62 0.5-1.11 1.11-1.11zm-1.11-2.42c0-0.61 0.49-1.11 1.11-1.11h9.13c0.6 0 1.11 0.49 1.11 1.11 0 0.6-0.5 1.11-1.11 1.11h-9.13c-0.61 0-1.11-0.5-1.11-1.11zm10.24-2.42h-9.13c-0.61 0-1.11-0.49-1.11-1.11 0-0.6 0.49-1.11 1.11-1.11h9.13c0.6 0 1.11 0.5 1.11 1.11-0.01 0.62-0.51 1.11-1.11 1.11zm-1.09 13.05h-15.25v-19.42h1.95v16.37c0 0.61 0.49 1.11 1.11 1.11h12.2v1.94z" />
    </svg>
  );
};
