import React, { useCallback, useRef } from 'react';
import { TDocumentTypeFile } from 'shared/constants/docs'
import { asyncForEach } from 'shared/utils/common';
import { getSelectedFile, TSelectedFile } from 'shared/utils/files/files';
import { Button } from '@mui/material'


type TFileInputButtonProps = {
  fileType: TDocumentTypeFile
  addFiles: (files: TSelectedFile[]) => void
  disabled?: boolean
  label?: string
};


export default function FileInputButton(props:TFileInputButtonProps):JSX.Element {
  const { disabled = false, fileType, addFiles, label } = props
  const inputRef = useRef<HTMLInputElement>(null) // Weird, `useRef<HTMLInputElement>(null)` does not work

  const handleButtonClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [inputRef])

  const handleInputChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('selected files [web]', e.target.files)
    const files = await asyncForEach(
      [...(e.target.files ?? [])],
      async (file) => getSelectedFile(file, fileType)
    )
    addFiles(files)
  }, [fileType, addFiles])


  return (
    <>
      <div>
        <Button onClick={handleButtonClick} disabled={disabled} size="small">
          {label ?? 'Add'}
        </Button>
      </div>
      <input ref={inputRef} onChange={handleInputChange} type="file" multiple style={{ display: 'none' }} />
    </>
  )
}
