import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionEpisodeUploadToYoutube
  from '../../../../components/ActionEpisodeUploadToYoutube/ActionEpisodeUploadToYoutube';
import EpisodeActionMenu from '../../../../components/Episodes/EpisodeActionMenu/EpisodeActionMenu';
import { WindowContainer } from '../../../../components/WindowContainer';
import { CONTENT_TYPE } from '../../../../constants/general';
import * as episodesActions from '../../../../modules/episodes/actions';
import EpisodeCreate from '../EpisodeCreate/EpisodeCreate';
import ListContainer from '../../../../components/ListContainer/ListContainer';
import SeasonCreate from '../SeasonCreate/SeasonCreate';
import EpisodeListItemDetails from './EpisodeListItemDetails';


const ItemIcons = ({ item, ...rest }) => (
  <>
    <ActionEpisodeUploadToYoutube
      episodeId={item._id}
      seriesId={item.seriesId}
      youtubeId={item.fileDoc && item.fileDoc.youtube && item.fileDoc.youtube.youtubeId}
    />
    <EpisodeActionMenu item={item} {...rest} />
  </>
);
ItemIcons.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    seriesId: PropTypes.string,
    fileDoc: PropTypes.shape({
      youtube: PropTypes.shape({
        youtubeId: PropTypes.string,
      }),
    })
  }),
  removeCallback: PropTypes.func,
};

const EpisodeListItemMiddle = ({item}) => `No. ${item.number ?? ''}`

export default function EpisodeList({ seriesId, seasonId, noPadding }) {
  const [list, setList] = useState([]);

  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);
  const [showCreateNewSeason, setShowCreateNewSeason] = useState(false);
  const [showCreateNewEpisode, setShowCreateNewEpisode] = useState(false);
  const update = useCallback(async () => {
    // await dispatch(episodesActions.readEpisodesList(seriesId, seasonId));
    const res = await dispatch(episodesActions.fetchEpisodes(seriesId, seasonId));
    setList(res);
    setReady(true);
  }, [dispatch, seriesId, seasonId]);

  useEffect(() => {
    update();
  }, [update]);

  const toggleCreateNewSeason = useCallback(async () => {
    setShowCreateNewSeason(s => !s);
  }, []);

  const toggleCreateNewEpisode = useCallback(async () => {
    setShowCreateNewEpisode(s => !s);
  }, []);

  return (
    <div style={{ paddingLeft: noPadding ? 0 : '50px' }}>
      <ListContainer
        ready={ready}
        items={list}
        type={CONTENT_TYPE.EPISODE}
        icons={props => <ItemIcons removeCallback={update} {...props} />}
        actions={seasonId
          ? [
            { label: 'Add Episode', onClick: toggleCreateNewEpisode },
          ]
          : [
            { label: 'Add Season', onClick: toggleCreateNewSeason },
            { label: 'Add Episode', onClick: toggleCreateNewEpisode },
          ]
        }
        details={EpisodeListItemDetails}
        middle={EpisodeListItemMiddle}
        title="Episodes"
        titleLevel={3}
        titleClassName="left"
      />
      {showCreateNewSeason && (
        <WindowContainer closeAction={toggleCreateNewSeason}>
          <SeasonCreate
            seriesId={seriesId}
            onChange={toggleCreateNewSeason}
          />
        </WindowContainer>
      )}
      {showCreateNewEpisode && (
        <WindowContainer closeAction={toggleCreateNewEpisode}>
          <EpisodeCreate
            seriesId={seriesId}
            seasonId={seasonId}
            onChange={toggleCreateNewEpisode}
          />
        </WindowContainer>
      )}
    </div>
  );
}

EpisodeList.propTypes = {
  seriesId: PropTypes.string.isRequired,
  seasonId: PropTypes.string,
  noPadding: PropTypes.bool,
};
