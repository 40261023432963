import { getFrameCanvas } from './canvas'
import { getDefaultPosition, getDefaultQuality, getDefaultTimeout, getDefaultType, validateErrors, validatePosition, validateQuality, validateRatio, validateSource, validateTimeout } from './options'
import { getTime } from './utils'

type TGetVideoFrameOptions = {
  position: number;
  quality?: number;
  type: string;
  timeout: number;
  ratio?: number;
}

type TGetVideoFrameResult = {
  height: number;
  position: number;
  type: string;
  url: string;
  width: number;
  time: number;
}

function getOptions(options?: Partial<TGetVideoFrameOptions>): TGetVideoFrameOptions {
  const { ratio, type, quality, position, timeout } = options ?? {};
  return {
    ratio,
    type: getDefaultType(type),
    quality: getDefaultQuality(quality),
    position: getDefaultPosition(position),
    timeout: getDefaultTimeout(timeout),
  };
}

function validate(videoSrc: string, options?: Partial<TGetVideoFrameOptions>): TGetVideoFrameOptions {
  const opts = getOptions(options);

  validateErrors([
    validateSource(videoSrc),
    validatePosition(opts.position),
    validateTimeout(opts.timeout),
    validateQuality(opts.quality),
    validateRatio(opts.ratio),
  ]);

  return opts;
}

export async function getVideoFrame(videoSrc: string, options?: Partial<TGetVideoFrameOptions>): Promise<TGetVideoFrameResult|undefined> {
  const opts = validate(videoSrc, options);

  try {
    const canvas = await getFrameCanvas(videoSrc, opts);
    if (canvas) {
      try {
        const start = window.performance.now();
        const url = canvas.toDataURL(opts.type, opts.quality);
        return {
          height: canvas.height,
          position: opts.position,
          type: opts.type,
          url,
          width: canvas.width,
          time: getTime(start),
        };
      } catch (e) {
        throw new Error(`Can't get dataUrl: ${e}`);
      }
    }
  } catch (e) {
    console.error('Error getting video frame', e);
  }

  return undefined;
}
