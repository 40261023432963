import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react'

interface IUseEditDocument {
  isEdit: boolean;
  toggleEdit: (cb?: () => unknown) => void;
  resetEdit: (cb?: () => unknown) => void;
}

const EditDocumentContext = createContext<IUseEditDocument>({
  isEdit: false,
  toggleEdit: () => undefined,
  resetEdit: () => undefined,
});

interface IEditDocumentProviderProps {
  children: ReactNode;
}

export default function EditDocumentProvider({ children }: IEditDocumentProviderProps): JSX.Element {
  const [isEdit, setIsEdit] = useState(false);

  const toggleEdit = useCallback((resetForm?: () => unknown) => {
    setIsEdit(edit => {
      if (edit && typeof resetForm === 'function') {
          resetForm();
      }
      return !edit;
    });
  }, []);

  const resetEdit = useCallback((resetForm?: () => unknown) => {
    setIsEdit(edit => {
      if (edit && typeof resetForm === 'function') {
          resetForm();
      }
      return false;
    });
  }, []);


  const value = useMemo(() => ({ isEdit, toggleEdit, resetEdit }), [isEdit, toggleEdit, resetEdit]);

  return (
    <EditDocumentContext.Provider value={value}>
      {children}
    </EditDocumentContext.Provider>
  );
}

export const useEditDocumentContext = (): IUseEditDocument => useContext(EditDocumentContext);
