import { Tab as MUITab, TabProps } from '@mui/material'
import { styled } from '@mui/material/styles';
import { FONT_FAMILY_HEADER } from 'shared/Providers/MuiTheme'

const Tab = styled(MUITab)<TabProps>(({ theme }) => ({
  fontFamily: FONT_FAMILY_HEADER,
  fontSize: theme.typography.pxToRem(31),
  textTransform: 'none',
}));

export default Tab;
