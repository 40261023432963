import React from 'react';
import { InformationMenu } from 'shared/Pages/common/Header/InformationMenu';
import { QuickSearch } from 'shared/Pages/common/Header/QuickSearch';
import styles from 'shared/Pages/common/Header/header.module.scss';
import AccountMenu from './AccountMenu'


export const Header: React.FC = () => {
  return (
    <header id="globalHeader" className={styles.wrapper}>
      <div className={styles.searchBar}>
        <QuickSearch />
      </div>
      <div className={styles.user}>
        <InformationMenu />
        <AccountMenu />
      </div>
    </header>
  );
}
