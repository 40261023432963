import { CloudValue } from 'shared/_model/cloud/types'

export default class CloudProvider<T extends CloudValue = CloudValue> {
  protected _value: T | undefined

  protected _url: string | undefined = undefined

  constructor(value: T) {
    this._value = value
  }

  public get value() {
    return this._value
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  public async getUrl(force?: boolean): Promise<string | undefined> {
    throw new Error('class must implement `getUrl` function')
  }
}
