// import { BrowserWindow, remote, remoteMain } from '../../../../../src/desktop/utils/system';

// TODO: this doesn't work when electron was updated

export const openModalWindow = (windowUrl, onRedirect) => {
    // const modalWindow = new BrowserWindow({
    //     parent: remote.getCurrentWindow(),
    //     modal: true,
    //     show: false,
    //     width: 600,
    //     height: 600,
    //     frame: false
    // });
    //
    // // remoteMain.enable(modalWindow.webContents);
    // modalWindow.loadURL(windowUrl);
    //
    // modalWindow.webContents.on('did-finish-load', () => {
    //     if (!modalWindow) {
    //         throw new Error('"mainWindow" is not defined');
    //     }
    //     modalWindow.show();
    //     modalWindow.focus();
    // });
    //
    // if (onRedirect) {
    //     modalWindow.webContents.on('will-redirect', (event, url) => {
    //         onRedirect(modalWindow, event, url);
    //     });
    // }
};
