import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import FileInputButton from 'app/components/FileInputButton'
import {
	DOC_TYPE_FILE_AUDIO,
	DOC_TYPE_FILE_FINISH_AUDIO,
	DOC_TYPE_FILE_FINISH_VIDEO,
	DOC_TYPE_FILE_MEDIA,
	DOC_TYPE_FILE_MISC,
	DOC_TYPE_FILE_PROJECT,
	DOCUMENT_DESCRIPTIONS,
} from '../../../constants/docs'
import { isFinishFile } from '../../../constants/pouchDB'
// import { getSelectedFiles } from '../../../utils/files/files';
import ActionFileTypeMenu from '../../ActionFileTypeMenu/ActionFileTypeMenu'
// import Button from '../../Button/Button';
import Input from '../../Input/Input'
import ProjectListItemFile from '../ProjectListItemFile/ProjectListItemFile'
import { IconFileTypeAudio } from './icons/IconFileTypeAudio'
import { IconFileTypeMedia } from './icons/IconFileTypeMedia'
import { IconFileTypeMisc } from './icons/IconFileTypeMisc'
import { IconFileTypeProject } from './icons/IconFileTypeProject'
import styles from './ProjectFileTypeContainer.module.css'

const DOC_TYPES_SETTINGS = {
	[DOC_TYPE_FILE_MEDIA]: {
		icon: <IconFileTypeMedia />,
		addButtonLabel: 'Add',
	},
	[DOC_TYPE_FILE_MISC]: {
		icon: <IconFileTypeMisc />,
		addButtonLabel: 'Add',
	},
	[DOC_TYPE_FILE_AUDIO]: {
		icon: <IconFileTypeAudio />,
		addButtonLabel: 'Add',
	},
	[DOC_TYPE_FILE_PROJECT]: {
		icon: <IconFileTypeProject />,
		addButtonLabel: 'Add',
	},
	[DOC_TYPE_FILE_FINISH_VIDEO]: {
		addButtonLabel: 'Add Video',
	},
	[DOC_TYPE_FILE_FINISH_AUDIO]: {
		addButtonLabel: 'Add Audio',
	},
}

export default function ProjectFileTypeContainer({
	projectId,
	projectProps,
	fileType,
	files,
	removeFile,
	saveFile,
	callback,
	addFiles,
	sharingId,
}) {
	const [checkList, setCheckList] = useState([])

	const isItemChecked = useCallback((id) => checkList.includes(id), [checkList])

	const handleItemCheckAction = useCallback((id) => {
		setCheckList((checkList) => {
			if (checkList.includes(id)) {
				return checkList.filter((val) => val !== id)
			}
			return [...checkList, id]
		})
	}, [])

	const handleItemCheckAllAction = useCallback(() => {
		setCheckList((checkList) => {
			if (checkList.length !== files.length) {
				return files.map((file) => file._id)
			}
			if (files.length) {
				return []
			}
		})
	}, [files])

	const header = useMemo(() => {
		return isFinishFile(fileType) ? (
			<h1>{DOCUMENT_DESCRIPTIONS[fileType].title}</h1>
		) : (
			<>
				<div className={styles.mediaGroupIcon}>{DOC_TYPES_SETTINGS[fileType].icon}</div>
				<div className={styles.mediaGroupTitle}>{`${DOCUMENT_DESCRIPTIONS[fileType].title}:`}</div>
			</>
		)
	}, [fileType])

	const footer = useMemo(() => {
		return (
			<>
				{addFiles && (
					<FileInputButton
						fileType={fileType}
						addFiles={addFiles}
						label={DOC_TYPES_SETTINGS[fileType].addButtonLabel}
					/>
				)}
				<ActionFileTypeMenu
					fileType={fileType}
					project={{
						_id: projectId,
						storeInCloud: projectProps.storeInCloud,
					}}
					removeFileAction={removeFile}
					removeFileCallback={callback}
					checkedFiles={files.filter((f) => isItemChecked(f._id))}
					checkDisabled={!files.length}
					selectedAll={checkList.length === files.length}
					checkListsCheckAllAction={handleItemCheckAllAction}
				/>
			</>
		)
	}, [
		addFiles,
		fileType,
		projectId,
		projectProps,
		removeFile,
		callback,
		files,
		isItemChecked,
		checkList,
		handleItemCheckAllAction,
	])

	return (
		<div className={styles.container}>
			<div className={cn(styles.header, isFinishFile(fileType) && styles.headerFull)}>{header}</div>
			<div className={cn(styles.content, isFinishFile(fileType) && styles.leftPad0)}>
				{files.map((fileDoc) => (
					<div
						key={fileDoc._id}
						className={cn(styles.file, isFinishFile(fileType) && styles.withHover)}>
						<ProjectListItemFile
							sharingId={sharingId}
							file={fileDoc}
							removeFile={removeFile}
							saveFile={saveFile}
							callback={callback}
							projectProps={projectProps}
						/>
						<div className={styles.fileActions}>
							<Input
								type={Input.TYPE.CHECKBOX}
								form={{ touched: null, errors: null }}
								field={{ name: `isChecked_${fileDoc._id}`, value: isItemChecked(fileDoc._id) }}
								rootClassName={styles.checkInput}
								noValidate
								onChange={() => handleItemCheckAction(fileDoc._id)}
							/>
						</div>
					</div>
				))}
			</div>
			<div className={cn(styles.footer, isFinishFile(fileType) && styles.footerRight)}>
				{footer}
			</div>
		</div>
	)
}

ProjectFileTypeContainer.propTypes = {
	sharingId: PropTypes.string,
	projectId: PropTypes.string.isRequired,
	fileType: PropTypes.string.isRequired,
	files: PropTypes.array,
	projectProps: PropTypes.shape({
		storeInCloud: PropTypes.bool,
		storeOnLocalDrive: PropTypes.bool,
	}),
	addFiles: PropTypes.func,
	removeFile: PropTypes.func,
	saveFile: PropTypes.func,
	callback: PropTypes.func,
}
