import axios, { AxiosError, AxiosResponse, AxiosRequestConfig, Method } from 'axios'
import { useMemo } from 'react';
import { getLocalStorageItem, LOCAL_STORAGE_KEY_TOKEN } from '../local-storage';
import ApiError from './api-error'

async function request<T>(method: Method, config: AxiosRequestConfig): Promise<T> {
	try {
		return await axios.request({...config, method});
	} catch (e) {
    let status = 500;
    let message: string;
    let aborted = false;
    if (e instanceof AxiosError) {
      if (e.response?.status) {
        status = e.response?.status;
      }
      aborted = (e.code === 'ERR_CANCELED') || e.name === 'CanceledError' || e.config?.signal?.aborted || false;
      message = e.response?.data?.message ?? e.message;
    } else if (e instanceof Error) {
      message = e.message;
    } else {
      message = `${e}`;
    }

		if (typeof message !== 'string') {
			message = JSON.stringify(message);
		}

		throw new ApiError(status, message, aborted);
	}
}

/** Modify request headers and adds token */
function getConfig(config?: AxiosRequestConfig, url?: string): AxiosRequestConfig {
  const token = getLocalStorageItem(LOCAL_STORAGE_KEY_TOKEN)?.token;
  const { headers, baseURL, ...rest } = config ?? {};
  return {
    ...rest,
    // TODO: can we make this prettier?
    baseURL: baseURL || url?.includes('://') || window.location.origin.includes('localhost') ? baseURL : process.env.REACT_APP_API_URI,
    headers: { ...headers, 'Authorization': `Bearer ${token}` }
  };
}

type THttpRequests = {
  request<T = unknown, R = AxiosResponse<T>, D = unknown>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T = unknown, R = AxiosResponse<T>, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
};

const httpRequests: THttpRequests = {
	request: async ({ method, ...config }) => request(method as Method, getConfig(config)),
	delete: async (url, config) => request('delete', { url, ...getConfig(config, url) }),
	get: async (url, config) => request('get', { url, ...getConfig(config, url) }),
	patch: async (url, data, config) => request('patch', { url, data, ...getConfig(config, url) }),
	post: async (url, data, config) => request('post', { url, data, ...getConfig(config, url) }),
	put: async (url, data, config) => request('put', { url, data, ...getConfig(config, url) }),
};

export const useAuthRequest = (): THttpRequests => useMemo<THttpRequests>(() => {
  async function runRequest<T>(method: Method, config: AxiosRequestConfig): Promise<T> {
    try {
      return await request(method, config);
    } catch (e) {
      if ((e as ApiError).needsAuthentication) {
        window.location.reload();
        return (undefined as unknown) as T;
      }
      throw e;
    }
  }

  return {
    request: async ({ method, ...config }) => runRequest(method as Method, getConfig(config)),
    delete: async (url, config) => runRequest('delete', { url, ...getConfig(config, url) }),
    get: async (url, config) => runRequest('get', { url, ...getConfig(config, url) }),
    patch: async (url, data, config) => runRequest('patch', { url, data, ...getConfig(config, url) }),
    post: async (url, data, config) => runRequest('post', { url, data, ...getConfig(config, url) }),
    put: async (url, data, config) => runRequest('put', { url, data, ...getConfig(config, url) }),
  };
}, []);

export default httpRequests;
