// import { app } from 'app/utils/system';
import configs from './configs';
// import { pathJoin } from '../utils/desktop/path';
import { isWeb } from './modules';

export const frontEndLocation = isWeb ? window.location.origin : configs.frontEndLocation;

// export const systemPaths = !isWeb
// 	? {
// 			home: app.getPath('home'), // TODO: remove later
// 			documents: app.getPath('documents'),
// 			appData: app.getPath('appData'),
// 			appPath: pathJoin(app.getPath('appData'), configs.appName),
// 			userData: pathJoin(app.getPath('appData'), configs.appName),
// 			// app.getPath('userData'),
// 	  }
// 	: {};
// console.log('tst: systemPaths', systemPaths);

console.log('tst: window.elApi.sys.paths', window.elApi?.sys?.paths);
