import React from 'react'
import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import useMenu from 'shared/hooks/use-menu'
import DeleteRoleMenuItem from './DeleteRoleMenuItem'

type ManageRoleMenuProps = {
  disabledDelete?: boolean;
  actionDelete: () => void;
}

export default function ManageRoleMenu({ disabledDelete, actionDelete}: ManageRoleMenuProps): JSX.Element {
  const { anchorEl, open, handleOpen, handleClose } = useMenu();

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <MoreVert fontSize="small"/>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DeleteRoleMenuItem
          disabled={disabledDelete}
          action={actionDelete}
          callback={handleClose}
        />
      </Menu>
    </>
  );
}
