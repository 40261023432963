import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { PlaylistAddCheck } from '@mui/icons-material';
import { useCallback, useMemo } from 'react';
import { DocTypeFiles } from 'shared/constants/docs';
import { useProjectContext } from 'shared/_pages/project/provider';

type Props = {
	type?: DocTypeFiles;
	handleClose: () => void;
};

export default function SelectFilesMenuItem({ type, handleClose }: Props) {
	const { files, selectedFileIds, setSelectedFileIds } = useProjectContext();

	const groupFiles = useMemo(() => {
		return type ? files.filter((f) => f.type === type) : files;
	}, [files, type]);

	const handleSelect = useCallback(
		(isSelect: boolean) => {
			setSelectedFileIds(undefined, isSelect);
			handleClose();
		},
		[setSelectedFileIds, handleClose]
	);

	const isSelectAll = selectedFileIds.length !== files.length;

	return (
		<MenuItem onClick={() => handleSelect(isSelectAll)} disabled={!groupFiles.length}>
			<ListItemIcon>
				<PlaylistAddCheck fontSize="small" />
			</ListItemIcon>
			<ListItemText>{isSelectAll ? 'Select all' : 'Unselect all'}</ListItemText>
		</MenuItem>
	);
}
