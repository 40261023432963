import cn from 'classnames';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonNew } from 'shared/components/ButtonNew';
import Input from 'shared/components/Input/Input';
import Spinner from 'shared/components/Spinner/Spinner';
import { useUsersStats } from 'shared/helpers/use-stats-hook';
import { useUsersManagement } from 'shared/helpers/use-users-management-hook';
import { apiActivateUser, apiDeactivateUser } from 'shared/modules/user/actions';
import { PageContainer } from 'shared/Pages/common/PageContainer';
import styles from 'shared/Pages/common/Stats/stats.module.scss';


type TRow = {
  isActive?: boolean
  userId: string
  name?: string
  email?: string
  lastLogin?: string
  lastProjectCreated?: string
  loginCount?: string
  projectCount?: string
  totalSize?: string
  finishAudioSize?: string
  finishVideoSize?: string
};
type TRowProps = TRow & {
  edit: boolean
  isActiveComponent?: ReactNode
}
type TRowTotalsProps = {
  val: string
  title: string
}

// const initialStatisticRow: Omit<TRow, 'userId'> = {};


const Row: React.FC<TRowProps> = (props) => {
  const {
    edit,
    isActive,
    isActiveComponent,
    userId,
    name,
    email,
    lastLogin,
    lastProjectCreated,
    loginCount,
    projectCount,
    totalSize,
    finishAudioSize,
    finishVideoSize,
  } = props;

  return (
    <div className={cn(styles.row, {
      [styles.disabled]: !isActive
    })}>
      {edit && <div hidden={!edit} className={styles.switch}>{isActiveComponent}</div>}
      {/*<div hidden={!edit}>{isActive}</div>*/}
      <div className={styles.tableGrid}>
        <div>{userId}</div>
        <div>{name}</div>
        <div>{email}</div>
        <div>{lastLogin}</div>
        <div>{lastProjectCreated}</div>
        <div>{loginCount}</div>
        <div>{projectCount}</div>
        <div>{totalSize ?? '-'}</div>
        <div>{finishAudioSize ?? '-'}</div>
        <div>{finishVideoSize ?? '-'}</div>
      </div>
    </div>
  );
  // return (
  //   <div className={cn(styles.row, {
  //     [styles.rowView]: !edit
  //   })}>
  //     {edit && <div>{isActive}</div>}
  //     <div>{userId}</div>
  //     <div>{name}</div>
  //     <div>{email}</div>
  //     <div>{lastLogin}</div>
  //     <div>{lastProjectCreated}</div>
  //     <div>{loginCount}</div>
  //     <div>{projectCount}</div>
  //     <div>{totalSize ?? '-'}</div>
  //     <div>{finishAudioSize ?? '-'}</div>
  //     <div>{finishVideoSize ?? '-'}</div>
  //   </div>
  // );
}
const RowTotals: React.FC<TRowTotalsProps> = ({ val, title }) => (
  <div className={styles.rowTotal}>
    <div>{val}</div>
    <div>{title}</div>
  </div>
)


export const Stats: React.FC = () => {
  const dispatch = useDispatch();
  const activateUser = useCallback(async (userId: string) => {
    await apiActivateUser(userId);
  }, [dispatch]);
  const deactivateUser = useCallback(async (userId: string) => {
    await dispatch(apiDeactivateUser(userId));
  }, [dispatch]);

  const [table, setTable] = useState<TRow[]>([]);
  const [submittingIds, setSubmittingIds] = useState<string[]>([]);

  const { users, readUser } = useUsersManagement();
  const { stats, totalStats } = useUsersStats();

  useEffect(() => {
    setTable(t => users.map(u => {
      const row = t.find(row => row.userId === u.user_id);
      return {
        ...(row ?? {}),
        userId: u.user_id,
        isActive: u.is_active,
        name: u.name,
        email: u.email,
      }
    }));
  }, [users]);

  const [edit, setEdit] = useState(false);
  const handleEdit = useCallback(() => {
    setEdit(s => !s);
  }, []);


  const toggleUserActive = useCallback(async (userId: string) => {
    const row = table.find(row => row.userId === userId);
    if (row) {
      setSubmittingIds(s => [...s, userId]);

      try {
        if (row.isActive) {
          await deactivateUser(userId);
        } else {
          await activateUser(userId);
        }
        await readUser(userId);
      } finally {
        setSubmittingIds(s => s.filter(id => id !== userId));
      }
    }
  }, [table, activateUser, deactivateUser, readUser]);

  const actions = useMemo(() => {
    return (
      <ButtonNew onClick={handleEdit}>
        {edit ? 'View' : 'Edit'}
      </ButtonNew>
    );
  }, [edit, handleEdit]);

  return (
    <PageContainer title="Stats" actions={actions}>
      <h3>
        Totals
      </h3>
      {!totalStats.length && (
        <div className={styles.spinner}>
          <Spinner big full darkBackground />
        </div>
      )}
      {totalStats.map(row => <RowTotals key={row.key} val={row.val} title={row.title} />)}
      <h3>
        Users
      </h3>
      {users.length ? (<>
        <strong>
          <Row edit={edit} {...{
            isActive: true,
            isActiveComponent: 'Is Active',
            userId: 'User Id',
            name: 'Name',
            email: 'eMail',
            lastLogin: 'Last Login',
            lastProjectCreated: 'Last Project',
            loginCount: 'Login Count',
            projectCount: 'Project Count',
            totalSize: 'Total',
            finishAudioSize: 'Finish Audio',
            finishVideoSize: 'Finish Video',
          }} />
        </strong>
        {table
          .filter(row => (edit || row.isActive))
          .map((row) => (
            <Row key={row.userId} edit={edit} {...{
              isActiveComponent: (
                <Input
                  field={{
                    name: `isActive_${row.userId}`,
                    value: row.isActive,
                    onChange: () => toggleUserActive(row.userId),
                  }}
                  type={Input.TYPE.SWITCH}
                  labelWidth={Input.LABEL_WIDTH.RIGHT}
                  spaceBottom={Input.SPACE_BOTTOM.NONE}
                  readOnly={submittingIds.includes(row.userId)}
                  wrapperClassName={styles.switchWrapper}
                />
              ),
              ...row,
              ...(stats[row.userId] ?? {}),
            }} />
          ))}
      </>) : (
        <div className={styles.spinner}>
          <Spinner big full darkBackground />
        </div>
      )}
    </PageContainer>
  );
}
