import PouchDB, { getDbName } from 'app/pouch-db';
// @tsnocheck
import configs from '../constants/configs';
import { ENDPOINTS } from '../constants/endpoints';
import { api, emitUserError } from '../constants/globals';
import { actionSetLocalDB, actionSetRemoteDB, actionSetSyncHandler } from '../modules/db/dbActions';
import { actionAuthError } from '../modules/general';
import { getDatabaseId } from '../utils/webSettings';
import { closeLocalDB, closeRemoteDB } from './dbCloseUtils';
import { cancelSyncDB, syncOnChange } from './dbSyncUtils';
import { isWeb } from '../constants/modules';

export const getUserDB = () => (_, getState) => {
    const { db: { userDB } } = getState();
    return userDB;
};
export const getRemoteDB = () => (_, getState) => {
    const { db: { remoteDB } } = getState();
    return remoteDB;
};
export const getSyncHandler = () => (_, getState) => {
    const { db: { syncHandler } } = getState();
    return syncHandler;
};


export const dbSyncInit = () => (dispatch) => {
    const userDB = dispatch(getUserDB());
    const remoteDB = dispatch(getRemoteDB());
    const opts = {
        live: true,
        retry: true
    };

    return userDB.sync(remoteDB, opts)
        .on('change', (change) => {
            /*  change object example:
            {
              direction: "pull",
              change: {
                doc_write_failures: 0,
                docs: [{
                  created: "2019-11-12T14:59:30.920Z",
                  meta: {type: ""},
                  projectId: "PRJu0dj3aSKHdyJf",
                  sharing: [{}],
                  size: 865,
                  title: "tmpE937.java",
                  type: "file-project",
                  updated: "2019-11-12T14:59:30.920Z",
                  _deleted: true,
                  _id: "FPRu0dj3aEUsX1X3",
                  _rev: "3-097b665bf1664b37bff54b08d24d06f3",
                  _revisions: {start: 3, ids: Array(3)},
                }],
                docs_read: 2,
                docs_written: 2,
                errors: [],
                last_seq: "5624-g1AAAAI7eJzLYWBg4MhgTmHgzcvPy09JdcjLz8gvLskBCjM.......bGrSA",
                ok: true,
                pending: 0,
                start_time: "2020-03-02T09:18:46.555Z",
              },
            }
            */
            console.log('Sync change', change);
            // TODO: Temporary off
            dispatch(syncOnChange(change));
        })
        // .on('paused', (err) => { // replication paused (e.g. replication up to date, user went offline)
        //     console.info('sync paused', err);
        // })
        // .on('active', () => dispatch(syncOnActive()))
        .on('denied', (err) => { // a document failed to replicate (e.g. due to permissions)
            console.info('sync denied', { err });
        })
        .on('complete', (
            // info
        ) => { // handle complete
            // console.info('sync complete', {info});
        })
        .on('error', (err) => {
            console.info('sync error', err);
        });
};
export const initSyncDB = () => async (dispatch) => {
    await dispatch(cancelSyncDB());

    const userDB = dispatch(getUserDB());
    const remoteDB = dispatch(getRemoteDB());

    const func = async () => new Promise((resolve) => {
        // do one way, one-off sync from the server until completion
        userDB.replicate.from(remoteDB)
            // .on('change', info => dispatch(syncOnChange({change: info, direction: 'pull'}))) // TODO: remove
            .on('complete', (
                // info
            ) => {
                // then two-way, continuous, retryable sync
                const syncHandler = dispatch(dbSyncInit());
                dispatch(actionSetSyncHandler(syncHandler));

                resolve(true);
            });
    });

    try {
        return await func();
    } catch (e) {
        dispatch(actionAuthError(e));
        return false;
    }
};


const printDbInfo = async (db) => {
    try {
        const info = await db.info();
        console.log('connected to', info);
        return true;
    } catch (e) {
        emitUserError(e, 'db info error');
    }
    return false;
};
const connectToDB = async (name, opts = undefined) => {
    let db;
    try {
        db = new PouchDB(name, opts);
    } catch (e) {
        emitUserError(e, 'Connect to DB');
    }

    if (db) {
        if (await printDbInfo(db)) { // need to call db.info() for remote DB to create it if it absent
            return db;
        }
        try {
            await db.close();
        } catch (e) {
            // nothing
        }
    }

    return null;
};

export const initLocalDB = () => async (dispatch) => {
    await dispatch(closeLocalDB());

    const databaseId = getDatabaseId();

    const dbUrl = isWeb ? `DB/${databaseId}` : await getDbName(databaseId);
    const db = await connectToDB(dbUrl);

    if (db) {
        dispatch(actionSetLocalDB(db));

        db.changes({
            since: 'now',
            live: true,
            include_docs: true
        })
            .on('change', (
                // change
            ) => {
                // console.log('User DB change', change);
                /* change object example:
                {
                  id: "FPRu0dj3adKoaB1H",
                  seq: 3841,
                  changes: [ { rev: "3-c769f34399a647df9fa507082cba90e6" } ],
                  doc: { // changed doc. file object example:
                    _id: "FPRu0dj3adKoaB1H",
                    _rev: "3-c769f34399a647df9fa507082cba90e6",
                    cloud: {s3: {…}},
                    created: "2019-11-12T14:53:20.128Z",
                    meta: {type: "text/plain"},
                    projectId: "PRJu0dj3aSKHdyJf",
                    sharing: [{…}],
                    size: 5094,
                    title: "2.txt",
                    type: "file-project",
                    updated: "2019-11-19T14:12:16.611Z",
                  },
                }
                */
                // TODO: Temporary off
                // setTimeout(() => dispatch(syncChanges(change)), 1000);
            })
            .on('complete', (info) => {
                // changes() was canceled
                console.log('complete', { info });
            })
            .on('error', (err) => {
                console.log('error', err);
            });
    } else {
        dispatch(actionAuthError());
    }
    return !!dispatch(getUserDB());
};


const fetchCouchConnectionHeaders = async () => {
    try {
        const couchHeaders = await api.apiGet(ENDPOINTS.couch.getSessionCookies);
        // console.log('couchHeaders', couchHeaders);
        localStorage.setItem('couch/headers', JSON.stringify(couchHeaders));
    } catch (e) {
        console.error('Get couch headers', e);
    }
};
export const getCouchConnectionHeaders = () => {
    const couchHeadersStr = localStorage.getItem('couch/headers');
    return couchHeadersStr ? JSON.parse(couchHeadersStr) : {};
};
export const setCouchConnectionHeaders = (opts) => {
    const couchHeaders = getCouchConnectionHeaders();
    Object.keys(couchHeaders).forEach(key => opts.headers.set(key, couchHeaders[key]));
    return opts;
};

export const initRemoteDB = () => async (dispatch) => {
    await dispatch(closeRemoteDB());

    await fetchCouchConnectionHeaders();
    setInterval(fetchCouchConnectionHeaders, configs.couchDbSessionTimeout * 1000);

    const dbUrl = `${configs.couchDbUri}/${getDatabaseId()}`;
    const db = await connectToDB(dbUrl, {
        fetch: (url, opts) => {
          return PouchDB.fetch(url, setCouchConnectionHeaders(opts))
            .then(res => {
              return res;
            })
            .catch(e => {
              console.error('fetch e', e);
            });
        }
    });

    if (db) {
        dispatch(actionSetRemoteDB(db));
    } else {
        dispatch(actionAuthError());
    }

    const res = !!getRemoteDB();

    return res
};
