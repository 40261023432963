import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../../constants/routes_old';
import styles from './RegisterSuccess.module.css';

const RegisterSuccess = () => (
    <div className={styles.wrapper}>
        <h2>
            Please confirm your email address
            <br />
            to complete Registration.
        </h2>
        <p>
            Please check your inbox for an email to be able to
            {' '}
            <Link to={ROUTE.login}>login</Link>
        </p>
    </div>
);

export default RegisterSuccess;
