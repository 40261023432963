import React, { FC, useMemo } from 'react';
import { isWeb } from 'shared/constants/modules';
import { r } from 'shared/constants/routes';
import { ROUTE } from 'shared/constants/routes_old';
import { AllProviders } from 'shared/Providers';
import { isSharing } from 'shared/utils/webSettings';
import SharedApp from 'shared/Pages/Shared/App/SharedApp';
import Home from 'shared/Pages/Site/Home';
import { Route, Switch } from 'react-router-dom';
import SyncLayout from 'shared/Pages/common/SyncLayout/SyncLayout';
import UserEventLayout from 'shared/Pages/common/UserEventLayout/UserEventLayout';
import { LoggedApp } from 'shared/Pages/common/App/LoggedApp';

export const App: FC = () => {
	const routes = useMemo(() => {
		return [
			...(isWeb ? [<Route key="home" exact path={r.home.path} component={Home} />] : []),
			...(isSharing()
				? [<Route key="sharing" path={ROUTE.share.id()} component={SharedApp} />]
				: []),
			<Route key="LoggedApp">
				<AllProviders>
					<LoggedApp />
				</AllProviders>
			</Route>,
		];
	}, []);

	// console.log('tst: App', { isSharing: isSharing(), routes });
	return (
		<>
			<UserEventLayout />
			<Switch>{routes}</Switch>
			<SyncLayout />
		</>
	);
};
