import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Search } from 'shared/components/Search';
import {
  DOC_TYPE_FILE_FINISH_AUDIO,
  DOC_TYPE_FILE_FINISH_VIDEO,
  DOC_TYPE_PROJECT,
  DOC_TYPE_SERIES, TDocumentWithTitle
} from 'shared/constants/docs';
import { ROUTE } from 'shared/constants/routes_old';
import { quickSearch } from 'shared/handlers/userDB';
import { useFilter } from 'shared/helpers/use-filter';
import { UserContext } from 'shared/Providers/User';
import styles from 'shared/Pages/common/Header/QuickSearch/quick-search.module.scss';


const ITEMS = [
  { key: DOC_TYPE_PROJECT, title: 'Projects' },
  { key: DOC_TYPE_SERIES, title: 'Series' },
  { key: DOC_TYPE_FILE_FINISH_VIDEO, title: 'Videos' },
  { key: DOC_TYPE_FILE_FINISH_AUDIO, title: 'Audio' },
];

const getItemLink = (item: TDocumentWithTitle) => {
  switch (item.type) {
    case DOC_TYPE_PROJECT:
      return ROUTE.projects.id(item._id);
    case DOC_TYPE_SERIES:
      return ROUTE.series.id(item._id);
    case DOC_TYPE_FILE_FINISH_VIDEO:
      return ROUTE.videos.id(item._id);
    case DOC_TYPE_FILE_FINISH_AUDIO:
      return ROUTE.audios.id(item._id);
    default:
      return '#';
  }
};


export const QuickSearch: React.FC = () => {
  const { permissions: { canUseApp } } = useContext(UserContext);

  const dispatch = useDispatch();

  const [showResults, setShowResults] = useState(false)
  const [results, setResults] = useState<Record<string, Array<TDocumentWithTitle>>>({})


  const action = useCallback(async (text: string) => {
    setResults(await dispatch(quickSearch(text)))
    setShowResults(true);
  }, [dispatch]);
  const resetAction = useCallback(() => {
    setResults({});
    setShowResults(false);
  }, []);

  const { value, loading, onChange, onBlur } = useFilter({ action, resetAction, autoReset: true });


  return (
    <Search
      inputProps={{
        placeholder: 'SEARCH',
        id: 'QuickSearch',
        size: '',
        readOnly: !canUseApp,
      }}
      loading={loading}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {showResults && (
        <div className={styles.results}>
          {ITEMS.map(({ key, title }) => {
            const items = results[key] || [];
            return (
              <div className={styles.group} key={key}>
                <h3>
                  {title}
                  <span className={styles.count}>
                        &nbsp;({items.length})
                      </span>
                </h3>
                {items.map(item => (
                  <Link to={getItemLink(item)} key={item._id}>{item.title}</Link>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </Search>
  )
}
