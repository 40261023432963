import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'shared/Providers/User';
import { ROUTE } from '../../../constants/routes_old';

export default function Logout() {
  const { logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to={ROUTE.login} />;
}
