import { MenuItem } from '@mui/material'
import React, { useCallback } from 'react'

type DeleteRoleMenuItemProps = {
  disabled?: boolean;
  action: () => void;
  callback: () => void;
}

export default function DeleteRoleMenuItem({ disabled = false, action, callback }: DeleteRoleMenuItemProps): JSX.Element {
  const onClick = useCallback(() => {
    action();
    if (callback) {
      callback();
    }
  }, [action, callback]);

  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      Delete
    </MenuItem>
  );
}
