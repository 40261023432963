import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useFormik } from 'formik'
import cn from 'classnames'
import {
  TextField,
  Chip,
  Box,
  TextFieldProps,
  FormControl,
  InputLabel,
  useTheme,
} from '@mui/material'
import { deepmerge } from '@mui/utils'

type TagFieldProps = TextFieldProps

function TagField({
  defaultValue,
  InputProps,
  onChange,
  sx,
  value,
  ...props
}: TagFieldProps) {
  const [tags, setTags] = useState<string[]>(
    Array.isArray(defaultValue) ? defaultValue : []
  )
  useEffect(() => {
    // Support controlled state
    if (value && Array.isArray(value)) {
      setTags(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Array.isArray(value) ? value?.join(',') : ''])

  const [inputValue, setInputValue] = useState('')

  const handleChange = useCallback(
    (newTags: string[]) => {
      setTimeout(() => {
        const event = {
          target: { name: props.name, value: newTags },
        } as unknown as ChangeEvent<HTMLInputElement>
        onChange?.(event)
      }, 1)
    },
    [props.name, onChange]
  )

  const addTag = useCallback(
    (tag: string) => {
      const normalizedTag = tag.toLowerCase()
      setTags((old) => {
        const normalizedOld = old.map((t) => t.toLowerCase())
        if (normalizedTag && !normalizedOld.includes(normalizedTag)) {
          const newTags = [...old, tag]
          handleChange(newTags)
          return newTags
        }
        return old
      })
      setInputValue('')
    },
    [handleChange]
  )

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault()
      addTag(inputValue.trim())
    } else if (e.key === 'Backspace' && inputValue === '') {
      setTags((old) => old.slice(0, -1))
    }
  }

  const handleDelete = (tagToDelete: string) => {
    const normalizedTag = tagToDelete.toLowerCase()
    setTags((old) => {
      const newTags = old.filter((tag) => tag.toLowerCase() !== normalizedTag)
      handleChange(newTags)
      return newTags
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const theme = useTheme()

  return (
    <TextField
      placeholder="Type and press Enter or Comma"
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      InputProps={deepmerge(InputProps ?? {}, {
        startAdornment: tags.map((tag) => (
          <Chip key={tag} label={tag} onDelete={() => handleDelete(tag)} />
        )),
      })}
      sx={deepmerge(sx ?? {}, {
        '.MuiInputBase-root': {
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(1),
          padding: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          flex: 1,
          minWidth: '5em',
          display: InputProps?.readOnly ? 'none' : undefined,
        },
        '.MuiChip-deleteIcon': {
          display: InputProps?.readOnly ? 'none' : undefined,
        },
      })}
      {...props}
    />
  )
}

type StyledTextFieldProps = {
  labelProps: {
    width: string | number
  }
  readOnly?: boolean
  onReadOnlyClick?: () => void
}

function TagFieldStyled(props: TextFieldProps & StyledTextFieldProps) {
  const {
    className,
    labelProps,
    readOnly = false,
    onReadOnlyClick,
    onClick,
    ...fieldProps
  } = props
  const { InputProps, name, sx = {} } = fieldProps

  return (
    <TagField
      id={name}
      {...fieldProps}
      onClick={readOnly ? onReadOnlyClick ?? onClick : onClick}
      className={cn('field', className)}
      InputProps={{ readOnly, ...InputProps }}
      sx={deepmerge(sx, {
        '.MuiFormLabel-root': {
          ...(labelProps.width ? { width: labelProps.width } : {}),
        },
      })}
    />
  )
}

type CommonTextFieldProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'onBlur' | 'error' | 'helperText'
> &
  StyledTextFieldProps

type FormTagFieldProps = CommonTextFieldProps & {
  formik: ReturnType<typeof useFormik>
  readOnly?: boolean
  name: string
}

type FieldTagFieldProps = Pick<
  TextFieldProps,
  'className' | 'name' | 'label' | 'sx' | 'fullWidth'
> &
  StyledTextFieldProps & {
    value?: string[]
  }

export function FieldTagField(props: FieldTagFieldProps) {
  const { className, labelProps, fullWidth, ...fieldProps } = props
  const { label, name, sx, value } = fieldProps

  const sxLabel = {
    ...(sx?.['.MuiFormLabel-root' as keyof typeof sx] ?? {}),
    ...(labelProps.width ? { width: labelProps.width } : {}),
  }

  return (
    <FormControl
      className={cn('field', className)}
      sx={{ flexDirection: 'row' }}
      {...fieldProps}>
      <InputLabel htmlFor={name} sx={sxLabel}>
        {label}
      </InputLabel>
      <Box
        display="inline-flex"
        gap={1}
        flexWrap="wrap"
        width={fullWidth ? '100%' : undefined}>
        {value?.map((tag) => (
          <Chip key={tag} label={tag} />
        ))}
      </Box>
      {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
    </FormControl>
  )
}

export function FormTagField({ formik, ...props }: FormTagFieldProps) {
  const { name } = props

  const helperText =
    formik.touched[name] &&
    (formik.errors[name] as TextFieldProps['helperText'])

  return (
    <TagFieldStyled
      id={name}
      {...props}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={helperText}
    />
  )
}
