import ApiError from './api-error'
import useApiSimple, { TApiError } from "./useApiSimple"


export type TRCouchGetSessionCookiesSuccess = {
  ['Set-Cookie']: string;
}
export type TCCouchGetSessionCookies = {
  cookie: string;
  expires: number | undefined;
  session: string;
}

type TRCouchGetSessionCookies = TRCouchGetSessionCookiesSuccess | TApiError

const getCookieVal = (cookieString: string, key: string): string => {
  return cookieString.split(';')
    .find((v) => v.toLowerCase().includes(`${key.toLowerCase()}=`))
    ?.split('=')[1].trim() ?? '';
};

const validate = (response?: TRCouchGetSessionCookies): response is TRCouchGetSessionCookiesSuccess =>
  !!(response && ('Set-Cookie' in response) && response?.['Set-Cookie']);

const convert = (response?: TRCouchGetSessionCookies): TCCouchGetSessionCookies | undefined => {
  if (validate(response)) {
    // AuthSession=bWVlZGk6NjQyRDRDQ0E6zFWwy5I7_HRW-C8e2uPBkMh169w; Version=1; Expires=Wed, 05-Apr-2023 12:06:18 GMT; Max-Age=6000; Path=/
    const cookie = response['Set-Cookie'].replace('; HttpOnly', '');
    const authSession = getCookieVal(cookie, 'AuthSession');
    const expires = new Date(getCookieVal(cookie, 'Expires')).getTime();
    const maxAge = parseInt(getCookieVal(cookie, 'Max-Age'), 10);
    return {
      cookie,
      expires: expires || maxAge ? Date.now() + 1000 * maxAge : undefined,
      session: `AuthSession=${authSession}`,
    };
  }
  return undefined;
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApiCouchGetSessionCookies(onError?: (error: ApiError) => void) {
  const { response, error, loading, doFetch } = useApiSimple<TRCouchGetSessionCookies, TCCouchGetSessionCookies>(
    { slug: '/api/couch/get-session-cookies', lazy: true },
    { validate, convert, onError }
  );

  return {
    headers: response,
    error,
    loading,
    doCouchGetSessionHeaders: doFetch,
  };
}
